@mixin calc($property, $expression) {
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -o-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

@mixin calc-important($property, $expression) {
  #{$property}: -webkit-calc(#{$expression}) !important;
  #{$property}: -moz-calc(#{$expression}) !important;
  #{$property}: -o-calc(#{$expression}) !important;
  #{$property}: calc(#{$expression}) !important;
}
