@charset "UTF-8";
:root {
    --kendo-color-app-surface: #ffffff;
    --kendo-color-on-app-surface: #3d3d3d;
    --kendo-color-subtle: #666666;
    --kendo-color-surface: #fafafa;
    --kendo-color-surface-alt: #ffffff;
    --kendo-color-border: rgba(0, 0, 0, 0.08);
    --kendo-color-border-alt: rgba(0, 0, 0, 0.16);
    --kendo-color-base-subtle: #ebebeb;
    --kendo-color-base-subtle-hover: #e0e0e0;
    --kendo-color-base-subtle-active: #d6d6d6;
    --kendo-color-base: #f5f5f5;
    --kendo-color-base-hover: #ebebeb;
    --kendo-color-base-active: #d6d6d6;
    --kendo-color-base-emphasis: #c2c2c2;
    --kendo-color-base-on-subtle: #3d3d3d;
    --kendo-color-on-base: #3d3d3d;
    --kendo-color-base-on-surface: #3d3d3d;
    --kendo-color-primary-subtle: #d5def5;
    --kendo-color-primary-subtle-hover: #b5c6ed;
    --kendo-color-primary-subtle-active: #96ade6;
    --kendo-color-primary: rgb(44, 91, 204);
    --kendo-color-primary-hover: #2a57c4;
    --kendo-color-primary-active: #2750b4;
    --kendo-color-primary-emphasis: #6184d9;
    --kendo-color-primary-on-subtle: #0f2047;
    --kendo-color-on-primary: white;
    --kendo-color-primary-on-surface: #214499;
    --kendo-color-secondary-subtle: #fafafa;
    --kendo-color-secondary-subtle-hover: #f5f5f5;
    --kendo-color-secondary-subtle-active: #ebebeb;
    --kendo-color-secondary: #666666;
    --kendo-color-secondary-hover: #525252;
    --kendo-color-secondary-active: #3d3d3d;
    --kendo-color-secondary-emphasis: #e0e0e0;
    --kendo-color-secondary-on-subtle: #141414;
    --kendo-color-on-secondary: #ffffff;
    --kendo-color-secondary-on-surface: #292929;
    --kendo-color-tertiary-subtle: #d8f1fd;
    --kendo-color-tertiary-subtle-hover: #c5eafc;
    --kendo-color-tertiary-subtle-active: #a3dffb;
    --kendo-color-tertiary: #03a9f4;
    --kendo-color-tertiary-hover: #039ae0;
    --kendo-color-tertiary-active: #028ccb;
    --kendo-color-tertiary-emphasis: #61c9f9;
    --kendo-color-tertiary-on-subtle: #023f5c;
    --kendo-color-on-tertiary: #ffffff;
    --kendo-color-tertiary-on-surface: #028ccb;
    --kendo-color-info-subtle: #d2e2fb;
    --kendo-color-info-subtle-hover: #bdd4f8;
    --kendo-color-info-subtle-active: #80acf4;
    --kendo-color-info: #0058e9;
    --kendo-color-info-hover: #0052d6;
    --kendo-color-info-active: #004ac2;
    --kendo-color-info-emphasis: #6098f2;
    --kendo-color-info-on-subtle: #002259;
    --kendo-color-on-info: #ffffff;
    --kendo-color-info-on-surface: #004ac2;
    --kendo-color-success-subtle: #dcf0d3;
    --kendo-color-success-subtle-hover: #cbe9bf;
    --kendo-color-success-subtle-active: #b7e1a5;
    --kendo-color-success: #37b400;
    --kendo-color-success-hover: #32a500;
    --kendo-color-success-active: #2d9600;
    --kendo-color-success-emphasis: #81d15f;
    --kendo-color-success-on-subtle: #1c5a00;
    --kendo-color-on-success: #ffffff;
    --kendo-color-success-on-surface: #2d9600;
    --kendo-color-warning-subtle: #fff4d3;
    --kendo-color-warning-subtle-hover: #ffeebd;
    --kendo-color-warning-subtle-active: #ffe79e;
    --kendo-color-warning: #ffc000;
    --kendo-color-warning-hover: #eaaf00;
    --kendo-color-warning-active: #d49f00;
    --kendo-color-warning-emphasis: #ffd760;
    --kendo-color-warning-on-subtle: #5e4700;
    --kendo-color-on-warning: #3d3d3d;
    --kendo-color-warning-on-surface: #ffc000;
    --kendo-color-error-subtle: #fcddda;
    --kendo-color-error-subtle-hover: #fbc8c3;
    --kendo-color-error-subtle-active: #f98b80;
    --kendo-color-error: #f31700;
    --kendo-color-error-hover: #df1600;
    --kendo-color-error-active: #ca1400;
    --kendo-color-error-emphasis: #f76f60;
    --kendo-color-error-on-subtle: #7a0c00;
    --kendo-color-on-error: #ffffff;
    --kendo-color-error-on-surface: #ca1400;
    --kendo-color-light-subtle: #fafafa;
    --kendo-color-light-subtle-hover: #f5f5f5;
    --kendo-color-light-subtle-active: #ebebeb;
    --kendo-color-light: #ebebeb;
    --kendo-color-light-hover: #e0e0e0;
    --kendo-color-light-active: #d6d6d6;
    --kendo-color-light-emphasis: #d6d6d6;
    --kendo-color-light-on-subtle: #141414;
    --kendo-color-on-light: #000000;
    --kendo-color-light-on-surface: #e0e0e0;
    --kendo-color-dark-subtle: #c2c2c2;
    --kendo-color-dark-subtle-hover: #adadad;
    --kendo-color-dark-subtle-active: #999999;
    --kendo-color-dark: #3d3d3d;
    --kendo-color-dark-hover: #292929;
    --kendo-color-dark-active: #1f1f1f;
    --kendo-color-dark-emphasis: #666666;
    --kendo-color-dark-on-subtle: #1f1f1f;
    --kendo-color-on-dark: #ffffff;
    --kendo-color-dark-on-surface: #141414;
    --kendo-color-inverse-subtle: #c2c2c2;
    --kendo-color-inverse-subtle-hover: #adadad;
    --kendo-color-inverse-subtle-active: #999999;
    --kendo-color-inverse: #3d3d3d;
    --kendo-color-inverse-hover: #292929;
    --kendo-color-inverse-active: #1f1f1f;
    --kendo-color-inverse-emphasis: #666666;
    --kendo-color-inverse-on-subtle: #1f1f1f;
    --kendo-color-on-inverse: #ffffff;
    --kendo-color-inverse-on-surface: #141414;
    --kendo-color-series-a: #ff6358;
    --kendo-color-series-a-bold: #bf4a42;
    --kendo-color-series-a-bolder: #80322c;
    --kendo-color-series-a-subtle: #ffb1ac;
    --kendo-color-series-a-subtler: #ff8a82;
    --kendo-color-series-b: #ffe162;
    --kendo-color-series-b-bold: #bfa94a;
    --kendo-color-series-b-bolder: #807131;
    --kendo-color-series-b-subtle: #fff0b1;
    --kendo-color-series-b-subtler: #ffe989;
    --kendo-color-series-c: #4cd180;
    --kendo-color-series-c-bold: #399d60;
    --kendo-color-series-c-bolder: #266940;
    --kendo-color-series-c-subtle: #a6e8c0;
    --kendo-color-series-c-subtler: #79dda0;
    --kendo-color-series-d: #4b5ffa;
    --kendo-color-series-d-bold: #3847bc;
    --kendo-color-series-d-bolder: #26307d;
    --kendo-color-series-d-subtle: #a5affd;
    --kendo-color-series-d-subtler: #7887fb;
    --kendo-color-series-e: #ac58ff;
    --kendo-color-series-e-bold: #8142bf;
    --kendo-color-series-e-bolder: #562c80;
    --kendo-color-series-e-subtle: #d6acff;
    --kendo-color-series-e-subtler: #c182ff;
    --kendo-color-series-f: #ff5892;
    --kendo-color-series-f-bold: #bf426e;
    --kendo-color-series-f-bolder: #802c49;
    --kendo-color-series-f-subtle: #ffacc9;
    --kendo-color-series-f-subtler: #ff82ae;
}

.k-sr-only {
    position: absolute;
    left: -1px;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.k-push-right-enter,
.k-push-right-appear {
    transform: translate(-100%, 0);
}
.k-push-right-enter-active,
.k-push-right-appear-active {
    transform: translate(0, 0);
    transition: transform 300ms ease-in-out;
}
.k-push-right-exit {
    transform: translate(0, 0);
}
.k-push-right-exit-active {
    transform: translate(100%, 0);
    transition: transform 300ms ease-in-out;
}

.k-push-left-enter,
.k-push-left-appear {
    transform: translate(100%, 0);
}
.k-push-left-enter-active,
.k-push-left-appear-active {
    transform: translate(0, 0);
    transition: transform 300ms ease-in-out;
}
.k-push-left-exit {
    transform: translate(0, 0);
}
.k-push-left-exit-active {
    transform: translate(-100%, 0);
    transition: transform 300ms ease-in-out;
}

.k-push-down-enter,
.k-push-down-appear {
    transform: translate(0, -100%);
}
.k-push-down-enter-active,
.k-push-down-appear-active {
    transform: translate(0, 0);
    transition: transform 300ms ease-in-out;
}
.k-push-down-exit {
    transform: translate(0, 0);
}
.k-push-down-exit-active {
    transform: translate(0, 100%);
    transition: transform 300ms ease-in-out;
}

.k-push-up-enter,
.k-push-up-appear {
    transform: translate(0, 100%);
}
.k-push-up-enter-active,
.k-push-up-appear-active {
    transform: translate(0, 0);
    transition: transform 300ms ease-in-out;
}
.k-push-up-exit {
    transform: translate(0, 0);
}
.k-push-up-exit-active {
    transform: translate(0, -100%);
    transition: transform 300ms ease-in-out;
}

.k-expand-vertical-enter,
.k-expand-vertical-appear {
    transform: scaleY(0);
}
.k-expand-vertical-enter-active,
.k-expand-vertical-appear-active {
    transform: scaleY(1);
    transition: transform 300ms ease-in-out;
}
.k-expand-vertical-exit {
    transform: scaleY(1);
}
.k-expand-vertical-exit-active {
    transform: scaleY(0);
    transition: transform 300ms ease-in-out;
}

.k-expand-horizontal-enter,
.k-expand-horizontal-appear {
    transform: scaleX(0);
}
.k-expand-horizontal-enter-active,
.k-expand-horizontal-appear-active {
    transform: scaleX(1);
    transition: transform 300ms ease-in-out;
}
.k-expand-horizontal-exit {
    transform: scaleX(1);
}
.k-expand-horizontal-exit-active {
    transform: scaleX(0);
    transition: transform 300ms ease-in-out;
}

.k-fade-enter,
.k-fade-appear {
    opacity: 0;
}
.k-fade-enter-active,
.k-fade-appear-active {
    opacity: 1;
    transition: opacity 500ms ease-in-out;
}
.k-fade-exit {
    opacity: 1;
}
.k-fade-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
}
.k-fade-exit-active + .k-fade-exit-active,
.k-fade-enter-active + .k-fade-enter-active {
    display: none;
}

.k-zoom-in-enter,
.k-zoom-in-appear {
    opacity: 0;
    transform: scale(0);
}
.k-zoom-in-enter-active,
.k-zoom-in-appear-active {
    opacity: 1;
    transform: scale(1);
    transition:
        transform,
        opacity 300ms ease-in-out;
}
.k-zoom-in-exit {
    opacity: 1;
    transform: scale(1);
}
.k-zoom-in-exit-active {
    opacity: 0;
    transform: scale(2);
    transition:
        transform,
        opacity 300ms ease-in-out;
}

.k-zoom-out-enter,
.k-zoom-out-appear {
    opacity: 0;
    transform: scale(2);
}
.k-zoom-out-enter-active,
.k-zoom-out-appear-active {
    opacity: 1;
    transform: scale(1);
    transition:
        transform,
        opacity 300ms ease-in-out;
}
.k-zoom-out-exit {
    opacity: 1;
    transform: scale(1);
}
.k-zoom-out-exit-active {
    opacity: 0;
    transform: scale(0);
    transition:
        transform,
        opacity 300ms ease-in-out;
}

.k-slide-in-appear {
    opacity: 0.1;
    transform: translate(0, -3em);
}
.k-slide-in-appear .k-centered {
    transform: translate(-50%, -60%);
}
.k-slide-in-appear-active {
    opacity: 1;
    transform: translate(0, 0);
    transition:
        transform 0.3s cubic-bezier(0.2, 0.6, 0.4, 1),
        opacity 0.3s cubic-bezier(0.2, 1, 0.2, 1);
}
.k-slide-in-appear-active .k-centered {
    transform: translate(-50%, -50%);
}

.k-slide-down-enter,
.k-slide-down-appear {
    transform: translateY(-100%);
}
.k-slide-down-enter-active,
.k-slide-down-appear-active {
    transform: translateY(0);
    transition: transform 300ms ease-in-out;
}
.k-slide-down-exit {
    transform: translateY(0);
}
.k-slide-down-exit-active {
    transform: translateY(-100%);
    transition: transform 300ms ease-in-out;
}

.k-slide-up-enter,
.k-slide-up-appear {
    transform: translateY(100%);
}
.k-slide-up-enter-active,
.k-slide-up-appear-active {
    transform: translateY(0);
    transition: transform 300ms ease-in-out;
}
.k-slide-up-exit {
    transform: translateY(0);
}
.k-slide-up-exit-active {
    transform: translateY(100%);
    transition: transform 300ms ease-in-out;
}

.k-slide-right-enter,
.k-slide-right-appear {
    transform: translateX(-100%);
}
.k-slide-right-enter-active,
.k-slide-right-appear-active {
    transform: translateX(0);
    transition: transform 300ms ease-in-out;
}
.k-slide-right-exit {
    transform: translateX(0);
}
.k-slide-right-exit-active {
    transform: translateX(-100%);
    transition: transform 300ms ease-in-out;
}

.k-slide-left-enter,
.k-slide-left-appear {
    transform: translateX(100%);
}
.k-slide-left-enter-active,
.k-slide-left-appear-active {
    transform: translateX(0);
    transition: transform 300ms ease-in-out;
}
.k-slide-left-exit {
    transform: translateX(0);
}
.k-slide-left-exit-active {
    transform: translateX(100%);
    transition: transform 300ms ease-in-out;
}

.k-reveal-vertical-enter,
.k-reveal-vertical-appear {
    max-height: 0;
}
.k-reveal-vertical-enter-active,
.k-reveal-vertical-appear-active {
    transition: max-height 300ms ease-in-out;
}
.k-reveal-vertical-exit-active {
    max-height: 0 !important;
    transition: max-height 300ms ease-in-out;
}

.k-reveal-horizontal-enter,
.k-reveal-horizontal-appear {
    max-width: 0;
}
.k-reveal-horizontal-enter-active,
.k-reveal-horizontal-appear-active {
    transition: max-width 300ms ease-in-out;
}
.k-reveal-horizontal-exit-active {
    max-width: 0 !important;
    transition: max-width 300ms ease-in-out;
}

.k-fx-end .k-fx-next,
.k-fx-end .k-fx-current {
    transition: all 350ms ease-out;
}

.k-fx {
    position: relative;
}
.k-fx .k-fx-current {
    z-index: 0;
}
.k-fx .k-fx-next {
    z-index: 1;
}

.k-fx-hidden,
.k-fx-hidden * {
    visibility: hidden !important;
}

.k-fx-reverse .k-fx-current {
    z-index: 1;
}
.k-fx-reverse .k-fx-next {
    z-index: 0;
}

.k-fx-zoom.k-fx-start .k-fx-next {
    transform: scale(0) !important;
}
.k-fx-zoom.k-fx-end .k-fx-next {
    transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-start .k-fx-next,
.k-fx-zoom.k-fx-reverse.k-fx-end .k-fx-next {
    transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-start .k-fx-current {
    transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-end .k-fx-current {
    transform: scale(0) !important;
}

.k-fx-fade.k-fx-start .k-fx-next {
    will-change: opacity;
    opacity: 0;
}
.k-fx-fade.k-fx-end .k-fx-next {
    opacity: 1;
}
.k-fx-fade.k-fx-reverse.k-fx-start .k-fx-current {
    will-change: opacity;
    opacity: 1;
}
.k-fx-fade.k-fx-reverse.k-fx-end .k-fx-current {
    opacity: 0;
}

.k-fx-slide.k-fx-end .k-fx-next .k-content,
.k-fx-slide.k-fx-end .k-fx-next .k-header,
.k-fx-slide.k-fx-end .k-fx-next .k-footer,
.k-fx-slide.k-fx-end .k-fx-current .k-content,
.k-fx-slide.k-fx-end .k-fx-current .k-header,
.k-fx-slide.k-fx-end .k-fx-current .k-footer,
.k-fx-slide.k-fx-end .k-fx-next .km-content,
.k-fx-slide.k-fx-end .k-fx-next .km-header,
.k-fx-slide.k-fx-end .k-fx-next .km-footer,
.k-fx-slide.k-fx-end .k-fx-current .km-content,
.k-fx-slide.k-fx-end .k-fx-current .km-header,
.k-fx-slide.k-fx-end .k-fx-current .km-footer {
    transition: all 350ms ease-out;
}
.k-fx-slide.k-fx-start .k-fx-next .k-content,
.k-fx-slide.k-fx-start .k-fx-next .km-content {
    will-change: transform;
    transform: translateX(100%);
}
.k-fx-slide.k-fx-start .k-fx-next .k-header,
.k-fx-slide.k-fx-start .k-fx-next .k-footer,
.k-fx-slide.k-fx-start .k-fx-next .km-header,
.k-fx-slide.k-fx-start .k-fx-next .km-footer {
    will-change: opacity;
    opacity: 0;
}
.k-fx-slide.k-fx-end .k-fx-current .k-content,
.k-fx-slide.k-fx-end .k-fx-current .km-content {
    transform: translateX(-100%);
}
.k-fx-slide.k-fx-end .k-fx-next .k-header,
.k-fx-slide.k-fx-end .k-fx-next .k-footer,
.k-fx-slide.k-fx-end .k-fx-next .km-header,
.k-fx-slide.k-fx-end .k-fx-next .km-footer {
    opacity: 1;
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .k-content,
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-content {
    will-change: transform;
    transform: translateX(0);
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .k-content,
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-content {
    transform: translateX(100%);
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .k-content,
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-content {
    transform: translateX(-100%);
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .k-content,
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-content {
    transform: translateX(0);
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .k-header,
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .k-footer,
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-header,
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-footer {
    will-change: opacity;
    opacity: 1;
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .k-header,
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .k-footer,
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-header,
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-footer {
    opacity: 1;
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .k-header,
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .k-footer,
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-header,
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-footer {
    opacity: 0;
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .k-header,
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .k-footer,
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-header,
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-footer {
    opacity: 1;
}
.k-fx-slide.k-fx-right.k-fx-start .k-fx-next .k-content,
.k-fx-slide.k-fx-right.k-fx-start .k-fx-next .km-content {
    transform: translateX(-100%);
}
.k-fx-slide.k-fx-right.k-fx-end .k-fx-current .k-content,
.k-fx-slide.k-fx-right.k-fx-end .k-fx-current .km-content {
    transform: translateX(100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current .k-content,
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current .km-content {
    transform: translateX(0);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current .k-content,
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current .km-content {
    transform: translateX(-100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next .k-content,
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next .km-content {
    transform: translateX(100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next .k-content,
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next .km-content {
    transform: translateX(0%);
}

.k-fx-tile.k-fx-start .k-fx-next {
    will-change: transform;
    transform: translateX(100%);
}
.k-fx-tile.k-fx-end .k-fx-current {
    transform: translateX(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-current {
    will-change: transform;
    transform: translateX(0);
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-current {
    transform: translateX(100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-next {
    transform: translateX(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-next {
    transform: translateX(0);
}
.k-fx-tile.k-fx-right.k-fx-start .k-fx-next {
    transform: translateX(-100%);
}
.k-fx-tile.k-fx-right.k-fx-end .k-fx-current {
    transform: translateX(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current {
    transform: translateX(0);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current {
    transform: translateX(-100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next {
    transform: translateX(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next {
    transform: translateX(0%);
}

.k-fx.k-fx-overlay.k-fx-start .k-fx-next,
.k-fx.k-fx-overlay.k-fx-left.k-fx-start .k-fx-next {
    will-change: transform;
    transform: translateX(100%);
}
.k-fx.k-fx-overlay.k-fx-right.k-fx-start .k-fx-next {
    transform: translateX(-100%);
}
.k-fx.k-fx-overlay.k-fx-up.k-fx-start .k-fx-next {
    transform: translateY(100%);
}
.k-fx.k-fx-overlay.k-fx-down.k-fx-start .k-fx-next {
    transform: translateY(-100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-start .k-fx-next {
    transform: none;
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-start .k-fx-current {
    will-change: transform;
    transform: none;
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-end .k-fx-current,
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-left.k-fx-end .k-fx-current {
    transform: translateX(100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-right.k-fx-end .k-fx-current {
    transform: translateX(-100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-up.k-fx-end .k-fx-current {
    transform: translateY(100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-down.k-fx-end .k-fx-current {
    transform: translateY(-100%);
}

.k-theme-test-class,
.k-common-test-class {
    opacity: 0;
}

.k-hstack,
.k-hbox {
    display: flex;
    flex-flow: row nowrap;
}

.k-vstack,
.k-vbox {
    display: flex;
    flex-flow: column nowrap;
}

.k-spacer,
.k-flex {
    flex: 1 1 auto;
}

.k-spacer-sized {
    flex: none;
}

.k-float-wrap::after,
.k-floatwrap::after {
    content: '';
    display: block;
    clear: both;
}

.k-flex-layout {
    display: flex;
}

.k-grid-layout {
    display: grid;
}

.k-separator {
    width: auto;
    height: 0;
    border-width: 1px 0 0;
    border-style: solid;
    border-color: inherit;
    display: block;
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
    align-self: stretch;
}

.k-separator-horizontal,
.k-vstack > .k-separator,
.k-vbox > .k-separator {
    width: auto;
    height: 0;
    border-width: 1px 0 0;
}

.k-separator-vertical,
.k-hstack > .k-separator,
.k-hbox > .k-separator {
    width: 0;
    height: auto;
    border-width: 0 0 0 1px;
}

hr.k-separator {
    margin: 0;
    padding: 0;
}

.k-hidden {
    display: none !important;
}

.k-rtl {
    direction: rtl;
}

[hidden] {
    display: none !important;
}

script {
    display: none !important;
}

.k-disabled,
.k-widget[disabled],
.k-disabled {
    outline: none;
    cursor: default;
    color: var(--kendo-disabled-text,);
    background-color: var(--kendo-disabled-bg,);
    border-color: var(--kendo-disabled-border,);
    opacity: var(--kendo-disabled-opacity, 0.6);
    filter: var(--kendo-disabled-filter, grayscale(0.1));
    pointer-events: none;
    box-shadow: none;
}
.k-disabled .k-link,
.k-widget[disabled] .k-link,
.k-disabled .k-link {
    cursor: default;
    outline: 0;
}
.k-disabled [disabled],
.k-disabled .k-disabled,
.k-widget[disabled] [disabled],
.k-widget[disabled] .k-disabled,
.k-disabled [disabled],
.k-disabled .k-disabled {
    --kendo-disabled-opacity: 1;
    --kendo-disabled-filter: grayscale(0);
}

.k-hr {
    margin-block: var(--kendo-spacing-4, 1rem);
    padding: 0;
    height: 0;
    border-width: 1px 0 0;
    border-style: solid;
    border-color: var(--kendo-component-border, var(--kendo-color-border, rgba(0, 0, 0, 0.08)));
    display: block;
    float: none;
    clear: both;
}

.k-d-flex-row > .k-hr {
    margin: 0;
    width: 0;
    height: auto;
    border-width: 0 0 0 1px;
    flex: 0 0 auto;
}

.k-d-flex-col > .k-hr {
    margin: 0;
    flex: 0 0 auto;
}

.k-sprite {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    overflow: hidden;
    background-repeat: no-repeat;
    font-size: 0;
    line-height: 0;
    text-align: center;
}

.k-image {
    display: inline-block;
}

.k-reset {
    margin: 0;
    padding: 0;
    border-width: 0;
    outline: 0;
    text-decoration: none;
    font: inherit;
    list-style: none;
}

kendo-sortable {
    display: block;
}

.k-link,
.k-link:hover {
    color: inherit;
    text-decoration: none;
    outline: 0;
    cursor: pointer;
}

.k-content {
    outline: 0;
}

.k-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.k-no-click {
    pointer-events: none;
}

.k-pdf-export-shadow {
    position: absolute;
    overflow: hidden;
    left: -15000px;
    width: 14400px;
}

.kendo-pdf-hide-pseudo-elements::before,
.kendo-pdf-hide-pseudo-elements::after {
    display: none !important;
}

.k-dirty {
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
    border-width: 3px;
    border-style: solid;
    border-block-start-color: currentColor;
    border-block-end-color: transparent;
    border-inline-start-color: transparent;
    border-inline-end-color: currentColor;
    position: absolute;
    inset-block-start: 0;
    inset-inline-end: 0;
}

.k-marquee {
    position: absolute;
    z-index: 100000;
}

.k-marquee-color,
.k-marquee-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.k-marquee-color {
    color: var(--kendo-neutral-160, var(--kendo-color-on-primary, white));
    background-color: var(--kendo-primary-60, var(--kendo-color-primary, rgb(44, 91, 204)));
    border-color: var(--kendo-primary-100, var(--kendo-color-border, rgba(0, 0, 0, 0.08)));
    opacity: 0.6;
}

.k-marquee-text {
    color: var(--kendo-neutral-160, var(--kendo-color-on-primary, white));
}

.k-text-selection ::selection {
    background-color: var(--kendo-color-primary, rgb(44, 91, 204));
    color: var(--kendo-color-on-primary, white);
}

.k-scrollbar {
    position: absolute;
    overflow: scroll;
}

.k-scrollbar-vertical {
    top: 0;
    right: 0;
    width: 17px;
    height: 100%;
    overflow-x: hidden;
}

.k-touch-scrollbar {
    display: none;
    position: absolute;
    z-index: 200000;
    height: 8px;
    width: 8px;
    border: 1px solid #8a8a8a;
    background-color: #858585;
}

.k-resize-handle,
.k-resize-hint {
    position: absolute;
    border-color: inherit;
    z-index: 200;
}

.k-resize-handle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.k-resize-handle::before {
    content: '';
    border: 0 solid;
    border-color: inherit;
}

.k-resize-n {
    width: 100%;
    height: 6px;
    flex-direction: row;
    left: 0;
    top: -3px;
    cursor: n-resize;
}

.k-resize-s {
    width: 100%;
    height: 6px;
    flex-direction: row;
    left: 0;
    bottom: -3px;
    cursor: s-resize;
}

.k-resize-w {
    width: 6px;
    height: 100%;
    flex-direction: column;
    top: 0;
    left: -3px;
    cursor: w-resize;
}

.k-resize-e {
    width: 6px;
    height: 100%;
    flex-direction: column;
    top: 0;
    right: -3px;
    cursor: e-resize;
}

.k-resize-sw,
.k-resize-se,
.k-resize-nw,
.k-resize-ne {
    width: 5px;
    height: 5px;
}

.k-resize-sw {
    cursor: sw-resize;
    bottom: 0;
    left: 0;
}

.k-resize-se {
    cursor: se-resize;
    bottom: 0;
    right: 0;
}

.k-resize-nw {
    cursor: nw-resize;
    top: 0;
    left: 0;
}

.k-resize-ne {
    cursor: ne-resize;
    top: 0;
    right: 0;
}

.k-vertical-resize {
    cursor: row-resize;
}

.k-horizontal-resize {
    cursor: col-resize;
}

.k-resize-hint {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.k-resize-hint .k-resize-hint-handle {
    width: auto;
    height: 20px;
    align-self: stretch;
}
.k-resize-hint .k-resize-hint-marker {
    width: 2px;
    height: auto;
    flex: 1 1 auto;
}

.k-resize-hint-vertical {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.k-resize-hint-vertical .k-resize-hint-handle {
    width: 20px;
    height: auto;
    align-self: stretch;
}
.k-resize-hint-vertical .k-resize-hint-marker {
    width: auto;
    height: 2px;
    flex: 1 1 auto;
}

.k-top-start,
.k-pos-top-start {
    top: 0;
    inset-inline-start: 0;
}

.k-top-end,
.k-pos-top-end {
    top: 0;
    inset-inline-end: 0;
}

.k-bottom-start,
.k-pos-bottom-start {
    bottom: 0;
    inset-inline-start: 0;
}

.k-bottom-end,
.k-pos-bottom-end {
    bottom: 0;
    inset-inline-end: 0;
}

.k-top-center,
.k-pos-top-center {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.k-middle-left,
.k-middle-start,
.k-pos-middle-start {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.k-middle-center {
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
}

.k-middle-right,
.k-middle-end,
.k-pos-middle-end {
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.k-bottom-center,
.k-pos-bottom-center {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

:root {
    --kendo-elevation-1: 0 2px 3px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.12);
    --kendo-elevation-2: 0 4px 6px rgba(0, 0, 0, 0.06), 0 4px 16px rgba(0, 0, 0, 0.12);
    --kendo-elevation-3: 0 6px 8px rgba(0, 0, 0, 0.08), 0 4px 16px rgba(0, 0, 0, 0.12);
    --kendo-elevation-4: 0 8px 10px rgba(0, 0, 0, 0.12), 0 4px 16px rgba(0, 0, 0, 0.12);
    --kendo-elevation-5: 0 10px 12px rgba(0, 0, 0, 0.16), 0 4px 16px rgba(0, 0, 0, 0.12);
    --kendo-elevation-6: 0 12px 14px rgba(0, 0, 0, 0.2), 0 4px 16px rgba(0, 0, 0, 0.12);
    --kendo-elevation-7: 0 14px 16px rgba(0, 0, 0, 0.24), 0 4px 16px rgba(0, 0, 0, 0.12);
    --kendo-elevation-8: 0 16px 18px rgba(0, 0, 0, 0.28), 0 4px 16px rgba(0, 0, 0, 0.12);
    --kendo-elevation-9: 0 32px 34px rgba(0, 0, 0, 0.32), 0 4px 16px rgba(0, 0, 0, 0.12);
}

:root {
    --kendo-font-family: inherit;
    --kendo-font-size: 0.875rem;
    --kendo-line-height: 1.4285714286;
    --kendo-font-weight: 400;
    --kendo-letter-spacing: ;
    --kendo-font-size-xxs: 0.5rem;
    --kendo-font-size-xs: 0.625rem;
    --kendo-font-size-sm: 0.75rem;
    --kendo-font-size-md: 0.875rem;
    --kendo-font-size-lg: 1rem;
    --kendo-font-size-xl: 1.25rem;
    --kendo-line-height-xs: 1;
    --kendo-line-height-sm: 1.25;
    --kendo-line-height-md: 1.4285714286;
    --kendo-line-height-lg: 1.5;
    --kendo-font-weight-thin: 100;
    --kendo-font-weight-extra-light: 200;
    --kendo-font-weight-light: 300;
    --kendo-font-weight-normal: 400;
    --kendo-font-weight-medium: 500;
    --kendo-font-weight-semibold: 600;
    --kendo-font-weight-bold: 700;
    --kendo-letter-spacing-tightest: -0.15px;
    --kendo-letter-spacing-tighter: -0.1px;
    --kendo-letter-spacing-tight: -0.5px;
    --kendo-letter-spacing-normal: 0px;
    --kendo-letter-spacing-wide: 0.5px;
    --kendo-letter-spacing-wider: 0.1px;
    --kendo-letter-spacing-widest: 0.15px;
    --kendo-font-family-sans: Arial, Verdana, Tahoma, Trebuchet MS, Helvetica, Impact, Gill Sans;
    --kendo-font-family-serif: Times New Roman, Georgia, Garamond, Palatino, Baskerville;
    --kendo-font-family-sans-serif: system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Noto Sans, Liberation Sans, Arial,
        sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    --kendo-font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, Roboto Mono, Ubuntu Mono, Lucida Console, Courier New, monospace;
}

:root {
    --kendo-spacing-0: 0px;
    --kendo-spacing-1px: 1px;
    --kendo-spacing-0\.5: 0.125rem;
    --kendo-spacing-1: 0.25rem;
    --kendo-spacing-1\.5: 0.375rem;
    --kendo-spacing-2: 0.5rem;
    --kendo-spacing-2\.5: 0.625rem;
    --kendo-spacing-3: 0.75rem;
    --kendo-spacing-3\.5: 0.875rem;
    --kendo-spacing-4: 1rem;
    --kendo-spacing-4\.5: 1.125rem;
    --kendo-spacing-5: 1.25rem;
    --kendo-spacing-5\.5: 1.375rem;
    --kendo-spacing-6: 1.5rem;
    --kendo-spacing-6\.5: 1.625rem;
    --kendo-spacing-7: 1.75rem;
    --kendo-spacing-7\.5: 1.875rem;
    --kendo-spacing-8: 2rem;
    --kendo-spacing-9: 2.25rem;
    --kendo-spacing-10: 2.5rem;
    --kendo-spacing-11: 2.75rem;
    --kendo-spacing-12: 3rem;
    --kendo-spacing-13: 3.25rem;
    --kendo-spacing-14: 3.5rem;
    --kendo-spacing-15: 3.75rem;
    --kendo-spacing-16: 4rem;
    --kendo-spacing-17: 4.25rem;
    --kendo-spacing-18: 4.5rem;
    --kendo-spacing-19: 4.75rem;
    --kendo-spacing-20: 5rem;
    --kendo-spacing-21: 5.25rem;
    --kendo-spacing-22: 5.5rem;
    --kendo-spacing-23: 5.75rem;
    --kendo-spacing-24: 6rem;
    --kendo-spacing-25: 7rem;
    --kendo-spacing-26: 8rem;
    --kendo-spacing-27: 9rem;
    --kendo-spacing-28: 10rem;
    --kendo-spacing-29: 11rem;
    --kendo-spacing-30: 12rem;
}

:root {
    --kendo-border-radius-none: 0px;
    --kendo-border-radius-xs: 1px;
    --kendo-border-radius-sm: 0.125rem;
    --kendo-border-radius-md: 0.25rem;
    --kendo-border-radius-lg: 0.375rem;
    --kendo-border-radius-xl: 0.5rem;
    --kendo-border-radius-xxl: 0.75rem;
    --kendo-border-radius-xxxl: 1rem;
    --kendo-border-radius-full: 9999px;
}

.k-rounded-none {
    border-radius: var(--kendo-border-radius-none, 0px);
}

.k-rounded-xs {
    border-radius: var(--kendo-border-radius-xs, 1px);
}

.k-rounded-sm {
    border-radius: var(--kendo-border-radius-sm, 0.125rem);
}

.k-rounded-md {
    border-radius: var(--kendo-border-radius-md, 0.25rem);
}

.k-rounded-lg {
    border-radius: var(--kendo-border-radius-lg, 0.375rem);
}

.k-rounded-xl {
    border-radius: var(--kendo-border-radius-xl, 0.5rem);
}

.k-rounded-xxl {
    border-radius: var(--kendo-border-radius-xxl, 0.75rem);
}

.k-rounded-xxxl {
    border-radius: var(--kendo-border-radius-xxxl, 1rem);
}

.k-rounded-full {
    border-radius: var(--kendo-border-radius-full, 9999px);
}

.k-body,
.k-typography {
    font-size: var(--kendo-font-size, inherit);
    font-family: var(--kendo-font-family, inherit);
    line-height: var(--kendo-line-height, normal);
    font-weight: var(--kendo-font-weight-normal, normal);
    letter-spacing: var(--kendo-letter-spacing, normal);
    margin: 0;
}
.k-body p,
.k-typography p {
    margin: 0 0 var(--kendo-font-size, inherit);
}

.k-h1 {
    font-size: var(--kendo-h1-font-size, calc(var(--kendo-font-size, 0.875rem) * 4));
    font-family: var(--kendo-h1-font-family, var(--kendo-font-family, inherit));
    line-height: var(--kendo-h1-line-height, 74px);
    font-weight: var(--kendo-h1-font-weight, var(--kendo-font-weight-normal, normal));
    letter-spacing: var(--kendo-h1-letter-spacing,);
    margin: var(--kendo-h1-margin, 0 0 var(--kendo-font-size, inherit));
}

.k-h2 {
    font-size: var(--kendo-h2-font-size, calc(var(--kendo-font-size, 0.875rem) * 3));
    font-family: var(--kendo-h2-font-family, var(--kendo-font-family, inherit));
    line-height: var(--kendo-h2-line-height, 56px);
    font-weight: var(--kendo-h2-font-weight, var(--kendo-font-weight-normal, normal));
    letter-spacing: var(--kendo-h2-letter-spacing,);
    margin: var(--kendo-h2-margin, 0 0 var(--kendo-font-size, inherit));
}

.k-h3 {
    font-size: var(--kendo-h3-font-size, calc(var(--kendo-font-size, 0.875rem) * 2.5));
    font-family: var(--kendo-h3-font-family, var(--kendo-font-family, inherit));
    line-height: var(--kendo-h3-line-height, 42px);
    font-weight: var(--kendo-h3-font-weight, var(--kendo-font-weight-normal, normal));
    letter-spacing: var(--kendo-h3-letter-spacing,);
    margin: var(--kendo-h3-margin, 0 0 var(--kendo-font-size, inherit));
}

.k-h4 {
    font-size: var(--kendo-h4-font-size, calc(var(--kendo-font-size, 0.875rem) * 2));
    font-family: var(--kendo-h4-font-family, var(--kendo-font-family, inherit));
    line-height: var(--kendo-h4-line-height, 40px);
    font-weight: var(--kendo-h4-font-weight, var(--kendo-font-weight-bold, normal));
    letter-spacing: var(--kendo-h4-letter-spacing,);
    margin: var(--kendo-h4-margin, 0 0 var(--kendo-font-size, inherit));
}

.k-h5 {
    font-size: var(--kendo-h5-font-size, calc(var(--kendo-font-size, 0.875rem) * 1.5));
    font-family: var(--kendo-h5-font-family, var(--kendo-font-family, inherit));
    line-height: var(--kendo-h5-line-height, 28px);
    font-weight: var(--kendo-h5-font-weight, var(--kendo-font-weight-bold, normal));
    letter-spacing: var(--kendo-h5-letter-spacing,);
    margin: var(--kendo-h5-margin, 0 0 var(--kendo-font-size, inherit));
}

.k-h6 {
    font-size: var(--kendo-h6-font-size, var(--kendo-font-size, inherit));
    font-family: var(--kendo-h6-font-family, var(--kendo-font-family, inherit));
    line-height: var(--kendo-h6-line-height, 20px);
    font-weight: var(--kendo-h6-font-weight, var(--kendo-font-weight-bold, normal));
    letter-spacing: var(--kendo-h6-letter-spacing,);
    margin: var(--kendo-h6-margin, 0 0 var(--kendo-font-size, inherit));
}

.k-paragraph {
    font-size: var(--kendo-paragraph-font-size, var(--kendo-font-size, inherit));
    font-family: var(--kendo-paragraph-font-family, var(--kendo-font-family, inherit));
    line-height: var(--kendo-paragraph-line-height, 20px);
    font-weight: var(--kendo-paragraph-font-weight, var(--kendo-font-weight-normal, normal));
    letter-spacing: var(--kendo-paragraph-letter-spacing,);
    margin: var(--kendo-paragraph-margin, 0 0 var(--kendo-font-size, inherit));
}

.k-pre,
.k-code {
    font-size: var(--kendo-code-font-size, var(--kendo-font-size, inherit));
    font-family: var(--kendo-code-font-family, var(--kendo-font-family-monospace, normal));
    line-height: var(--kendo-code-line-height, 20px);
    font-weight: var(--kendo-code-font-weight, var(--kendo-font-weight-normal, normal));
    letter-spacing: var(--kendo-code-letter-spacing,);
    border-style: solid;
    border-width: 1px;
}

.k-pre {
    padding-block: var(--kendo-spacing-4, 1rem);
    padding-inline: var(--kendo-spacing-6, 1.5rem);
    overflow: auto;
}

.k-pre > .k-code {
    padding: 0;
    border-width: 0;
    border-color: transparent;
    background-color: transparent;
    font-family: inherit;
    font-size: inherit;
}

.k-code {
    padding-block: var(--kendo-spacing-0, 0px);
    padding-inline: var(--kendo-spacing-1, 0.25rem);
}

.k-display-1 {
    font-size: var(--kendo-display1-font-size, calc(var(--kendo-font-size, 0.875rem) * 8));
    font-family: var(--kendo-display1-font-family, var(--kendo-font-family, inherit));
    line-height: var(--kendo-display1-line-height, 1.2);
    font-weight: var(--kendo-display1-font-weight, var(--kendo-font-weight-light, normal));
    letter-spacing: var(--kendo-display1-letter-spacing,);
}

.k-display-2 {
    font-size: var(--kendo-display2-font-size, calc(var(--kendo-font-size, 0.875rem) * 7));
    font-family: var(--kendo-display2-font-family, var(--kendo-font-family, inherit));
    line-height: var(--kendo-display2-line-height, 1.2);
    font-weight: var(--kendo-display2-font-weight, var(--kendo-font-weight-light, normal));
    letter-spacing: var(--kendo-display2-letter-spacing,);
}

.k-display-3 {
    font-size: var(--kendo-display3-font-size, calc(var(--kendo-font-size, 0.875rem) * 6));
    font-family: var(--kendo-display3-font-family, var(--kendo-font-family, inherit));
    line-height: var(--kendo-display3-line-height, 1.2);
    font-weight: var(--kendo-display3-font-weight, var(--kendo-font-weight-light, normal));
    letter-spacing: var(--kendo-display3-letter-spacing,);
}

.k-display-4 {
    font-size: var(--kendo-display4-font-size, calc(var(--kendo-font-size, 0.875rem) * 5));
    font-family: var(--kendo-display4-font-family, var(--kendo-font-family, inherit));
    line-height: var(--kendo-display4-line-height, 1.2);
    font-weight: var(--kendo-display4-font-weight, var(--kendo-font-weight-light, normal));
    letter-spacing: var(--kendo-display4-letter-spacing,);
}

.k-body {
    color: var(--kendo-color-on-app-surface, #3d3d3d);
    background-color: var(--kendo-color-app-surface, #ffffff);
}

.k-pre,
.k-code {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    color: var(--kendo-color-on-app-surface, #3d3d3d);
    background-color: var(--kendo-color-surface, #fafafa);
}

.k-m-0 {
    margin: var(--kendo-spacing-0, 0px);
}

.\!k-m-0 {
    margin: var(--kendo-spacing-0, 0px) !important;
}

.k-m-1px {
    margin: var(--kendo-spacing-1px, 1px);
}

.\!k-m-1px {
    margin: var(--kendo-spacing-1px, 1px) !important;
}

.k-m-0\.5 {
    margin: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-m-0\.5 {
    margin: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-m-1 {
    margin: var(--kendo-spacing-1, 0.25rem);
}

.\!k-m-1 {
    margin: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-m-1\.5 {
    margin: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-m-1\.5 {
    margin: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-m-2 {
    margin: var(--kendo-spacing-2, 0.5rem);
}

.\!k-m-2 {
    margin: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-m-2\.5 {
    margin: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-m-2\.5 {
    margin: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-m-3 {
    margin: var(--kendo-spacing-3, 0.75rem);
}

.\!k-m-3 {
    margin: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-m-3\.5 {
    margin: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-m-3\.5 {
    margin: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-m-4 {
    margin: var(--kendo-spacing-4, 1rem);
}

.\!k-m-4 {
    margin: var(--kendo-spacing-4, 1rem) !important;
}

.k-m-4\.5 {
    margin: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-m-4\.5 {
    margin: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-m-5 {
    margin: var(--kendo-spacing-5, 1.25rem);
}

.\!k-m-5 {
    margin: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-m-5\.5 {
    margin: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-m-5\.5 {
    margin: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-m-6 {
    margin: var(--kendo-spacing-6, 1.5rem);
}

.\!k-m-6 {
    margin: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-m-6\.5 {
    margin: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-m-6\.5 {
    margin: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-m-7 {
    margin: var(--kendo-spacing-7, 1.75rem);
}

.\!k-m-7 {
    margin: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-m-7\.5 {
    margin: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-m-7\.5 {
    margin: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-m-8 {
    margin: var(--kendo-spacing-8, 2rem);
}

.\!k-m-8 {
    margin: var(--kendo-spacing-8, 2rem) !important;
}

.k-m-9 {
    margin: var(--kendo-spacing-9, 2.25rem);
}

.\!k-m-9 {
    margin: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-m-10 {
    margin: var(--kendo-spacing-10, 2.5rem);
}

.\!k-m-10 {
    margin: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-m-11 {
    margin: var(--kendo-spacing-11, 2.75rem);
}

.\!k-m-11 {
    margin: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-m-12 {
    margin: var(--kendo-spacing-12, 3rem);
}

.\!k-m-12 {
    margin: var(--kendo-spacing-12, 3rem) !important;
}

.k-m-13 {
    margin: var(--kendo-spacing-13, 3.25rem);
}

.\!k-m-13 {
    margin: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-m-14 {
    margin: var(--kendo-spacing-14, 3.5rem);
}

.\!k-m-14 {
    margin: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-m-15 {
    margin: var(--kendo-spacing-15, 3.75rem);
}

.\!k-m-15 {
    margin: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-m-16 {
    margin: var(--kendo-spacing-16, 4rem);
}

.\!k-m-16 {
    margin: var(--kendo-spacing-16, 4rem) !important;
}

.k-m-17 {
    margin: var(--kendo-spacing-17, 4.25rem);
}

.\!k-m-17 {
    margin: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-m-18 {
    margin: var(--kendo-spacing-18, 4.5rem);
}

.\!k-m-18 {
    margin: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-m-19 {
    margin: var(--kendo-spacing-19, 4.75rem);
}

.\!k-m-19 {
    margin: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-m-20 {
    margin: var(--kendo-spacing-20, 5rem);
}

.\!k-m-20 {
    margin: var(--kendo-spacing-20, 5rem) !important;
}

.k-m-21 {
    margin: var(--kendo-spacing-21, 5.25rem);
}

.\!k-m-21 {
    margin: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-m-22 {
    margin: var(--kendo-spacing-22, 5.5rem);
}

.\!k-m-22 {
    margin: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-m-23 {
    margin: var(--kendo-spacing-23, 5.75rem);
}

.\!k-m-23 {
    margin: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-m-24 {
    margin: var(--kendo-spacing-24, 6rem);
}

.\!k-m-24 {
    margin: var(--kendo-spacing-24, 6rem) !important;
}

.k-m-25 {
    margin: var(--kendo-spacing-25, 7rem);
}

.\!k-m-25 {
    margin: var(--kendo-spacing-25, 7rem) !important;
}

.k-m-26 {
    margin: var(--kendo-spacing-26, 8rem);
}

.\!k-m-26 {
    margin: var(--kendo-spacing-26, 8rem) !important;
}

.k-m-27 {
    margin: var(--kendo-spacing-27, 9rem);
}

.\!k-m-27 {
    margin: var(--kendo-spacing-27, 9rem) !important;
}

.k-m-28 {
    margin: var(--kendo-spacing-28, 10rem);
}

.\!k-m-28 {
    margin: var(--kendo-spacing-28, 10rem) !important;
}

.k-m-29 {
    margin: var(--kendo-spacing-29, 11rem);
}

.\!k-m-29 {
    margin: var(--kendo-spacing-29, 11rem) !important;
}

.k-m-30 {
    margin: var(--kendo-spacing-30, 12rem);
}

.\!k-m-30 {
    margin: var(--kendo-spacing-30, 12rem) !important;
}

.k-m--0 {
    margin: var(--kendo-spacing--0, 0px);
}

.\!k-m--0 {
    margin: var(--kendo-spacing--0, 0px) !important;
}

.k-m--1px {
    margin: var(--kendo-spacing--1px, -1px);
}

.\!k-m--1px {
    margin: var(--kendo-spacing--1px, -1px) !important;
}

.k-m--0\.5 {
    margin: var(--kendo-spacing--0\.5, -0.125rem);
}

.\!k-m--0\.5 {
    margin: var(--kendo-spacing--0\.5, -0.125rem) !important;
}

.k-m--1 {
    margin: var(--kendo-spacing--1, -0.25rem);
}

.\!k-m--1 {
    margin: var(--kendo-spacing--1, -0.25rem) !important;
}

.k-m--1\.5 {
    margin: var(--kendo-spacing--1\.5, -0.375rem);
}

.\!k-m--1\.5 {
    margin: var(--kendo-spacing--1\.5, -0.375rem) !important;
}

.k-m--2 {
    margin: var(--kendo-spacing--2, -0.5rem);
}

.\!k-m--2 {
    margin: var(--kendo-spacing--2, -0.5rem) !important;
}

.k-m--2\.5 {
    margin: var(--kendo-spacing--2\.5, -0.625rem);
}

.\!k-m--2\.5 {
    margin: var(--kendo-spacing--2\.5, -0.625rem) !important;
}

.k-m--3 {
    margin: var(--kendo-spacing--3, -0.75rem);
}

.\!k-m--3 {
    margin: var(--kendo-spacing--3, -0.75rem) !important;
}

.k-m--3\.5 {
    margin: var(--kendo-spacing--3\.5, -0.875rem);
}

.\!k-m--3\.5 {
    margin: var(--kendo-spacing--3\.5, -0.875rem) !important;
}

.k-m--4 {
    margin: var(--kendo-spacing--4, -1rem);
}

.\!k-m--4 {
    margin: var(--kendo-spacing--4, -1rem) !important;
}

.k-m--4\.5 {
    margin: var(--kendo-spacing--4\.5, -1.125rem);
}

.\!k-m--4\.5 {
    margin: var(--kendo-spacing--4\.5, -1.125rem) !important;
}

.k-m--5 {
    margin: var(--kendo-spacing--5, -1.25rem);
}

.\!k-m--5 {
    margin: var(--kendo-spacing--5, -1.25rem) !important;
}

.k-m--5\.5 {
    margin: var(--kendo-spacing--5\.5, -1.375rem);
}

.\!k-m--5\.5 {
    margin: var(--kendo-spacing--5\.5, -1.375rem) !important;
}

.k-m--6 {
    margin: var(--kendo-spacing--6, -1.5rem);
}

.\!k-m--6 {
    margin: var(--kendo-spacing--6, -1.5rem) !important;
}

.k-m--6\.5 {
    margin: var(--kendo-spacing--6\.5, -1.625rem);
}

.\!k-m--6\.5 {
    margin: var(--kendo-spacing--6\.5, -1.625rem) !important;
}

.k-m--7 {
    margin: var(--kendo-spacing--7, -1.75rem);
}

.\!k-m--7 {
    margin: var(--kendo-spacing--7, -1.75rem) !important;
}

.k-m--7\.5 {
    margin: var(--kendo-spacing--7\.5, -1.875rem);
}

.\!k-m--7\.5 {
    margin: var(--kendo-spacing--7\.5, -1.875rem) !important;
}

.k-m--8 {
    margin: var(--kendo-spacing--8, -2rem);
}

.\!k-m--8 {
    margin: var(--kendo-spacing--8, -2rem) !important;
}

.k-m--9 {
    margin: var(--kendo-spacing--9, -2.25rem);
}

.\!k-m--9 {
    margin: var(--kendo-spacing--9, -2.25rem) !important;
}

.k-m--10 {
    margin: var(--kendo-spacing--10, -2.5rem);
}

.\!k-m--10 {
    margin: var(--kendo-spacing--10, -2.5rem) !important;
}

.k-m--11 {
    margin: var(--kendo-spacing--11, -2.75rem);
}

.\!k-m--11 {
    margin: var(--kendo-spacing--11, -2.75rem) !important;
}

.k-m--12 {
    margin: var(--kendo-spacing--12, -3rem);
}

.\!k-m--12 {
    margin: var(--kendo-spacing--12, -3rem) !important;
}

.k-m--13 {
    margin: var(--kendo-spacing--13, -3.25rem);
}

.\!k-m--13 {
    margin: var(--kendo-spacing--13, -3.25rem) !important;
}

.k-m--14 {
    margin: var(--kendo-spacing--14, -3.5rem);
}

.\!k-m--14 {
    margin: var(--kendo-spacing--14, -3.5rem) !important;
}

.k-m--15 {
    margin: var(--kendo-spacing--15, -3.75rem);
}

.\!k-m--15 {
    margin: var(--kendo-spacing--15, -3.75rem) !important;
}

.k-m--16 {
    margin: var(--kendo-spacing--16, -4rem);
}

.\!k-m--16 {
    margin: var(--kendo-spacing--16, -4rem) !important;
}

.k-m--17 {
    margin: var(--kendo-spacing--17, -4.25rem);
}

.\!k-m--17 {
    margin: var(--kendo-spacing--17, -4.25rem) !important;
}

.k-m--18 {
    margin: var(--kendo-spacing--18, -4.5rem);
}

.\!k-m--18 {
    margin: var(--kendo-spacing--18, -4.5rem) !important;
}

.k-m--19 {
    margin: var(--kendo-spacing--19, -4.75rem);
}

.\!k-m--19 {
    margin: var(--kendo-spacing--19, -4.75rem) !important;
}

.k-m--20 {
    margin: var(--kendo-spacing--20, -5rem);
}

.\!k-m--20 {
    margin: var(--kendo-spacing--20, -5rem) !important;
}

.k-m--21 {
    margin: var(--kendo-spacing--21, -5.25rem);
}

.\!k-m--21 {
    margin: var(--kendo-spacing--21, -5.25rem) !important;
}

.k-m--22 {
    margin: var(--kendo-spacing--22, -5.5rem);
}

.\!k-m--22 {
    margin: var(--kendo-spacing--22, -5.5rem) !important;
}

.k-m--23 {
    margin: var(--kendo-spacing--23, -5.75rem);
}

.\!k-m--23 {
    margin: var(--kendo-spacing--23, -5.75rem) !important;
}

.k-m--24 {
    margin: var(--kendo-spacing--24, -6rem);
}

.\!k-m--24 {
    margin: var(--kendo-spacing--24, -6rem) !important;
}

.k-m--25 {
    margin: var(--kendo-spacing--25, -7rem);
}

.\!k-m--25 {
    margin: var(--kendo-spacing--25, -7rem) !important;
}

.k-m--26 {
    margin: var(--kendo-spacing--26, -8rem);
}

.\!k-m--26 {
    margin: var(--kendo-spacing--26, -8rem) !important;
}

.k-m--27 {
    margin: var(--kendo-spacing--27, -9rem);
}

.\!k-m--27 {
    margin: var(--kendo-spacing--27, -9rem) !important;
}

.k-m--28 {
    margin: var(--kendo-spacing--28, -10rem);
}

.\!k-m--28 {
    margin: var(--kendo-spacing--28, -10rem) !important;
}

.k-m--29 {
    margin: var(--kendo-spacing--29, -11rem);
}

.\!k-m--29 {
    margin: var(--kendo-spacing--29, -11rem) !important;
}

.k-m--30 {
    margin: var(--kendo-spacing--30, -12rem);
}

.\!k-m--30 {
    margin: var(--kendo-spacing--30, -12rem) !important;
}

.k-m-xs {
    margin: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-m-xs {
    margin: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-m-sm {
    margin: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-m-sm {
    margin: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-m-md {
    margin: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-m-md {
    margin: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-m-lg {
    margin: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-m-lg {
    margin: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-m-xl {
    margin: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-m-xl {
    margin: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-m-thin {
    margin: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-m-thin {
    margin: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-m-hair {
    margin: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-m-hair {
    margin: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-m-auto {
    margin: var(--kendo-spacing-auto, auto);
}

.\!k-m-auto {
    margin: var(--kendo-spacing-auto, auto) !important;
}

.k-mt-0 {
    margin-top: var(--kendo-spacing-0, 0px);
}

.\!k-mt-0 {
    margin-top: var(--kendo-spacing-0, 0px) !important;
}

.k-mt-1px {
    margin-top: var(--kendo-spacing-1px, 1px);
}

.\!k-mt-1px {
    margin-top: var(--kendo-spacing-1px, 1px) !important;
}

.k-mt-0\.5 {
    margin-top: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-mt-0\.5 {
    margin-top: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-mt-1 {
    margin-top: var(--kendo-spacing-1, 0.25rem);
}

.\!k-mt-1 {
    margin-top: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-mt-1\.5 {
    margin-top: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-mt-1\.5 {
    margin-top: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-mt-2 {
    margin-top: var(--kendo-spacing-2, 0.5rem);
}

.\!k-mt-2 {
    margin-top: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-mt-2\.5 {
    margin-top: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-mt-2\.5 {
    margin-top: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-mt-3 {
    margin-top: var(--kendo-spacing-3, 0.75rem);
}

.\!k-mt-3 {
    margin-top: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-mt-3\.5 {
    margin-top: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-mt-3\.5 {
    margin-top: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-mt-4 {
    margin-top: var(--kendo-spacing-4, 1rem);
}

.\!k-mt-4 {
    margin-top: var(--kendo-spacing-4, 1rem) !important;
}

.k-mt-4\.5 {
    margin-top: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-mt-4\.5 {
    margin-top: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-mt-5 {
    margin-top: var(--kendo-spacing-5, 1.25rem);
}

.\!k-mt-5 {
    margin-top: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-mt-5\.5 {
    margin-top: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-mt-5\.5 {
    margin-top: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-mt-6 {
    margin-top: var(--kendo-spacing-6, 1.5rem);
}

.\!k-mt-6 {
    margin-top: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-mt-6\.5 {
    margin-top: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-mt-6\.5 {
    margin-top: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-mt-7 {
    margin-top: var(--kendo-spacing-7, 1.75rem);
}

.\!k-mt-7 {
    margin-top: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-mt-7\.5 {
    margin-top: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-mt-7\.5 {
    margin-top: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-mt-8 {
    margin-top: var(--kendo-spacing-8, 2rem);
}

.\!k-mt-8 {
    margin-top: var(--kendo-spacing-8, 2rem) !important;
}

.k-mt-9 {
    margin-top: var(--kendo-spacing-9, 2.25rem);
}

.\!k-mt-9 {
    margin-top: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-mt-10 {
    margin-top: var(--kendo-spacing-10, 2.5rem);
}

.\!k-mt-10 {
    margin-top: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-mt-11 {
    margin-top: var(--kendo-spacing-11, 2.75rem);
}

.\!k-mt-11 {
    margin-top: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-mt-12 {
    margin-top: var(--kendo-spacing-12, 3rem);
}

.\!k-mt-12 {
    margin-top: var(--kendo-spacing-12, 3rem) !important;
}

.k-mt-13 {
    margin-top: var(--kendo-spacing-13, 3.25rem);
}

.\!k-mt-13 {
    margin-top: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-mt-14 {
    margin-top: var(--kendo-spacing-14, 3.5rem);
}

.\!k-mt-14 {
    margin-top: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-mt-15 {
    margin-top: var(--kendo-spacing-15, 3.75rem);
}

.\!k-mt-15 {
    margin-top: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-mt-16 {
    margin-top: var(--kendo-spacing-16, 4rem);
}

.\!k-mt-16 {
    margin-top: var(--kendo-spacing-16, 4rem) !important;
}

.k-mt-17 {
    margin-top: var(--kendo-spacing-17, 4.25rem);
}

.\!k-mt-17 {
    margin-top: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-mt-18 {
    margin-top: var(--kendo-spacing-18, 4.5rem);
}

.\!k-mt-18 {
    margin-top: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-mt-19 {
    margin-top: var(--kendo-spacing-19, 4.75rem);
}

.\!k-mt-19 {
    margin-top: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-mt-20 {
    margin-top: var(--kendo-spacing-20, 5rem);
}

.\!k-mt-20 {
    margin-top: var(--kendo-spacing-20, 5rem) !important;
}

.k-mt-21 {
    margin-top: var(--kendo-spacing-21, 5.25rem);
}

.\!k-mt-21 {
    margin-top: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-mt-22 {
    margin-top: var(--kendo-spacing-22, 5.5rem);
}

.\!k-mt-22 {
    margin-top: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-mt-23 {
    margin-top: var(--kendo-spacing-23, 5.75rem);
}

.\!k-mt-23 {
    margin-top: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-mt-24 {
    margin-top: var(--kendo-spacing-24, 6rem);
}

.\!k-mt-24 {
    margin-top: var(--kendo-spacing-24, 6rem) !important;
}

.k-mt-25 {
    margin-top: var(--kendo-spacing-25, 7rem);
}

.\!k-mt-25 {
    margin-top: var(--kendo-spacing-25, 7rem) !important;
}

.k-mt-26 {
    margin-top: var(--kendo-spacing-26, 8rem);
}

.\!k-mt-26 {
    margin-top: var(--kendo-spacing-26, 8rem) !important;
}

.k-mt-27 {
    margin-top: var(--kendo-spacing-27, 9rem);
}

.\!k-mt-27 {
    margin-top: var(--kendo-spacing-27, 9rem) !important;
}

.k-mt-28 {
    margin-top: var(--kendo-spacing-28, 10rem);
}

.\!k-mt-28 {
    margin-top: var(--kendo-spacing-28, 10rem) !important;
}

.k-mt-29 {
    margin-top: var(--kendo-spacing-29, 11rem);
}

.\!k-mt-29 {
    margin-top: var(--kendo-spacing-29, 11rem) !important;
}

.k-mt-30 {
    margin-top: var(--kendo-spacing-30, 12rem);
}

.\!k-mt-30 {
    margin-top: var(--kendo-spacing-30, 12rem) !important;
}

.k-mt--0 {
    margin-top: var(--kendo-spacing--0, 0px);
}

.\!k-mt--0 {
    margin-top: var(--kendo-spacing--0, 0px) !important;
}

.k-mt--1px {
    margin-top: var(--kendo-spacing--1px, -1px);
}

.\!k-mt--1px {
    margin-top: var(--kendo-spacing--1px, -1px) !important;
}

.k-mt--0\.5 {
    margin-top: var(--kendo-spacing--0\.5, -0.125rem);
}

.\!k-mt--0\.5 {
    margin-top: var(--kendo-spacing--0\.5, -0.125rem) !important;
}

.k-mt--1 {
    margin-top: var(--kendo-spacing--1, -0.25rem);
}

.\!k-mt--1 {
    margin-top: var(--kendo-spacing--1, -0.25rem) !important;
}

.k-mt--1\.5 {
    margin-top: var(--kendo-spacing--1\.5, -0.375rem);
}

.\!k-mt--1\.5 {
    margin-top: var(--kendo-spacing--1\.5, -0.375rem) !important;
}

.k-mt--2 {
    margin-top: var(--kendo-spacing--2, -0.5rem);
}

.\!k-mt--2 {
    margin-top: var(--kendo-spacing--2, -0.5rem) !important;
}

.k-mt--2\.5 {
    margin-top: var(--kendo-spacing--2\.5, -0.625rem);
}

.\!k-mt--2\.5 {
    margin-top: var(--kendo-spacing--2\.5, -0.625rem) !important;
}

.k-mt--3 {
    margin-top: var(--kendo-spacing--3, -0.75rem);
}

.\!k-mt--3 {
    margin-top: var(--kendo-spacing--3, -0.75rem) !important;
}

.k-mt--3\.5 {
    margin-top: var(--kendo-spacing--3\.5, -0.875rem);
}

.\!k-mt--3\.5 {
    margin-top: var(--kendo-spacing--3\.5, -0.875rem) !important;
}

.k-mt--4 {
    margin-top: var(--kendo-spacing--4, -1rem);
}

.\!k-mt--4 {
    margin-top: var(--kendo-spacing--4, -1rem) !important;
}

.k-mt--4\.5 {
    margin-top: var(--kendo-spacing--4\.5, -1.125rem);
}

.\!k-mt--4\.5 {
    margin-top: var(--kendo-spacing--4\.5, -1.125rem) !important;
}

.k-mt--5 {
    margin-top: var(--kendo-spacing--5, -1.25rem);
}

.\!k-mt--5 {
    margin-top: var(--kendo-spacing--5, -1.25rem) !important;
}

.k-mt--5\.5 {
    margin-top: var(--kendo-spacing--5\.5, -1.375rem);
}

.\!k-mt--5\.5 {
    margin-top: var(--kendo-spacing--5\.5, -1.375rem) !important;
}

.k-mt--6 {
    margin-top: var(--kendo-spacing--6, -1.5rem);
}

.\!k-mt--6 {
    margin-top: var(--kendo-spacing--6, -1.5rem) !important;
}

.k-mt--6\.5 {
    margin-top: var(--kendo-spacing--6\.5, -1.625rem);
}

.\!k-mt--6\.5 {
    margin-top: var(--kendo-spacing--6\.5, -1.625rem) !important;
}

.k-mt--7 {
    margin-top: var(--kendo-spacing--7, -1.75rem);
}

.\!k-mt--7 {
    margin-top: var(--kendo-spacing--7, -1.75rem) !important;
}

.k-mt--7\.5 {
    margin-top: var(--kendo-spacing--7\.5, -1.875rem);
}

.\!k-mt--7\.5 {
    margin-top: var(--kendo-spacing--7\.5, -1.875rem) !important;
}

.k-mt--8 {
    margin-top: var(--kendo-spacing--8, -2rem);
}

.\!k-mt--8 {
    margin-top: var(--kendo-spacing--8, -2rem) !important;
}

.k-mt--9 {
    margin-top: var(--kendo-spacing--9, -2.25rem);
}

.\!k-mt--9 {
    margin-top: var(--kendo-spacing--9, -2.25rem) !important;
}

.k-mt--10 {
    margin-top: var(--kendo-spacing--10, -2.5rem);
}

.\!k-mt--10 {
    margin-top: var(--kendo-spacing--10, -2.5rem) !important;
}

.k-mt--11 {
    margin-top: var(--kendo-spacing--11, -2.75rem);
}

.\!k-mt--11 {
    margin-top: var(--kendo-spacing--11, -2.75rem) !important;
}

.k-mt--12 {
    margin-top: var(--kendo-spacing--12, -3rem);
}

.\!k-mt--12 {
    margin-top: var(--kendo-spacing--12, -3rem) !important;
}

.k-mt--13 {
    margin-top: var(--kendo-spacing--13, -3.25rem);
}

.\!k-mt--13 {
    margin-top: var(--kendo-spacing--13, -3.25rem) !important;
}

.k-mt--14 {
    margin-top: var(--kendo-spacing--14, -3.5rem);
}

.\!k-mt--14 {
    margin-top: var(--kendo-spacing--14, -3.5rem) !important;
}

.k-mt--15 {
    margin-top: var(--kendo-spacing--15, -3.75rem);
}

.\!k-mt--15 {
    margin-top: var(--kendo-spacing--15, -3.75rem) !important;
}

.k-mt--16 {
    margin-top: var(--kendo-spacing--16, -4rem);
}

.\!k-mt--16 {
    margin-top: var(--kendo-spacing--16, -4rem) !important;
}

.k-mt--17 {
    margin-top: var(--kendo-spacing--17, -4.25rem);
}

.\!k-mt--17 {
    margin-top: var(--kendo-spacing--17, -4.25rem) !important;
}

.k-mt--18 {
    margin-top: var(--kendo-spacing--18, -4.5rem);
}

.\!k-mt--18 {
    margin-top: var(--kendo-spacing--18, -4.5rem) !important;
}

.k-mt--19 {
    margin-top: var(--kendo-spacing--19, -4.75rem);
}

.\!k-mt--19 {
    margin-top: var(--kendo-spacing--19, -4.75rem) !important;
}

.k-mt--20 {
    margin-top: var(--kendo-spacing--20, -5rem);
}

.\!k-mt--20 {
    margin-top: var(--kendo-spacing--20, -5rem) !important;
}

.k-mt--21 {
    margin-top: var(--kendo-spacing--21, -5.25rem);
}

.\!k-mt--21 {
    margin-top: var(--kendo-spacing--21, -5.25rem) !important;
}

.k-mt--22 {
    margin-top: var(--kendo-spacing--22, -5.5rem);
}

.\!k-mt--22 {
    margin-top: var(--kendo-spacing--22, -5.5rem) !important;
}

.k-mt--23 {
    margin-top: var(--kendo-spacing--23, -5.75rem);
}

.\!k-mt--23 {
    margin-top: var(--kendo-spacing--23, -5.75rem) !important;
}

.k-mt--24 {
    margin-top: var(--kendo-spacing--24, -6rem);
}

.\!k-mt--24 {
    margin-top: var(--kendo-spacing--24, -6rem) !important;
}

.k-mt--25 {
    margin-top: var(--kendo-spacing--25, -7rem);
}

.\!k-mt--25 {
    margin-top: var(--kendo-spacing--25, -7rem) !important;
}

.k-mt--26 {
    margin-top: var(--kendo-spacing--26, -8rem);
}

.\!k-mt--26 {
    margin-top: var(--kendo-spacing--26, -8rem) !important;
}

.k-mt--27 {
    margin-top: var(--kendo-spacing--27, -9rem);
}

.\!k-mt--27 {
    margin-top: var(--kendo-spacing--27, -9rem) !important;
}

.k-mt--28 {
    margin-top: var(--kendo-spacing--28, -10rem);
}

.\!k-mt--28 {
    margin-top: var(--kendo-spacing--28, -10rem) !important;
}

.k-mt--29 {
    margin-top: var(--kendo-spacing--29, -11rem);
}

.\!k-mt--29 {
    margin-top: var(--kendo-spacing--29, -11rem) !important;
}

.k-mt--30 {
    margin-top: var(--kendo-spacing--30, -12rem);
}

.\!k-mt--30 {
    margin-top: var(--kendo-spacing--30, -12rem) !important;
}

.k-mt-xs {
    margin-top: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-mt-xs {
    margin-top: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-mt-sm {
    margin-top: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-mt-sm {
    margin-top: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-mt-md {
    margin-top: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-mt-md {
    margin-top: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-mt-lg {
    margin-top: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-mt-lg {
    margin-top: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-mt-xl {
    margin-top: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-mt-xl {
    margin-top: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-mt-thin {
    margin-top: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-mt-thin {
    margin-top: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-mt-hair {
    margin-top: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-mt-hair {
    margin-top: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-mt-auto {
    margin-top: var(--kendo-spacing-auto, auto);
}

.\!k-mt-auto {
    margin-top: var(--kendo-spacing-auto, auto) !important;
}

.k-mr-0 {
    margin-right: var(--kendo-spacing-0, 0px);
}

.\!k-mr-0 {
    margin-right: var(--kendo-spacing-0, 0px) !important;
}

.k-mr-1px {
    margin-right: var(--kendo-spacing-1px, 1px);
}

.\!k-mr-1px {
    margin-right: var(--kendo-spacing-1px, 1px) !important;
}

.k-mr-0\.5 {
    margin-right: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-mr-0\.5 {
    margin-right: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-mr-1 {
    margin-right: var(--kendo-spacing-1, 0.25rem);
}

.\!k-mr-1 {
    margin-right: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-mr-1\.5 {
    margin-right: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-mr-1\.5 {
    margin-right: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-mr-2 {
    margin-right: var(--kendo-spacing-2, 0.5rem);
}

.\!k-mr-2 {
    margin-right: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-mr-2\.5 {
    margin-right: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-mr-2\.5 {
    margin-right: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-mr-3 {
    margin-right: var(--kendo-spacing-3, 0.75rem);
}

.\!k-mr-3 {
    margin-right: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-mr-3\.5 {
    margin-right: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-mr-3\.5 {
    margin-right: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-mr-4 {
    margin-right: var(--kendo-spacing-4, 1rem);
}

.\!k-mr-4 {
    margin-right: var(--kendo-spacing-4, 1rem) !important;
}

.k-mr-4\.5 {
    margin-right: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-mr-4\.5 {
    margin-right: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-mr-5 {
    margin-right: var(--kendo-spacing-5, 1.25rem);
}

.\!k-mr-5 {
    margin-right: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-mr-5\.5 {
    margin-right: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-mr-5\.5 {
    margin-right: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-mr-6 {
    margin-right: var(--kendo-spacing-6, 1.5rem);
}

.\!k-mr-6 {
    margin-right: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-mr-6\.5 {
    margin-right: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-mr-6\.5 {
    margin-right: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-mr-7 {
    margin-right: var(--kendo-spacing-7, 1.75rem);
}

.\!k-mr-7 {
    margin-right: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-mr-7\.5 {
    margin-right: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-mr-7\.5 {
    margin-right: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-mr-8 {
    margin-right: var(--kendo-spacing-8, 2rem);
}

.\!k-mr-8 {
    margin-right: var(--kendo-spacing-8, 2rem) !important;
}

.k-mr-9 {
    margin-right: var(--kendo-spacing-9, 2.25rem);
}

.\!k-mr-9 {
    margin-right: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-mr-10 {
    margin-right: var(--kendo-spacing-10, 2.5rem);
}

.\!k-mr-10 {
    margin-right: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-mr-11 {
    margin-right: var(--kendo-spacing-11, 2.75rem);
}

.\!k-mr-11 {
    margin-right: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-mr-12 {
    margin-right: var(--kendo-spacing-12, 3rem);
}

.\!k-mr-12 {
    margin-right: var(--kendo-spacing-12, 3rem) !important;
}

.k-mr-13 {
    margin-right: var(--kendo-spacing-13, 3.25rem);
}

.\!k-mr-13 {
    margin-right: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-mr-14 {
    margin-right: var(--kendo-spacing-14, 3.5rem);
}

.\!k-mr-14 {
    margin-right: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-mr-15 {
    margin-right: var(--kendo-spacing-15, 3.75rem);
}

.\!k-mr-15 {
    margin-right: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-mr-16 {
    margin-right: var(--kendo-spacing-16, 4rem);
}

.\!k-mr-16 {
    margin-right: var(--kendo-spacing-16, 4rem) !important;
}

.k-mr-17 {
    margin-right: var(--kendo-spacing-17, 4.25rem);
}

.\!k-mr-17 {
    margin-right: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-mr-18 {
    margin-right: var(--kendo-spacing-18, 4.5rem);
}

.\!k-mr-18 {
    margin-right: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-mr-19 {
    margin-right: var(--kendo-spacing-19, 4.75rem);
}

.\!k-mr-19 {
    margin-right: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-mr-20 {
    margin-right: var(--kendo-spacing-20, 5rem);
}

.\!k-mr-20 {
    margin-right: var(--kendo-spacing-20, 5rem) !important;
}

.k-mr-21 {
    margin-right: var(--kendo-spacing-21, 5.25rem);
}

.\!k-mr-21 {
    margin-right: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-mr-22 {
    margin-right: var(--kendo-spacing-22, 5.5rem);
}

.\!k-mr-22 {
    margin-right: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-mr-23 {
    margin-right: var(--kendo-spacing-23, 5.75rem);
}

.\!k-mr-23 {
    margin-right: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-mr-24 {
    margin-right: var(--kendo-spacing-24, 6rem);
}

.\!k-mr-24 {
    margin-right: var(--kendo-spacing-24, 6rem) !important;
}

.k-mr-25 {
    margin-right: var(--kendo-spacing-25, 7rem);
}

.\!k-mr-25 {
    margin-right: var(--kendo-spacing-25, 7rem) !important;
}

.k-mr-26 {
    margin-right: var(--kendo-spacing-26, 8rem);
}

.\!k-mr-26 {
    margin-right: var(--kendo-spacing-26, 8rem) !important;
}

.k-mr-27 {
    margin-right: var(--kendo-spacing-27, 9rem);
}

.\!k-mr-27 {
    margin-right: var(--kendo-spacing-27, 9rem) !important;
}

.k-mr-28 {
    margin-right: var(--kendo-spacing-28, 10rem);
}

.\!k-mr-28 {
    margin-right: var(--kendo-spacing-28, 10rem) !important;
}

.k-mr-29 {
    margin-right: var(--kendo-spacing-29, 11rem);
}

.\!k-mr-29 {
    margin-right: var(--kendo-spacing-29, 11rem) !important;
}

.k-mr-30 {
    margin-right: var(--kendo-spacing-30, 12rem);
}

.\!k-mr-30 {
    margin-right: var(--kendo-spacing-30, 12rem) !important;
}

.k-mr--0 {
    margin-right: var(--kendo-spacing--0, 0px);
}

.\!k-mr--0 {
    margin-right: var(--kendo-spacing--0, 0px) !important;
}

.k-mr--1px {
    margin-right: var(--kendo-spacing--1px, -1px);
}

.\!k-mr--1px {
    margin-right: var(--kendo-spacing--1px, -1px) !important;
}

.k-mr--0\.5 {
    margin-right: var(--kendo-spacing--0\.5, -0.125rem);
}

.\!k-mr--0\.5 {
    margin-right: var(--kendo-spacing--0\.5, -0.125rem) !important;
}

.k-mr--1 {
    margin-right: var(--kendo-spacing--1, -0.25rem);
}

.\!k-mr--1 {
    margin-right: var(--kendo-spacing--1, -0.25rem) !important;
}

.k-mr--1\.5 {
    margin-right: var(--kendo-spacing--1\.5, -0.375rem);
}

.\!k-mr--1\.5 {
    margin-right: var(--kendo-spacing--1\.5, -0.375rem) !important;
}

.k-mr--2 {
    margin-right: var(--kendo-spacing--2, -0.5rem);
}

.\!k-mr--2 {
    margin-right: var(--kendo-spacing--2, -0.5rem) !important;
}

.k-mr--2\.5 {
    margin-right: var(--kendo-spacing--2\.5, -0.625rem);
}

.\!k-mr--2\.5 {
    margin-right: var(--kendo-spacing--2\.5, -0.625rem) !important;
}

.k-mr--3 {
    margin-right: var(--kendo-spacing--3, -0.75rem);
}

.\!k-mr--3 {
    margin-right: var(--kendo-spacing--3, -0.75rem) !important;
}

.k-mr--3\.5 {
    margin-right: var(--kendo-spacing--3\.5, -0.875rem);
}

.\!k-mr--3\.5 {
    margin-right: var(--kendo-spacing--3\.5, -0.875rem) !important;
}

.k-mr--4 {
    margin-right: var(--kendo-spacing--4, -1rem);
}

.\!k-mr--4 {
    margin-right: var(--kendo-spacing--4, -1rem) !important;
}

.k-mr--4\.5 {
    margin-right: var(--kendo-spacing--4\.5, -1.125rem);
}

.\!k-mr--4\.5 {
    margin-right: var(--kendo-spacing--4\.5, -1.125rem) !important;
}

.k-mr--5 {
    margin-right: var(--kendo-spacing--5, -1.25rem);
}

.\!k-mr--5 {
    margin-right: var(--kendo-spacing--5, -1.25rem) !important;
}

.k-mr--5\.5 {
    margin-right: var(--kendo-spacing--5\.5, -1.375rem);
}

.\!k-mr--5\.5 {
    margin-right: var(--kendo-spacing--5\.5, -1.375rem) !important;
}

.k-mr--6 {
    margin-right: var(--kendo-spacing--6, -1.5rem);
}

.\!k-mr--6 {
    margin-right: var(--kendo-spacing--6, -1.5rem) !important;
}

.k-mr--6\.5 {
    margin-right: var(--kendo-spacing--6\.5, -1.625rem);
}

.\!k-mr--6\.5 {
    margin-right: var(--kendo-spacing--6\.5, -1.625rem) !important;
}

.k-mr--7 {
    margin-right: var(--kendo-spacing--7, -1.75rem);
}

.\!k-mr--7 {
    margin-right: var(--kendo-spacing--7, -1.75rem) !important;
}

.k-mr--7\.5 {
    margin-right: var(--kendo-spacing--7\.5, -1.875rem);
}

.\!k-mr--7\.5 {
    margin-right: var(--kendo-spacing--7\.5, -1.875rem) !important;
}

.k-mr--8 {
    margin-right: var(--kendo-spacing--8, -2rem);
}

.\!k-mr--8 {
    margin-right: var(--kendo-spacing--8, -2rem) !important;
}

.k-mr--9 {
    margin-right: var(--kendo-spacing--9, -2.25rem);
}

.\!k-mr--9 {
    margin-right: var(--kendo-spacing--9, -2.25rem) !important;
}

.k-mr--10 {
    margin-right: var(--kendo-spacing--10, -2.5rem);
}

.\!k-mr--10 {
    margin-right: var(--kendo-spacing--10, -2.5rem) !important;
}

.k-mr--11 {
    margin-right: var(--kendo-spacing--11, -2.75rem);
}

.\!k-mr--11 {
    margin-right: var(--kendo-spacing--11, -2.75rem) !important;
}

.k-mr--12 {
    margin-right: var(--kendo-spacing--12, -3rem);
}

.\!k-mr--12 {
    margin-right: var(--kendo-spacing--12, -3rem) !important;
}

.k-mr--13 {
    margin-right: var(--kendo-spacing--13, -3.25rem);
}

.\!k-mr--13 {
    margin-right: var(--kendo-spacing--13, -3.25rem) !important;
}

.k-mr--14 {
    margin-right: var(--kendo-spacing--14, -3.5rem);
}

.\!k-mr--14 {
    margin-right: var(--kendo-spacing--14, -3.5rem) !important;
}

.k-mr--15 {
    margin-right: var(--kendo-spacing--15, -3.75rem);
}

.\!k-mr--15 {
    margin-right: var(--kendo-spacing--15, -3.75rem) !important;
}

.k-mr--16 {
    margin-right: var(--kendo-spacing--16, -4rem);
}

.\!k-mr--16 {
    margin-right: var(--kendo-spacing--16, -4rem) !important;
}

.k-mr--17 {
    margin-right: var(--kendo-spacing--17, -4.25rem);
}

.\!k-mr--17 {
    margin-right: var(--kendo-spacing--17, -4.25rem) !important;
}

.k-mr--18 {
    margin-right: var(--kendo-spacing--18, -4.5rem);
}

.\!k-mr--18 {
    margin-right: var(--kendo-spacing--18, -4.5rem) !important;
}

.k-mr--19 {
    margin-right: var(--kendo-spacing--19, -4.75rem);
}

.\!k-mr--19 {
    margin-right: var(--kendo-spacing--19, -4.75rem) !important;
}

.k-mr--20 {
    margin-right: var(--kendo-spacing--20, -5rem);
}

.\!k-mr--20 {
    margin-right: var(--kendo-spacing--20, -5rem) !important;
}

.k-mr--21 {
    margin-right: var(--kendo-spacing--21, -5.25rem);
}

.\!k-mr--21 {
    margin-right: var(--kendo-spacing--21, -5.25rem) !important;
}

.k-mr--22 {
    margin-right: var(--kendo-spacing--22, -5.5rem);
}

.\!k-mr--22 {
    margin-right: var(--kendo-spacing--22, -5.5rem) !important;
}

.k-mr--23 {
    margin-right: var(--kendo-spacing--23, -5.75rem);
}

.\!k-mr--23 {
    margin-right: var(--kendo-spacing--23, -5.75rem) !important;
}

.k-mr--24 {
    margin-right: var(--kendo-spacing--24, -6rem);
}

.\!k-mr--24 {
    margin-right: var(--kendo-spacing--24, -6rem) !important;
}

.k-mr--25 {
    margin-right: var(--kendo-spacing--25, -7rem);
}

.\!k-mr--25 {
    margin-right: var(--kendo-spacing--25, -7rem) !important;
}

.k-mr--26 {
    margin-right: var(--kendo-spacing--26, -8rem);
}

.\!k-mr--26 {
    margin-right: var(--kendo-spacing--26, -8rem) !important;
}

.k-mr--27 {
    margin-right: var(--kendo-spacing--27, -9rem);
}

.\!k-mr--27 {
    margin-right: var(--kendo-spacing--27, -9rem) !important;
}

.k-mr--28 {
    margin-right: var(--kendo-spacing--28, -10rem);
}

.\!k-mr--28 {
    margin-right: var(--kendo-spacing--28, -10rem) !important;
}

.k-mr--29 {
    margin-right: var(--kendo-spacing--29, -11rem);
}

.\!k-mr--29 {
    margin-right: var(--kendo-spacing--29, -11rem) !important;
}

.k-mr--30 {
    margin-right: var(--kendo-spacing--30, -12rem);
}

.\!k-mr--30 {
    margin-right: var(--kendo-spacing--30, -12rem) !important;
}

.k-mr-xs {
    margin-right: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-mr-xs {
    margin-right: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-mr-sm {
    margin-right: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-mr-sm {
    margin-right: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-mr-md {
    margin-right: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-mr-md {
    margin-right: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-mr-lg {
    margin-right: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-mr-lg {
    margin-right: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-mr-xl {
    margin-right: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-mr-xl {
    margin-right: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-mr-thin {
    margin-right: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-mr-thin {
    margin-right: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-mr-hair {
    margin-right: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-mr-hair {
    margin-right: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-mr-auto {
    margin-right: var(--kendo-spacing-auto, auto);
}

.\!k-mr-auto {
    margin-right: var(--kendo-spacing-auto, auto) !important;
}

.k-mb-0 {
    margin-bottom: var(--kendo-spacing-0, 0px);
}

.\!k-mb-0 {
    margin-bottom: var(--kendo-spacing-0, 0px) !important;
}

.k-mb-1px {
    margin-bottom: var(--kendo-spacing-1px, 1px);
}

.\!k-mb-1px {
    margin-bottom: var(--kendo-spacing-1px, 1px) !important;
}

.k-mb-0\.5 {
    margin-bottom: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-mb-0\.5 {
    margin-bottom: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-mb-1 {
    margin-bottom: var(--kendo-spacing-1, 0.25rem);
}

.\!k-mb-1 {
    margin-bottom: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-mb-1\.5 {
    margin-bottom: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-mb-1\.5 {
    margin-bottom: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-mb-2 {
    margin-bottom: var(--kendo-spacing-2, 0.5rem);
}

.\!k-mb-2 {
    margin-bottom: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-mb-2\.5 {
    margin-bottom: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-mb-2\.5 {
    margin-bottom: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-mb-3 {
    margin-bottom: var(--kendo-spacing-3, 0.75rem);
}

.\!k-mb-3 {
    margin-bottom: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-mb-3\.5 {
    margin-bottom: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-mb-3\.5 {
    margin-bottom: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-mb-4 {
    margin-bottom: var(--kendo-spacing-4, 1rem);
}

.\!k-mb-4 {
    margin-bottom: var(--kendo-spacing-4, 1rem) !important;
}

.k-mb-4\.5 {
    margin-bottom: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-mb-4\.5 {
    margin-bottom: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-mb-5 {
    margin-bottom: var(--kendo-spacing-5, 1.25rem);
}

.\!k-mb-5 {
    margin-bottom: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-mb-5\.5 {
    margin-bottom: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-mb-5\.5 {
    margin-bottom: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-mb-6 {
    margin-bottom: var(--kendo-spacing-6, 1.5rem);
}

.\!k-mb-6 {
    margin-bottom: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-mb-6\.5 {
    margin-bottom: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-mb-6\.5 {
    margin-bottom: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-mb-7 {
    margin-bottom: var(--kendo-spacing-7, 1.75rem);
}

.\!k-mb-7 {
    margin-bottom: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-mb-7\.5 {
    margin-bottom: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-mb-7\.5 {
    margin-bottom: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-mb-8 {
    margin-bottom: var(--kendo-spacing-8, 2rem);
}

.\!k-mb-8 {
    margin-bottom: var(--kendo-spacing-8, 2rem) !important;
}

.k-mb-9 {
    margin-bottom: var(--kendo-spacing-9, 2.25rem);
}

.\!k-mb-9 {
    margin-bottom: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-mb-10 {
    margin-bottom: var(--kendo-spacing-10, 2.5rem);
}

.\!k-mb-10 {
    margin-bottom: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-mb-11 {
    margin-bottom: var(--kendo-spacing-11, 2.75rem);
}

.\!k-mb-11 {
    margin-bottom: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-mb-12 {
    margin-bottom: var(--kendo-spacing-12, 3rem);
}

.\!k-mb-12 {
    margin-bottom: var(--kendo-spacing-12, 3rem) !important;
}

.k-mb-13 {
    margin-bottom: var(--kendo-spacing-13, 3.25rem);
}

.\!k-mb-13 {
    margin-bottom: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-mb-14 {
    margin-bottom: var(--kendo-spacing-14, 3.5rem);
}

.\!k-mb-14 {
    margin-bottom: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-mb-15 {
    margin-bottom: var(--kendo-spacing-15, 3.75rem);
}

.\!k-mb-15 {
    margin-bottom: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-mb-16 {
    margin-bottom: var(--kendo-spacing-16, 4rem);
}

.\!k-mb-16 {
    margin-bottom: var(--kendo-spacing-16, 4rem) !important;
}

.k-mb-17 {
    margin-bottom: var(--kendo-spacing-17, 4.25rem);
}

.\!k-mb-17 {
    margin-bottom: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-mb-18 {
    margin-bottom: var(--kendo-spacing-18, 4.5rem);
}

.\!k-mb-18 {
    margin-bottom: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-mb-19 {
    margin-bottom: var(--kendo-spacing-19, 4.75rem);
}

.\!k-mb-19 {
    margin-bottom: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-mb-20 {
    margin-bottom: var(--kendo-spacing-20, 5rem);
}

.\!k-mb-20 {
    margin-bottom: var(--kendo-spacing-20, 5rem) !important;
}

.k-mb-21 {
    margin-bottom: var(--kendo-spacing-21, 5.25rem);
}

.\!k-mb-21 {
    margin-bottom: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-mb-22 {
    margin-bottom: var(--kendo-spacing-22, 5.5rem);
}

.\!k-mb-22 {
    margin-bottom: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-mb-23 {
    margin-bottom: var(--kendo-spacing-23, 5.75rem);
}

.\!k-mb-23 {
    margin-bottom: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-mb-24 {
    margin-bottom: var(--kendo-spacing-24, 6rem);
}

.\!k-mb-24 {
    margin-bottom: var(--kendo-spacing-24, 6rem) !important;
}

.k-mb-25 {
    margin-bottom: var(--kendo-spacing-25, 7rem);
}

.\!k-mb-25 {
    margin-bottom: var(--kendo-spacing-25, 7rem) !important;
}

.k-mb-26 {
    margin-bottom: var(--kendo-spacing-26, 8rem);
}

.\!k-mb-26 {
    margin-bottom: var(--kendo-spacing-26, 8rem) !important;
}

.k-mb-27 {
    margin-bottom: var(--kendo-spacing-27, 9rem);
}

.\!k-mb-27 {
    margin-bottom: var(--kendo-spacing-27, 9rem) !important;
}

.k-mb-28 {
    margin-bottom: var(--kendo-spacing-28, 10rem);
}

.\!k-mb-28 {
    margin-bottom: var(--kendo-spacing-28, 10rem) !important;
}

.k-mb-29 {
    margin-bottom: var(--kendo-spacing-29, 11rem);
}

.\!k-mb-29 {
    margin-bottom: var(--kendo-spacing-29, 11rem) !important;
}

.k-mb-30 {
    margin-bottom: var(--kendo-spacing-30, 12rem);
}

.\!k-mb-30 {
    margin-bottom: var(--kendo-spacing-30, 12rem) !important;
}

.k-mb--0 {
    margin-bottom: var(--kendo-spacing--0, 0px);
}

.\!k-mb--0 {
    margin-bottom: var(--kendo-spacing--0, 0px) !important;
}

.k-mb--1px {
    margin-bottom: var(--kendo-spacing--1px, -1px);
}

.\!k-mb--1px {
    margin-bottom: var(--kendo-spacing--1px, -1px) !important;
}

.k-mb--0\.5 {
    margin-bottom: var(--kendo-spacing--0\.5, -0.125rem);
}

.\!k-mb--0\.5 {
    margin-bottom: var(--kendo-spacing--0\.5, -0.125rem) !important;
}

.k-mb--1 {
    margin-bottom: var(--kendo-spacing--1, -0.25rem);
}

.\!k-mb--1 {
    margin-bottom: var(--kendo-spacing--1, -0.25rem) !important;
}

.k-mb--1\.5 {
    margin-bottom: var(--kendo-spacing--1\.5, -0.375rem);
}

.\!k-mb--1\.5 {
    margin-bottom: var(--kendo-spacing--1\.5, -0.375rem) !important;
}

.k-mb--2 {
    margin-bottom: var(--kendo-spacing--2, -0.5rem);
}

.\!k-mb--2 {
    margin-bottom: var(--kendo-spacing--2, -0.5rem) !important;
}

.k-mb--2\.5 {
    margin-bottom: var(--kendo-spacing--2\.5, -0.625rem);
}

.\!k-mb--2\.5 {
    margin-bottom: var(--kendo-spacing--2\.5, -0.625rem) !important;
}

.k-mb--3 {
    margin-bottom: var(--kendo-spacing--3, -0.75rem);
}

.\!k-mb--3 {
    margin-bottom: var(--kendo-spacing--3, -0.75rem) !important;
}

.k-mb--3\.5 {
    margin-bottom: var(--kendo-spacing--3\.5, -0.875rem);
}

.\!k-mb--3\.5 {
    margin-bottom: var(--kendo-spacing--3\.5, -0.875rem) !important;
}

.k-mb--4 {
    margin-bottom: var(--kendo-spacing--4, -1rem);
}

.\!k-mb--4 {
    margin-bottom: var(--kendo-spacing--4, -1rem) !important;
}

.k-mb--4\.5 {
    margin-bottom: var(--kendo-spacing--4\.5, -1.125rem);
}

.\!k-mb--4\.5 {
    margin-bottom: var(--kendo-spacing--4\.5, -1.125rem) !important;
}

.k-mb--5 {
    margin-bottom: var(--kendo-spacing--5, -1.25rem);
}

.\!k-mb--5 {
    margin-bottom: var(--kendo-spacing--5, -1.25rem) !important;
}

.k-mb--5\.5 {
    margin-bottom: var(--kendo-spacing--5\.5, -1.375rem);
}

.\!k-mb--5\.5 {
    margin-bottom: var(--kendo-spacing--5\.5, -1.375rem) !important;
}

.k-mb--6 {
    margin-bottom: var(--kendo-spacing--6, -1.5rem);
}

.\!k-mb--6 {
    margin-bottom: var(--kendo-spacing--6, -1.5rem) !important;
}

.k-mb--6\.5 {
    margin-bottom: var(--kendo-spacing--6\.5, -1.625rem);
}

.\!k-mb--6\.5 {
    margin-bottom: var(--kendo-spacing--6\.5, -1.625rem) !important;
}

.k-mb--7 {
    margin-bottom: var(--kendo-spacing--7, -1.75rem);
}

.\!k-mb--7 {
    margin-bottom: var(--kendo-spacing--7, -1.75rem) !important;
}

.k-mb--7\.5 {
    margin-bottom: var(--kendo-spacing--7\.5, -1.875rem);
}

.\!k-mb--7\.5 {
    margin-bottom: var(--kendo-spacing--7\.5, -1.875rem) !important;
}

.k-mb--8 {
    margin-bottom: var(--kendo-spacing--8, -2rem);
}

.\!k-mb--8 {
    margin-bottom: var(--kendo-spacing--8, -2rem) !important;
}

.k-mb--9 {
    margin-bottom: var(--kendo-spacing--9, -2.25rem);
}

.\!k-mb--9 {
    margin-bottom: var(--kendo-spacing--9, -2.25rem) !important;
}

.k-mb--10 {
    margin-bottom: var(--kendo-spacing--10, -2.5rem);
}

.\!k-mb--10 {
    margin-bottom: var(--kendo-spacing--10, -2.5rem) !important;
}

.k-mb--11 {
    margin-bottom: var(--kendo-spacing--11, -2.75rem);
}

.\!k-mb--11 {
    margin-bottom: var(--kendo-spacing--11, -2.75rem) !important;
}

.k-mb--12 {
    margin-bottom: var(--kendo-spacing--12, -3rem);
}

.\!k-mb--12 {
    margin-bottom: var(--kendo-spacing--12, -3rem) !important;
}

.k-mb--13 {
    margin-bottom: var(--kendo-spacing--13, -3.25rem);
}

.\!k-mb--13 {
    margin-bottom: var(--kendo-spacing--13, -3.25rem) !important;
}

.k-mb--14 {
    margin-bottom: var(--kendo-spacing--14, -3.5rem);
}

.\!k-mb--14 {
    margin-bottom: var(--kendo-spacing--14, -3.5rem) !important;
}

.k-mb--15 {
    margin-bottom: var(--kendo-spacing--15, -3.75rem);
}

.\!k-mb--15 {
    margin-bottom: var(--kendo-spacing--15, -3.75rem) !important;
}

.k-mb--16 {
    margin-bottom: var(--kendo-spacing--16, -4rem);
}

.\!k-mb--16 {
    margin-bottom: var(--kendo-spacing--16, -4rem) !important;
}

.k-mb--17 {
    margin-bottom: var(--kendo-spacing--17, -4.25rem);
}

.\!k-mb--17 {
    margin-bottom: var(--kendo-spacing--17, -4.25rem) !important;
}

.k-mb--18 {
    margin-bottom: var(--kendo-spacing--18, -4.5rem);
}

.\!k-mb--18 {
    margin-bottom: var(--kendo-spacing--18, -4.5rem) !important;
}

.k-mb--19 {
    margin-bottom: var(--kendo-spacing--19, -4.75rem);
}

.\!k-mb--19 {
    margin-bottom: var(--kendo-spacing--19, -4.75rem) !important;
}

.k-mb--20 {
    margin-bottom: var(--kendo-spacing--20, -5rem);
}

.\!k-mb--20 {
    margin-bottom: var(--kendo-spacing--20, -5rem) !important;
}

.k-mb--21 {
    margin-bottom: var(--kendo-spacing--21, -5.25rem);
}

.\!k-mb--21 {
    margin-bottom: var(--kendo-spacing--21, -5.25rem) !important;
}

.k-mb--22 {
    margin-bottom: var(--kendo-spacing--22, -5.5rem);
}

.\!k-mb--22 {
    margin-bottom: var(--kendo-spacing--22, -5.5rem) !important;
}

.k-mb--23 {
    margin-bottom: var(--kendo-spacing--23, -5.75rem);
}

.\!k-mb--23 {
    margin-bottom: var(--kendo-spacing--23, -5.75rem) !important;
}

.k-mb--24 {
    margin-bottom: var(--kendo-spacing--24, -6rem);
}

.\!k-mb--24 {
    margin-bottom: var(--kendo-spacing--24, -6rem) !important;
}

.k-mb--25 {
    margin-bottom: var(--kendo-spacing--25, -7rem);
}

.\!k-mb--25 {
    margin-bottom: var(--kendo-spacing--25, -7rem) !important;
}

.k-mb--26 {
    margin-bottom: var(--kendo-spacing--26, -8rem);
}

.\!k-mb--26 {
    margin-bottom: var(--kendo-spacing--26, -8rem) !important;
}

.k-mb--27 {
    margin-bottom: var(--kendo-spacing--27, -9rem);
}

.\!k-mb--27 {
    margin-bottom: var(--kendo-spacing--27, -9rem) !important;
}

.k-mb--28 {
    margin-bottom: var(--kendo-spacing--28, -10rem);
}

.\!k-mb--28 {
    margin-bottom: var(--kendo-spacing--28, -10rem) !important;
}

.k-mb--29 {
    margin-bottom: var(--kendo-spacing--29, -11rem);
}

.\!k-mb--29 {
    margin-bottom: var(--kendo-spacing--29, -11rem) !important;
}

.k-mb--30 {
    margin-bottom: var(--kendo-spacing--30, -12rem);
}

.\!k-mb--30 {
    margin-bottom: var(--kendo-spacing--30, -12rem) !important;
}

.k-mb-xs {
    margin-bottom: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-mb-xs {
    margin-bottom: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-mb-sm {
    margin-bottom: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-mb-sm {
    margin-bottom: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-mb-md {
    margin-bottom: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-mb-md {
    margin-bottom: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-mb-lg {
    margin-bottom: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-mb-lg {
    margin-bottom: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-mb-xl {
    margin-bottom: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-mb-xl {
    margin-bottom: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-mb-thin {
    margin-bottom: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-mb-thin {
    margin-bottom: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-mb-hair {
    margin-bottom: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-mb-hair {
    margin-bottom: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-mb-auto {
    margin-bottom: var(--kendo-spacing-auto, auto);
}

.\!k-mb-auto {
    margin-bottom: var(--kendo-spacing-auto, auto) !important;
}

.k-ml-0 {
    margin-left: var(--kendo-spacing-0, 0px);
}

.\!k-ml-0 {
    margin-left: var(--kendo-spacing-0, 0px) !important;
}

.k-ml-1px {
    margin-left: var(--kendo-spacing-1px, 1px);
}

.\!k-ml-1px {
    margin-left: var(--kendo-spacing-1px, 1px) !important;
}

.k-ml-0\.5 {
    margin-left: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-ml-0\.5 {
    margin-left: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-ml-1 {
    margin-left: var(--kendo-spacing-1, 0.25rem);
}

.\!k-ml-1 {
    margin-left: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-ml-1\.5 {
    margin-left: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-ml-1\.5 {
    margin-left: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-ml-2 {
    margin-left: var(--kendo-spacing-2, 0.5rem);
}

.\!k-ml-2 {
    margin-left: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-ml-2\.5 {
    margin-left: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-ml-2\.5 {
    margin-left: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-ml-3 {
    margin-left: var(--kendo-spacing-3, 0.75rem);
}

.\!k-ml-3 {
    margin-left: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-ml-3\.5 {
    margin-left: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-ml-3\.5 {
    margin-left: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-ml-4 {
    margin-left: var(--kendo-spacing-4, 1rem);
}

.\!k-ml-4 {
    margin-left: var(--kendo-spacing-4, 1rem) !important;
}

.k-ml-4\.5 {
    margin-left: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-ml-4\.5 {
    margin-left: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-ml-5 {
    margin-left: var(--kendo-spacing-5, 1.25rem);
}

.\!k-ml-5 {
    margin-left: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-ml-5\.5 {
    margin-left: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-ml-5\.5 {
    margin-left: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-ml-6 {
    margin-left: var(--kendo-spacing-6, 1.5rem);
}

.\!k-ml-6 {
    margin-left: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-ml-6\.5 {
    margin-left: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-ml-6\.5 {
    margin-left: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-ml-7 {
    margin-left: var(--kendo-spacing-7, 1.75rem);
}

.\!k-ml-7 {
    margin-left: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-ml-7\.5 {
    margin-left: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-ml-7\.5 {
    margin-left: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-ml-8 {
    margin-left: var(--kendo-spacing-8, 2rem);
}

.\!k-ml-8 {
    margin-left: var(--kendo-spacing-8, 2rem) !important;
}

.k-ml-9 {
    margin-left: var(--kendo-spacing-9, 2.25rem);
}

.\!k-ml-9 {
    margin-left: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-ml-10 {
    margin-left: var(--kendo-spacing-10, 2.5rem);
}

.\!k-ml-10 {
    margin-left: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-ml-11 {
    margin-left: var(--kendo-spacing-11, 2.75rem);
}

.\!k-ml-11 {
    margin-left: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-ml-12 {
    margin-left: var(--kendo-spacing-12, 3rem);
}

.\!k-ml-12 {
    margin-left: var(--kendo-spacing-12, 3rem) !important;
}

.k-ml-13 {
    margin-left: var(--kendo-spacing-13, 3.25rem);
}

.\!k-ml-13 {
    margin-left: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-ml-14 {
    margin-left: var(--kendo-spacing-14, 3.5rem);
}

.\!k-ml-14 {
    margin-left: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-ml-15 {
    margin-left: var(--kendo-spacing-15, 3.75rem);
}

.\!k-ml-15 {
    margin-left: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-ml-16 {
    margin-left: var(--kendo-spacing-16, 4rem);
}

.\!k-ml-16 {
    margin-left: var(--kendo-spacing-16, 4rem) !important;
}

.k-ml-17 {
    margin-left: var(--kendo-spacing-17, 4.25rem);
}

.\!k-ml-17 {
    margin-left: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-ml-18 {
    margin-left: var(--kendo-spacing-18, 4.5rem);
}

.\!k-ml-18 {
    margin-left: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-ml-19 {
    margin-left: var(--kendo-spacing-19, 4.75rem);
}

.\!k-ml-19 {
    margin-left: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-ml-20 {
    margin-left: var(--kendo-spacing-20, 5rem);
}

.\!k-ml-20 {
    margin-left: var(--kendo-spacing-20, 5rem) !important;
}

.k-ml-21 {
    margin-left: var(--kendo-spacing-21, 5.25rem);
}

.\!k-ml-21 {
    margin-left: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-ml-22 {
    margin-left: var(--kendo-spacing-22, 5.5rem);
}

.\!k-ml-22 {
    margin-left: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-ml-23 {
    margin-left: var(--kendo-spacing-23, 5.75rem);
}

.\!k-ml-23 {
    margin-left: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-ml-24 {
    margin-left: var(--kendo-spacing-24, 6rem);
}

.\!k-ml-24 {
    margin-left: var(--kendo-spacing-24, 6rem) !important;
}

.k-ml-25 {
    margin-left: var(--kendo-spacing-25, 7rem);
}

.\!k-ml-25 {
    margin-left: var(--kendo-spacing-25, 7rem) !important;
}

.k-ml-26 {
    margin-left: var(--kendo-spacing-26, 8rem);
}

.\!k-ml-26 {
    margin-left: var(--kendo-spacing-26, 8rem) !important;
}

.k-ml-27 {
    margin-left: var(--kendo-spacing-27, 9rem);
}

.\!k-ml-27 {
    margin-left: var(--kendo-spacing-27, 9rem) !important;
}

.k-ml-28 {
    margin-left: var(--kendo-spacing-28, 10rem);
}

.\!k-ml-28 {
    margin-left: var(--kendo-spacing-28, 10rem) !important;
}

.k-ml-29 {
    margin-left: var(--kendo-spacing-29, 11rem);
}

.\!k-ml-29 {
    margin-left: var(--kendo-spacing-29, 11rem) !important;
}

.k-ml-30 {
    margin-left: var(--kendo-spacing-30, 12rem);
}

.\!k-ml-30 {
    margin-left: var(--kendo-spacing-30, 12rem) !important;
}

.k-ml--0 {
    margin-left: var(--kendo-spacing--0, 0px);
}

.\!k-ml--0 {
    margin-left: var(--kendo-spacing--0, 0px) !important;
}

.k-ml--1px {
    margin-left: var(--kendo-spacing--1px, -1px);
}

.\!k-ml--1px {
    margin-left: var(--kendo-spacing--1px, -1px) !important;
}

.k-ml--0\.5 {
    margin-left: var(--kendo-spacing--0\.5, -0.125rem);
}

.\!k-ml--0\.5 {
    margin-left: var(--kendo-spacing--0\.5, -0.125rem) !important;
}

.k-ml--1 {
    margin-left: var(--kendo-spacing--1, -0.25rem);
}

.\!k-ml--1 {
    margin-left: var(--kendo-spacing--1, -0.25rem) !important;
}

.k-ml--1\.5 {
    margin-left: var(--kendo-spacing--1\.5, -0.375rem);
}

.\!k-ml--1\.5 {
    margin-left: var(--kendo-spacing--1\.5, -0.375rem) !important;
}

.k-ml--2 {
    margin-left: var(--kendo-spacing--2, -0.5rem);
}

.\!k-ml--2 {
    margin-left: var(--kendo-spacing--2, -0.5rem) !important;
}

.k-ml--2\.5 {
    margin-left: var(--kendo-spacing--2\.5, -0.625rem);
}

.\!k-ml--2\.5 {
    margin-left: var(--kendo-spacing--2\.5, -0.625rem) !important;
}

.k-ml--3 {
    margin-left: var(--kendo-spacing--3, -0.75rem);
}

.\!k-ml--3 {
    margin-left: var(--kendo-spacing--3, -0.75rem) !important;
}

.k-ml--3\.5 {
    margin-left: var(--kendo-spacing--3\.5, -0.875rem);
}

.\!k-ml--3\.5 {
    margin-left: var(--kendo-spacing--3\.5, -0.875rem) !important;
}

.k-ml--4 {
    margin-left: var(--kendo-spacing--4, -1rem);
}

.\!k-ml--4 {
    margin-left: var(--kendo-spacing--4, -1rem) !important;
}

.k-ml--4\.5 {
    margin-left: var(--kendo-spacing--4\.5, -1.125rem);
}

.\!k-ml--4\.5 {
    margin-left: var(--kendo-spacing--4\.5, -1.125rem) !important;
}

.k-ml--5 {
    margin-left: var(--kendo-spacing--5, -1.25rem);
}

.\!k-ml--5 {
    margin-left: var(--kendo-spacing--5, -1.25rem) !important;
}

.k-ml--5\.5 {
    margin-left: var(--kendo-spacing--5\.5, -1.375rem);
}

.\!k-ml--5\.5 {
    margin-left: var(--kendo-spacing--5\.5, -1.375rem) !important;
}

.k-ml--6 {
    margin-left: var(--kendo-spacing--6, -1.5rem);
}

.\!k-ml--6 {
    margin-left: var(--kendo-spacing--6, -1.5rem) !important;
}

.k-ml--6\.5 {
    margin-left: var(--kendo-spacing--6\.5, -1.625rem);
}

.\!k-ml--6\.5 {
    margin-left: var(--kendo-spacing--6\.5, -1.625rem) !important;
}

.k-ml--7 {
    margin-left: var(--kendo-spacing--7, -1.75rem);
}

.\!k-ml--7 {
    margin-left: var(--kendo-spacing--7, -1.75rem) !important;
}

.k-ml--7\.5 {
    margin-left: var(--kendo-spacing--7\.5, -1.875rem);
}

.\!k-ml--7\.5 {
    margin-left: var(--kendo-spacing--7\.5, -1.875rem) !important;
}

.k-ml--8 {
    margin-left: var(--kendo-spacing--8, -2rem);
}

.\!k-ml--8 {
    margin-left: var(--kendo-spacing--8, -2rem) !important;
}

.k-ml--9 {
    margin-left: var(--kendo-spacing--9, -2.25rem);
}

.\!k-ml--9 {
    margin-left: var(--kendo-spacing--9, -2.25rem) !important;
}

.k-ml--10 {
    margin-left: var(--kendo-spacing--10, -2.5rem);
}

.\!k-ml--10 {
    margin-left: var(--kendo-spacing--10, -2.5rem) !important;
}

.k-ml--11 {
    margin-left: var(--kendo-spacing--11, -2.75rem);
}

.\!k-ml--11 {
    margin-left: var(--kendo-spacing--11, -2.75rem) !important;
}

.k-ml--12 {
    margin-left: var(--kendo-spacing--12, -3rem);
}

.\!k-ml--12 {
    margin-left: var(--kendo-spacing--12, -3rem) !important;
}

.k-ml--13 {
    margin-left: var(--kendo-spacing--13, -3.25rem);
}

.\!k-ml--13 {
    margin-left: var(--kendo-spacing--13, -3.25rem) !important;
}

.k-ml--14 {
    margin-left: var(--kendo-spacing--14, -3.5rem);
}

.\!k-ml--14 {
    margin-left: var(--kendo-spacing--14, -3.5rem) !important;
}

.k-ml--15 {
    margin-left: var(--kendo-spacing--15, -3.75rem);
}

.\!k-ml--15 {
    margin-left: var(--kendo-spacing--15, -3.75rem) !important;
}

.k-ml--16 {
    margin-left: var(--kendo-spacing--16, -4rem);
}

.\!k-ml--16 {
    margin-left: var(--kendo-spacing--16, -4rem) !important;
}

.k-ml--17 {
    margin-left: var(--kendo-spacing--17, -4.25rem);
}

.\!k-ml--17 {
    margin-left: var(--kendo-spacing--17, -4.25rem) !important;
}

.k-ml--18 {
    margin-left: var(--kendo-spacing--18, -4.5rem);
}

.\!k-ml--18 {
    margin-left: var(--kendo-spacing--18, -4.5rem) !important;
}

.k-ml--19 {
    margin-left: var(--kendo-spacing--19, -4.75rem);
}

.\!k-ml--19 {
    margin-left: var(--kendo-spacing--19, -4.75rem) !important;
}

.k-ml--20 {
    margin-left: var(--kendo-spacing--20, -5rem);
}

.\!k-ml--20 {
    margin-left: var(--kendo-spacing--20, -5rem) !important;
}

.k-ml--21 {
    margin-left: var(--kendo-spacing--21, -5.25rem);
}

.\!k-ml--21 {
    margin-left: var(--kendo-spacing--21, -5.25rem) !important;
}

.k-ml--22 {
    margin-left: var(--kendo-spacing--22, -5.5rem);
}

.\!k-ml--22 {
    margin-left: var(--kendo-spacing--22, -5.5rem) !important;
}

.k-ml--23 {
    margin-left: var(--kendo-spacing--23, -5.75rem);
}

.\!k-ml--23 {
    margin-left: var(--kendo-spacing--23, -5.75rem) !important;
}

.k-ml--24 {
    margin-left: var(--kendo-spacing--24, -6rem);
}

.\!k-ml--24 {
    margin-left: var(--kendo-spacing--24, -6rem) !important;
}

.k-ml--25 {
    margin-left: var(--kendo-spacing--25, -7rem);
}

.\!k-ml--25 {
    margin-left: var(--kendo-spacing--25, -7rem) !important;
}

.k-ml--26 {
    margin-left: var(--kendo-spacing--26, -8rem);
}

.\!k-ml--26 {
    margin-left: var(--kendo-spacing--26, -8rem) !important;
}

.k-ml--27 {
    margin-left: var(--kendo-spacing--27, -9rem);
}

.\!k-ml--27 {
    margin-left: var(--kendo-spacing--27, -9rem) !important;
}

.k-ml--28 {
    margin-left: var(--kendo-spacing--28, -10rem);
}

.\!k-ml--28 {
    margin-left: var(--kendo-spacing--28, -10rem) !important;
}

.k-ml--29 {
    margin-left: var(--kendo-spacing--29, -11rem);
}

.\!k-ml--29 {
    margin-left: var(--kendo-spacing--29, -11rem) !important;
}

.k-ml--30 {
    margin-left: var(--kendo-spacing--30, -12rem);
}

.\!k-ml--30 {
    margin-left: var(--kendo-spacing--30, -12rem) !important;
}

.k-ml-xs {
    margin-left: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-ml-xs {
    margin-left: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-ml-sm {
    margin-left: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-ml-sm {
    margin-left: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-ml-md {
    margin-left: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-ml-md {
    margin-left: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-ml-lg {
    margin-left: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-ml-lg {
    margin-left: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-ml-xl {
    margin-left: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-ml-xl {
    margin-left: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-ml-thin {
    margin-left: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-ml-thin {
    margin-left: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-ml-hair {
    margin-left: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-ml-hair {
    margin-left: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-ml-auto {
    margin-left: var(--kendo-spacing-auto, auto);
}

.\!k-ml-auto {
    margin-left: var(--kendo-spacing-auto, auto) !important;
}

.k-mx-0 {
    margin-inline: var(--kendo-spacing-0, 0px);
}

.\!k-mx-0 {
    margin-inline: var(--kendo-spacing-0, 0px) !important;
}

.k-mx-1px {
    margin-inline: var(--kendo-spacing-1px, 1px);
}

.\!k-mx-1px {
    margin-inline: var(--kendo-spacing-1px, 1px) !important;
}

.k-mx-0\.5 {
    margin-inline: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-mx-0\.5 {
    margin-inline: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-mx-1 {
    margin-inline: var(--kendo-spacing-1, 0.25rem);
}

.\!k-mx-1 {
    margin-inline: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-mx-1\.5 {
    margin-inline: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-mx-1\.5 {
    margin-inline: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-mx-2 {
    margin-inline: var(--kendo-spacing-2, 0.5rem);
}

.\!k-mx-2 {
    margin-inline: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-mx-2\.5 {
    margin-inline: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-mx-2\.5 {
    margin-inline: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-mx-3 {
    margin-inline: var(--kendo-spacing-3, 0.75rem);
}

.\!k-mx-3 {
    margin-inline: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-mx-3\.5 {
    margin-inline: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-mx-3\.5 {
    margin-inline: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-mx-4 {
    margin-inline: var(--kendo-spacing-4, 1rem);
}

.\!k-mx-4 {
    margin-inline: var(--kendo-spacing-4, 1rem) !important;
}

.k-mx-4\.5 {
    margin-inline: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-mx-4\.5 {
    margin-inline: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-mx-5 {
    margin-inline: var(--kendo-spacing-5, 1.25rem);
}

.\!k-mx-5 {
    margin-inline: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-mx-5\.5 {
    margin-inline: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-mx-5\.5 {
    margin-inline: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-mx-6 {
    margin-inline: var(--kendo-spacing-6, 1.5rem);
}

.\!k-mx-6 {
    margin-inline: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-mx-6\.5 {
    margin-inline: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-mx-6\.5 {
    margin-inline: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-mx-7 {
    margin-inline: var(--kendo-spacing-7, 1.75rem);
}

.\!k-mx-7 {
    margin-inline: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-mx-7\.5 {
    margin-inline: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-mx-7\.5 {
    margin-inline: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-mx-8 {
    margin-inline: var(--kendo-spacing-8, 2rem);
}

.\!k-mx-8 {
    margin-inline: var(--kendo-spacing-8, 2rem) !important;
}

.k-mx-9 {
    margin-inline: var(--kendo-spacing-9, 2.25rem);
}

.\!k-mx-9 {
    margin-inline: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-mx-10 {
    margin-inline: var(--kendo-spacing-10, 2.5rem);
}

.\!k-mx-10 {
    margin-inline: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-mx-11 {
    margin-inline: var(--kendo-spacing-11, 2.75rem);
}

.\!k-mx-11 {
    margin-inline: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-mx-12 {
    margin-inline: var(--kendo-spacing-12, 3rem);
}

.\!k-mx-12 {
    margin-inline: var(--kendo-spacing-12, 3rem) !important;
}

.k-mx-13 {
    margin-inline: var(--kendo-spacing-13, 3.25rem);
}

.\!k-mx-13 {
    margin-inline: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-mx-14 {
    margin-inline: var(--kendo-spacing-14, 3.5rem);
}

.\!k-mx-14 {
    margin-inline: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-mx-15 {
    margin-inline: var(--kendo-spacing-15, 3.75rem);
}

.\!k-mx-15 {
    margin-inline: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-mx-16 {
    margin-inline: var(--kendo-spacing-16, 4rem);
}

.\!k-mx-16 {
    margin-inline: var(--kendo-spacing-16, 4rem) !important;
}

.k-mx-17 {
    margin-inline: var(--kendo-spacing-17, 4.25rem);
}

.\!k-mx-17 {
    margin-inline: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-mx-18 {
    margin-inline: var(--kendo-spacing-18, 4.5rem);
}

.\!k-mx-18 {
    margin-inline: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-mx-19 {
    margin-inline: var(--kendo-spacing-19, 4.75rem);
}

.\!k-mx-19 {
    margin-inline: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-mx-20 {
    margin-inline: var(--kendo-spacing-20, 5rem);
}

.\!k-mx-20 {
    margin-inline: var(--kendo-spacing-20, 5rem) !important;
}

.k-mx-21 {
    margin-inline: var(--kendo-spacing-21, 5.25rem);
}

.\!k-mx-21 {
    margin-inline: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-mx-22 {
    margin-inline: var(--kendo-spacing-22, 5.5rem);
}

.\!k-mx-22 {
    margin-inline: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-mx-23 {
    margin-inline: var(--kendo-spacing-23, 5.75rem);
}

.\!k-mx-23 {
    margin-inline: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-mx-24 {
    margin-inline: var(--kendo-spacing-24, 6rem);
}

.\!k-mx-24 {
    margin-inline: var(--kendo-spacing-24, 6rem) !important;
}

.k-mx-25 {
    margin-inline: var(--kendo-spacing-25, 7rem);
}

.\!k-mx-25 {
    margin-inline: var(--kendo-spacing-25, 7rem) !important;
}

.k-mx-26 {
    margin-inline: var(--kendo-spacing-26, 8rem);
}

.\!k-mx-26 {
    margin-inline: var(--kendo-spacing-26, 8rem) !important;
}

.k-mx-27 {
    margin-inline: var(--kendo-spacing-27, 9rem);
}

.\!k-mx-27 {
    margin-inline: var(--kendo-spacing-27, 9rem) !important;
}

.k-mx-28 {
    margin-inline: var(--kendo-spacing-28, 10rem);
}

.\!k-mx-28 {
    margin-inline: var(--kendo-spacing-28, 10rem) !important;
}

.k-mx-29 {
    margin-inline: var(--kendo-spacing-29, 11rem);
}

.\!k-mx-29 {
    margin-inline: var(--kendo-spacing-29, 11rem) !important;
}

.k-mx-30 {
    margin-inline: var(--kendo-spacing-30, 12rem);
}

.\!k-mx-30 {
    margin-inline: var(--kendo-spacing-30, 12rem) !important;
}

.k-mx--0 {
    margin-inline: var(--kendo-spacing--0, 0px);
}

.\!k-mx--0 {
    margin-inline: var(--kendo-spacing--0, 0px) !important;
}

.k-mx--1px {
    margin-inline: var(--kendo-spacing--1px, -1px);
}

.\!k-mx--1px {
    margin-inline: var(--kendo-spacing--1px, -1px) !important;
}

.k-mx--0\.5 {
    margin-inline: var(--kendo-spacing--0\.5, -0.125rem);
}

.\!k-mx--0\.5 {
    margin-inline: var(--kendo-spacing--0\.5, -0.125rem) !important;
}

.k-mx--1 {
    margin-inline: var(--kendo-spacing--1, -0.25rem);
}

.\!k-mx--1 {
    margin-inline: var(--kendo-spacing--1, -0.25rem) !important;
}

.k-mx--1\.5 {
    margin-inline: var(--kendo-spacing--1\.5, -0.375rem);
}

.\!k-mx--1\.5 {
    margin-inline: var(--kendo-spacing--1\.5, -0.375rem) !important;
}

.k-mx--2 {
    margin-inline: var(--kendo-spacing--2, -0.5rem);
}

.\!k-mx--2 {
    margin-inline: var(--kendo-spacing--2, -0.5rem) !important;
}

.k-mx--2\.5 {
    margin-inline: var(--kendo-spacing--2\.5, -0.625rem);
}

.\!k-mx--2\.5 {
    margin-inline: var(--kendo-spacing--2\.5, -0.625rem) !important;
}

.k-mx--3 {
    margin-inline: var(--kendo-spacing--3, -0.75rem);
}

.\!k-mx--3 {
    margin-inline: var(--kendo-spacing--3, -0.75rem) !important;
}

.k-mx--3\.5 {
    margin-inline: var(--kendo-spacing--3\.5, -0.875rem);
}

.\!k-mx--3\.5 {
    margin-inline: var(--kendo-spacing--3\.5, -0.875rem) !important;
}

.k-mx--4 {
    margin-inline: var(--kendo-spacing--4, -1rem);
}

.\!k-mx--4 {
    margin-inline: var(--kendo-spacing--4, -1rem) !important;
}

.k-mx--4\.5 {
    margin-inline: var(--kendo-spacing--4\.5, -1.125rem);
}

.\!k-mx--4\.5 {
    margin-inline: var(--kendo-spacing--4\.5, -1.125rem) !important;
}

.k-mx--5 {
    margin-inline: var(--kendo-spacing--5, -1.25rem);
}

.\!k-mx--5 {
    margin-inline: var(--kendo-spacing--5, -1.25rem) !important;
}

.k-mx--5\.5 {
    margin-inline: var(--kendo-spacing--5\.5, -1.375rem);
}

.\!k-mx--5\.5 {
    margin-inline: var(--kendo-spacing--5\.5, -1.375rem) !important;
}

.k-mx--6 {
    margin-inline: var(--kendo-spacing--6, -1.5rem);
}

.\!k-mx--6 {
    margin-inline: var(--kendo-spacing--6, -1.5rem) !important;
}

.k-mx--6\.5 {
    margin-inline: var(--kendo-spacing--6\.5, -1.625rem);
}

.\!k-mx--6\.5 {
    margin-inline: var(--kendo-spacing--6\.5, -1.625rem) !important;
}

.k-mx--7 {
    margin-inline: var(--kendo-spacing--7, -1.75rem);
}

.\!k-mx--7 {
    margin-inline: var(--kendo-spacing--7, -1.75rem) !important;
}

.k-mx--7\.5 {
    margin-inline: var(--kendo-spacing--7\.5, -1.875rem);
}

.\!k-mx--7\.5 {
    margin-inline: var(--kendo-spacing--7\.5, -1.875rem) !important;
}

.k-mx--8 {
    margin-inline: var(--kendo-spacing--8, -2rem);
}

.\!k-mx--8 {
    margin-inline: var(--kendo-spacing--8, -2rem) !important;
}

.k-mx--9 {
    margin-inline: var(--kendo-spacing--9, -2.25rem);
}

.\!k-mx--9 {
    margin-inline: var(--kendo-spacing--9, -2.25rem) !important;
}

.k-mx--10 {
    margin-inline: var(--kendo-spacing--10, -2.5rem);
}

.\!k-mx--10 {
    margin-inline: var(--kendo-spacing--10, -2.5rem) !important;
}

.k-mx--11 {
    margin-inline: var(--kendo-spacing--11, -2.75rem);
}

.\!k-mx--11 {
    margin-inline: var(--kendo-spacing--11, -2.75rem) !important;
}

.k-mx--12 {
    margin-inline: var(--kendo-spacing--12, -3rem);
}

.\!k-mx--12 {
    margin-inline: var(--kendo-spacing--12, -3rem) !important;
}

.k-mx--13 {
    margin-inline: var(--kendo-spacing--13, -3.25rem);
}

.\!k-mx--13 {
    margin-inline: var(--kendo-spacing--13, -3.25rem) !important;
}

.k-mx--14 {
    margin-inline: var(--kendo-spacing--14, -3.5rem);
}

.\!k-mx--14 {
    margin-inline: var(--kendo-spacing--14, -3.5rem) !important;
}

.k-mx--15 {
    margin-inline: var(--kendo-spacing--15, -3.75rem);
}

.\!k-mx--15 {
    margin-inline: var(--kendo-spacing--15, -3.75rem) !important;
}

.k-mx--16 {
    margin-inline: var(--kendo-spacing--16, -4rem);
}

.\!k-mx--16 {
    margin-inline: var(--kendo-spacing--16, -4rem) !important;
}

.k-mx--17 {
    margin-inline: var(--kendo-spacing--17, -4.25rem);
}

.\!k-mx--17 {
    margin-inline: var(--kendo-spacing--17, -4.25rem) !important;
}

.k-mx--18 {
    margin-inline: var(--kendo-spacing--18, -4.5rem);
}

.\!k-mx--18 {
    margin-inline: var(--kendo-spacing--18, -4.5rem) !important;
}

.k-mx--19 {
    margin-inline: var(--kendo-spacing--19, -4.75rem);
}

.\!k-mx--19 {
    margin-inline: var(--kendo-spacing--19, -4.75rem) !important;
}

.k-mx--20 {
    margin-inline: var(--kendo-spacing--20, -5rem);
}

.\!k-mx--20 {
    margin-inline: var(--kendo-spacing--20, -5rem) !important;
}

.k-mx--21 {
    margin-inline: var(--kendo-spacing--21, -5.25rem);
}

.\!k-mx--21 {
    margin-inline: var(--kendo-spacing--21, -5.25rem) !important;
}

.k-mx--22 {
    margin-inline: var(--kendo-spacing--22, -5.5rem);
}

.\!k-mx--22 {
    margin-inline: var(--kendo-spacing--22, -5.5rem) !important;
}

.k-mx--23 {
    margin-inline: var(--kendo-spacing--23, -5.75rem);
}

.\!k-mx--23 {
    margin-inline: var(--kendo-spacing--23, -5.75rem) !important;
}

.k-mx--24 {
    margin-inline: var(--kendo-spacing--24, -6rem);
}

.\!k-mx--24 {
    margin-inline: var(--kendo-spacing--24, -6rem) !important;
}

.k-mx--25 {
    margin-inline: var(--kendo-spacing--25, -7rem);
}

.\!k-mx--25 {
    margin-inline: var(--kendo-spacing--25, -7rem) !important;
}

.k-mx--26 {
    margin-inline: var(--kendo-spacing--26, -8rem);
}

.\!k-mx--26 {
    margin-inline: var(--kendo-spacing--26, -8rem) !important;
}

.k-mx--27 {
    margin-inline: var(--kendo-spacing--27, -9rem);
}

.\!k-mx--27 {
    margin-inline: var(--kendo-spacing--27, -9rem) !important;
}

.k-mx--28 {
    margin-inline: var(--kendo-spacing--28, -10rem);
}

.\!k-mx--28 {
    margin-inline: var(--kendo-spacing--28, -10rem) !important;
}

.k-mx--29 {
    margin-inline: var(--kendo-spacing--29, -11rem);
}

.\!k-mx--29 {
    margin-inline: var(--kendo-spacing--29, -11rem) !important;
}

.k-mx--30 {
    margin-inline: var(--kendo-spacing--30, -12rem);
}

.\!k-mx--30 {
    margin-inline: var(--kendo-spacing--30, -12rem) !important;
}

.k-mx-xs {
    margin-inline: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-mx-xs {
    margin-inline: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-mx-sm {
    margin-inline: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-mx-sm {
    margin-inline: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-mx-md {
    margin-inline: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-mx-md {
    margin-inline: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-mx-lg {
    margin-inline: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-mx-lg {
    margin-inline: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-mx-xl {
    margin-inline: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-mx-xl {
    margin-inline: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-mx-thin {
    margin-inline: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-mx-thin {
    margin-inline: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-mx-hair {
    margin-inline: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-mx-hair {
    margin-inline: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-mx-auto {
    margin-inline: var(--kendo-spacing-auto, auto);
}

.\!k-mx-auto {
    margin-inline: var(--kendo-spacing-auto, auto) !important;
}

.k-my-0 {
    margin-block: var(--kendo-spacing-0, 0px);
}

.\!k-my-0 {
    margin-block: var(--kendo-spacing-0, 0px) !important;
}

.k-my-1px {
    margin-block: var(--kendo-spacing-1px, 1px);
}

.\!k-my-1px {
    margin-block: var(--kendo-spacing-1px, 1px) !important;
}

.k-my-0\.5 {
    margin-block: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-my-0\.5 {
    margin-block: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-my-1 {
    margin-block: var(--kendo-spacing-1, 0.25rem);
}

.\!k-my-1 {
    margin-block: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-my-1\.5 {
    margin-block: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-my-1\.5 {
    margin-block: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-my-2 {
    margin-block: var(--kendo-spacing-2, 0.5rem);
}

.\!k-my-2 {
    margin-block: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-my-2\.5 {
    margin-block: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-my-2\.5 {
    margin-block: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-my-3 {
    margin-block: var(--kendo-spacing-3, 0.75rem);
}

.\!k-my-3 {
    margin-block: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-my-3\.5 {
    margin-block: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-my-3\.5 {
    margin-block: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-my-4 {
    margin-block: var(--kendo-spacing-4, 1rem);
}

.\!k-my-4 {
    margin-block: var(--kendo-spacing-4, 1rem) !important;
}

.k-my-4\.5 {
    margin-block: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-my-4\.5 {
    margin-block: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-my-5 {
    margin-block: var(--kendo-spacing-5, 1.25rem);
}

.\!k-my-5 {
    margin-block: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-my-5\.5 {
    margin-block: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-my-5\.5 {
    margin-block: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-my-6 {
    margin-block: var(--kendo-spacing-6, 1.5rem);
}

.\!k-my-6 {
    margin-block: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-my-6\.5 {
    margin-block: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-my-6\.5 {
    margin-block: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-my-7 {
    margin-block: var(--kendo-spacing-7, 1.75rem);
}

.\!k-my-7 {
    margin-block: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-my-7\.5 {
    margin-block: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-my-7\.5 {
    margin-block: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-my-8 {
    margin-block: var(--kendo-spacing-8, 2rem);
}

.\!k-my-8 {
    margin-block: var(--kendo-spacing-8, 2rem) !important;
}

.k-my-9 {
    margin-block: var(--kendo-spacing-9, 2.25rem);
}

.\!k-my-9 {
    margin-block: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-my-10 {
    margin-block: var(--kendo-spacing-10, 2.5rem);
}

.\!k-my-10 {
    margin-block: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-my-11 {
    margin-block: var(--kendo-spacing-11, 2.75rem);
}

.\!k-my-11 {
    margin-block: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-my-12 {
    margin-block: var(--kendo-spacing-12, 3rem);
}

.\!k-my-12 {
    margin-block: var(--kendo-spacing-12, 3rem) !important;
}

.k-my-13 {
    margin-block: var(--kendo-spacing-13, 3.25rem);
}

.\!k-my-13 {
    margin-block: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-my-14 {
    margin-block: var(--kendo-spacing-14, 3.5rem);
}

.\!k-my-14 {
    margin-block: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-my-15 {
    margin-block: var(--kendo-spacing-15, 3.75rem);
}

.\!k-my-15 {
    margin-block: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-my-16 {
    margin-block: var(--kendo-spacing-16, 4rem);
}

.\!k-my-16 {
    margin-block: var(--kendo-spacing-16, 4rem) !important;
}

.k-my-17 {
    margin-block: var(--kendo-spacing-17, 4.25rem);
}

.\!k-my-17 {
    margin-block: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-my-18 {
    margin-block: var(--kendo-spacing-18, 4.5rem);
}

.\!k-my-18 {
    margin-block: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-my-19 {
    margin-block: var(--kendo-spacing-19, 4.75rem);
}

.\!k-my-19 {
    margin-block: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-my-20 {
    margin-block: var(--kendo-spacing-20, 5rem);
}

.\!k-my-20 {
    margin-block: var(--kendo-spacing-20, 5rem) !important;
}

.k-my-21 {
    margin-block: var(--kendo-spacing-21, 5.25rem);
}

.\!k-my-21 {
    margin-block: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-my-22 {
    margin-block: var(--kendo-spacing-22, 5.5rem);
}

.\!k-my-22 {
    margin-block: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-my-23 {
    margin-block: var(--kendo-spacing-23, 5.75rem);
}

.\!k-my-23 {
    margin-block: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-my-24 {
    margin-block: var(--kendo-spacing-24, 6rem);
}

.\!k-my-24 {
    margin-block: var(--kendo-spacing-24, 6rem) !important;
}

.k-my-25 {
    margin-block: var(--kendo-spacing-25, 7rem);
}

.\!k-my-25 {
    margin-block: var(--kendo-spacing-25, 7rem) !important;
}

.k-my-26 {
    margin-block: var(--kendo-spacing-26, 8rem);
}

.\!k-my-26 {
    margin-block: var(--kendo-spacing-26, 8rem) !important;
}

.k-my-27 {
    margin-block: var(--kendo-spacing-27, 9rem);
}

.\!k-my-27 {
    margin-block: var(--kendo-spacing-27, 9rem) !important;
}

.k-my-28 {
    margin-block: var(--kendo-spacing-28, 10rem);
}

.\!k-my-28 {
    margin-block: var(--kendo-spacing-28, 10rem) !important;
}

.k-my-29 {
    margin-block: var(--kendo-spacing-29, 11rem);
}

.\!k-my-29 {
    margin-block: var(--kendo-spacing-29, 11rem) !important;
}

.k-my-30 {
    margin-block: var(--kendo-spacing-30, 12rem);
}

.\!k-my-30 {
    margin-block: var(--kendo-spacing-30, 12rem) !important;
}

.k-my--0 {
    margin-block: var(--kendo-spacing--0, 0px);
}

.\!k-my--0 {
    margin-block: var(--kendo-spacing--0, 0px) !important;
}

.k-my--1px {
    margin-block: var(--kendo-spacing--1px, -1px);
}

.\!k-my--1px {
    margin-block: var(--kendo-spacing--1px, -1px) !important;
}

.k-my--0\.5 {
    margin-block: var(--kendo-spacing--0\.5, -0.125rem);
}

.\!k-my--0\.5 {
    margin-block: var(--kendo-spacing--0\.5, -0.125rem) !important;
}

.k-my--1 {
    margin-block: var(--kendo-spacing--1, -0.25rem);
}

.\!k-my--1 {
    margin-block: var(--kendo-spacing--1, -0.25rem) !important;
}

.k-my--1\.5 {
    margin-block: var(--kendo-spacing--1\.5, -0.375rem);
}

.\!k-my--1\.5 {
    margin-block: var(--kendo-spacing--1\.5, -0.375rem) !important;
}

.k-my--2 {
    margin-block: var(--kendo-spacing--2, -0.5rem);
}

.\!k-my--2 {
    margin-block: var(--kendo-spacing--2, -0.5rem) !important;
}

.k-my--2\.5 {
    margin-block: var(--kendo-spacing--2\.5, -0.625rem);
}

.\!k-my--2\.5 {
    margin-block: var(--kendo-spacing--2\.5, -0.625rem) !important;
}

.k-my--3 {
    margin-block: var(--kendo-spacing--3, -0.75rem);
}

.\!k-my--3 {
    margin-block: var(--kendo-spacing--3, -0.75rem) !important;
}

.k-my--3\.5 {
    margin-block: var(--kendo-spacing--3\.5, -0.875rem);
}

.\!k-my--3\.5 {
    margin-block: var(--kendo-spacing--3\.5, -0.875rem) !important;
}

.k-my--4 {
    margin-block: var(--kendo-spacing--4, -1rem);
}

.\!k-my--4 {
    margin-block: var(--kendo-spacing--4, -1rem) !important;
}

.k-my--4\.5 {
    margin-block: var(--kendo-spacing--4\.5, -1.125rem);
}

.\!k-my--4\.5 {
    margin-block: var(--kendo-spacing--4\.5, -1.125rem) !important;
}

.k-my--5 {
    margin-block: var(--kendo-spacing--5, -1.25rem);
}

.\!k-my--5 {
    margin-block: var(--kendo-spacing--5, -1.25rem) !important;
}

.k-my--5\.5 {
    margin-block: var(--kendo-spacing--5\.5, -1.375rem);
}

.\!k-my--5\.5 {
    margin-block: var(--kendo-spacing--5\.5, -1.375rem) !important;
}

.k-my--6 {
    margin-block: var(--kendo-spacing--6, -1.5rem);
}

.\!k-my--6 {
    margin-block: var(--kendo-spacing--6, -1.5rem) !important;
}

.k-my--6\.5 {
    margin-block: var(--kendo-spacing--6\.5, -1.625rem);
}

.\!k-my--6\.5 {
    margin-block: var(--kendo-spacing--6\.5, -1.625rem) !important;
}

.k-my--7 {
    margin-block: var(--kendo-spacing--7, -1.75rem);
}

.\!k-my--7 {
    margin-block: var(--kendo-spacing--7, -1.75rem) !important;
}

.k-my--7\.5 {
    margin-block: var(--kendo-spacing--7\.5, -1.875rem);
}

.\!k-my--7\.5 {
    margin-block: var(--kendo-spacing--7\.5, -1.875rem) !important;
}

.k-my--8 {
    margin-block: var(--kendo-spacing--8, -2rem);
}

.\!k-my--8 {
    margin-block: var(--kendo-spacing--8, -2rem) !important;
}

.k-my--9 {
    margin-block: var(--kendo-spacing--9, -2.25rem);
}

.\!k-my--9 {
    margin-block: var(--kendo-spacing--9, -2.25rem) !important;
}

.k-my--10 {
    margin-block: var(--kendo-spacing--10, -2.5rem);
}

.\!k-my--10 {
    margin-block: var(--kendo-spacing--10, -2.5rem) !important;
}

.k-my--11 {
    margin-block: var(--kendo-spacing--11, -2.75rem);
}

.\!k-my--11 {
    margin-block: var(--kendo-spacing--11, -2.75rem) !important;
}

.k-my--12 {
    margin-block: var(--kendo-spacing--12, -3rem);
}

.\!k-my--12 {
    margin-block: var(--kendo-spacing--12, -3rem) !important;
}

.k-my--13 {
    margin-block: var(--kendo-spacing--13, -3.25rem);
}

.\!k-my--13 {
    margin-block: var(--kendo-spacing--13, -3.25rem) !important;
}

.k-my--14 {
    margin-block: var(--kendo-spacing--14, -3.5rem);
}

.\!k-my--14 {
    margin-block: var(--kendo-spacing--14, -3.5rem) !important;
}

.k-my--15 {
    margin-block: var(--kendo-spacing--15, -3.75rem);
}

.\!k-my--15 {
    margin-block: var(--kendo-spacing--15, -3.75rem) !important;
}

.k-my--16 {
    margin-block: var(--kendo-spacing--16, -4rem);
}

.\!k-my--16 {
    margin-block: var(--kendo-spacing--16, -4rem) !important;
}

.k-my--17 {
    margin-block: var(--kendo-spacing--17, -4.25rem);
}

.\!k-my--17 {
    margin-block: var(--kendo-spacing--17, -4.25rem) !important;
}

.k-my--18 {
    margin-block: var(--kendo-spacing--18, -4.5rem);
}

.\!k-my--18 {
    margin-block: var(--kendo-spacing--18, -4.5rem) !important;
}

.k-my--19 {
    margin-block: var(--kendo-spacing--19, -4.75rem);
}

.\!k-my--19 {
    margin-block: var(--kendo-spacing--19, -4.75rem) !important;
}

.k-my--20 {
    margin-block: var(--kendo-spacing--20, -5rem);
}

.\!k-my--20 {
    margin-block: var(--kendo-spacing--20, -5rem) !important;
}

.k-my--21 {
    margin-block: var(--kendo-spacing--21, -5.25rem);
}

.\!k-my--21 {
    margin-block: var(--kendo-spacing--21, -5.25rem) !important;
}

.k-my--22 {
    margin-block: var(--kendo-spacing--22, -5.5rem);
}

.\!k-my--22 {
    margin-block: var(--kendo-spacing--22, -5.5rem) !important;
}

.k-my--23 {
    margin-block: var(--kendo-spacing--23, -5.75rem);
}

.\!k-my--23 {
    margin-block: var(--kendo-spacing--23, -5.75rem) !important;
}

.k-my--24 {
    margin-block: var(--kendo-spacing--24, -6rem);
}

.\!k-my--24 {
    margin-block: var(--kendo-spacing--24, -6rem) !important;
}

.k-my--25 {
    margin-block: var(--kendo-spacing--25, -7rem);
}

.\!k-my--25 {
    margin-block: var(--kendo-spacing--25, -7rem) !important;
}

.k-my--26 {
    margin-block: var(--kendo-spacing--26, -8rem);
}

.\!k-my--26 {
    margin-block: var(--kendo-spacing--26, -8rem) !important;
}

.k-my--27 {
    margin-block: var(--kendo-spacing--27, -9rem);
}

.\!k-my--27 {
    margin-block: var(--kendo-spacing--27, -9rem) !important;
}

.k-my--28 {
    margin-block: var(--kendo-spacing--28, -10rem);
}

.\!k-my--28 {
    margin-block: var(--kendo-spacing--28, -10rem) !important;
}

.k-my--29 {
    margin-block: var(--kendo-spacing--29, -11rem);
}

.\!k-my--29 {
    margin-block: var(--kendo-spacing--29, -11rem) !important;
}

.k-my--30 {
    margin-block: var(--kendo-spacing--30, -12rem);
}

.\!k-my--30 {
    margin-block: var(--kendo-spacing--30, -12rem) !important;
}

.k-my-xs {
    margin-block: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-my-xs {
    margin-block: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-my-sm {
    margin-block: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-my-sm {
    margin-block: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-my-md {
    margin-block: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-my-md {
    margin-block: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-my-lg {
    margin-block: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-my-lg {
    margin-block: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-my-xl {
    margin-block: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-my-xl {
    margin-block: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-my-thin {
    margin-block: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-my-thin {
    margin-block: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-my-hair {
    margin-block: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-my-hair {
    margin-block: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-my-auto {
    margin-block: var(--kendo-spacing-auto, auto);
}

.\!k-my-auto {
    margin-block: var(--kendo-spacing-auto, auto) !important;
}

.k-font-family-sans {
    font-family: var(--kendo-font-family-sans, Arial, Verdana, Tahoma, Trebuchet MS, Helvetica, Impact, Gill Sans);
}

.\!k-font-family-sans {
    font-family: var(--kendo-font-family-sans, Arial, Verdana, Tahoma, Trebuchet MS, Helvetica, Impact, Gill Sans) !important;
}

.k-font-family-serif {
    font-family: var(--kendo-font-family-serif, Times New Roman, Georgia, Garamond, Palatino, Baskerville);
}

.\!k-font-family-serif {
    font-family: var(--kendo-font-family-serif, Times New Roman, Georgia, Garamond, Palatino, Baskerville) !important;
}

.k-font-family-sans-serif {
    font-family: var(
        --kendo-font-family-sans-serif,
        system-ui,
        -apple-system,
        Segoe UI,
        Roboto,
        Helvetica Neue,
        Noto Sans,
        Liberation Sans,
        Arial,
        sans-serif,
        Apple Color Emoji,
        Segoe UI Emoji,
        Segoe UI Symbol,
        Noto Color Emoji
    );
}

.\!k-font-family-sans-serif {
    font-family: var(
        --kendo-font-family-sans-serif,
        system-ui,
        -apple-system,
        Segoe UI,
        Roboto,
        Helvetica Neue,
        Noto Sans,
        Liberation Sans,
        Arial,
        sans-serif,
        Apple Color Emoji,
        Segoe UI Emoji,
        Segoe UI Symbol,
        Noto Color Emoji
    ) !important;
}

.k-font-family-monospace {
    font-family: var(
        --kendo-font-family-monospace,
        SFMono-Regular,
        Menlo,
        Monaco,
        Consolas,
        Roboto Mono,
        Ubuntu Mono,
        Lucida Console,
        Courier New,
        monospace
    );
}

.\!k-font-family-monospace {
    font-family: var(
        --kendo-font-family-monospace,
        SFMono-Regular,
        Menlo,
        Monaco,
        Consolas,
        Roboto Mono,
        Ubuntu Mono,
        Lucida Console,
        Courier New,
        monospace
    ) !important;
}

.k-font-size-xxs {
    font-size: var(--kendo-font-size-xxs, 0.5rem);
}

.\!k-font-size-xxs {
    font-size: var(--kendo-font-size-xxs, 0.5rem) !important;
}

.k-font-size-xs {
    font-size: var(--kendo-font-size-xs, 0.625rem);
}

.\!k-font-size-xs {
    font-size: var(--kendo-font-size-xs, 0.625rem) !important;
}

.k-font-size-sm {
    font-size: var(--kendo-font-size-sm, 0.75rem);
}

.\!k-font-size-sm {
    font-size: var(--kendo-font-size-sm, 0.75rem) !important;
}

.k-font-size-md {
    font-size: var(--kendo-font-size-md, 0.875rem);
}

.\!k-font-size-md {
    font-size: var(--kendo-font-size-md, 0.875rem) !important;
}

.k-font-size-lg {
    font-size: var(--kendo-font-size-lg, 1rem);
}

.\!k-font-size-lg {
    font-size: var(--kendo-font-size-lg, 1rem) !important;
}

.k-font-size-xl {
    font-size: var(--kendo-font-size-xl, 1.25rem);
}

.\!k-font-size-xl {
    font-size: var(--kendo-font-size-xl, 1.25rem) !important;
}

.k-fs-xxs {
    font-size: var(--kendo-fs-xxs, 0.5rem);
}

.\!k-fs-xxs {
    font-size: var(--kendo-fs-xxs, 0.5rem) !important;
}

.k-fs-xs {
    font-size: var(--kendo-fs-xs, 0.625rem);
}

.\!k-fs-xs {
    font-size: var(--kendo-fs-xs, 0.625rem) !important;
}

.k-fs-sm {
    font-size: var(--kendo-fs-sm, 0.75rem);
}

.\!k-fs-sm {
    font-size: var(--kendo-fs-sm, 0.75rem) !important;
}

.k-fs-md {
    font-size: var(--kendo-fs-md, 0.875rem);
}

.\!k-fs-md {
    font-size: var(--kendo-fs-md, 0.875rem) !important;
}

.k-fs-lg {
    font-size: var(--kendo-fs-lg, 1rem);
}

.\!k-fs-lg {
    font-size: var(--kendo-fs-lg, 1rem) !important;
}

.k-fs-xl {
    font-size: var(--kendo-fs-xl, 1.25rem);
}

.\!k-fs-xl {
    font-size: var(--kendo-fs-xl, 1.25rem) !important;
}

.k-font-style-italic {
    font-style: var(--kendo-font-style-italic, italic);
}

.\!k-font-style-italic {
    font-style: var(--kendo-font-style-italic, italic) !important;
}

.k-font-style-non-italic {
    font-style: var(--kendo-font-style-non-italic, normal);
}

.\!k-font-style-non-italic {
    font-style: var(--kendo-font-style-non-italic, normal) !important;
}

.k-font-style-oblique {
    font-style: var(--kendo-font-style-oblique, oblique);
}

.\!k-font-style-oblique {
    font-style: var(--kendo-font-style-oblique, oblique) !important;
}

.k-font-thin {
    font-weight: var(--kendo-font-weight-thin, 100);
}

.\!k-font-thin {
    font-weight: var(--kendo-font-weight-thin, 100) !important;
}

.k-font-extra-light {
    font-weight: var(--kendo-font-weight-extra-light, 200);
}

.\!k-font-extra-light {
    font-weight: var(--kendo-font-weight-extra-light, 200) !important;
}

.k-font-light {
    font-weight: var(--kendo-font-weight-light, 300);
}

.\!k-font-light {
    font-weight: var(--kendo-font-weight-light, 300) !important;
}

.k-font-normal {
    font-weight: var(--kendo-font-weight-normal, 400);
}

.\!k-font-normal {
    font-weight: var(--kendo-font-weight-normal, 400) !important;
}

.k-font-medium {
    font-weight: var(--kendo-font-weight-medium, 500);
}

.\!k-font-medium {
    font-weight: var(--kendo-font-weight-medium, 500) !important;
}

.k-font-semibold {
    font-weight: var(--kendo-font-weight-semibold, 600);
}

.\!k-font-semibold {
    font-weight: var(--kendo-font-weight-semibold, 600) !important;
}

.k-font-bold {
    font-weight: var(--kendo-font-weight-bold, 700);
}

.\!k-font-bold {
    font-weight: var(--kendo-font-weight-bold, 700) !important;
}

.k-font-weight-thin {
    font-weight: var(--kendo-font-weight-thin, 100);
}

.\!k-font-weight-thin {
    font-weight: var(--kendo-font-weight-thin, 100) !important;
}

.k-font-weight-extra-light {
    font-weight: var(--kendo-font-weight-extra-light, 200);
}

.\!k-font-weight-extra-light {
    font-weight: var(--kendo-font-weight-extra-light, 200) !important;
}

.k-font-weight-light {
    font-weight: var(--kendo-font-weight-light, 300);
}

.\!k-font-weight-light {
    font-weight: var(--kendo-font-weight-light, 300) !important;
}

.k-font-weight-normal {
    font-weight: var(--kendo-font-weight-normal, 400);
}

.\!k-font-weight-normal {
    font-weight: var(--kendo-font-weight-normal, 400) !important;
}

.k-font-weight-medium {
    font-weight: var(--kendo-font-weight-medium, 500);
}

.\!k-font-weight-medium {
    font-weight: var(--kendo-font-weight-medium, 500) !important;
}

.k-font-weight-semibold {
    font-weight: var(--kendo-font-weight-semibold, 600);
}

.\!k-font-weight-semibold {
    font-weight: var(--kendo-font-weight-semibold, 600) !important;
}

.k-font-weight-bold {
    font-weight: var(--kendo-font-weight-bold, 700);
}

.\!k-font-weight-bold {
    font-weight: var(--kendo-font-weight-bold, 700) !important;
}

.k-letter-spacing-tightest {
    letter-spacing: var(--kendo-letter-spacing-tightest, -0.15px);
}

.\!k-letter-spacing-tightest {
    letter-spacing: var(--kendo-letter-spacing-tightest, -0.15px) !important;
}

.k-letter-spacing-tighter {
    letter-spacing: var(--kendo-letter-spacing-tighter, -0.1px);
}

.\!k-letter-spacing-tighter {
    letter-spacing: var(--kendo-letter-spacing-tighter, -0.1px) !important;
}

.k-letter-spacing-tight {
    letter-spacing: var(--kendo-letter-spacing-tight, -0.5px);
}

.\!k-letter-spacing-tight {
    letter-spacing: var(--kendo-letter-spacing-tight, -0.5px) !important;
}

.k-letter-spacing-normal {
    letter-spacing: var(--kendo-letter-spacing-normal, 0px);
}

.\!k-letter-spacing-normal {
    letter-spacing: var(--kendo-letter-spacing-normal, 0px) !important;
}

.k-letter-spacing-wide {
    letter-spacing: var(--kendo-letter-spacing-wide, 0.5px);
}

.\!k-letter-spacing-wide {
    letter-spacing: var(--kendo-letter-spacing-wide, 0.5px) !important;
}

.k-letter-spacing-wider {
    letter-spacing: var(--kendo-letter-spacing-wider, 0.1px);
}

.\!k-letter-spacing-wider {
    letter-spacing: var(--kendo-letter-spacing-wider, 0.1px) !important;
}

.k-letter-spacing-widest {
    letter-spacing: var(--kendo-letter-spacing-widest, 0.15px);
}

.\!k-letter-spacing-widest {
    letter-spacing: var(--kendo-letter-spacing-widest, 0.15px) !important;
}

.k-line-height-xs {
    line-height: var(--kendo-line-height-xs, 1);
}

.\!k-line-height-xs {
    line-height: var(--kendo-line-height-xs, 1) !important;
}

.k-line-height-sm {
    line-height: var(--kendo-line-height-sm, 1.25);
}

.\!k-line-height-sm {
    line-height: var(--kendo-line-height-sm, 1.25) !important;
}

.k-line-height-md {
    line-height: var(--kendo-line-height-md, 1.4285714286);
}

.\!k-line-height-md {
    line-height: var(--kendo-line-height-md, 1.4285714286) !important;
}

.k-line-height-lg {
    line-height: var(--kendo-line-height-lg, 1.5);
}

.\!k-line-height-lg {
    line-height: var(--kendo-line-height-lg, 1.5) !important;
}

.k-list-none {
    list-style-type: none;
}

.\!k-list-none {
    list-style-type: none !important;
}

.k-list-disc {
    list-style-type: disc;
}

.\!k-list-disc {
    list-style-type: disc !important;
}

.k-list-decimal {
    list-style-type: decimal;
}

.\!k-list-decimal {
    list-style-type: decimal !important;
}

.k-text-left {
    text-align: left;
}

.\!k-text-left {
    text-align: left !important;
}

.k-text-right {
    text-align: right;
}

.\!k-text-right {
    text-align: right !important;
}

.k-text-center {
    text-align: center;
}

.\!k-text-center {
    text-align: center !important;
}

.k-text-justify {
    text-align: justify;
}

.\!k-text-justify {
    text-align: justify !important;
}

.k-text-start {
    text-align: start;
}

.\!k-text-start {
    text-align: start !important;
}

.k-text-end {
    text-align: end;
}

.\!k-text-end {
    text-align: end !important;
}

.k-text-app-surface {
    color: var(--kendo-color-app-surface, #ffffff);
}

.\!k-text-app-surface {
    color: var(--kendo-color-app-surface, #ffffff) !important;
}

.k-text-on-app-surface {
    color: var(--kendo-color-on-app-surface, #3d3d3d);
}

.\!k-text-on-app-surface {
    color: var(--kendo-color-on-app-surface, #3d3d3d) !important;
}

.k-text-subtle {
    color: var(--kendo-color-subtle, #666666);
}

.\!k-text-subtle {
    color: var(--kendo-color-subtle, #666666) !important;
}

.k-text-surface {
    color: var(--kendo-color-surface, #fafafa);
}

.\!k-text-surface {
    color: var(--kendo-color-surface, #fafafa) !important;
}

.k-text-surface-alt {
    color: var(--kendo-color-surface-alt, #ffffff);
}

.\!k-text-surface-alt {
    color: var(--kendo-color-surface-alt, #ffffff) !important;
}

.k-text-border {
    color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}

.\!k-text-border {
    color: var(--kendo-color-border, rgba(0, 0, 0, 0.08)) !important;
}

.k-text-border-alt {
    color: var(--kendo-color-border-alt, rgba(0, 0, 0, 0.16));
}

.\!k-text-border-alt {
    color: var(--kendo-color-border-alt, rgba(0, 0, 0, 0.16)) !important;
}

.k-text-base-subtle {
    color: var(--kendo-color-base-subtle, #ebebeb);
}

.\!k-text-base-subtle {
    color: var(--kendo-color-base-subtle, #ebebeb) !important;
}

.k-text-base-emphasis {
    color: var(--kendo-color-base-emphasis, #c2c2c2);
}

.\!k-text-base-emphasis {
    color: var(--kendo-color-base-emphasis, #c2c2c2) !important;
}

.k-text-primary-subtle {
    color: var(--kendo-color-primary-subtle, #d5def5);
}

.\!k-text-primary-subtle {
    color: var(--kendo-color-primary-subtle, #d5def5) !important;
}

.k-text-primary {
    color: var(--kendo-color-primary, rgb(44, 91, 204));
}

.\!k-text-primary {
    color: var(--kendo-color-primary, rgb(44, 91, 204)) !important;
}

.k-text-primary-emphasis {
    color: var(--kendo-color-primary-emphasis, #6184d9);
}

.\!k-text-primary-emphasis {
    color: var(--kendo-color-primary-emphasis, #6184d9) !important;
}

.k-text-secondary-subtle {
    color: var(--kendo-color-secondary-subtle, #fafafa);
}

.\!k-text-secondary-subtle {
    color: var(--kendo-color-secondary-subtle, #fafafa) !important;
}

.k-text-secondary {
    color: var(--kendo-color-secondary, #666666);
}

.\!k-text-secondary {
    color: var(--kendo-color-secondary, #666666) !important;
}

.k-text-secondary-emphasis {
    color: var(--kendo-color-secondary-emphasis, #e0e0e0);
}

.\!k-text-secondary-emphasis {
    color: var(--kendo-color-secondary-emphasis, #e0e0e0) !important;
}

.k-text-tertiary-subtle {
    color: var(--kendo-color-tertiary-subtle, #d8f1fd);
}

.\!k-text-tertiary-subtle {
    color: var(--kendo-color-tertiary-subtle, #d8f1fd) !important;
}

.k-text-tertiary {
    color: var(--kendo-color-tertiary, #03a9f4);
}

.\!k-text-tertiary {
    color: var(--kendo-color-tertiary, #03a9f4) !important;
}

.k-text-tertiary-emphasis {
    color: var(--kendo-color-tertiary-emphasis, #61c9f9);
}

.\!k-text-tertiary-emphasis {
    color: var(--kendo-color-tertiary-emphasis, #61c9f9) !important;
}

.k-text-info-subtle {
    color: var(--kendo-color-info-subtle, #d2e2fb);
}

.\!k-text-info-subtle {
    color: var(--kendo-color-info-subtle, #d2e2fb) !important;
}

.k-text-info {
    color: var(--kendo-color-info, #0058e9);
}

.\!k-text-info {
    color: var(--kendo-color-info, #0058e9) !important;
}

.k-text-info-emphasis {
    color: var(--kendo-color-info-emphasis, #6098f2);
}

.\!k-text-info-emphasis {
    color: var(--kendo-color-info-emphasis, #6098f2) !important;
}

.k-text-success-subtle {
    color: var(--kendo-color-success-subtle, #dcf0d3);
}

.\!k-text-success-subtle {
    color: var(--kendo-color-success-subtle, #dcf0d3) !important;
}

.k-text-success {
    color: var(--kendo-color-success, #37b400);
}

.\!k-text-success {
    color: var(--kendo-color-success, #37b400) !important;
}

.k-text-success-emphasis {
    color: var(--kendo-color-success-emphasis, #81d15f);
}

.\!k-text-success-emphasis {
    color: var(--kendo-color-success-emphasis, #81d15f) !important;
}

.k-text-warning-subtle {
    color: var(--kendo-color-warning-subtle, #fff4d3);
}

.\!k-text-warning-subtle {
    color: var(--kendo-color-warning-subtle, #fff4d3) !important;
}

.k-text-warning {
    color: var(--kendo-color-warning, #ffc000);
}

.\!k-text-warning {
    color: var(--kendo-color-warning, #ffc000) !important;
}

.k-text-warning-emphasis {
    color: var(--kendo-color-warning-emphasis, #ffd760);
}

.\!k-text-warning-emphasis {
    color: var(--kendo-color-warning-emphasis, #ffd760) !important;
}

.k-text-error-subtle {
    color: var(--kendo-color-error-subtle, #fcddda);
}

.\!k-text-error-subtle {
    color: var(--kendo-color-error-subtle, #fcddda) !important;
}

.k-text-error {
    color: var(--kendo-color-error, #f31700);
}

.\!k-text-error {
    color: var(--kendo-color-error, #f31700) !important;
}

.k-text-error-emphasis {
    color: var(--kendo-color-error-emphasis, #f76f60);
}

.\!k-text-error-emphasis {
    color: var(--kendo-color-error-emphasis, #f76f60) !important;
}

.k-text-light-subtle {
    color: var(--kendo-color-light-subtle, #fafafa);
}

.\!k-text-light-subtle {
    color: var(--kendo-color-light-subtle, #fafafa) !important;
}

.k-text-light {
    color: var(--kendo-color-light, #ebebeb);
}

.\!k-text-light {
    color: var(--kendo-color-light, #ebebeb) !important;
}

.k-text-light-emphasis {
    color: var(--kendo-color-light-emphasis, #d6d6d6);
}

.\!k-text-light-emphasis {
    color: var(--kendo-color-light-emphasis, #d6d6d6) !important;
}

.k-text-dark-subtle {
    color: var(--kendo-color-dark-subtle, #c2c2c2);
}

.\!k-text-dark-subtle {
    color: var(--kendo-color-dark-subtle, #c2c2c2) !important;
}

.k-text-dark {
    color: var(--kendo-color-dark, #3d3d3d);
}

.\!k-text-dark {
    color: var(--kendo-color-dark, #3d3d3d) !important;
}

.k-text-dark-emphasis {
    color: var(--kendo-color-dark-emphasis, #666666);
}

.\!k-text-dark-emphasis {
    color: var(--kendo-color-dark-emphasis, #666666) !important;
}

.k-text-inherit {
    color: var(--kendo-color-inherit, inherit);
}

.\!k-text-inherit {
    color: var(--kendo-color-inherit, inherit) !important;
}

.k-text-current {
    color: var(--kendo-color-current, currentColor);
}

.\!k-text-current {
    color: var(--kendo-color-current, currentColor) !important;
}

.k-text-transparent {
    color: var(--kendo-color-transparent, transparent);
}

.\!k-text-transparent {
    color: var(--kendo-color-transparent, transparent) !important;
}

.k-text-black {
    color: var(--kendo-color-black, black);
}

.\!k-text-black {
    color: var(--kendo-color-black, black) !important;
}

.k-text-white {
    color: var(--kendo-color-white, white);
}

.\!k-text-white {
    color: var(--kendo-color-white, white) !important;
}

.k-color-app-surface {
    color: var(--kendo-color-app-surface, #ffffff);
}

.\!k-color-app-surface {
    color: var(--kendo-color-app-surface, #ffffff) !important;
}

.k-color-on-app-surface {
    color: var(--kendo-color-on-app-surface, #3d3d3d);
}

.\!k-color-on-app-surface {
    color: var(--kendo-color-on-app-surface, #3d3d3d) !important;
}

.k-color-subtle {
    color: var(--kendo-color-subtle, #666666);
}

.\!k-color-subtle {
    color: var(--kendo-color-subtle, #666666) !important;
}

.k-color-surface {
    color: var(--kendo-color-surface, #fafafa);
}

.\!k-color-surface {
    color: var(--kendo-color-surface, #fafafa) !important;
}

.k-color-surface-alt {
    color: var(--kendo-color-surface-alt, #ffffff);
}

.\!k-color-surface-alt {
    color: var(--kendo-color-surface-alt, #ffffff) !important;
}

.k-color-border {
    color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}

.\!k-color-border {
    color: var(--kendo-color-border, rgba(0, 0, 0, 0.08)) !important;
}

.k-color-border-alt {
    color: var(--kendo-color-border-alt, rgba(0, 0, 0, 0.16));
}

.\!k-color-border-alt {
    color: var(--kendo-color-border-alt, rgba(0, 0, 0, 0.16)) !important;
}

.k-color-base-subtle {
    color: var(--kendo-color-base-subtle, #ebebeb);
}

.\!k-color-base-subtle {
    color: var(--kendo-color-base-subtle, #ebebeb) !important;
}

.k-color-base-emphasis {
    color: var(--kendo-color-base-emphasis, #c2c2c2);
}

.\!k-color-base-emphasis {
    color: var(--kendo-color-base-emphasis, #c2c2c2) !important;
}

.k-color-primary-subtle {
    color: var(--kendo-color-primary-subtle, #d5def5);
}

.\!k-color-primary-subtle {
    color: var(--kendo-color-primary-subtle, #d5def5) !important;
}

.k-color-primary {
    color: var(--kendo-color-primary, rgb(44, 91, 204));
}

.\!k-color-primary {
    color: var(--kendo-color-primary, rgb(44, 91, 204)) !important;
}

.k-color-primary-emphasis {
    color: var(--kendo-color-primary-emphasis, #6184d9);
}

.\!k-color-primary-emphasis {
    color: var(--kendo-color-primary-emphasis, #6184d9) !important;
}

.k-color-secondary-subtle {
    color: var(--kendo-color-secondary-subtle, #fafafa);
}

.\!k-color-secondary-subtle {
    color: var(--kendo-color-secondary-subtle, #fafafa) !important;
}

.k-color-secondary {
    color: var(--kendo-color-secondary, #666666);
}

.\!k-color-secondary {
    color: var(--kendo-color-secondary, #666666) !important;
}

.k-color-secondary-emphasis {
    color: var(--kendo-color-secondary-emphasis, #e0e0e0);
}

.\!k-color-secondary-emphasis {
    color: var(--kendo-color-secondary-emphasis, #e0e0e0) !important;
}

.k-color-tertiary-subtle {
    color: var(--kendo-color-tertiary-subtle, #d8f1fd);
}

.\!k-color-tertiary-subtle {
    color: var(--kendo-color-tertiary-subtle, #d8f1fd) !important;
}

.k-color-tertiary {
    color: var(--kendo-color-tertiary, #03a9f4);
}

.\!k-color-tertiary {
    color: var(--kendo-color-tertiary, #03a9f4) !important;
}

.k-color-tertiary-emphasis {
    color: var(--kendo-color-tertiary-emphasis, #61c9f9);
}

.\!k-color-tertiary-emphasis {
    color: var(--kendo-color-tertiary-emphasis, #61c9f9) !important;
}

.k-color-info-subtle {
    color: var(--kendo-color-info-subtle, #d2e2fb);
}

.\!k-color-info-subtle {
    color: var(--kendo-color-info-subtle, #d2e2fb) !important;
}

.k-color-info {
    color: var(--kendo-color-info, #0058e9);
}

.\!k-color-info {
    color: var(--kendo-color-info, #0058e9) !important;
}

.k-color-info-emphasis {
    color: var(--kendo-color-info-emphasis, #6098f2);
}

.\!k-color-info-emphasis {
    color: var(--kendo-color-info-emphasis, #6098f2) !important;
}

.k-color-success-subtle {
    color: var(--kendo-color-success-subtle, #dcf0d3);
}

.\!k-color-success-subtle {
    color: var(--kendo-color-success-subtle, #dcf0d3) !important;
}

.k-color-success {
    color: var(--kendo-color-success, #37b400);
}

.\!k-color-success {
    color: var(--kendo-color-success, #37b400) !important;
}

.k-color-success-emphasis {
    color: var(--kendo-color-success-emphasis, #81d15f);
}

.\!k-color-success-emphasis {
    color: var(--kendo-color-success-emphasis, #81d15f) !important;
}

.k-color-warning-subtle {
    color: var(--kendo-color-warning-subtle, #fff4d3);
}

.\!k-color-warning-subtle {
    color: var(--kendo-color-warning-subtle, #fff4d3) !important;
}

.k-color-warning {
    color: var(--kendo-color-warning, #ffc000);
}

.\!k-color-warning {
    color: var(--kendo-color-warning, #ffc000) !important;
}

.k-color-warning-emphasis {
    color: var(--kendo-color-warning-emphasis, #ffd760);
}

.\!k-color-warning-emphasis {
    color: var(--kendo-color-warning-emphasis, #ffd760) !important;
}

.k-color-error-subtle {
    color: var(--kendo-color-error-subtle, #fcddda);
}

.\!k-color-error-subtle {
    color: var(--kendo-color-error-subtle, #fcddda) !important;
}

.k-color-error {
    color: var(--kendo-color-error, #f31700);
}

.\!k-color-error {
    color: var(--kendo-color-error, #f31700) !important;
}

.k-color-error-emphasis {
    color: var(--kendo-color-error-emphasis, #f76f60);
}

.\!k-color-error-emphasis {
    color: var(--kendo-color-error-emphasis, #f76f60) !important;
}

.k-color-light-subtle {
    color: var(--kendo-color-light-subtle, #fafafa);
}

.\!k-color-light-subtle {
    color: var(--kendo-color-light-subtle, #fafafa) !important;
}

.k-color-light {
    color: var(--kendo-color-light, #ebebeb);
}

.\!k-color-light {
    color: var(--kendo-color-light, #ebebeb) !important;
}

.k-color-light-emphasis {
    color: var(--kendo-color-light-emphasis, #d6d6d6);
}

.\!k-color-light-emphasis {
    color: var(--kendo-color-light-emphasis, #d6d6d6) !important;
}

.k-color-dark-subtle {
    color: var(--kendo-color-dark-subtle, #c2c2c2);
}

.\!k-color-dark-subtle {
    color: var(--kendo-color-dark-subtle, #c2c2c2) !important;
}

.k-color-dark {
    color: var(--kendo-color-dark, #3d3d3d);
}

.\!k-color-dark {
    color: var(--kendo-color-dark, #3d3d3d) !important;
}

.k-color-dark-emphasis {
    color: var(--kendo-color-dark-emphasis, #666666);
}

.\!k-color-dark-emphasis {
    color: var(--kendo-color-dark-emphasis, #666666) !important;
}

.k-color-inherit {
    color: var(--kendo-color-inherit, inherit);
}

.\!k-color-inherit {
    color: var(--kendo-color-inherit, inherit) !important;
}

.k-color-current {
    color: var(--kendo-color-current, currentColor);
}

.\!k-color-current {
    color: var(--kendo-color-current, currentColor) !important;
}

.k-color-transparent {
    color: var(--kendo-color-transparent, transparent);
}

.\!k-color-transparent {
    color: var(--kendo-color-transparent, transparent) !important;
}

.k-color-black {
    color: var(--kendo-color-black, black);
}

.\!k-color-black {
    color: var(--kendo-color-black, black) !important;
}

.k-color-white {
    color: var(--kendo-color-white, white);
}

.\!k-color-white {
    color: var(--kendo-color-white, white) !important;
}

.k-text-underline {
    text-decoration: underline;
}

.\!k-text-underline {
    text-decoration: underline !important;
}

.k-text-overline {
    text-decoration: overline;
}

.\!k-text-overline {
    text-decoration: overline !important;
}

.k-text-line-through {
    text-decoration: line-through;
}

.\!k-text-line-through {
    text-decoration: line-through !important;
}

.k-text-no-underline {
    text-decoration: none;
}

.\!k-text-no-underline {
    text-decoration: none !important;
}

.k-text-clip {
    text-overflow: clip;
}

.\!k-text-clip {
    text-overflow: clip !important;
}

.k-text-ellipsis {
    text-overflow: ellipsis;
}

.\!k-text-ellipsis {
    text-overflow: ellipsis !important;
}

.k-text-truncate,
.k-text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.\!k-text-truncate,
.\!k-text-ellipsis {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.k-text-uppercase {
    text-transform: uppercase;
}

.\!k-text-uppercase {
    text-transform: uppercase !important;
}

.k-text-lowercase {
    text-transform: lowercase;
}

.\!k-text-lowercase {
    text-transform: lowercase !important;
}

.k-text-capitalize {
    text-transform: capitalize;
}

.\!k-text-capitalize {
    text-transform: capitalize !important;
}

.k-text-normal-case {
    text-transform: none;
}

.\!k-text-normal-case {
    text-transform: none !important;
}

.k-align-baseline {
    vertical-align: baseline;
}

.\!k-align-baseline {
    vertical-align: baseline !important;
}

.k-align-top {
    vertical-align: top;
}

.\!k-align-top {
    vertical-align: top !important;
}

.k-align-middle {
    vertical-align: middle;
}

.\!k-align-middle {
    vertical-align: middle !important;
}

.k-align-bottom {
    vertical-align: bottom;
}

.\!k-align-bottom {
    vertical-align: bottom !important;
}

.k-align-text-top {
    vertical-align: text-top;
}

.\!k-align-text-top {
    vertical-align: text-top !important;
}

.k-align-text-bottom {
    vertical-align: text-bottom;
}

.\!k-align-text-bottom {
    vertical-align: text-bottom !important;
}

.k-align-sub {
    vertical-align: sub;
}

.\!k-align-sub {
    vertical-align: sub !important;
}

.k-align-super {
    vertical-align: super;
}

.\!k-align-super {
    vertical-align: super !important;
}

.k-white-space-normal {
    white-space: normal;
}

.\!k-white-space-normal {
    white-space: normal !important;
}

.k-white-space-nowrap,
.k-text-nowrap {
    white-space: nowrap;
}

.\!k-white-space-nowrap {
    white-space: nowrap !important;
}

.k-white-space-pre {
    white-space: pre;
}

.\!k-white-space-pre {
    white-space: pre !important;
}

.k-white-space-pre-line {
    white-space: pre-line;
}

.\!k-white-space-pre-line {
    white-space: pre-line !important;
}

.k-white-space-pre-wrap {
    white-space: pre-wrap;
}

.\!k-white-space-pre-wrap {
    white-space: pre-wrap !important;
}

.k-white-space-break-spaces {
    white-space: break-spaces;
}

.\!k-white-space-break-spaces {
    white-space: break-spaces !important;
}

.k-whitespace-normal {
    white-space: normal;
}

.\!k-whitespace-normal {
    white-space: normal !important;
}

.k-whitespace-nowrap {
    white-space: nowrap;
}

.\!k-whitespace-nowrap {
    white-space: nowrap !important;
}

.k-whitespace-pre {
    white-space: pre;
}

.\!k-whitespace-pre {
    white-space: pre !important;
}

.k-whitespace-pre-line {
    white-space: pre-line;
}

.\!k-whitespace-pre-line {
    white-space: pre-line !important;
}

.k-whitespace-pre-wrap {
    white-space: pre-wrap;
}

.\!k-whitespace-pre-wrap {
    white-space: pre-wrap !important;
}

.k-whitespace-break-spaces {
    white-space: break-spaces;
}

.\!k-whitespace-break-spaces {
    white-space: break-spaces !important;
}

.k-svg-icon {
    width: 16px;
    height: 16px;
    outline: 0;
    line-height: 1;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    position: relative;
}
.k-svg-icon > svg {
    fill: currentColor;
    flex: 1 1 auto;
}

.k-svg-icon.k-icon-xs {
    width: calc(16px * 0.75);
    height: calc(16px * 0.75);
}
.k-svg-icon.k-icon-sm {
    width: calc(16px * 0.875);
    height: calc(16px * 0.875);
}
.k-svg-icon.k-icon-md {
    width: 16px;
    height: 16px;
}
.k-svg-icon.k-icon-lg {
    width: calc(16px * 1.25);
    height: calc(16px * 1.25);
}
.k-svg-icon.k-icon-xl {
    width: calc(16px * 1.5);
    height: calc(16px * 1.5);
}
.k-svg-icon.k-icon-xxl {
    width: calc(16px * 2);
    height: calc(16px * 2);
}
.k-svg-icon.k-icon-xxxl {
    width: calc(16px * 3);
    height: calc(16px * 3);
}

.k-flip-h.k-svg-icon,
.k-svg-icon.k-flip-x,
.k-flip-v.k-svg-icon,
.k-svg-icon.k-flip-y,
.k-flip-h.k-flip-v.k-svg-icon {
    transform: none;
}

.k-flip-h > svg,
.k-flip-x > svg {
    transform: scaleX(-1);
}

.k-flip-v > svg,
.k-flip-y > svg {
    transform: scaleY(-1);
}

.k-flip-h.k-flip-v > svg,
.k-flip-h.k-flip-y > svg,
.k-flip-v.k-flip-x > svg,
.k-flip-x.k-flip-y > svg,
.k-flip-both > svg {
    transform: scale(-1, -1);
}

.k-rotate-0.k-svg-icon {
    transform: none;
}
.k-rotate-0 > svg {
    transform: rotate(0deg);
}

.k-rotate-45.k-svg-icon {
    transform: none;
}
.k-rotate-45 > svg {
    transform: rotate(45deg);
}

.k-rotate-90.k-svg-icon {
    transform: none;
}
.k-rotate-90 > svg {
    transform: rotate(90deg);
}

.k-rotate-135.k-svg-icon {
    transform: none;
}
.k-rotate-135 > svg {
    transform: rotate(135deg);
}

.k-rotate-180.k-svg-icon {
    transform: none;
}
.k-rotate-180 > svg {
    transform: rotate(180deg);
}

.k-rotate-225.k-svg-icon {
    transform: none;
}
.k-rotate-225 > svg {
    transform: rotate(225deg);
}

.k-rotate-270.k-svg-icon {
    transform: none;
}
.k-rotate-270 > svg {
    transform: rotate(270deg);
}

.k-rotate-315.k-svg-icon {
    transform: none;
}
.k-rotate-315 > svg {
    transform: rotate(315deg);
}

.k-icon-wrap {
    flex: none;
    display: inline-flex;
    flex-flow: row nowrap;
    gap: 0;
    align-items: center;
    align-self: flex-start;
    vertical-align: middle;
    position: relative;
}
.k-icon-wrap::before {
    content: '​';
    width: 0;
    overflow: hidden;
    flex: none;
    display: inline-block;
    vertical-align: top;
}

.k-icon-wrapper-host {
    display: contents;
}

.k-icon-with-modifier {
    position: relative;
    margin: 0.25em;
}

.k-icon.k-icon-modifier {
    width: 1em;
    height: 1em;
    position: absolute;
    font-size: 0.5em;
    bottom: 0;
    right: 0;
    margin: 0 -0.5em -0.5em 0;
}

.k-i-none::before {
    content: '';
    display: none;
}

.k-icon-action {
    display: inline-flex;
    padding: var(--kendo-spacing-1, 0.25rem);
    line-height: 1;
    align-items: center;
    vertical-align: middle;
    cursor: pointer;
}

.k-sprite {
    display: inline-block;
    width: 16px;
    height: 16px;
    overflow: hidden;
    background-repeat: no-repeat;
    font-size: 0;
    line-height: 0;
    text-align: center;
}

.k-image {
    display: inline-block;
}

.k-rotate-0 {
    transform: rotate(0deg);
}

.\!k-rotate-0 {
    transform: rotate(0deg) !important;
}

.k-rotate-45 {
    transform: rotate(45deg);
}

.\!k-rotate-45 {
    transform: rotate(45deg) !important;
}

.k-rotate-90 {
    transform: rotate(90deg);
}

.\!k-rotate-90 {
    transform: rotate(90deg) !important;
}

.k-rotate-135 {
    transform: rotate(135deg);
}

.\!k-rotate-135 {
    transform: rotate(135deg) !important;
}

.k-rotate-180 {
    transform: rotate(180deg);
}

.\!k-rotate-180 {
    transform: rotate(180deg) !important;
}

.k-rotate-225 {
    transform: rotate(225deg);
}

.\!k-rotate-225 {
    transform: rotate(225deg) !important;
}

.k-rotate-270 {
    transform: rotate(270deg);
}

.\!k-rotate-270 {
    transform: rotate(270deg) !important;
}

.k-rotate-315 {
    transform: rotate(315deg);
}

.\!k-rotate-315 {
    transform: rotate(315deg) !important;
}

.k-flip-h,
.k-flip-x {
    transform: scaleX(-1);
}

.\!k-flip-h,
.\!k-flip-x {
    transform: scaleX(-1) !important;
}

.k-flip-v,
.k-flip-y {
    transform: scaleY(-1);
}

.\!k-flip-v,
.\!k-flip-y {
    transform: scaleY(-1) !important;
}

.k-flip-h.k-flip-v,
.k-flip-h.k-flip-y,
.k-flip-v.k-flip-x,
.k-flip-x.k-flip-y {
    transform: scale(-1, -1);
}

.\!k-flip-h.\!k-flip-v,
.\!k-flip-h.\!k-flip-y,
.\!k-flip-v.\!k-flip-x,
.\!k-flip-x.\!k-flip-y {
    transform: scale(-1, -1) !important;
}

.k-messagebox {
    margin: 0 0 var(--kendo-spacing-4, 1rem) 0;
    padding-block: var(--kendo-spacing-1, 0.25rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
    border-width: 0 0 0 4px;
    border-style: solid;
    box-sizing: border-box;
    font-size: var(--kendo-font-size, inherit);
    line-height: var(--kendo-line-height, normal);
}
.k-messagebox a {
    color: inherit;
    font-style: italic;
    text-decoration: underline;
}

.k-messagebox-primary {
    border-color: var(--kendo-color-primary-emphasis, #6184d9);
    color: var(--kendo-color-primary-on-subtle, #0f2047);
    background-color: var(--kendo-color-primary-subtle, #d5def5);
}

.k-messagebox-secondary {
    border-color: var(--kendo-color-secondary-emphasis, #e0e0e0);
    color: var(--kendo-color-secondary-on-subtle, #141414);
    background-color: var(--kendo-color-secondary-subtle, #fafafa);
}

.k-messagebox-tertiary {
    border-color: var(--kendo-color-tertiary-emphasis, #61c9f9);
    color: var(--kendo-color-tertiary-on-subtle, #023f5c);
    background-color: var(--kendo-color-tertiary-subtle, #d8f1fd);
}

.k-messagebox-info {
    border-color: var(--kendo-color-info-emphasis, #6098f2);
    color: var(--kendo-color-info-on-subtle, #002259);
    background-color: var(--kendo-color-info-subtle, #d2e2fb);
}

.k-messagebox-success {
    border-color: var(--kendo-color-success-emphasis, #81d15f);
    color: var(--kendo-color-success-on-subtle, #1c5a00);
    background-color: var(--kendo-color-success-subtle, #dcf0d3);
}

.k-messagebox-warning {
    border-color: var(--kendo-color-warning-emphasis, #ffd760);
    color: var(--kendo-color-warning-on-subtle, #5e4700);
    background-color: var(--kendo-color-warning-subtle, #fff4d3);
}

.k-messagebox-error {
    border-color: var(--kendo-color-error-emphasis, #f76f60);
    color: var(--kendo-color-error-on-subtle, #7a0c00);
    background-color: var(--kendo-color-error-subtle, #fcddda);
}

.k-messagebox-dark {
    border-color: var(--kendo-color-dark-emphasis, #666666);
    color: var(--kendo-color-dark-on-subtle, #1f1f1f);
    background-color: var(--kendo-color-dark-subtle, #c2c2c2);
}

.k-messagebox-light {
    border-color: var(--kendo-color-light-emphasis, #d6d6d6);
    color: var(--kendo-color-light-on-subtle, #141414);
    background-color: var(--kendo-color-light-subtle, #fafafa);
}

.k-messagebox-inverse {
    border-color: var(--kendo-color-dark-hover, #292929);
    color: var(--kendo-color-dark-on-subtle, #1f1f1f);
    background-color: var(--kendo-color-dark-subtle, #c2c2c2);
}

.k-badge {
    padding: 0;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    box-sizing: border-box;
    color: inherit;
    background-color: transparent;
    font: inherit;
    text-align: center;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
}
.k-badge *,
.k-badge *::before,
.k-badge *::after {
    box-sizing: border-box;
}

.k-badge > * {
    color: inherit;
}

a.k-badge,
.k-badge > a {
    text-decoration: none;
}

a.k-badge:hover,
.k-badge > a:hover {
    text-decoration: underline;
}

.k-badge-icon {
    max-width: 1em;
    max-height: 1em;
    font-size: inherit;
}

.k-badge-icon.k-svg-icon svg {
    fill: currentColor;
}

.k-badge-sm {
    padding-block: var(--kendo-spacing-0\.5, 0.125rem);
    padding-inline: var(--kendo-spacing-0\.5, 0.125rem);
    font-size: var(--kendo-font-size-xs, inherit);
    line-height: 1;
    min-width: calc(1em + var(--kendo-spacing-0\.5, 0.125rem) * 2 + 1px * 2);
}
.k-badge-sm:empty {
    padding: var(--kendo-spacing-0\.5, 0.125rem);
    min-width: auto;
}

.k-badge-md {
    padding-block: var(--kendo-spacing-1, 0.25rem);
    padding-inline: var(--kendo-spacing-1, 0.25rem);
    font-size: var(--kendo-font-size-xs, inherit);
    line-height: 1;
    min-width: calc(1em + var(--kendo-spacing-1, 0.25rem) * 2 + 1px * 2);
}
.k-badge-md:empty {
    padding: var(--kendo-spacing-1, 0.25rem);
    min-width: auto;
}

.k-badge-lg {
    padding-block: var(--kendo-spacing-1\.5, 0.375rem);
    padding-inline: var(--kendo-spacing-1\.5, 0.375rem);
    font-size: var(--kendo-font-size-xs, inherit);
    line-height: 1;
    min-width: calc(1em + var(--kendo-spacing-1\.5, 0.375rem) * 2 + 1px * 2);
}
.k-badge-lg:empty {
    padding: var(--kendo-spacing-1\.5, 0.375rem);
    min-width: auto;
}

kendo-badge-container {
    display: inline-block;
}

.k-badge-container {
    position: relative;
    overflow: visible;
}

.k-badge-inside {
    position: absolute;
    z-index: 9999;
}
.k-badge-edge {
    position: absolute;
    z-index: 9999;
}
.k-badge-edge.k-top-start {
    transform: translate(-50%, -50%);
}
.k-badge-edge.k-top-end {
    transform: translate(50%, -50%);
}
.k-badge-edge.k-bottom-start {
    transform: translate(-50%, 50%);
}
.k-badge-edge.k-bottom-end {
    transform: translate(50%, 50%);
}

.k-rtl .k-badge-edge.k-top-start,
[dir='rtl'].k-badge-edge.k-top-start,
[dir='rtl'] .k-badge-edge.k-top-start {
    transform: translate(50%, -50%);
}
.k-rtl .k-badge-edge.k-top-end,
[dir='rtl'].k-badge-edge.k-top-end,
[dir='rtl'] .k-badge-edge.k-top-end {
    transform: translate(-50%, -50%);
}
.k-rtl .k-badge-edge.k-bottom-start,
[dir='rtl'].k-badge-edge.k-bottom-start,
[dir='rtl'] .k-badge-edge.k-bottom-start {
    transform: translate(50%, 50%);
}
.k-rtl .k-badge-edge.k-bottom-end,
[dir='rtl'].k-badge-edge.k-bottom-end,
[dir='rtl'] .k-badge-edge.k-bottom-end {
    transform: translate(-50%, 50%);
}

.k-badge-outside {
    position: absolute;
    z-index: 9999;
}
.k-badge-outside.k-top-start {
    transform: translate(-100%, -100%);
}
.k-badge-outside.k-top-end {
    transform: translate(100%, -100%);
}
.k-badge-outside.k-bottom-start {
    transform: translate(-100%, 100%);
}
.k-badge-outside.k-bottom-end {
    transform: translate(100%, 100%);
}

.k-rtl .k-badge-outside.k-top-start,
[dir='rtl'].k-badge-outside.k-top-start,
[dir='rtl'] .k-badge-outside.k-top-start {
    transform: translate(100%, -100%);
}
.k-rtl .k-badge-outside.k-top-end,
[dir='rtl'].k-badge-outside.k-top-end,
[dir='rtl'] .k-badge-outside.k-top-end {
    transform: translate(-100%, -100%);
}
.k-rtl .k-badge-outside.k-bottom-start,
[dir='rtl'].k-badge-outside.k-bottom-start,
[dir='rtl'] .k-badge-outside.k-bottom-start {
    transform: translate(100%, 100%);
}
.k-rtl .k-badge-outside.k-bottom-end,
[dir='rtl'].k-badge-outside.k-bottom-end,
[dir='rtl'] .k-badge-outside.k-bottom-end {
    transform: translate(-100%, 100%);
}

.k-badge-border-cutout {
    box-shadow: 0 0 0 2px var(--kendo-color-surface-alt, #ffffff);
}

.k-badge-solid-primary {
    border-color: var(--kendo-color-primary, rgb(44, 91, 204));
    color: var(--kendo-color-on-primary, white);
    background-color: var(--kendo-color-primary, rgb(44, 91, 204));
}

.k-badge-solid-secondary {
    border-color: var(--kendo-color-secondary, #666666);
    color: var(--kendo-color-on-secondary, #ffffff);
    background-color: var(--kendo-color-secondary, #666666);
}

.k-badge-solid-tertiary {
    border-color: var(--kendo-color-tertiary, #03a9f4);
    color: var(--kendo-color-on-tertiary, #ffffff);
    background-color: var(--kendo-color-tertiary, #03a9f4);
}

.k-badge-solid-info {
    border-color: var(--kendo-color-info, #0058e9);
    color: var(--kendo-color-on-info, #ffffff);
    background-color: var(--kendo-color-info, #0058e9);
}

.k-badge-solid-success {
    border-color: var(--kendo-color-success, #37b400);
    color: var(--kendo-color-on-success, #ffffff);
    background-color: var(--kendo-color-success, #37b400);
}

.k-badge-solid-warning {
    border-color: var(--kendo-color-warning, #ffc000);
    color: var(--kendo-color-on-warning, #3d3d3d);
    background-color: var(--kendo-color-warning, #ffc000);
}

.k-badge-solid-error {
    border-color: var(--kendo-color-error, #f31700);
    color: var(--kendo-color-on-error, #ffffff);
    background-color: var(--kendo-color-error, #f31700);
}

.k-badge-solid-dark {
    border-color: var(--kendo-color-dark, #3d3d3d);
    color: var(--kendo-color-on-dark, #ffffff);
    background-color: var(--kendo-color-dark, #3d3d3d);
}

.k-badge-solid-light {
    border-color: var(--kendo-color-light, #ebebeb);
    color: var(--kendo-color-on-light, #000000);
    background-color: var(--kendo-color-light, #ebebeb);
}

.k-badge-solid-inverse {
    border-color: var(--kendo-color-dark, #3d3d3d);
    color: var(--kendo-color-on-inverse, #ffffff);
    background-color: var(--kendo-color-dark, #3d3d3d);
}

.k-badge-outline {
    border-color: currentColor;
    background-color: transparent;
}

.k-badge-outline-primary {
    color: var(--kendo-color-primary, rgb(44, 91, 204));
}

.k-badge-outline-secondary {
    color: var(--kendo-color-secondary, #666666);
}

.k-badge-outline-tertiary {
    color: var(--kendo-color-tertiary, #03a9f4);
}

.k-badge-outline-info {
    color: var(--kendo-color-info, #0058e9);
}

.k-badge-outline-success {
    color: var(--kendo-color-success, #37b400);
}

.k-badge-outline-warning {
    color: var(--kendo-color-warning, #ffc000);
}

.k-badge-outline-error {
    color: var(--kendo-color-error, #f31700);
}

.k-badge-outline-dark {
    color: var(--kendo-color-dark, #3d3d3d);
}

.k-badge-outline-light {
    color: var(--kendo-color-light, #ebebeb);
}

.k-badge-outline-inverse {
    color: var(--kendo-color-dark, #3d3d3d);
}

.k-button {
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    color: inherit;
    background: none;
    font-family: var(--kendo-font-family, inherit);
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: var(--kendo-spacing-1, 0.25rem);
    vertical-align: middle;
    user-select: none;
    cursor: pointer;
    outline: none;
    -webkit-appearance: none;
    position: relative;
    transition: color 0.2s ease-in-out;
}
.k-button *,
.k-button *::before,
.k-button *::after {
    box-sizing: border-box;
}
.k-button:disabled,
.k-button.k-disabled {
    outline: none;
    cursor: default;
    opacity: 0.6;
    filter: grayscale(0.1);
    pointer-events: none;
    box-shadow: none;
}
.k-button::-moz-focus-inner {
    padding: 0;
    border: 0;
    outline: 0;
}
.k-button:hover,
.k-button:focus {
    text-decoration: none;
    outline: 0;
}

.k-icon-button {
    gap: 0;
}
.k-icon-button .k-icon {
    display: inline-flex;
}

.k-button-icon {
    color: inherit;
    align-self: center;
    position: relative;
}

.k-button-sm {
    padding-block: var(--kendo-spacing-0\.5, 0.125rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
    font-size: var(--kendo-font-size, inherit);
    line-height: var(--kendo-line-height, normal);
}

.k-button-sm.k-icon-button {
    padding: var(--kendo-spacing-0\.5, 0.125rem);
}
.k-button-sm.k-icon-button .k-button-icon {
    min-width: calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal));
    min-height: calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal));
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.k-button-sm.k-icon-button .k-button-icon.k-svg-icon > svg,
.k-button-sm.k-icon-button .k-button-icon.k-icon-md > svg {
    width: 16px;
    height: 16px;
}
.k-button-sm.k-icon-button .k-button-icon.k-icon-xs > svg {
    width: calc(16px * 0.75);
    height: calc(16px * 0.75);
}
.k-button-sm.k-icon-button .k-button-icon.k-icon-sm > svg {
    width: calc(16px * 0.875);
    height: calc(16px * 0.875);
}
.k-button-sm.k-icon-button .k-button-icon.k-icon-lg > svg {
    width: calc(16px * 1.25);
    height: calc(16px * 1.25);
}
.k-button-sm.k-icon-button .k-button-icon.k-icon-xl > svg {
    width: calc(16px * 1.5);
    height: calc(16px * 1.5);
}
.k-button-sm.k-icon-button .k-button-icon.k-icon-xxl > svg {
    width: calc(16px * 2);
    height: calc(16px * 2);
}
.k-button-sm.k-icon-button .k-button-icon.k-icon-xxxl > svg {
    width: calc(16px * 3);
    height: calc(16px * 3);
}

.k-button-md {
    padding-block: var(--kendo-spacing-1, 0.25rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
    font-size: var(--kendo-font-size, inherit);
    line-height: var(--kendo-line-height, normal);
}

.k-button-md.k-icon-button {
    padding: var(--kendo-spacing-1, 0.25rem);
}
.k-button-md.k-icon-button .k-button-icon {
    min-width: calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal));
    min-height: calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal));
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.k-button-md.k-icon-button .k-button-icon.k-svg-icon > svg,
.k-button-md.k-icon-button .k-button-icon.k-icon-md > svg {
    width: 16px;
    height: 16px;
}
.k-button-md.k-icon-button .k-button-icon.k-icon-xs > svg {
    width: calc(16px * 0.75);
    height: calc(16px * 0.75);
}
.k-button-md.k-icon-button .k-button-icon.k-icon-sm > svg {
    width: calc(16px * 0.875);
    height: calc(16px * 0.875);
}
.k-button-md.k-icon-button .k-button-icon.k-icon-lg > svg {
    width: calc(16px * 1.25);
    height: calc(16px * 1.25);
}
.k-button-md.k-icon-button .k-button-icon.k-icon-xl > svg {
    width: calc(16px * 1.5);
    height: calc(16px * 1.5);
}
.k-button-md.k-icon-button .k-button-icon.k-icon-xxl > svg {
    width: calc(16px * 2);
    height: calc(16px * 2);
}
.k-button-md.k-icon-button .k-button-icon.k-icon-xxxl > svg {
    width: calc(16px * 3);
    height: calc(16px * 3);
}

.k-button-lg {
    padding-block: var(--kendo-spacing-2, 0.5rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
    font-size: var(--kendo-font-size-lg, inherit);
    line-height: var(--kendo-line-height-lg, normal);
}

.k-button-lg.k-icon-button {
    padding: var(--kendo-spacing-2, 0.5rem);
}
.k-button-lg.k-icon-button .k-button-icon {
    min-width: calc(var(--kendo-font-size-lg, inherit) * var(--kendo-line-height-lg, normal));
    min-height: calc(var(--kendo-font-size-lg, inherit) * var(--kendo-line-height-lg, normal));
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.k-button-lg.k-icon-button .k-button-icon.k-svg-icon > svg,
.k-button-lg.k-icon-button .k-button-icon.k-icon-md > svg {
    width: 16px;
    height: 16px;
}
.k-button-lg.k-icon-button .k-button-icon.k-icon-xs > svg {
    width: calc(16px * 0.75);
    height: calc(16px * 0.75);
}
.k-button-lg.k-icon-button .k-button-icon.k-icon-sm > svg {
    width: calc(16px * 0.875);
    height: calc(16px * 0.875);
}
.k-button-lg.k-icon-button .k-button-icon.k-icon-lg > svg {
    width: calc(16px * 1.25);
    height: calc(16px * 1.25);
}
.k-button-lg.k-icon-button .k-button-icon.k-icon-xl > svg {
    width: calc(16px * 1.5);
    height: calc(16px * 1.5);
}
.k-button-lg.k-icon-button .k-button-icon.k-icon-xxl > svg {
    width: calc(16px * 2);
    height: calc(16px * 2);
}
.k-button-lg.k-icon-button .k-button-icon.k-icon-xxxl > svg {
    width: calc(16px * 3);
    height: calc(16px * 3);
}

.k-button-square {
    aspect-ratio: 1;
}

.k-button-group {
    margin: 0;
    padding: 0;
    border-width: 0;
    box-sizing: border-box;
    list-style: none;
    outline: 0;
    display: inline-flex;
    flex-flow: row nowrap;
    vertical-align: middle;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-button-group > .k-button + .k-button {
    margin-inline-start: -1px;
}
.k-button-group > .k-button:hover,
.k-button-group > .k-button.k-hover,
.k-button-group > .k-button:focus,
.k-button-group > .k-button.k-focus,
.k-button-group > .k-button:active,
.k-button-group > .k-button.k-active,
.k-button-group > .k-button.k-selected {
    z-index: 2;
}
.k-button-group .k-button:not(:first-child):not(:last-child) {
    border-start-end-radius: 0;
    border-end-end-radius: 0;
    border-start-start-radius: 0;
    border-end-start-radius: 0;
}
.k-button-group > .k-button:first-child:not(:only-child) {
    border-start-end-radius: 0;
    border-end-end-radius: 0;
}
.k-button-group > .k-button:last-child:not(:only-child) {
    border-start-start-radius: 0;
    border-end-start-radius: 0;
}
.k-button-group:disabled,
.k-button-group[disabled],
.k-button-group.k-disabled {
    opacity: 1;
    filter: none;
}

.k-button-group-stretched {
    width: 100%;
}
.k-button-group-stretched > * {
    flex: 1 0 0%;
    overflow: hidden;
}

.k-button-flat {
    border-color: transparent !important;
    color: inherit;
    background: none !important;
    box-shadow: none !important;
}
.k-button-flat::before {
    display: block !important;
}
.k-button-flat::after {
    box-shadow: inset 0 0 0 2px currentColor;
    display: block !important;
}
.k-button-flat:focus::after,
.k-button-flat.k-focus::after {
    opacity: 0.12;
}

.k-button-outline {
    border-color: currentColor;
    color: inherit;
    background: none;
}

.k-button-link {
    border-color: transparent;
    color: inherit;
    text-decoration: none;
    background: none;
}
.k-button-link:hover,
.k-button-link.k-hover {
    text-decoration: underline;
}

.k-button-clear {
    border-color: transparent !important;
    color: inherit;
    background: none !important;
    box-shadow: none !important;
}

.k-button-overlay,
.k-button::before {
    border-radius: inherit;
    content: '';
    background: currentColor;
    opacity: 0;
    display: none;
    pointer-events: none;
    position: absolute;
    left: -1px;
    right: -1px;
    top: -1px;
    bottom: -1px;
    z-index: 0;
    transition: opacity 0.2s ease-in-out;
}

.k-button:hover::before,
.k-button.k-hover::before {
    opacity: 0.04;
}
.k-button.k-no-focus:not(:hover)::before,
.k-button.k-no-focus:not(.k-hover)::before {
    opacity: 0;
}
.k-button:active::before,
.k-button.k-active::before {
    opacity: 0.16;
}
.k-button.k-selected::before {
    opacity: 0.2;
}

.k-button::after {
    border-radius: inherit;
    content: '';
    opacity: 0;
    display: none;
    pointer-events: none;
    position: absolute;
    left: -1px;
    right: -1px;
    top: -1px;
    bottom: -1px;
    z-index: 0;
    transition: opacity 0.2s ease-in-out;
}

.k-button-solid-base {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    color: var(--kendo-color-on-base, #3d3d3d);
    background-color: var(--kendo-color-base, #f5f5f5);
}
.k-button-solid-base:hover,
.k-button-solid-base.k-hover {
    background-color: var(--kendo-color-base-hover, #ebebeb);
}
.k-button-solid-base:focus,
.k-button-solid-base.k-focus {
    box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-button-solid-base:active,
.k-button-solid-base.k-active {
    background-color: var(--kendo-color-base-active, #d6d6d6);
}
.k-button-solid-base.k-selected {
    border-color: var(--kendo-color-primary, rgb(44, 91, 204));
    color: var(--kendo-color-on-primary, white);
    background-color: var(--kendo-color-primary, rgb(44, 91, 204));
}
.k-button-solid-primary {
    border-color: var(--kendo-color-primary, rgb(44, 91, 204));
    color: var(--kendo-color-on-primary, white);
    background-color: var(--kendo-color-primary, rgb(44, 91, 204));
}
.k-button-solid-primary:hover,
.k-button-solid-primary.k-hover {
    border-color: var(--kendo-color-primary-hover, #2a57c4);
    background-color: var(--kendo-color-primary-hover, #2a57c4);
}
.k-button-solid-primary:focus,
.k-button-solid-primary.k-focus {
    box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-primary, rgb(44, 91, 204)) 30%, transparent);
}
.k-button-solid-primary:active,
.k-button-solid-primary.k-active {
    border-color: var(--kendo-color-primary-active, #2750b4);
    background-color: var(--kendo-color-primary-active, #2750b4);
}
.k-button-solid-primary.k-selected {
    border-color: var(--kendo-color-primary-active, #2750b4);
    background-color: var(--kendo-color-primary-active, #2750b4);
}
.k-button-solid-secondary {
    border-color: var(--kendo-color-secondary, #666666);
    color: var(--kendo-color-on-secondary, #ffffff);
    background-color: var(--kendo-color-secondary, #666666);
}
.k-button-solid-secondary:hover,
.k-button-solid-secondary.k-hover {
    border-color: var(--kendo-color-secondary-hover, #525252);
    background-color: var(--kendo-color-secondary-hover, #525252);
}
.k-button-solid-secondary:focus,
.k-button-solid-secondary.k-focus {
    box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-secondary, #666666) 30%, transparent);
}
.k-button-solid-secondary:active,
.k-button-solid-secondary.k-active {
    border-color: var(--kendo-color-secondary-active, #3d3d3d);
    background-color: var(--kendo-color-secondary-active, #3d3d3d);
}
.k-button-solid-secondary.k-selected {
    border-color: var(--kendo-color-secondary-active, #3d3d3d);
    background-color: var(--kendo-color-secondary-active, #3d3d3d);
}
.k-button-solid-tertiary {
    border-color: var(--kendo-color-tertiary, #03a9f4);
    color: var(--kendo-color-on-tertiary, #ffffff);
    background-color: var(--kendo-color-tertiary, #03a9f4);
}
.k-button-solid-tertiary:hover,
.k-button-solid-tertiary.k-hover {
    border-color: var(--kendo-color-tertiary-hover, #039ae0);
    background-color: var(--kendo-color-tertiary-hover, #039ae0);
}
.k-button-solid-tertiary:focus,
.k-button-solid-tertiary.k-focus {
    box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-tertiary, #03a9f4) 30%, transparent);
}
.k-button-solid-tertiary:active,
.k-button-solid-tertiary.k-active {
    border-color: var(--kendo-color-tertiary-active, #028ccb);
    background-color: var(--kendo-color-tertiary-active, #028ccb);
}
.k-button-solid-tertiary.k-selected {
    border-color: var(--kendo-color-tertiary-active, #028ccb);
    background-color: var(--kendo-color-tertiary-active, #028ccb);
}
.k-button-solid-info {
    border-color: var(--kendo-color-info, #0058e9);
    color: var(--kendo-color-on-info, #ffffff);
    background-color: var(--kendo-color-info, #0058e9);
}
.k-button-solid-info:hover,
.k-button-solid-info.k-hover {
    border-color: var(--kendo-color-info-hover, #0052d6);
    background-color: var(--kendo-color-info-hover, #0052d6);
}
.k-button-solid-info:focus,
.k-button-solid-info.k-focus {
    box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-info, #0058e9) 30%, transparent);
}
.k-button-solid-info:active,
.k-button-solid-info.k-active {
    border-color: var(--kendo-color-info-active, #004ac2);
    background-color: var(--kendo-color-info-active, #004ac2);
}
.k-button-solid-info.k-selected {
    border-color: var(--kendo-color-info-active, #004ac2);
    background-color: var(--kendo-color-info-active, #004ac2);
}
.k-button-solid-success {
    border-color: var(--kendo-color-success, #37b400);
    color: var(--kendo-color-on-success, #ffffff);
    background-color: var(--kendo-color-success, #37b400);
}
.k-button-solid-success:hover,
.k-button-solid-success.k-hover {
    border-color: var(--kendo-color-success-hover, #32a500);
    background-color: var(--kendo-color-success-hover, #32a500);
}
.k-button-solid-success:focus,
.k-button-solid-success.k-focus {
    box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-success, #37b400) 30%, transparent);
}
.k-button-solid-success:active,
.k-button-solid-success.k-active {
    border-color: var(--kendo-color-success-active, #2d9600);
    background-color: var(--kendo-color-success-active, #2d9600);
}
.k-button-solid-success.k-selected {
    border-color: var(--kendo-color-success-active, #2d9600);
    background-color: var(--kendo-color-success-active, #2d9600);
}
.k-button-solid-warning {
    border-color: var(--kendo-color-warning, #ffc000);
    color: var(--kendo-color-on-warning, #3d3d3d);
    background-color: var(--kendo-color-warning, #ffc000);
}
.k-button-solid-warning:hover,
.k-button-solid-warning.k-hover {
    border-color: var(--kendo-color-warning-hover, #eaaf00);
    background-color: var(--kendo-color-warning-hover, #eaaf00);
}
.k-button-solid-warning:focus,
.k-button-solid-warning.k-focus {
    box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-warning, #ffc000) 30%, transparent);
}
.k-button-solid-warning:active,
.k-button-solid-warning.k-active {
    border-color: var(--kendo-color-warning-active, #d49f00);
    background-color: var(--kendo-color-warning-active, #d49f00);
}
.k-button-solid-warning.k-selected {
    border-color: var(--kendo-color-warning-active, #d49f00);
    background-color: var(--kendo-color-warning-active, #d49f00);
}
.k-button-solid-error {
    border-color: var(--kendo-color-error, #f31700);
    color: var(--kendo-color-on-error, #ffffff);
    background-color: var(--kendo-color-error, #f31700);
}
.k-button-solid-error:hover,
.k-button-solid-error.k-hover {
    border-color: var(--kendo-color-error-hover, #df1600);
    background-color: var(--kendo-color-error-hover, #df1600);
}
.k-button-solid-error:focus,
.k-button-solid-error.k-focus {
    box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-error, #f31700) 30%, transparent);
}
.k-button-solid-error:active,
.k-button-solid-error.k-active {
    border-color: var(--kendo-color-error-active, #ca1400);
    background-color: var(--kendo-color-error-active, #ca1400);
}
.k-button-solid-error.k-selected {
    border-color: var(--kendo-color-error-active, #ca1400);
    background-color: var(--kendo-color-error-active, #ca1400);
}
.k-button-solid-dark {
    border-color: var(--kendo-color-dark, #3d3d3d);
    color: var(--kendo-color-on-dark, #ffffff);
    background-color: var(--kendo-color-dark, #3d3d3d);
}
.k-button-solid-dark:hover,
.k-button-solid-dark.k-hover {
    border-color: var(--kendo-color-dark-hover, #292929);
    background-color: var(--kendo-color-dark-hover, #292929);
}
.k-button-solid-dark:focus,
.k-button-solid-dark.k-focus {
    box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-dark, #3d3d3d) 30%, transparent);
}
.k-button-solid-dark:active,
.k-button-solid-dark.k-active {
    border-color: var(--kendo-color-dark-active, #1f1f1f);
    background-color: var(--kendo-color-dark-active, #1f1f1f);
}
.k-button-solid-dark.k-selected {
    border-color: var(--kendo-color-dark-active, #1f1f1f);
    background-color: var(--kendo-color-dark-active, #1f1f1f);
}
.k-button-solid-light {
    border-color: var(--kendo-color-light, #ebebeb);
    color: var(--kendo-color-on-light, #000000);
    background-color: var(--kendo-color-light, #ebebeb);
}
.k-button-solid-light:hover,
.k-button-solid-light.k-hover {
    border-color: var(--kendo-color-light-hover, #e0e0e0);
    background-color: var(--kendo-color-light-hover, #e0e0e0);
}
.k-button-solid-light:focus,
.k-button-solid-light.k-focus {
    box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-light, #ebebeb) 30%, transparent);
}
.k-button-solid-light:active,
.k-button-solid-light.k-active {
    border-color: var(--kendo-color-light-active, #d6d6d6);
    background-color: var(--kendo-color-light-active, #d6d6d6);
}
.k-button-solid-light.k-selected {
    border-color: var(--kendo-color-light-active, #d6d6d6);
    background-color: var(--kendo-color-light-active, #d6d6d6);
}
.k-button-solid-inverse {
    border-color: var(--kendo-color-inverse, #3d3d3d);
    color: var(--kendo-color-on-inverse, #ffffff);
    background-color: var(--kendo-color-inverse, #3d3d3d);
}
.k-button-solid-inverse:hover,
.k-button-solid-inverse.k-hover {
    border-color: var(--kendo-color-inverse-hover, #292929);
    background-color: var(--kendo-color-inverse-hover, #292929);
}
.k-button-solid-inverse:focus,
.k-button-solid-inverse.k-focus {
    box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-inverse, #3d3d3d) 30%, transparent);
}
.k-button-solid-inverse:active,
.k-button-solid-inverse.k-active {
    border-color: var(--kendo-color-inverse-active, #1f1f1f);
    background-color: var(--kendo-color-inverse-active, #1f1f1f);
}
.k-button-solid-inverse.k-selected {
    border-color: var(--kendo-color-inverse-active, #1f1f1f);
    background-color: var(--kendo-color-inverse-active, #1f1f1f);
}
.k-button-outline-primary {
    box-shadow: none;
    border-color: currentColor;
    color: var(--kendo-color-primary-on-surface, #214499);
    background-color: transparent;
}
.k-button-outline-primary:hover,
.k-button-outline-primary.k-hover {
    border-color: var(--kendo-color-primary, rgb(44, 91, 204));
    color: var(--kendo-color-on-primary, white);
    background-color: var(--kendo-color-primary, rgb(44, 91, 204));
}
.k-button-outline-primary:focus,
.k-button-outline-primary.k-focus {
    box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-primary, rgb(44, 91, 204)) 30%, transparent);
}
.k-button-outline-primary:active,
.k-button-outline-primary.k-active {
    border-color: var(--kendo-color-primary, rgb(44, 91, 204));
    color: var(--kendo-color-on-primary, white);
    background-color: var(--kendo-color-primary, rgb(44, 91, 204));
}
.k-button-outline-primary.k-selected {
    border-color: var(--kendo-color-primary, rgb(44, 91, 204));
    color: var(--kendo-color-on-primary, white);
    background-color: var(--kendo-color-primary, rgb(44, 91, 204));
}
.k-button-outline-secondary {
    box-shadow: none;
    border-color: currentColor;
    color: var(--kendo-color-secondary-on-surface, #292929);
    background-color: transparent;
}
.k-button-outline-secondary:hover,
.k-button-outline-secondary.k-hover {
    border-color: var(--kendo-color-secondary, #666666);
    color: var(--kendo-color-on-secondary, #ffffff);
    background-color: var(--kendo-color-secondary, #666666);
}
.k-button-outline-secondary:focus,
.k-button-outline-secondary.k-focus {
    box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-secondary, #666666) 30%, transparent);
}
.k-button-outline-secondary:active,
.k-button-outline-secondary.k-active {
    border-color: var(--kendo-color-secondary, #666666);
    color: var(--kendo-color-on-secondary, #ffffff);
    background-color: var(--kendo-color-secondary, #666666);
}
.k-button-outline-secondary.k-selected {
    border-color: var(--kendo-color-secondary, #666666);
    color: var(--kendo-color-on-secondary, #ffffff);
    background-color: var(--kendo-color-secondary, #666666);
}
.k-button-outline-tertiary {
    box-shadow: none;
    border-color: currentColor;
    color: var(--kendo-color-tertiary-on-surface, #028ccb);
    background-color: transparent;
}
.k-button-outline-tertiary:hover,
.k-button-outline-tertiary.k-hover {
    border-color: var(--kendo-color-tertiary, #03a9f4);
    color: var(--kendo-color-on-tertiary, #ffffff);
    background-color: var(--kendo-color-tertiary, #03a9f4);
}
.k-button-outline-tertiary:focus,
.k-button-outline-tertiary.k-focus {
    box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-tertiary, #03a9f4) 30%, transparent);
}
.k-button-outline-tertiary:active,
.k-button-outline-tertiary.k-active {
    border-color: var(--kendo-color-tertiary, #03a9f4);
    color: var(--kendo-color-on-tertiary, #ffffff);
    background-color: var(--kendo-color-tertiary, #03a9f4);
}
.k-button-outline-tertiary.k-selected {
    border-color: var(--kendo-color-tertiary, #03a9f4);
    color: var(--kendo-color-on-tertiary, #ffffff);
    background-color: var(--kendo-color-tertiary, #03a9f4);
}
.k-button-outline-info {
    box-shadow: none;
    border-color: currentColor;
    color: var(--kendo-color-info-on-surface, #004ac2);
    background-color: transparent;
}
.k-button-outline-info:hover,
.k-button-outline-info.k-hover {
    border-color: var(--kendo-color-info, #0058e9);
    color: var(--kendo-color-on-info, #ffffff);
    background-color: var(--kendo-color-info, #0058e9);
}
.k-button-outline-info:focus,
.k-button-outline-info.k-focus {
    box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-info, #0058e9) 30%, transparent);
}
.k-button-outline-info:active,
.k-button-outline-info.k-active {
    border-color: var(--kendo-color-info, #0058e9);
    color: var(--kendo-color-on-info, #ffffff);
    background-color: var(--kendo-color-info, #0058e9);
}
.k-button-outline-info.k-selected {
    border-color: var(--kendo-color-info, #0058e9);
    color: var(--kendo-color-on-info, #ffffff);
    background-color: var(--kendo-color-info, #0058e9);
}
.k-button-outline-success {
    box-shadow: none;
    border-color: currentColor;
    color: var(--kendo-color-success-on-surface, #2d9600);
    background-color: transparent;
}
.k-button-outline-success:hover,
.k-button-outline-success.k-hover {
    border-color: var(--kendo-color-success, #37b400);
    color: var(--kendo-color-on-success, #ffffff);
    background-color: var(--kendo-color-success, #37b400);
}
.k-button-outline-success:focus,
.k-button-outline-success.k-focus {
    box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-success, #37b400) 30%, transparent);
}
.k-button-outline-success:active,
.k-button-outline-success.k-active {
    border-color: var(--kendo-color-success, #37b400);
    color: var(--kendo-color-on-success, #ffffff);
    background-color: var(--kendo-color-success, #37b400);
}
.k-button-outline-success.k-selected {
    border-color: var(--kendo-color-success, #37b400);
    color: var(--kendo-color-on-success, #ffffff);
    background-color: var(--kendo-color-success, #37b400);
}
.k-button-outline-warning {
    box-shadow: none;
    border-color: currentColor;
    color: var(--kendo-color-warning-on-surface, #ffc000);
    background-color: transparent;
}
.k-button-outline-warning:hover,
.k-button-outline-warning.k-hover {
    border-color: var(--kendo-color-warning, #ffc000);
    color: var(--kendo-color-on-warning, #3d3d3d);
    background-color: var(--kendo-color-warning, #ffc000);
}
.k-button-outline-warning:focus,
.k-button-outline-warning.k-focus {
    box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-warning, #ffc000) 30%, transparent);
}
.k-button-outline-warning:active,
.k-button-outline-warning.k-active {
    border-color: var(--kendo-color-warning, #ffc000);
    color: var(--kendo-color-on-warning, #3d3d3d);
    background-color: var(--kendo-color-warning, #ffc000);
}
.k-button-outline-warning.k-selected {
    border-color: var(--kendo-color-warning, #ffc000);
    color: var(--kendo-color-on-warning, #3d3d3d);
    background-color: var(--kendo-color-warning, #ffc000);
}
.k-button-outline-error {
    box-shadow: none;
    border-color: currentColor;
    color: var(--kendo-color-error-on-surface, #ca1400);
    background-color: transparent;
}
.k-button-outline-error:hover,
.k-button-outline-error.k-hover {
    border-color: var(--kendo-color-error, #f31700);
    color: var(--kendo-color-on-error, #ffffff);
    background-color: var(--kendo-color-error, #f31700);
}
.k-button-outline-error:focus,
.k-button-outline-error.k-focus {
    box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-error, #f31700) 30%, transparent);
}
.k-button-outline-error:active,
.k-button-outline-error.k-active {
    border-color: var(--kendo-color-error, #f31700);
    color: var(--kendo-color-on-error, #ffffff);
    background-color: var(--kendo-color-error, #f31700);
}
.k-button-outline-error.k-selected {
    border-color: var(--kendo-color-error, #f31700);
    color: var(--kendo-color-on-error, #ffffff);
    background-color: var(--kendo-color-error, #f31700);
}
.k-button-outline-dark {
    box-shadow: none;
    border-color: currentColor;
    color: var(--kendo-color-dark-on-surface, #141414);
    background-color: transparent;
}
.k-button-outline-dark:hover,
.k-button-outline-dark.k-hover {
    border-color: var(--kendo-color-dark, #3d3d3d);
    color: var(--kendo-color-on-dark, #ffffff);
    background-color: var(--kendo-color-dark, #3d3d3d);
}
.k-button-outline-dark:focus,
.k-button-outline-dark.k-focus {
    box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-dark, #3d3d3d) 30%, transparent);
}
.k-button-outline-dark:active,
.k-button-outline-dark.k-active {
    border-color: var(--kendo-color-dark, #3d3d3d);
    color: var(--kendo-color-on-dark, #ffffff);
    background-color: var(--kendo-color-dark, #3d3d3d);
}
.k-button-outline-dark.k-selected {
    border-color: var(--kendo-color-dark, #3d3d3d);
    color: var(--kendo-color-on-dark, #ffffff);
    background-color: var(--kendo-color-dark, #3d3d3d);
}
.k-button-outline-light {
    box-shadow: none;
    border-color: currentColor;
    color: var(--kendo-color-light-on-surface, #e0e0e0);
    background-color: transparent;
}
.k-button-outline-light:hover,
.k-button-outline-light.k-hover {
    border-color: var(--kendo-color-light, #ebebeb);
    color: var(--kendo-color-on-light, #000000);
    background-color: var(--kendo-color-light, #ebebeb);
}
.k-button-outline-light:focus,
.k-button-outline-light.k-focus {
    box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-light, #ebebeb) 30%, transparent);
}
.k-button-outline-light:active,
.k-button-outline-light.k-active {
    border-color: var(--kendo-color-light, #ebebeb);
    color: var(--kendo-color-on-light, #000000);
    background-color: var(--kendo-color-light, #ebebeb);
}
.k-button-outline-light.k-selected {
    border-color: var(--kendo-color-light, #ebebeb);
    color: var(--kendo-color-on-light, #000000);
    background-color: var(--kendo-color-light, #ebebeb);
}
.k-button-outline-inverse {
    box-shadow: none;
    border-color: currentColor;
    color: var(--kendo-color-inverse-on-surface, #141414);
    background-color: transparent;
}
.k-button-outline-inverse:hover,
.k-button-outline-inverse.k-hover {
    border-color: var(--kendo-color-dark, #3d3d3d);
    color: var(--kendo-color-on-inverse, #ffffff);
    background-color: var(--kendo-color-dark, #3d3d3d);
}
.k-button-outline-inverse:focus,
.k-button-outline-inverse.k-focus {
    box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-inverse, #3d3d3d) 30%, transparent);
}
.k-button-outline-inverse:active,
.k-button-outline-inverse.k-active {
    border-color: var(--kendo-color-dark, #3d3d3d);
    color: var(--kendo-color-on-inverse, #ffffff);
    background-color: var(--kendo-color-dark, #3d3d3d);
}
.k-button-outline-inverse.k-selected {
    border-color: var(--kendo-color-dark, #3d3d3d);
    color: var(--kendo-color-on-inverse, #ffffff);
    background-color: var(--kendo-color-dark, #3d3d3d);
}
.k-button-outline-base {
    box-shadow: none;
    border-color: currentColor;
    color: var(--kendo-color-base-on-surface, #3d3d3d);
    background-color: transparent;
}
.k-button-outline-base:hover,
.k-button-outline-base.k-hover {
    border-color: var(--kendo-color-on-base, #3d3d3d);
    color: var(--kendo-color-base, #f5f5f5);
    background-color: var(--kendo-color-on-base, #3d3d3d);
}
.k-button-outline-base:focus,
.k-button-outline-base.k-focus {
    box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 30%, transparent);
}
.k-button-outline-base:active,
.k-button-outline-base.k-active {
    border-color: var(--kendo-color-on-base, #3d3d3d);
    color: var(--kendo-color-base, #f5f5f5);
    background-color: var(--kendo-color-on-base, #3d3d3d);
}
.k-button-outline-base.k-selected {
    border-color: var(--kendo-color-on-base, #3d3d3d);
    color: var(--kendo-color-base, #f5f5f5);
    background-color: var(--kendo-color-on-base, #3d3d3d);
}
.k-button-flat-primary {
    color: var(--kendo-color-primary-on-surface, #214499);
}
.k-button-flat-secondary {
    color: var(--kendo-color-secondary-on-surface, #292929);
}
.k-button-flat-tertiary {
    color: var(--kendo-color-tertiary-on-surface, #028ccb);
}
.k-button-flat-info {
    color: var(--kendo-color-info-on-surface, #004ac2);
}
.k-button-flat-success {
    color: var(--kendo-color-success-on-surface, #2d9600);
}
.k-button-flat-warning {
    color: var(--kendo-color-warning-on-surface, #ffc000);
}
.k-button-flat-error {
    color: var(--kendo-color-error-on-surface, #ca1400);
}
.k-button-flat-dark {
    color: var(--kendo-color-dark-on-surface, #141414);
}
.k-button-flat-light {
    color: var(--kendo-color-light-on-surface, #e0e0e0);
}
.k-button-flat-inverse {
    color: var(--kendo-color-inverse-on-surface, #141414);
}
.k-button-flat-base {
    color: inherit;
}
.k-button-link-primary {
    color: var(--kendo-color-primary, rgb(44, 91, 204));
}
.k-button-link-primary:hover,
.k-button-link-primary.k-hover {
    color: var(--kendo-color-primary-hover, #2a57c4);
}
.k-button-link-primary:focus,
.k-button-link-primary.k-focus {
    box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-primary, rgb(44, 91, 204)) 30%, transparent);
}
.k-button-link-primary:active,
.k-button-link-primary.k-active {
    color: var(--kendo-color-primary-active, #2750b4);
}
.k-button-link-primary.k-selected {
    color: var(--kendo-color-primary-active, #2750b4);
}
.k-button-link-secondary {
    color: var(--kendo-color-secondary, #666666);
}
.k-button-link-secondary:hover,
.k-button-link-secondary.k-hover {
    color: var(--kendo-color-secondary-hover, #525252);
}
.k-button-link-secondary:focus,
.k-button-link-secondary.k-focus {
    box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-secondary, #666666) 30%, transparent);
}
.k-button-link-secondary:active,
.k-button-link-secondary.k-active {
    color: var(--kendo-color-secondary-active, #3d3d3d);
}
.k-button-link-secondary.k-selected {
    color: var(--kendo-color-secondary-active, #3d3d3d);
}
.k-button-link-tertiary {
    color: var(--kendo-color-tertiary, #03a9f4);
}
.k-button-link-tertiary:hover,
.k-button-link-tertiary.k-hover {
    color: var(--kendo-color-tertiary-hover, #039ae0);
}
.k-button-link-tertiary:focus,
.k-button-link-tertiary.k-focus {
    box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-tertiary, #03a9f4) 30%, transparent);
}
.k-button-link-tertiary:active,
.k-button-link-tertiary.k-active {
    color: var(--kendo-color-tertiary-active, #028ccb);
}
.k-button-link-tertiary.k-selected {
    color: var(--kendo-color-tertiary-active, #028ccb);
}
.k-button-link-info {
    color: var(--kendo-color-info, #0058e9);
}
.k-button-link-info:hover,
.k-button-link-info.k-hover {
    color: var(--kendo-color-info-hover, #0052d6);
}
.k-button-link-info:focus,
.k-button-link-info.k-focus {
    box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-info, #0058e9) 30%, transparent);
}
.k-button-link-info:active,
.k-button-link-info.k-active {
    color: var(--kendo-color-info-active, #004ac2);
}
.k-button-link-info.k-selected {
    color: var(--kendo-color-info-active, #004ac2);
}
.k-button-link-success {
    color: var(--kendo-color-success, #37b400);
}
.k-button-link-success:hover,
.k-button-link-success.k-hover {
    color: var(--kendo-color-success-hover, #32a500);
}
.k-button-link-success:focus,
.k-button-link-success.k-focus {
    box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-success, #37b400) 30%, transparent);
}
.k-button-link-success:active,
.k-button-link-success.k-active {
    color: var(--kendo-color-success-active, #2d9600);
}
.k-button-link-success.k-selected {
    color: var(--kendo-color-success-active, #2d9600);
}
.k-button-link-warning {
    color: var(--kendo-color-warning, #ffc000);
}
.k-button-link-warning:hover,
.k-button-link-warning.k-hover {
    color: var(--kendo-color-warning-hover, #eaaf00);
}
.k-button-link-warning:focus,
.k-button-link-warning.k-focus {
    box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-warning, #ffc000) 30%, transparent);
}
.k-button-link-warning:active,
.k-button-link-warning.k-active {
    color: var(--kendo-color-warning-active, #d49f00);
}
.k-button-link-warning.k-selected {
    color: var(--kendo-color-warning-active, #d49f00);
}
.k-button-link-error {
    color: var(--kendo-color-error, #f31700);
}
.k-button-link-error:hover,
.k-button-link-error.k-hover {
    color: var(--kendo-color-error-hover, #df1600);
}
.k-button-link-error:focus,
.k-button-link-error.k-focus {
    box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-error, #f31700) 30%, transparent);
}
.k-button-link-error:active,
.k-button-link-error.k-active {
    color: var(--kendo-color-error-active, #ca1400);
}
.k-button-link-error.k-selected {
    color: var(--kendo-color-error-active, #ca1400);
}
.k-button-link-dark {
    color: var(--kendo-color-dark, #3d3d3d);
}
.k-button-link-dark:hover,
.k-button-link-dark.k-hover {
    color: var(--kendo-color-dark-hover, #292929);
}
.k-button-link-dark:focus,
.k-button-link-dark.k-focus {
    box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-dark, #3d3d3d) 30%, transparent);
}
.k-button-link-dark:active,
.k-button-link-dark.k-active {
    color: var(--kendo-color-dark-active, #1f1f1f);
}
.k-button-link-dark.k-selected {
    color: var(--kendo-color-dark-active, #1f1f1f);
}
.k-button-link-light {
    color: var(--kendo-color-light, #ebebeb);
}
.k-button-link-light:hover,
.k-button-link-light.k-hover {
    color: var(--kendo-color-light-hover, #e0e0e0);
}
.k-button-link-light:focus,
.k-button-link-light.k-focus {
    box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-light, #ebebeb) 30%, transparent);
}
.k-button-link-light:active,
.k-button-link-light.k-active {
    color: var(--kendo-color-light-active, #d6d6d6);
}
.k-button-link-light.k-selected {
    color: var(--kendo-color-light-active, #d6d6d6);
}
.k-button-link-inverse {
    color: var(--kendo-color-dark, #3d3d3d);
}
.k-button-link-inverse:hover,
.k-button-link-inverse.k-hover {
    color: var(--kendo-color-inverse-hover, #292929);
}
.k-button-link-inverse:focus,
.k-button-link-inverse.k-focus {
    box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-inverse, #3d3d3d) 30%, transparent);
}
.k-button-link-inverse:active,
.k-button-link-inverse.k-active {
    color: var(--kendo-color-inverse-active, #1f1f1f);
}
.k-button-link-inverse.k-selected {
    color: var(--kendo-color-inverse-active, #1f1f1f);
}
.k-button-link-base {
    color: var(--kendo-color-on-app-surface, #3d3d3d);
}
.k-button-link-base:hover,
.k-button-link-base.k-hover {
    color: var(--kendo-color-on-app-surface, #3d3d3d);
}
.k-button-link-base:focus,
.k-button-link-base.k-focus {
    box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 30%, transparent);
}
.k-button-link-base:active,
.k-button-link-base.k-active {
    color: var(--kendo-color-on-app-surface, #3d3d3d);
}
.k-button-link-base.k-selected {
    color: var(--kendo-color-on-app-surface, #3d3d3d);
}
.k-button-clear-primary {
    color: var(--kendo-color-primary-on-surface, #214499);
}
.k-button-clear-primary:focus::after,
.k-button-clear-primary.k-focus::after,
.k-button-clear-primary:active::after {
    background-color: currentColor;
    opacity: 0.1;
    display: block;
}
.k-button-clear-secondary {
    color: var(--kendo-color-secondary-on-surface, #292929);
}
.k-button-clear-secondary:focus::after,
.k-button-clear-secondary.k-focus::after,
.k-button-clear-secondary:active::after {
    background-color: currentColor;
    opacity: 0.1;
    display: block;
}
.k-button-clear-tertiary {
    color: var(--kendo-color-tertiary-on-surface, #028ccb);
}
.k-button-clear-tertiary:focus::after,
.k-button-clear-tertiary.k-focus::after,
.k-button-clear-tertiary:active::after {
    background-color: currentColor;
    opacity: 0.1;
    display: block;
}
.k-button-clear-info {
    color: var(--kendo-color-info-on-surface, #004ac2);
}
.k-button-clear-info:focus::after,
.k-button-clear-info.k-focus::after,
.k-button-clear-info:active::after {
    background-color: currentColor;
    opacity: 0.1;
    display: block;
}
.k-button-clear-success {
    color: var(--kendo-color-success-on-surface, #2d9600);
}
.k-button-clear-success:focus::after,
.k-button-clear-success.k-focus::after,
.k-button-clear-success:active::after {
    background-color: currentColor;
    opacity: 0.1;
    display: block;
}
.k-button-clear-warning {
    color: var(--kendo-color-warning-on-surface, #ffc000);
}
.k-button-clear-warning:focus::after,
.k-button-clear-warning.k-focus::after,
.k-button-clear-warning:active::after {
    background-color: currentColor;
    opacity: 0.1;
    display: block;
}
.k-button-clear-error {
    color: var(--kendo-color-error-on-surface, #ca1400);
}
.k-button-clear-error:focus::after,
.k-button-clear-error.k-focus::after,
.k-button-clear-error:active::after {
    background-color: currentColor;
    opacity: 0.1;
    display: block;
}
.k-button-clear-dark {
    color: var(--kendo-color-dark-on-surface, #141414);
}
.k-button-clear-dark:focus::after,
.k-button-clear-dark.k-focus::after,
.k-button-clear-dark:active::after {
    background-color: currentColor;
    opacity: 0.1;
    display: block;
}
.k-button-clear-light {
    color: var(--kendo-color-light-on-surface, #e0e0e0);
}
.k-button-clear-light:focus::after,
.k-button-clear-light.k-focus::after,
.k-button-clear-light:active::after {
    background-color: currentColor;
    opacity: 0.1;
    display: block;
}
.k-button-clear-inverse {
    color: var(--kendo-color-inverse-on-surface, #141414);
}
.k-button-clear-inverse:focus::after,
.k-button-clear-inverse.k-focus::after,
.k-button-clear-inverse:active::after {
    background-color: currentColor;
    opacity: 0.1;
    display: block;
}
.k-button-clear-base {
    color: var(--kendo-color-base-on-surface, #3d3d3d);
}
.k-button-clear-base:focus::after,
.k-button-clear-base.k-focus::after,
.k-button-clear-base:active::after {
    background-color: currentColor;
    opacity: 0.1;
    display: block;
}
.k-animation-container {
    position: absolute;
    overflow: hidden;
    z-index: 100;
}
.k-animation-container-fixed {
    position: fixed;
}
.k-animation-container-relative {
    position: relative;
    display: inline-block;
}

.k-animation-container {
    border-radius: 0 0 0.25rem 0.25rem;
}

.k-animation-container-shown {
    overflow: visible;
}

.k-popup {
    margin: 0;
    padding: 0;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    font-size: var(--kendo-font-size, inherit);
    line-height: var(--kendo-line-height, normal);
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.k-popup .k-item {
    outline: none;
}

.k-popup > .k-colorpalette {
    padding-block: var(--kendo-spacing-2, 0.5rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
}

.k-popup.k-popup-transparent {
    border-width: 0;
    background-color: transparent;
    box-shadow: none;
}

.k-popup.k-popup-flush {
    padding: 0;
}

.k-popup > .k-widget,
.k-popup > .k-coloreditor {
    border-width: 0;
}

.k-shadow {
    box-shadow: var(--kendo-elevation-4, 0 8px 10px rgba(0, 0, 0, 0.12), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.k-popup {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    color: var(--kendo-color-on-app-surface, #3d3d3d);
    background-color: var(--kendo-color-surface-alt, #ffffff);
    box-shadow: var(--kendo-elevation-4, 0 8px 10px rgba(0, 0, 0, 0.12), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.k-animation-container {
    border-radius: 0 0 var(--kendo-border-radius-md, 0.25rem) var(--kendo-border-radius-md, 0.25rem);
}

.k-checkbox {
    margin: 0;
    padding: 0;
    line-height: initial;
    border-width: 1px;
    border-style: solid;
    outline: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    flex: none;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
    -webkit-appearance: none;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='var%28--kendo-color-on-primary, white%29' stroke-linecap='square' stroke-linejoin='square' stroke-width='2' d='M3,8 l3,3 l7-7'/%3e%3c/svg%3e");
}

.k-checkbox:indeterminate,
.k-checkbox.k-indeterminate {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='var%28--kendo-color-primary, rgb%2844, 91, 204%29%29' stroke-linecap='square' stroke-linejoin='square' stroke-width='2' d='M4,8 h8'/%3e%3c/svg%3e");
}

.k-checkbox:disabled,
.k-checkbox.k-disabled {
    outline: none;
    cursor: default;
    opacity: 0.6;
    filter: grayscale(0.1);
    pointer-events: none;
    box-shadow: none;
}

.k-checkbox-wrap {
    flex: none;
    display: inline-flex;
    flex-flow: row nowrap;
    gap: 0;
    align-items: center;
    align-self: flex-start;
    vertical-align: middle;
    position: relative;
}
.k-checkbox-wrap::before {
    content: '​';
    width: 0px;
    overflow: hidden;
    flex: none;
    display: inline-block;
    vertical-align: top;
}

.k-checkbox-label {
    margin: 0;
    padding: 0;
    display: inline-flex;
    align-items: flex-start;
    gap: var(--kendo-spacing-1, 0.25rem);
    vertical-align: middle;
    position: relative;
    cursor: pointer;
}
.k-checkbox-label .k-ripple {
    visibility: hidden !important;
}

.k-checkbox + .k-label,
.k-checkbox-wrap + .k-label,
.k-checkbox + .k-checkbox-label,
.k-checkbox-wrap + .k-checkbox-label {
    display: inline;
    margin-inline-start: var(--kendo-spacing-1, 0.25rem);
}

.k-checkbox-label:empty {
    display: none !important;
}

.k-checkbox-label.k-no-text {
    min-width: 1px;
}

.k-checkbox-list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: column nowrap;
    gap: 0;
    list-style: none;
}

.k-checkbox-item,
.k-checkbox-list-item {
    padding-block: var(--kendo-spacing-1, 0.25rem);
    padding-inline: var(--kendo-spacing-0, 0px);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    align-content: center;
    gap: var(--kendo-spacing-1, 0.25rem);
}
.k-checkbox-item .k-checkbox-label,
.k-checkbox-list-item .k-checkbox-label {
    margin: 0;
}

.k-checkbox-list-horizontal,
.k-checkbox-list.k-list-horizontal {
    display: flex;
    flex-flow: row wrap;
    gap: var(--kendo-spacing-4, 1rem);
}

.k-ripple-container .k-checkbox::after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 100%;
    z-index: -1;
    transition:
        opacity 100ms linear,
        transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate(-50%, -50%) scale(0);
    transform-origin: center center;
}
.k-ripple-container .k-checkbox:focus,
.k-ripple-container .k-checkbox.k-focus {
    box-shadow: none !important;
}
.k-ripple-container .k-checkbox:disabled::after,
.k-ripple-container .k-checkbox.k-disabled::after {
    display: none;
}

.k-checkbox-sm {
    width: var(--kendo-spacing-3, 0.75rem);
    height: var(--kendo-spacing-3, 0.75rem);
}
.k-checkbox-sm::before {
    font-size: var(--kendo-spacing-2\.5, 0.625rem);
}

.k-ripple-container .k-checkbox-sm::after {
    width: 300%;
    height: 300%;
}

.k-checkbox-md {
    width: var(--kendo-spacing-4, 1rem);
    height: var(--kendo-spacing-4, 1rem);
}
.k-checkbox-md::before {
    font-size: var(--kendo-spacing-3\.5, 0.875rem);
}

.k-ripple-container .k-checkbox-md::after {
    width: 300%;
    height: 300%;
}

.k-checkbox-lg {
    width: var(--kendo-spacing-5, 1.25rem);
    height: var(--kendo-spacing-5, 1.25rem);
}
.k-checkbox-lg::before {
    font-size: var(--kendo-spacing-4\.5, 1.125rem);
}

.k-ripple-container .k-checkbox-lg::after {
    width: 300%;
    height: 300%;
}

.k-checkbox {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    background-color: var(--kendo-color-surface-alt, #ffffff);
}

.k-checkbox:focus,
.k-checkbox.k-focus {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.06);
}

.k-checkbox:indeterminate,
.k-checkbox.k-indeterminate {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    color: var(--kendo-color-primary, rgb(44, 91, 204));
    background-color: var(--kendo-color-surface-alt, #ffffff);
}

.k-checkbox:checked,
.k-checkbox.k-checked {
    border-color: var(--kendo-color-primary, rgb(44, 91, 204));
    color: var(--kendo-color-on-primary, white);
    background-color: var(--kendo-color-primary, rgb(44, 91, 204));
}

.k-checkbox:checked:focus,
.k-checkbox.k-checked.k-focus {
    box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-primary, rgb(44, 91, 204)) 30%, transparent);
}

.k-checkbox.k-invalid {
    border-color: var(--kendo-color-error, #f31700);
}

.k-checkbox.k-invalid + .k-checkbox-label {
    color: var(--kendo-color-error, #f31700);
}

.k-checkbox-wrap .k-ripple-blob {
    color: var(--kendo-color-primary, rgb(44, 91, 204));
    opacity: 0.25;
}

.k-ripple-container .k-checkbox::after {
    background: var(--kendo-color-primary, rgb(44, 91, 204));
    opacity: 0.25;
}

.k-list-container {
    display: flex;
    flex-flow: column nowrap;
}
.k-list-container > .k-list {
    flex: 1;
    height: 100%;
}

.k-list {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: var(--kendo-font-family, inherit);
    display: flex;
    flex-flow: column nowrap;
    outline: none;
    position: relative;
    overflow: hidden;
}
.k-list *,
.k-list *::before,
.k-list *::after {
    box-sizing: border-box;
}

.k-popup > .k-list {
    height: 100%;
    border-width: 0;
}

.k-list-group-sticky-header {
    border-width: 0;
    border-width: 0 0 1px;
    border-style: solid;
    font-weight: var(--kendo-font-weight-bold, normal);
    white-space: nowrap;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    flex: none;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.k-list-content {
    border-color: inherit;
    flex: 1 1 auto;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
}

.k-list-ul {
    margin: 0;
    padding: 0;
    border-width: 0;
    border-color: inherit;
    height: auto;
    list-style: none;
}

.k-list-item,
.k-list-optionlabel {
    border: 0;
    outline: none;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    align-content: center;
    gap: var(--kendo-spacing-1, 0.25rem);
    position: relative;
    transition-property: color, background-color, outline-color, box-shadow;
    transition-duration: 200ms;
    transition-timing-function: ease;
}
.k-list-item.k-first::before,
.k-first.k-list-optionlabel::before {
    content: '';
    border-width: 1px 0 0;
    border-style: solid;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.k-list-item-text::before,
.k-list-header-text::before,
.k-list-optionlabel::before {
    content: '​';
    width: 0px;
    overflow: hidden;
}

.k-list-optionlabel {
    column-gap: 0;
}

.k-list-group-item {
    border-width: 0;
    border-width: 1px 0 0;
    border-style: solid;
    font-weight: var(--kendo-font-weight-bold, normal);
    cursor: default;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    align-content: center;
    gap: var(--kendo-spacing-1, 0.25rem);
    position: relative;
}

.k-list-item-group-label {
    padding-block: 0;
    padding-inline: 0.5em;
    font-size: 0.75em;
    position: absolute;
    top: 0;
    inset-inline-end: 0;
}

.k-virtual-content,
.k-virtual-list .k-list-content {
    overflow-y: scroll;
}

.k-virtual-list .k-list-item,
.k-virtual-list .k-list-optionlabel,
.k-virtual-list .k-list-group-item,
.k-virtual-content .k-list-item,
.k-virtual-content .k-list-optionlabel,
.k-virtual-content .k-list-group-item {
    position: absolute;
    width: 100%;
}

.k-virtual-list .k-list-item-text,
.k-virtual-list .k-list-header-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.k-virtual-list .k-list-optionlabel {
    position: relative;
}

.k-list-filter {
    display: block;
    position: relative;
    padding: var(--kendo-spacing-2, 0.5rem);
    box-sizing: border-box;
    flex: none;
}

.k-list-sm {
    font-size: var(--kendo-font-size, inherit);
    line-height: var(--kendo-line-height, normal);
}
.k-list-sm .k-list-group-sticky-header {
    padding-block: var(--kendo-spacing-0\.5, 0.125rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-list-sm .k-list-item,
.k-list-sm .k-list-optionlabel {
    padding-block: var(--kendo-spacing-0\.5, 0.125rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-list-sm .k-list-group-item {
    padding-block: var(--kendo-spacing-0\.5, 0.125rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
}

.k-list-md {
    font-size: var(--kendo-font-size, inherit);
    line-height: var(--kendo-line-height, normal);
}
.k-list-md .k-list-group-sticky-header {
    padding-block: var(--kendo-spacing-1, 0.25rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-list-md .k-list-item,
.k-list-md .k-list-optionlabel {
    padding-block: var(--kendo-spacing-1, 0.25rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-list-md .k-list-group-item {
    padding-block: var(--kendo-spacing-1, 0.25rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
}

.k-list-lg {
    font-size: var(--kendo-font-size-lg, inherit);
    line-height: var(--kendo-line-height-lg, normal);
}
.k-list-lg .k-list-group-sticky-header {
    padding-block: var(--kendo-spacing-1\.5, 0.375rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-list-lg .k-list-item,
.k-list-lg .k-list-optionlabel {
    padding-block: var(--kendo-spacing-2, 0.5rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-list-lg .k-list-group-item {
    padding-block: var(--kendo-spacing-1\.5, 0.375rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
}

.k-no-data,
.k-nodata {
    min-height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: lighter;
    text-align: center;
    white-space: normal;
}

.k-list {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    color: var(--kendo-color-on-app-surface, #3d3d3d);
    background-color: var(--kendo-color-surface-alt, #ffffff);
}

.k-list-group-sticky-header {
    border-color: inherit;
    box-shadow: var(--kendo-elevation-2, 0 4px 6px rgba(0, 0, 0, 0.06), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.k-list-item:hover,
.k-list-optionlabel:hover,
.k-list-item.k-hover,
.k-hover.k-list-optionlabel {
    color: var(--kendo-color-on-app-surface, #3d3d3d);
    background-color: var(--kendo-color-base-hover, #ebebeb);
}
.k-list-item:focus,
.k-list-optionlabel:focus,
.k-list-item.k-focus,
.k-focus.k-list-optionlabel {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}
.k-list-item.k-selected,
.k-selected.k-list-optionlabel {
    color: var(--kendo-color-on-primary, white);
    background-color: var(--kendo-color-primary, rgb(44, 91, 204));
}
.k-list-item.k-selected:hover,
.k-selected.k-list-optionlabel:hover,
.k-list-item.k-selected.k-hover,
.k-selected.k-hover.k-list-optionlabel {
    color: var(--kendo-color-on-primary, white);
    background-color: var(--kendo-color-primary-hover, #2a57c4);
}

.k-list-optionlabel,
.k-list-optionlabel.k-hover,
.k-list-optionlabel:hover {
    color: var(--kendo-color-subtle, #666666);
}

.k-list-group-item {
    border-color: inherit;
}

.k-list-item-group-label {
    color: var(--kendo-color-surface-alt, #ffffff);
    background-color: var(--kendo-color-on-app-surface, #3d3d3d);
}

.k-no-data,
.k-nodata {
    color: var(--kendo-color-subtle, #666666);
}

.k-menu-bar,
.k-menu {
    border-width: 0px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-family: var(--kendo-font-family, inherit);
    font-size: var(--kendo-font-size, inherit);
    line-height: var(--kendo-line-height, normal);
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    position: relative;
    cursor: default;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-menu-bar *,
.k-menu *,
.k-menu-bar *::before,
.k-menu *::before,
.k-menu-bar *::after,
.k-menu *::after {
    box-sizing: border-box;
}

.k-menu-item {
    border-width: 0;
    outline: 0;
    display: flex;
    flex-flow: column nowrap;
    flex: none;
    position: relative;
    user-select: none;
}

.k-menu-item-content {
    display: block;
    height: auto;
    overflow: visible;
}
.k-menu-item-content .k-actions,
.k-menu-item-content .k-form-buttons,
.k-menu-item-content .k-edit-buttons,
.k-menu-item-content .k-action-buttons,
.k-menu-item-content .k-columnmenu-actions {
    margin-top: 0;
}

.k-menu-link {
    padding-block: var(--kendo-spacing-1\.5, 0.375rem);
    padding-inline: var(--kendo-spacing-3, 0.75rem);
    outline: 0;
    color: inherit;
    display: flex;
    flex-flow: row nowrap;
    flex: 1 1 auto;
    gap: var(--kendo-spacing-1, 0.25rem);
    align-items: center;
    position: relative;
    white-space: nowrap;
    cursor: pointer;
}

.k-menu-link-text {
    display: flex;
    flex-flow: row nowrap;
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
}

.k-menu-expand-arrow {
    margin-inline-end: calc(var(--kendo-spacing-1, 0.25rem) * -1);
    display: inline-flex;
    flex-flow: row wrap;
    align-items: center;
    flex: none;
    position: relative;
}

.k-menu-horizontal {
    flex-direction: row;
}
.k-menu-horizontal > .k-menu-item + .k-menu-item {
    margin-inline-start: var(--kendo-spacing-0, 0px);
}
.k-menu-horizontal > .k-separator {
    margin-block: 0;
    margin-inline: var(--kendo-spacing-1, 0.25rem);
    width: 0;
    height: auto;
    border-width: 0 0 0 1px;
    border-style: solid;
}

.k-menu-vertical {
    flex-direction: column;
}
.k-menu-vertical > .k-menu-item + .k-menu-item {
    margin-top: var(--kendo-spacing-0, 0px);
}
.k-menu-vertical > .k-menu-item > .k-menu-link {
    padding-block: var(--kendo-spacing-1, 0.25rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
    padding-inline-end: calc(var(--kendo-spacing-2, 0.5rem) * 2 + 16px);
}
.k-menu-vertical > .k-menu-item > .k-menu-link > .k-menu-expand-arrow {
    margin-inline-start: var(--kendo-spacing-2, 0.5rem);
    margin-inline-end: calc(-1 * (calc(var(--kendo-spacing-2, 0.5rem) * 2 + 16px) - var(--kendo-spacing-2, 0.5rem) / 2));
}
.k-menu-vertical > .k-separator {
    margin-block: var(--kendo-spacing-1, 0.25rem);
    margin-inline: 0;
    height: 0;
    border-width: 1px 0 0;
    border-style: solid;
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    display: block;
}

.k-menu-popup {
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    overflow: auto;
    max-height: 80vh;
}
.k-menu-popup *,
.k-menu-popup *::before,
.k-menu-popup *::after {
    box-sizing: border-box;
}

.k-menu-group {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: column nowrap;
    position: absolute;
}
.k-menu-group .k-menu-item + .k-menu-item {
    margin-top: var(--kendo-spacing-0, 0px);
}
.k-menu-group .k-separator {
    margin-block: var(--kendo-spacing-1, 0.25rem);
    margin-inline: 0;
    height: 0;
    border-width: 1px 0 0;
    border-style: solid;
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    display: block;
}

.k-menu-popup .k-menu-group {
    position: relative;
}

.k-menu-group-sm {
    font-size: var(--kendo-font-size, inherit);
    line-height: var(--kendo-line-height, normal);
}
.k-menu-group-sm .k-menu-item {
    font-size: var(--kendo-font-size, inherit);
    line-height: var(--kendo-line-height, normal);
}
.k-menu-group-sm .k-menu-link {
    padding-block: var(--kendo-spacing-0\.5, 0.125rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
    padding-inline-end: calc(var(--kendo-spacing-2, 0.5rem) * 2 + 16px);
}
.k-menu-group-sm .k-menu-expand-arrow {
    margin-inline-start: var(--kendo-spacing-2, 0.5rem);
    margin-inline-end: calc(-1 * (calc(var(--kendo-spacing-2, 0.5rem) * 2 + 16px) - var(--kendo-spacing-2, 0.5rem) / 2));
}

.k-menu-group-md {
    font-size: var(--kendo-font-size, inherit);
    line-height: var(--kendo-line-height, normal);
}
.k-menu-group-md .k-menu-item {
    font-size: var(--kendo-font-size, inherit);
    line-height: var(--kendo-line-height, normal);
}
.k-menu-group-md .k-menu-link {
    padding-block: var(--kendo-spacing-1, 0.25rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
    padding-inline-end: calc(var(--kendo-spacing-2, 0.5rem) * 2 + 16px);
}
.k-menu-group-md .k-menu-expand-arrow {
    margin-inline-start: var(--kendo-spacing-2, 0.5rem);
    margin-inline-end: calc(-1 * (calc(var(--kendo-spacing-2, 0.5rem) * 2 + 16px) - var(--kendo-spacing-2, 0.5rem) / 2));
}

.k-menu-group-lg {
    font-size: var(--kendo-font-size-lg, inherit);
    line-height: var(--kendo-line-height-lg, normal);
}
.k-menu-group-lg .k-menu-item {
    font-size: var(--kendo-font-size-lg, inherit);
    line-height: var(--kendo-line-height-lg, normal);
}
.k-menu-group-lg .k-menu-link {
    padding-block: var(--kendo-spacing-1\.5, 0.375rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
    padding-inline-end: calc(var(--kendo-spacing-2, 0.5rem) * 2 + 16px);
}
.k-menu-group-lg .k-menu-expand-arrow {
    margin-inline-start: var(--kendo-spacing-2, 0.5rem);
    margin-inline-end: calc(-1 * (calc(var(--kendo-spacing-2, 0.5rem) * 2 + 16px) - var(--kendo-spacing-2, 0.5rem) / 2));
}

.k-popups-wrapper {
    position: relative;
    border: 0;
    margin: 0;
    padding: 0;
}

.k-context-menu {
    margin: 0;
    border-width: 1px;
    border-style: solid;
}
.k-animation-container .k-context-menu.k-menu-horizontal {
    display: flex !important;
    flex-wrap: nowrap;
}

.k-context-menu-popup {
    z-index: 12000;
}
.k-context-menu-popup .k-context-menu {
    border-width: 0;
}

.k-popup .k-context-menu,
.k-context-menu-popup .k-context-menu {
    border-width: 0;
}

.k-menu-scroll-wrapper {
    margin: 0;
    padding: 0;
    border: 0;
    position: relative;
}
.k-menu-scroll-wrapper .k-menu {
    overflow: hidden;
    flex-wrap: nowrap;
}
.k-menu-scroll-wrapper .k-menu-scroll-button {
    border-radius: 0;
    padding: 0;
    border-width: 0;
    border-color: inherit;
    color: inherit;
    background: inherit;
    position: absolute;
}
.k-menu-scroll-wrapper .k-menu-scroll-button.k-scroll-left {
    top: 0;
    inset-inline-start: 0;
    height: 100%;
    width: 16px;
    border-right-width: 1px;
}
.k-menu-scroll-wrapper .k-menu-scroll-button.k-scroll-right {
    top: 0;
    inset-inline-end: 0;
    height: 100%;
    width: 16px;
    border-left-width: 1px;
}
.k-menu-scroll-wrapper .k-menu-scroll-button.k-scroll-up {
    top: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 16px;
    border-bottom-width: 1px;
}
.k-menu-scroll-wrapper .k-menu-scroll-button.k-scroll-down {
    bottom: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 16px;
    border-top-width: 1px;
}

.k-menu:not(.k-context-menu) {
    color: var(--kendo-color-on-app-surface, #3d3d3d);
    background-color: transparent;
}
.k-menu:not(.k-context-menu) > .k-item {
    color: var(--kendo-color-primary, rgb(44, 91, 204));
}
.k-menu:not(.k-context-menu) > .k-item:hover,
.k-menu:not(.k-context-menu) > .k-item.k-hover {
    color: var(--kendo-color-primary-hover, #2a57c4);
}
.k-menu:not(.k-context-menu) > .k-item:active,
.k-menu:not(.k-context-menu) > .k-item.k-active {
    color: var(--kendo-color-on-app-surface, #3d3d3d);
}
.k-menu:not(.k-context-menu) > .k-item:focus,
.k-menu:not(.k-context-menu) > .k-item.k-focus {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}

.k-menu-group,
.k-menu.k-context-menu {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    color: var(--kendo-color-on-app-surface, #3d3d3d);
    background-color: var(--kendo-color-surface-alt, #ffffff);
}
.k-menu-group .k-item > .k-link:hover,
.k-menu-group .k-item > .k-link.k-hover,
.k-menu.k-context-menu .k-item > .k-link:hover,
.k-menu.k-context-menu .k-item > .k-link.k-hover {
    color: var(--kendo-color-on-app-surface, #3d3d3d);
    background-color: var(--kendo-color-base-hover, #ebebeb);
}
.k-menu-group .k-item > .k-link:active,
.k-menu-group .k-item > .k-link.k-active,
.k-menu-group .k-item > .k-link.k-selected,
.k-menu.k-context-menu .k-item > .k-link:active,
.k-menu.k-context-menu .k-item > .k-link.k-active,
.k-menu.k-context-menu .k-item > .k-link.k-selected {
    color: var(--kendo-color-on-primary, white);
    background-color: var(--kendo-color-primary, rgb(44, 91, 204));
}
.k-menu-group .k-item:focus > .k-link,
.k-menu-group .k-item.k-focus > .k-link,
.k-menu.k-context-menu .k-item:focus > .k-link,
.k-menu.k-context-menu .k-item.k-focus > .k-link {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}

.k-menu-scroll-wrapper .k-menu-scroll-button {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    color: var(--kendo-color-primary, rgb(44, 91, 204));
    background-color: var(--kendo-color-surface-alt, #ffffff);
}
.k-menu-scroll-wrapper .k-menu-scroll-button:hover {
    color: var(--kendo-color-primary-hover, #2a57c4);
}
.k-menu-scroll-wrapper .k-menu-scroll-button:hover::before {
    opacity: 0;
}

.k-toolbar {
    margin: 0;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-family: var(--kendo-font-family, inherit);
    font-size: var(--kendo-font-size, inherit);
    line-height: var(--kendo-line-height, normal);
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-toolbar::before {
    content: '';
    height: calc(var(--kendo-line-height, normal) * 1em + var(--kendo-spacing-1, 0.25rem) * 2 + 2px);
}
.k-toolbar.k-toolbar-resizable {
    flex-wrap: nowrap;
}
.k-toolbar > * {
    flex-shrink: 0;
    display: inline-flex;
    align-content: center;
    vertical-align: middle;
}
.k-toolbar > * > label {
    align-self: center;
}
.k-toolbar .k-input,
.k-toolbar .k-picker {
    width: 10em;
}
.k-toolbar .k-color-picker {
    width: min-content;
}
.k-toolbar .k-toolbar-overflow-button {
    margin-inline-start: auto;
}
.k-toolbar .k-separator,
.k-toolbar .k-toolbar-separator {
    width: 0;
    height: calc(1.4285714286 * 1em);
    border-width: 0 0 0 1px;
    border-style: solid;
    align-self: center;
}
.k-toolbar .k-toolbar-spacer {
    height: calc(1.4285714286 * 1em);
    flex: 1 0 0%;
}
.k-toolbar .k-toolbar-item:focus,
.k-toolbar .k-toolbar-item.k-focus {
    text-decoration: none;
    outline: 0;
}

.k-toolbar-group {
    display: flex;
    flex-direction: inherit;
    flex-wrap: nowrap;
    gap: inherit;
}

.k-toolbar-renderer {
    display: inline-block;
    border-color: inherit;
}

.k-toolbar-sm {
    padding-block: var(--kendo-spacing-1, 0.25rem);
    padding-inline: var(--kendo-spacing-1, 0.25rem);
    gap: var(--kendo-spacing-1\.5, 0.375rem);
}
.k-toolbar-sm::before {
    margin-inline-start: calc(var(--kendo-spacing-1\.5, 0.375rem) * -1);
}
.k-toolbar-sm > * > label {
    margin-inline-end: var(--kendo-spacing-1\.5, 0.375rem);
}

.k-toolbar-md {
    padding-block: var(--kendo-spacing-2, 0.5rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
    gap: var(--kendo-spacing-2, 0.5rem);
}
.k-toolbar-md::before {
    margin-inline-start: calc(var(--kendo-spacing-2, 0.5rem) * -1);
}
.k-toolbar-md > * > label {
    margin-inline-end: var(--kendo-spacing-2, 0.5rem);
}

.k-toolbar-lg {
    padding-block: var(--kendo-spacing-2\.5, 0.625rem);
    padding-inline: var(--kendo-spacing-2\.5, 0.625rem);
    gap: var(--kendo-spacing-2\.5, 0.625rem);
}
.k-toolbar-lg::before {
    margin-inline-start: calc(var(--kendo-spacing-2\.5, 0.625rem) * -1);
}
.k-toolbar-lg > * > label {
    margin-inline-end: var(--kendo-spacing-2\.5, 0.625rem);
}

.k-toolbar-sm::before {
    height: calc(var(--kendo-line-height, normal) * 1em + var(--kendo-spacing-0\.5, 0.125rem) * 2 + 2px);
}

.k-toolbar-md::before {
    height: calc(var(--kendo-line-height, normal) * 1em + var(--kendo-spacing-1, 0.25rem) * 2 + 2px);
}

.k-toolbar-lg::before {
    height: calc(var(--kendo-line-height-lg, normal) * 1em + var(--kendo-spacing-2, 0.5rem) * 2 + 2px);
}

.k-floating-toolbar,
.editorToolbarWindow.k-window-content {
    padding-block: var(--kendo-spacing-2, 0.5rem) !important;
    padding-inline: var(--kendo-spacing-2, 0.5rem) !important;
    border-width: 1px;
    border-style: solid;
    display: flex;
    flex-flow: row nowrap;
    gap: var(--kendo-spacing-2, 0.5rem);
    align-items: center;
}
.k-floating-toolbar .k-toolbar,
.editorToolbarWindow.k-window-content .k-toolbar {
    padding: 0;
    border-width: 0;
    flex-shrink: 1;
    color: inherit;
    background: none;
}

.k-editortoolbar-dragHandle {
    cursor: move;
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-shrink: 0;
}

.k-overflow-container {
    font-size: var(--kendo-font-size, inherit);
    line-height: var(--kendo-line-height, normal);
}
.k-overflow-container > .k-item {
    border-color: inherit;
}
.k-overflow-container .k-separator {
    margin: var(--kendo-spacing-1, 0.25rem) 0;
}
.k-overflow-container .k-overflow-tool-group {
    display: block;
}
.k-overflow-container .k-overflow-button {
    padding-block: var(--kendo-spacing-1, 0.25rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
    width: 100%;
    height: auto;
    border-width: 0;
    border-radius: 0;
    aspect-ratio: auto;
    color: inherit;
    background-color: transparent;
    background-image: none;
    font: inherit;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    align-content: center;
    gap: var(--kendo-spacing-1, 0.25rem);
    justify-content: flex-start;
    position: relative;
}
.k-overflow-container .k-button-group {
    box-shadow: none;
    display: flex;
    flex-flow: column nowrap;
}
.k-overflow-container .k-button-group .k-button {
    margin: 0;
}
.k-overflow-container .k-split-button {
    display: flex;
    flex-direction: column;
}
.k-overflow-container .k-overflow-hidden {
    display: none;
}

.k-toolbar.k-toolbar-flat {
    border-width: 1px 0;
    border-top-color: transparent !important;
    border-bottom-color: inherit;
    color: inherit;
    background: none !important;
    box-shadow: none;
}

.k-toolbar {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    color: var(--kendo-color-on-app-surface, #3d3d3d);
    background-color: var(--kendo-color-surface, #fafafa);
}
.k-toolbar .k-separator,
.k-toolbar .k-toolbar-separator {
    border-color: inherit;
}
.k-toolbar .k-toolbar-item:focus,
.k-toolbar .k-toolbar-item.k-focus {
    box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-floating-toolbar,
.editorToolbarWindow.k-window-content {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    color: var(--kendo-color-on-app-surface, #3d3d3d);
    background-color: var(--kendo-color-surface, #fafafa);
}

.k-overflow-container .k-button:hover,
.k-overflow-container .k-button.k-hover {
    color: var(--kendo-color-on-app-surface, #3d3d3d);
    background: var(--kendo-color-base-hover, #ebebeb);
}
.k-overflow-container .k-button:focus,
.k-overflow-container .k-button.k-focus {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}
.k-overflow-container .k-button:active,
.k-overflow-container .k-button.k-active {
    color: var(--kendo-color-on-app-surface, #3d3d3d);
    background: var(--kendo-color-base-hover, #ebebeb);
}
.k-overflow-container .k-button.k-selected {
    color: var(--kendo-color-on-primary, white);
    background: var(--kendo-color-primary, rgb(44, 91, 204));
}
.k-overflow-container .k-button:disabled,
.k-overflow-container .k-button.k-disabled {
    color: inherit;
}

.k-overflow-auto {
    overflow: auto;
}

.\!k-overflow-auto {
    overflow: auto !important;
}

.k-overflow-hidden {
    overflow: hidden;
}

.\!k-overflow-hidden {
    overflow: hidden !important;
}

.k-overflow-visible {
    overflow: visible;
}

.\!k-overflow-visible {
    overflow: visible !important;
}

.k-overflow-scroll {
    overflow: scroll;
}

.\!k-overflow-scroll {
    overflow: scroll !important;
}

.k-overflow-clip {
    overflow: clip;
}

.\!k-overflow-clip {
    overflow: clip !important;
}

.k-overflow-x-auto {
    overflow-x: auto;
}

.\!k-overflow-x-auto {
    overflow-x: auto !important;
}

.k-overflow-x-hidden {
    overflow-x: hidden;
}

.\!k-overflow-x-hidden {
    overflow-x: hidden !important;
}

.k-overflow-x-visible {
    overflow-x: visible;
}

.\!k-overflow-x-visible {
    overflow-x: visible !important;
}

.k-overflow-x-scroll {
    overflow-x: scroll;
}

.\!k-overflow-x-scroll {
    overflow-x: scroll !important;
}

.k-overflow-x-clip {
    overflow-x: clip;
}

.\!k-overflow-x-clip {
    overflow-x: clip !important;
}

.k-overflow-y-auto {
    overflow-y: auto;
}

.\!k-overflow-y-auto {
    overflow-y: auto !important;
}

.k-overflow-y-hidden {
    overflow-y: hidden;
}

.\!k-overflow-y-hidden {
    overflow-y: hidden !important;
}

.k-overflow-y-visible {
    overflow-y: visible;
}

.\!k-overflow-y-visible {
    overflow-y: visible !important;
}

.k-overflow-y-scroll {
    overflow-y: scroll;
}

.\!k-overflow-y-scroll {
    overflow-y: scroll !important;
}

.k-overflow-y-clip {
    overflow-y: clip;
}

.\!k-overflow-y-clip {
    overflow-y: clip !important;
}

.k-actions,
.k-form-buttons,
.k-edit-buttons,
.k-action-buttons,
.k-columnmenu-actions {
    padding-block: var(--kendo-spacing-2, 0.5rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
    box-sizing: border-box;
    border-width: 0px 0 0 0;
    border-style: solid;
    border-color: inherit;
    flex-shrink: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: var(--kendo-spacing-2, 0.5rem);
    overflow: hidden;
    clear: both;
}
.k-actions *,
.k-form-buttons *,
.k-edit-buttons *,
.k-action-buttons *,
.k-columnmenu-actions *,
.k-actions *::before,
.k-form-buttons *::before,
.k-edit-buttons *::before,
.k-action-buttons *::before,
.k-columnmenu-actions *::before,
.k-actions *::after,
.k-form-buttons *::after,
.k-edit-buttons *::after,
.k-action-buttons *::after,
.k-columnmenu-actions *::after {
    box-sizing: border-box;
}

.k-popup .k-actions,
.k-popup .k-form-buttons,
.k-popup .k-edit-buttons,
.k-popup .k-action-buttons,
.k-popup .k-columnmenu-actions {
    margin-top: var(--kendo-spacing-4, 1rem);
}

.k-actions-start {
    justify-content: flex-start;
}

.k-actions-center {
    justify-content: center;
}

.k-actions-end {
    justify-content: flex-end;
}

.k-actions-stretched > * {
    flex: 1 0 0%;
}

.k-actions-horizontal {
    width: 100%;
    flex-flow: row nowrap;
}

.k-actions-vertical {
    flex-flow: column nowrap;
}

.k-justify-content-normal {
    justify-content: normal;
}

.\!k-justify-content-normal {
    justify-content: normal !important;
}

.k-justify-content-stretch {
    justify-content: stretch;
}

.\!k-justify-content-stretch {
    justify-content: stretch !important;
}

.k-justify-content-center {
    justify-content: center;
}

.\!k-justify-content-center {
    justify-content: center !important;
}

.k-justify-content-start {
    justify-content: start;
}

.\!k-justify-content-start {
    justify-content: start !important;
}

.k-justify-content-end {
    justify-content: end;
}

.\!k-justify-content-end {
    justify-content: end !important;
}

.k-justify-content-flex-start {
    justify-content: flex-start;
}

.\!k-justify-content-flex-start {
    justify-content: flex-start !important;
}

.k-justify-content-flex-end {
    justify-content: flex-end;
}

.\!k-justify-content-flex-end {
    justify-content: flex-end !important;
}

.k-justify-content-left {
    justify-content: left;
}

.\!k-justify-content-left {
    justify-content: left !important;
}

.k-justify-content-right {
    justify-content: right;
}

.\!k-justify-content-right {
    justify-content: right !important;
}

.k-justify-content-baseline {
    justify-content: baseline;
}

.\!k-justify-content-baseline {
    justify-content: baseline !important;
}

.k-justify-content-first-baseline {
    justify-content: first baseline;
}

.\!k-justify-content-first-baseline {
    justify-content: first baseline !important;
}

.k-justify-content-last-baseline {
    justify-content: last baseline;
}

.\!k-justify-content-last-baseline {
    justify-content: last baseline !important;
}

.k-justify-content-between {
    justify-content: space-between;
}

.\!k-justify-content-between {
    justify-content: space-between !important;
}

.k-justify-content-around {
    justify-content: space-around;
}

.\!k-justify-content-around {
    justify-content: space-around !important;
}

.k-justify-content-evenly {
    justify-content: space-evenly;
}

.\!k-justify-content-evenly {
    justify-content: space-evenly !important;
}

.k-justify-content-stretch > * {
    flex: 1 0 0%;
}

.\!.k-justify-content-stretch > * {
    flex: 1 0 0% !important;
}

.k-form,
.k-form-inline {
    font-size: var(--kendo-font-size, inherit);
    line-height: var(--kendo-line-height, normal);
}

.k-fieldset {
    margin: var(--kendo-spacing-7\.5, 1.875rem);
    border-width: 1px 0 0;
    border-style: solid;
    padding: 25px 0 0;
}
.k-fieldset > legend {
    margin-left: 0;
    padding: 0 var(--kendo-spacing-2, 0.5rem) 0 0;
    text-transform: uppercase;
}

kendo-label > .k-label {
    margin: 0;
}

.k-label-optional {
    margin-left: var(--kendo-spacing-1\.5, 0.375rem);
    font-size: var(--kendo-font-size-sm, inherit);
    font-style: italic;
    align-self: center;
}

.k-label-empty::before {
    content: '';
    display: inline-block;
}

.k-form {
    border: 0;
}
.k-form .k-form-field > .k-label,
.k-form .k-form-field > kendo-label,
.k-form .k-form-label {
    margin-bottom: var(--kendo-spacing-0, 0px);
    display: flex;
    flex-flow: row nowrap;
}
.k-form .k-label + .k-radio {
    margin-left: 0;
}
.k-form .k-form-fieldset {
    margin: 2em 0 0;
    padding: var(--kendo-spacing-0, 0px);
    border: 0;
}
.k-form .k-form-fieldset > *:not(.k-hidden):first-child,
.k-form .k-form-fieldset > *.k-hidden + :not(.k-hidden) {
    margin-top: 0;
}
.k-form .k-form-legend {
    margin: 0 0 1em;
    padding: var(--kendo-spacing-0, 0px);
    border-width: 0 0 2px;
    border-style: solid;
    width: 100%;
    font-size: var(--kendo-font-size, inherit);
    text-transform: uppercase;
}
.k-form .k-form-group {
    padding: 0;
}
.k-form > *:not(.k-hidden):first-child,
.k-form > *.k-hidden + :not(.k-hidden) {
    margin-top: 0;
}
.k-form .k-form-field,
.k-form .k-form-field-wrap {
    display: block;
}
.k-form .k-form-field-disabled .k-label,
.k-form .k-form-field-disabled .k-form-label {
    outline: none;
    cursor: default;
    opacity: 0.6;
    filter: grayscale(0.1);
    pointer-events: none;
    box-shadow: none;
}
.k-form .k-alert-error {
    font-size: var(--kendo-font-size-sm, inherit);
    margin-top: calc(var(--kendo-spacing-1, 0.25rem) * 2);
}
.k-form .k-field-info {
    display: inline-block;
    font-size: var(--kendo-font-size-xs, inherit);
    line-height: 1;
    margin-block: 0;
    margin-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-form .k-multiselect,
.k-form .k-floating-label-container,
.k-form .k-daterangepicker .k-dateinput,
.k-form .k-signature {
    display: inline-flex;
    width: 100%;
}

.k-form-buttons {
    padding: 0;
    overflow: visible;
}

.k-form-hint,
.k-form-error {
    margin-top: var(--kendo-spacing-1, 0.25rem);
    display: flex;
    font-size: var(--kendo-font-size-sm, inherit);
    font-style: italic;
}

.k-text-start,
.k-buttons-start {
    justify-content: flex-start;
}

.k-text-end,
.k-buttons-end {
    justify-content: flex-end;
}

.k-form-separator {
    margin: var(--kendo-spacing-3\.5, 0.875rem) 0 0;
    border-width: 1px 0 0;
    border-style: solid;
    display: block;
    flex: 0 0 auto;
}

.k-form-horizontal .k-form-field {
    display: flex;
}
.k-form-horizontal .k-form-field > .k-label,
.k-form-horizontal .k-form-field > kendo-label,
.k-form-horizontal .k-form-field > .k-form-label {
    margin-right: var(--kendo-spacing-2\.5, 0.625rem);
    padding-top: calc(var(--kendo-spacing-2\.5, 0.625rem) / 2);
    width: 25%;
    text-align: end;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
}
.k-form-horizontal .k-form-field .k-label-optional {
    margin: 0;
    align-self: inherit;
}
.k-form-horizontal .k-form-field-wrap {
    max-width: calc(100% - 25% - var(--kendo-spacing-2\.5, 0.625rem));
    flex: 1 1 auto;
}

.k-form [dir='rtl'] .k-label-optional,
.k-rtl .k-form .k-label-optional {
    margin-left: 0;
    margin-right: var(--kendo-spacing-1\.5, 0.375rem);
}

.k-form-horizontal [dir='rtl'].k-form-field > .k-label,
.k-form-horizontal [dir='rtl'].k-form-field > kendo-label,
.k-form-horizontal [dir='rtl'].k-form-field > .k-form-label,
.k-form-horizontal [dir='rtl'] .k-form-field > .k-label,
.k-form-horizontal [dir='rtl'] .k-form-field > kendo-label,
.k-form-horizontal [dir='rtl'] .k-form-field > .k-form-label,
.k-rtl .k-form-horizontal.k-form-field > .k-label,
.k-rtl .k-form-horizontal.k-form-field > kendo-label,
.k-rtl .k-form-horizontal.k-form-field > .k-form-label,
.k-rtl .k-form-horizontal .k-form-field > .k-label,
.k-rtl .k-form-horizontal .k-form-field > kendo-label,
.k-rtl .k-form-horizontal .k-form-field > .k-form-label {
    margin-right: 0;
    margin-left: var(--kendo-spacing-2\.5, 0.625rem);
}

.k-form-sm .k-form-field,
.k-form-sm .k-form-buttons {
    margin-top: var(--kendo-spacing-2\.5, 0.625rem);
}

.k-form-md .k-form-field,
.k-form-md .k-form-buttons {
    margin-top: var(--kendo-spacing-3\.5, 0.875rem);
}

.k-form-lg .k-form-field,
.k-form-lg .k-form-buttons {
    margin-top: var(--kendo-spacing-4\.5, 1.125rem);
}

.k-form-inline {
    padding: calc(var(--kendo-spacing-2, 0.5rem) * 2);
}
.k-form-inline fieldset {
    border-width: 1px 0 0;
    border-style: solid;
    margin: calc(calc(var(--kendo-spacing-2, 0.5rem) * 2) * 2) 0;
    padding: 0;
}
.k-form-inline fieldset:first-child:first-of-type {
    margin-top: 0;
}
.k-form-inline fieldset:last-child:last-of-type {
    margin-bottom: 0;
}
.k-form-inline legend {
    font-size: var(--kendo-font-size-sm, inherit);
    text-align: start;
    font-weight: 600;
    line-height: 1;
    margin-bottom: calc(var(--kendo-spacing-1\.5, 0.375rem) * 2);
    text-transform: uppercase;
    padding: 0 var(--kendo-spacing-2, 0.5rem) 0 0;
    width: auto;
}
.k-form-inline .k-form-field {
    display: flex;
    align-items: flex-start;
    text-align: start;
    margin-bottom: calc(var(--kendo-spacing-1\.5, 0.375rem) * 2);
}
.k-form-inline .k-form-field > span:not(.k-widget) {
    width: 25%;
    text-align: end;
    line-height: var(--kendo-line-height, normal);
    padding: calc(var(--kendo-spacing-1, 0.25rem) + 1px) 0;
    padding-right: var(--kendo-spacing-3, 0.75rem);
    align-self: center;
}
.k-form-inline .k-form-field > input {
    align-self: center;
}
.k-form-inline .k-form-field > input:not(.k-checkbox):not(.k-radio) {
    flex: 1 1 auto;
}
.k-form-inline .k-form-field .k-alert-error {
    font-size: var(--kendo-font-size-sm, inherit);
    margin-top: calc(var(--kendo-spacing-1, 0.25rem) * 2);
}
.k-form-inline .k-form-field .k-field-info {
    display: block;
    font-size: var(--kendo-font-size-xs, inherit);
    line-height: 1;
    margin: 0;
}
.k-form-inline .k-form-field:last-child {
    margin-bottom: 0;
}

.k-edit-form-container {
    width: 400px;
    min-width: 400px;
    border-color: inherit;
    position: relative;
}

.k-popup-edit-form > .k-actions,
.k-popup-edit-form > .k-edit-buttons,
.k-popup-edit-form > .k-action-buttons,
.k-popup-edit-form > .k-columnmenu-actions,
.k-popup-edit-form > .k-form-buttons,
.k-edit-form-container .k-actions,
.k-edit-form-container .k-edit-buttons,
.k-edit-form-container .k-action-buttons,
.k-edit-form-container .k-columnmenu-actions,
.k-edit-form-container .k-form-buttons {
    margin: var(--kendo-spacing-4, 1rem) -var(--kendo-spacing-4, 1rem) -var(--kendo-spacing-4, 1rem);
}

.k-edit-label {
    margin: 0 0 var(--kendo-spacing-4, 1rem) 0;
    padding: calc(var(--kendo-spacing-1, 0.25rem) + 1px) 0;
    width: 30%;
    line-height: var(--kendo-line-height, normal);
    text-align: end;
    float: left;
    clear: both;
}

.k-edit-field {
    margin: 0 0 var(--kendo-spacing-4, 1rem) 0;
    width: 65%;
    float: right;
    clear: right;
    position: relative;
}
.k-edit-field.k-no-editor {
    padding: calc(var(--kendo-spacing-1, 0.25rem) + 1px) 0;
}

.k-edit-field > .k-widget {
    width: 100%;
    box-sizing: border-box;
}
.k-edit-field input[type='radio']:not(.k-radio),
.k-edit-field input[type='checkbox']:not(.k-checkbox) {
    margin-right: 0.4ex;
}
.k-edit-field .k-radio-label,
.k-edit-field .k-checkbox-label {
    margin-right: var(--kendo-spacing-4, 1rem);
}
.k-edit-field > .k-reset > li + li {
    margin-top: var(--kendo-spacing-2, 0.5rem);
}
.k-edit-field .k-reset .k-widget {
    margin: 0 0.4ex 0 1ex;
}

.k-form,
.k-form-inline {
    color: var(--kendo-color-on-app-surface, #3d3d3d);
}
.k-form fieldset legend,
.k-form-inline fieldset legend {
    color: var(--kendo-color-on-app-surface, #3d3d3d);
}
.k-form .k-form-legend,
.k-form-inline .k-form-legend {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}
.k-form .k-field-info,
.k-form-inline .k-field-info {
    color: var(--kendo-color-subtle, #666666);
}
.k-form .k-alert-error,
.k-form-inline .k-alert-error {
    color: var(--kendo-color-error, #f31700);
}

.k-form-error,
.k-text-error,
.k-form-field-error .k-label {
    color: var(--kendo-color-error, #f31700);
}

.k-form-separator {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}

.k-d-none {
    display: none;
}

.\!k-d-none {
    display: none !important;
}

.k-d-contents {
    display: contents;
}

.\!k-d-contents {
    display: contents !important;
}

.k-d-block {
    display: block;
}

.\!k-d-block {
    display: block !important;
}

.k-d-inline {
    display: inline;
}

.\!k-d-inline {
    display: inline !important;
}

.k-d-inline-block {
    display: inline-block;
}

.\!k-d-inline-block {
    display: inline-block !important;
}

.k-d-flex,
.k-d-flex-col,
.k-d-flex-row {
    display: flex;
}

.\!k-d-flex,
.\!k-d-flex-col,
.\!k-d-flex-row {
    display: flex !important;
}

.k-d-inline-flex {
    display: inline-flex;
}

.\!k-d-inline-flex {
    display: inline-flex !important;
}

.k-d-grid {
    display: grid;
}

.\!k-d-grid {
    display: grid !important;
}

.k-d-inline-grid {
    display: inline-grid;
}

.\!k-d-inline-grid {
    display: inline-grid !important;
}

.k-d-table {
    display: table;
}

.\!k-d-table {
    display: table !important;
}

.k-d-inline-table {
    display: inline-table;
}

.\!k-d-inline-table {
    display: inline-table !important;
}

.k-d-table-row {
    display: table-row;
}

.\!k-d-table-row {
    display: table-row !important;
}

.k-d-table-cell {
    display: table-cell;
}

.\!k-d-table-cell {
    display: table-cell !important;
}

.k-d-list-item {
    display: list-item;
}

.\!k-d-list-item {
    display: list-item !important;
}

.k-display-none {
    display: none;
}

.\!k-display-none {
    display: none !important;
}

.k-display-contents {
    display: contents;
}

.\!k-display-contents {
    display: contents !important;
}

.k-display-block {
    display: block;
}

.\!k-display-block {
    display: block !important;
}

.k-display-inline {
    display: inline;
}

.\!k-display-inline {
    display: inline !important;
}

.k-display-inline-block {
    display: inline-block;
}

.\!k-display-inline-block {
    display: inline-block !important;
}

.k-display-flex {
    display: flex;
}

.\!k-display-flex {
    display: flex !important;
}

.k-display-inline-flex {
    display: inline-flex;
}

.\!k-display-inline-flex {
    display: inline-flex !important;
}

.k-display-grid {
    display: grid;
}

.\!k-display-grid {
    display: grid !important;
}

.k-display-inline-grid {
    display: inline-grid;
}

.\!k-display-inline-grid {
    display: inline-grid !important;
}

.k-display-table {
    display: table;
}

.\!k-display-table {
    display: table !important;
}

.k-display-inline-table {
    display: inline-table;
}

.\!k-display-inline-table {
    display: inline-table !important;
}

.k-display-table-row {
    display: table-row;
}

.\!k-display-table-row {
    display: table-row !important;
}

.k-display-table-cell {
    display: table-cell;
}

.\!k-display-table-cell {
    display: table-cell !important;
}

.k-display-list-item {
    display: list-item;
}

.\!k-display-list-item {
    display: list-item !important;
}

.k-gap-0 {
    gap: var(--kendo-spacing-0, 0px);
}

.\!k-gap-0 {
    gap: var(--kendo-spacing-0, 0px) !important;
}

.k-gap-1px {
    gap: var(--kendo-spacing-1px, 1px);
}

.\!k-gap-1px {
    gap: var(--kendo-spacing-1px, 1px) !important;
}

.k-gap-0\.5 {
    gap: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-gap-0\.5 {
    gap: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-gap-1 {
    gap: var(--kendo-spacing-1, 0.25rem);
}

.\!k-gap-1 {
    gap: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-gap-1\.5 {
    gap: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-gap-1\.5 {
    gap: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-gap-2 {
    gap: var(--kendo-spacing-2, 0.5rem);
}

.\!k-gap-2 {
    gap: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-gap-2\.5 {
    gap: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-gap-2\.5 {
    gap: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-gap-3 {
    gap: var(--kendo-spacing-3, 0.75rem);
}

.\!k-gap-3 {
    gap: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-gap-3\.5 {
    gap: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-gap-3\.5 {
    gap: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-gap-4 {
    gap: var(--kendo-spacing-4, 1rem);
}

.\!k-gap-4 {
    gap: var(--kendo-spacing-4, 1rem) !important;
}

.k-gap-4\.5 {
    gap: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-gap-4\.5 {
    gap: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-gap-5 {
    gap: var(--kendo-spacing-5, 1.25rem);
}

.\!k-gap-5 {
    gap: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-gap-5\.5 {
    gap: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-gap-5\.5 {
    gap: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-gap-6 {
    gap: var(--kendo-spacing-6, 1.5rem);
}

.\!k-gap-6 {
    gap: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-gap-6\.5 {
    gap: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-gap-6\.5 {
    gap: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-gap-7 {
    gap: var(--kendo-spacing-7, 1.75rem);
}

.\!k-gap-7 {
    gap: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-gap-7\.5 {
    gap: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-gap-7\.5 {
    gap: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-gap-8 {
    gap: var(--kendo-spacing-8, 2rem);
}

.\!k-gap-8 {
    gap: var(--kendo-spacing-8, 2rem) !important;
}

.k-gap-9 {
    gap: var(--kendo-spacing-9, 2.25rem);
}

.\!k-gap-9 {
    gap: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-gap-10 {
    gap: var(--kendo-spacing-10, 2.5rem);
}

.\!k-gap-10 {
    gap: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-gap-11 {
    gap: var(--kendo-spacing-11, 2.75rem);
}

.\!k-gap-11 {
    gap: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-gap-12 {
    gap: var(--kendo-spacing-12, 3rem);
}

.\!k-gap-12 {
    gap: var(--kendo-spacing-12, 3rem) !important;
}

.k-gap-13 {
    gap: var(--kendo-spacing-13, 3.25rem);
}

.\!k-gap-13 {
    gap: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-gap-14 {
    gap: var(--kendo-spacing-14, 3.5rem);
}

.\!k-gap-14 {
    gap: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-gap-15 {
    gap: var(--kendo-spacing-15, 3.75rem);
}

.\!k-gap-15 {
    gap: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-gap-16 {
    gap: var(--kendo-spacing-16, 4rem);
}

.\!k-gap-16 {
    gap: var(--kendo-spacing-16, 4rem) !important;
}

.k-gap-17 {
    gap: var(--kendo-spacing-17, 4.25rem);
}

.\!k-gap-17 {
    gap: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-gap-18 {
    gap: var(--kendo-spacing-18, 4.5rem);
}

.\!k-gap-18 {
    gap: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-gap-19 {
    gap: var(--kendo-spacing-19, 4.75rem);
}

.\!k-gap-19 {
    gap: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-gap-20 {
    gap: var(--kendo-spacing-20, 5rem);
}

.\!k-gap-20 {
    gap: var(--kendo-spacing-20, 5rem) !important;
}

.k-gap-21 {
    gap: var(--kendo-spacing-21, 5.25rem);
}

.\!k-gap-21 {
    gap: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-gap-22 {
    gap: var(--kendo-spacing-22, 5.5rem);
}

.\!k-gap-22 {
    gap: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-gap-23 {
    gap: var(--kendo-spacing-23, 5.75rem);
}

.\!k-gap-23 {
    gap: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-gap-24 {
    gap: var(--kendo-spacing-24, 6rem);
}

.\!k-gap-24 {
    gap: var(--kendo-spacing-24, 6rem) !important;
}

.k-gap-25 {
    gap: var(--kendo-spacing-25, 7rem);
}

.\!k-gap-25 {
    gap: var(--kendo-spacing-25, 7rem) !important;
}

.k-gap-26 {
    gap: var(--kendo-spacing-26, 8rem);
}

.\!k-gap-26 {
    gap: var(--kendo-spacing-26, 8rem) !important;
}

.k-gap-27 {
    gap: var(--kendo-spacing-27, 9rem);
}

.\!k-gap-27 {
    gap: var(--kendo-spacing-27, 9rem) !important;
}

.k-gap-28 {
    gap: var(--kendo-spacing-28, 10rem);
}

.\!k-gap-28 {
    gap: var(--kendo-spacing-28, 10rem) !important;
}

.k-gap-29 {
    gap: var(--kendo-spacing-29, 11rem);
}

.\!k-gap-29 {
    gap: var(--kendo-spacing-29, 11rem) !important;
}

.k-gap-30 {
    gap: var(--kendo-spacing-30, 12rem);
}

.\!k-gap-30 {
    gap: var(--kendo-spacing-30, 12rem) !important;
}

.k-gap-xs {
    gap: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-gap-xs {
    gap: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-gap-sm {
    gap: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-gap-sm {
    gap: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-gap-md {
    gap: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-gap-md {
    gap: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-gap-lg {
    gap: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-gap-lg {
    gap: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-gap-xl {
    gap: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-gap-xl {
    gap: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-gap-thin {
    gap: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-gap-thin {
    gap: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-gap-hair {
    gap: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-gap-hair {
    gap: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-gap-x-0 {
    column-gap: var(--kendo-spacing-0, 0px);
}

.\!k-gap-x-0 {
    column-gap: var(--kendo-spacing-0, 0px) !important;
}

.k-gap-x-1px {
    column-gap: var(--kendo-spacing-1px, 1px);
}

.\!k-gap-x-1px {
    column-gap: var(--kendo-spacing-1px, 1px) !important;
}

.k-gap-x-0\.5 {
    column-gap: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-gap-x-0\.5 {
    column-gap: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-gap-x-1 {
    column-gap: var(--kendo-spacing-1, 0.25rem);
}

.\!k-gap-x-1 {
    column-gap: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-gap-x-1\.5 {
    column-gap: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-gap-x-1\.5 {
    column-gap: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-gap-x-2 {
    column-gap: var(--kendo-spacing-2, 0.5rem);
}

.\!k-gap-x-2 {
    column-gap: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-gap-x-2\.5 {
    column-gap: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-gap-x-2\.5 {
    column-gap: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-gap-x-3 {
    column-gap: var(--kendo-spacing-3, 0.75rem);
}

.\!k-gap-x-3 {
    column-gap: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-gap-x-3\.5 {
    column-gap: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-gap-x-3\.5 {
    column-gap: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-gap-x-4 {
    column-gap: var(--kendo-spacing-4, 1rem);
}

.\!k-gap-x-4 {
    column-gap: var(--kendo-spacing-4, 1rem) !important;
}

.k-gap-x-4\.5 {
    column-gap: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-gap-x-4\.5 {
    column-gap: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-gap-x-5 {
    column-gap: var(--kendo-spacing-5, 1.25rem);
}

.\!k-gap-x-5 {
    column-gap: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-gap-x-5\.5 {
    column-gap: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-gap-x-5\.5 {
    column-gap: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-gap-x-6 {
    column-gap: var(--kendo-spacing-6, 1.5rem);
}

.\!k-gap-x-6 {
    column-gap: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-gap-x-6\.5 {
    column-gap: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-gap-x-6\.5 {
    column-gap: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-gap-x-7 {
    column-gap: var(--kendo-spacing-7, 1.75rem);
}

.\!k-gap-x-7 {
    column-gap: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-gap-x-7\.5 {
    column-gap: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-gap-x-7\.5 {
    column-gap: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-gap-x-8 {
    column-gap: var(--kendo-spacing-8, 2rem);
}

.\!k-gap-x-8 {
    column-gap: var(--kendo-spacing-8, 2rem) !important;
}

.k-gap-x-9 {
    column-gap: var(--kendo-spacing-9, 2.25rem);
}

.\!k-gap-x-9 {
    column-gap: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-gap-x-10 {
    column-gap: var(--kendo-spacing-10, 2.5rem);
}

.\!k-gap-x-10 {
    column-gap: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-gap-x-11 {
    column-gap: var(--kendo-spacing-11, 2.75rem);
}

.\!k-gap-x-11 {
    column-gap: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-gap-x-12 {
    column-gap: var(--kendo-spacing-12, 3rem);
}

.\!k-gap-x-12 {
    column-gap: var(--kendo-spacing-12, 3rem) !important;
}

.k-gap-x-13 {
    column-gap: var(--kendo-spacing-13, 3.25rem);
}

.\!k-gap-x-13 {
    column-gap: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-gap-x-14 {
    column-gap: var(--kendo-spacing-14, 3.5rem);
}

.\!k-gap-x-14 {
    column-gap: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-gap-x-15 {
    column-gap: var(--kendo-spacing-15, 3.75rem);
}

.\!k-gap-x-15 {
    column-gap: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-gap-x-16 {
    column-gap: var(--kendo-spacing-16, 4rem);
}

.\!k-gap-x-16 {
    column-gap: var(--kendo-spacing-16, 4rem) !important;
}

.k-gap-x-17 {
    column-gap: var(--kendo-spacing-17, 4.25rem);
}

.\!k-gap-x-17 {
    column-gap: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-gap-x-18 {
    column-gap: var(--kendo-spacing-18, 4.5rem);
}

.\!k-gap-x-18 {
    column-gap: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-gap-x-19 {
    column-gap: var(--kendo-spacing-19, 4.75rem);
}

.\!k-gap-x-19 {
    column-gap: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-gap-x-20 {
    column-gap: var(--kendo-spacing-20, 5rem);
}

.\!k-gap-x-20 {
    column-gap: var(--kendo-spacing-20, 5rem) !important;
}

.k-gap-x-21 {
    column-gap: var(--kendo-spacing-21, 5.25rem);
}

.\!k-gap-x-21 {
    column-gap: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-gap-x-22 {
    column-gap: var(--kendo-spacing-22, 5.5rem);
}

.\!k-gap-x-22 {
    column-gap: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-gap-x-23 {
    column-gap: var(--kendo-spacing-23, 5.75rem);
}

.\!k-gap-x-23 {
    column-gap: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-gap-x-24 {
    column-gap: var(--kendo-spacing-24, 6rem);
}

.\!k-gap-x-24 {
    column-gap: var(--kendo-spacing-24, 6rem) !important;
}

.k-gap-x-25 {
    column-gap: var(--kendo-spacing-25, 7rem);
}

.\!k-gap-x-25 {
    column-gap: var(--kendo-spacing-25, 7rem) !important;
}

.k-gap-x-26 {
    column-gap: var(--kendo-spacing-26, 8rem);
}

.\!k-gap-x-26 {
    column-gap: var(--kendo-spacing-26, 8rem) !important;
}

.k-gap-x-27 {
    column-gap: var(--kendo-spacing-27, 9rem);
}

.\!k-gap-x-27 {
    column-gap: var(--kendo-spacing-27, 9rem) !important;
}

.k-gap-x-28 {
    column-gap: var(--kendo-spacing-28, 10rem);
}

.\!k-gap-x-28 {
    column-gap: var(--kendo-spacing-28, 10rem) !important;
}

.k-gap-x-29 {
    column-gap: var(--kendo-spacing-29, 11rem);
}

.\!k-gap-x-29 {
    column-gap: var(--kendo-spacing-29, 11rem) !important;
}

.k-gap-x-30 {
    column-gap: var(--kendo-spacing-30, 12rem);
}

.\!k-gap-x-30 {
    column-gap: var(--kendo-spacing-30, 12rem) !important;
}

.k-gap-x-xs {
    column-gap: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-gap-x-xs {
    column-gap: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-gap-x-sm {
    column-gap: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-gap-x-sm {
    column-gap: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-gap-x-md {
    column-gap: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-gap-x-md {
    column-gap: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-gap-x-lg {
    column-gap: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-gap-x-lg {
    column-gap: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-gap-x-xl {
    column-gap: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-gap-x-xl {
    column-gap: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-gap-x-thin {
    column-gap: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-gap-x-thin {
    column-gap: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-gap-x-hair {
    column-gap: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-gap-x-hair {
    column-gap: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-gap-y-0 {
    row-gap: var(--kendo-spacing-0, 0px);
}

.\!k-gap-y-0 {
    row-gap: var(--kendo-spacing-0, 0px) !important;
}

.k-gap-y-1px {
    row-gap: var(--kendo-spacing-1px, 1px);
}

.\!k-gap-y-1px {
    row-gap: var(--kendo-spacing-1px, 1px) !important;
}

.k-gap-y-0\.5 {
    row-gap: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-gap-y-0\.5 {
    row-gap: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-gap-y-1 {
    row-gap: var(--kendo-spacing-1, 0.25rem);
}

.\!k-gap-y-1 {
    row-gap: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-gap-y-1\.5 {
    row-gap: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-gap-y-1\.5 {
    row-gap: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-gap-y-2 {
    row-gap: var(--kendo-spacing-2, 0.5rem);
}

.\!k-gap-y-2 {
    row-gap: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-gap-y-2\.5 {
    row-gap: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-gap-y-2\.5 {
    row-gap: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-gap-y-3 {
    row-gap: var(--kendo-spacing-3, 0.75rem);
}

.\!k-gap-y-3 {
    row-gap: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-gap-y-3\.5 {
    row-gap: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-gap-y-3\.5 {
    row-gap: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-gap-y-4 {
    row-gap: var(--kendo-spacing-4, 1rem);
}

.\!k-gap-y-4 {
    row-gap: var(--kendo-spacing-4, 1rem) !important;
}

.k-gap-y-4\.5 {
    row-gap: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-gap-y-4\.5 {
    row-gap: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-gap-y-5 {
    row-gap: var(--kendo-spacing-5, 1.25rem);
}

.\!k-gap-y-5 {
    row-gap: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-gap-y-5\.5 {
    row-gap: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-gap-y-5\.5 {
    row-gap: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-gap-y-6 {
    row-gap: var(--kendo-spacing-6, 1.5rem);
}

.\!k-gap-y-6 {
    row-gap: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-gap-y-6\.5 {
    row-gap: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-gap-y-6\.5 {
    row-gap: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-gap-y-7 {
    row-gap: var(--kendo-spacing-7, 1.75rem);
}

.\!k-gap-y-7 {
    row-gap: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-gap-y-7\.5 {
    row-gap: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-gap-y-7\.5 {
    row-gap: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-gap-y-8 {
    row-gap: var(--kendo-spacing-8, 2rem);
}

.\!k-gap-y-8 {
    row-gap: var(--kendo-spacing-8, 2rem) !important;
}

.k-gap-y-9 {
    row-gap: var(--kendo-spacing-9, 2.25rem);
}

.\!k-gap-y-9 {
    row-gap: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-gap-y-10 {
    row-gap: var(--kendo-spacing-10, 2.5rem);
}

.\!k-gap-y-10 {
    row-gap: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-gap-y-11 {
    row-gap: var(--kendo-spacing-11, 2.75rem);
}

.\!k-gap-y-11 {
    row-gap: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-gap-y-12 {
    row-gap: var(--kendo-spacing-12, 3rem);
}

.\!k-gap-y-12 {
    row-gap: var(--kendo-spacing-12, 3rem) !important;
}

.k-gap-y-13 {
    row-gap: var(--kendo-spacing-13, 3.25rem);
}

.\!k-gap-y-13 {
    row-gap: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-gap-y-14 {
    row-gap: var(--kendo-spacing-14, 3.5rem);
}

.\!k-gap-y-14 {
    row-gap: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-gap-y-15 {
    row-gap: var(--kendo-spacing-15, 3.75rem);
}

.\!k-gap-y-15 {
    row-gap: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-gap-y-16 {
    row-gap: var(--kendo-spacing-16, 4rem);
}

.\!k-gap-y-16 {
    row-gap: var(--kendo-spacing-16, 4rem) !important;
}

.k-gap-y-17 {
    row-gap: var(--kendo-spacing-17, 4.25rem);
}

.\!k-gap-y-17 {
    row-gap: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-gap-y-18 {
    row-gap: var(--kendo-spacing-18, 4.5rem);
}

.\!k-gap-y-18 {
    row-gap: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-gap-y-19 {
    row-gap: var(--kendo-spacing-19, 4.75rem);
}

.\!k-gap-y-19 {
    row-gap: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-gap-y-20 {
    row-gap: var(--kendo-spacing-20, 5rem);
}

.\!k-gap-y-20 {
    row-gap: var(--kendo-spacing-20, 5rem) !important;
}

.k-gap-y-21 {
    row-gap: var(--kendo-spacing-21, 5.25rem);
}

.\!k-gap-y-21 {
    row-gap: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-gap-y-22 {
    row-gap: var(--kendo-spacing-22, 5.5rem);
}

.\!k-gap-y-22 {
    row-gap: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-gap-y-23 {
    row-gap: var(--kendo-spacing-23, 5.75rem);
}

.\!k-gap-y-23 {
    row-gap: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-gap-y-24 {
    row-gap: var(--kendo-spacing-24, 6rem);
}

.\!k-gap-y-24 {
    row-gap: var(--kendo-spacing-24, 6rem) !important;
}

.k-gap-y-25 {
    row-gap: var(--kendo-spacing-25, 7rem);
}

.\!k-gap-y-25 {
    row-gap: var(--kendo-spacing-25, 7rem) !important;
}

.k-gap-y-26 {
    row-gap: var(--kendo-spacing-26, 8rem);
}

.\!k-gap-y-26 {
    row-gap: var(--kendo-spacing-26, 8rem) !important;
}

.k-gap-y-27 {
    row-gap: var(--kendo-spacing-27, 9rem);
}

.\!k-gap-y-27 {
    row-gap: var(--kendo-spacing-27, 9rem) !important;
}

.k-gap-y-28 {
    row-gap: var(--kendo-spacing-28, 10rem);
}

.\!k-gap-y-28 {
    row-gap: var(--kendo-spacing-28, 10rem) !important;
}

.k-gap-y-29 {
    row-gap: var(--kendo-spacing-29, 11rem);
}

.\!k-gap-y-29 {
    row-gap: var(--kendo-spacing-29, 11rem) !important;
}

.k-gap-y-30 {
    row-gap: var(--kendo-spacing-30, 12rem);
}

.\!k-gap-y-30 {
    row-gap: var(--kendo-spacing-30, 12rem) !important;
}

.k-gap-y-xs {
    row-gap: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-gap-y-xs {
    row-gap: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-gap-y-sm {
    row-gap: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-gap-y-sm {
    row-gap: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-gap-y-md {
    row-gap: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-gap-y-md {
    row-gap: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-gap-y-lg {
    row-gap: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-gap-y-lg {
    row-gap: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-gap-y-xl {
    row-gap: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-gap-y-xl {
    row-gap: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-gap-y-thin {
    row-gap: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-gap-y-thin {
    row-gap: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-gap-y-hair {
    row-gap: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-gap-y-hair {
    row-gap: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

.\!k-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

.k-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.\!k-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}

.k-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.\!k-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
}

.k-grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
}

.\!k-grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
}

.k-grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
}

.\!k-grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
}

.k-grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
}

.\!k-grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
}

.k-grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
}

.\!k-grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
}

.k-grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
}

.\!k-grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
}

.k-grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
}

.\!k-grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
}

.k-grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
}

.\!k-grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
}

.k-grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
}

.\!k-grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
}

.k-grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
}

.\!k-grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
}

.k-grid-cols-none {
    grid-template-columns: none;
}

.\!k-grid-cols-none {
    grid-template-columns: none !important;
}

.k-col-span-1 {
    grid-column: span 1 / span 1;
}

.\!k-col-span-1 {
    grid-column: span 1 / span 1 !important;
}

.k-col-span-2 {
    grid-column: span 2 / span 2;
}

.\!k-col-span-2 {
    grid-column: span 2 / span 2 !important;
}

.k-col-span-3 {
    grid-column: span 3 / span 3;
}

.\!k-col-span-3 {
    grid-column: span 3 / span 3 !important;
}

.k-col-span-4 {
    grid-column: span 4 / span 4;
}

.\!k-col-span-4 {
    grid-column: span 4 / span 4 !important;
}

.k-col-span-5 {
    grid-column: span 5 / span 5;
}

.\!k-col-span-5 {
    grid-column: span 5 / span 5 !important;
}

.k-col-span-6 {
    grid-column: span 6 / span 6;
}

.\!k-col-span-6 {
    grid-column: span 6 / span 6 !important;
}

.k-col-span-7 {
    grid-column: span 7 / span 7;
}

.\!k-col-span-7 {
    grid-column: span 7 / span 7 !important;
}

.k-col-span-8 {
    grid-column: span 8 / span 8;
}

.\!k-col-span-8 {
    grid-column: span 8 / span 8 !important;
}

.k-col-span-9 {
    grid-column: span 9 / span 9;
}

.\!k-col-span-9 {
    grid-column: span 9 / span 9 !important;
}

.k-col-span-10 {
    grid-column: span 10 / span 10;
}

.\!k-col-span-10 {
    grid-column: span 10 / span 10 !important;
}

.k-col-span-11 {
    grid-column: span 11 / span 11;
}

.\!k-col-span-11 {
    grid-column: span 11 / span 11 !important;
}

.k-col-span-12 {
    grid-column: span 12 / span 12;
}

.\!k-col-span-12 {
    grid-column: span 12 / span 12 !important;
}

.k-col-span-full {
    grid-column: 1 / -1;
}

.\!k-col-span-full {
    grid-column: 1 / -1 !important;
}

.k-col-span-auto {
    grid-column: auto;
}

.\!k-col-span-auto {
    grid-column: auto !important;
}

.k-col-start-1 {
    grid-column-start: 1;
}

.\!k-col-start-1 {
    grid-column-start: 1 !important;
}

.k-col-start-2 {
    grid-column-start: 2;
}

.\!k-col-start-2 {
    grid-column-start: 2 !important;
}

.k-col-start-3 {
    grid-column-start: 3;
}

.\!k-col-start-3 {
    grid-column-start: 3 !important;
}

.k-col-start-4 {
    grid-column-start: 4;
}

.\!k-col-start-4 {
    grid-column-start: 4 !important;
}

.k-col-start-5 {
    grid-column-start: 5;
}

.\!k-col-start-5 {
    grid-column-start: 5 !important;
}

.k-col-start-6 {
    grid-column-start: 6;
}

.\!k-col-start-6 {
    grid-column-start: 6 !important;
}

.k-col-start-7 {
    grid-column-start: 7;
}

.\!k-col-start-7 {
    grid-column-start: 7 !important;
}

.k-col-start-8 {
    grid-column-start: 8;
}

.\!k-col-start-8 {
    grid-column-start: 8 !important;
}

.k-col-start-9 {
    grid-column-start: 9;
}

.\!k-col-start-9 {
    grid-column-start: 9 !important;
}

.k-col-start-10 {
    grid-column-start: 10;
}

.\!k-col-start-10 {
    grid-column-start: 10 !important;
}

.k-col-start-11 {
    grid-column-start: 11;
}

.\!k-col-start-11 {
    grid-column-start: 11 !important;
}

.k-col-start-12 {
    grid-column-start: 12;
}

.\!k-col-start-12 {
    grid-column-start: 12 !important;
}

.k-col-start-13 {
    grid-column-start: 13;
}

.\!k-col-start-13 {
    grid-column-start: 13 !important;
}

.k-col-start-auto {
    grid-column-start: auto;
}

.\!k-col-start-auto {
    grid-column-start: auto !important;
}

.k-col-end-1 {
    grid-column-end: 1;
}

.\!k-col-end-1 {
    grid-column-end: 1 !important;
}

.k-col-end-2 {
    grid-column-end: 2;
}

.\!k-col-end-2 {
    grid-column-end: 2 !important;
}

.k-col-end-3 {
    grid-column-end: 3;
}

.\!k-col-end-3 {
    grid-column-end: 3 !important;
}

.k-col-end-4 {
    grid-column-end: 4;
}

.\!k-col-end-4 {
    grid-column-end: 4 !important;
}

.k-col-end-5 {
    grid-column-end: 5;
}

.\!k-col-end-5 {
    grid-column-end: 5 !important;
}

.k-col-end-6 {
    grid-column-end: 6;
}

.\!k-col-end-6 {
    grid-column-end: 6 !important;
}

.k-col-end-7 {
    grid-column-end: 7;
}

.\!k-col-end-7 {
    grid-column-end: 7 !important;
}

.k-col-end-8 {
    grid-column-end: 8;
}

.\!k-col-end-8 {
    grid-column-end: 8 !important;
}

.k-col-end-9 {
    grid-column-end: 9;
}

.\!k-col-end-9 {
    grid-column-end: 9 !important;
}

.k-col-end-10 {
    grid-column-end: 10;
}

.\!k-col-end-10 {
    grid-column-end: 10 !important;
}

.k-col-end-11 {
    grid-column-end: 11;
}

.\!k-col-end-11 {
    grid-column-end: 11 !important;
}

.k-col-end-12 {
    grid-column-end: 12;
}

.\!k-col-end-12 {
    grid-column-end: 12 !important;
}

.k-col-end-13 {
    grid-column-end: 13;
}

.\!k-col-end-13 {
    grid-column-end: 13 !important;
}

.k-col-end-auto {
    grid-column-end: auto;
}

.\!k-col-end-auto {
    grid-column-end: auto !important;
}

.k-colspan-1 {
    grid-column: span 1 / span 1;
}

.\!k-colspan-1 {
    grid-column: span 1 / span 1 !important;
}

.k-colspan-2 {
    grid-column: span 2 / span 2;
}

.\!k-colspan-2 {
    grid-column: span 2 / span 2 !important;
}

.k-colspan-3 {
    grid-column: span 3 / span 3;
}

.\!k-colspan-3 {
    grid-column: span 3 / span 3 !important;
}

.k-colspan-4 {
    grid-column: span 4 / span 4;
}

.\!k-colspan-4 {
    grid-column: span 4 / span 4 !important;
}

.k-colspan-5 {
    grid-column: span 5 / span 5;
}

.\!k-colspan-5 {
    grid-column: span 5 / span 5 !important;
}

.k-colspan-6 {
    grid-column: span 6 / span 6;
}

.\!k-colspan-6 {
    grid-column: span 6 / span 6 !important;
}

.k-colspan-7 {
    grid-column: span 7 / span 7;
}

.\!k-colspan-7 {
    grid-column: span 7 / span 7 !important;
}

.k-colspan-8 {
    grid-column: span 8 / span 8;
}

.\!k-colspan-8 {
    grid-column: span 8 / span 8 !important;
}

.k-colspan-9 {
    grid-column: span 9 / span 9;
}

.\!k-colspan-9 {
    grid-column: span 9 / span 9 !important;
}

.k-colspan-10 {
    grid-column: span 10 / span 10;
}

.\!k-colspan-10 {
    grid-column: span 10 / span 10 !important;
}

.k-colspan-11 {
    grid-column: span 11 / span 11;
}

.\!k-colspan-11 {
    grid-column: span 11 / span 11 !important;
}

.k-colspan-12 {
    grid-column: span 12 / span 12;
}

.\!k-colspan-12 {
    grid-column: span 12 / span 12 !important;
}

.k-colspan-full,
.k-colspan-all {
    grid-column: 1 / -1;
}

.\!k-colspan-full,
.\!k-colspan-all {
    grid-column: 1 / -1 !important;
}

.k-colspan-auto {
    grid-column: auto;
}

.\!k-colspan-auto {
    grid-column: auto !important;
}

.k-tooltip {
    border-radius: var(--kendo-border-radius-md, 0.25rem);
    margin: 0;
    padding-block: var(--kendo-spacing-1, 0.25rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
    border-width: 0px;
    border-style: solid;
    box-sizing: border-box;
    background-repeat: repeat-x;
    outline: 0;
    font-family: var(--kendo-font-family, inherit);
    font-size: var(--kendo-font-size, inherit);
    line-height: var(--kendo-line-height-sm, normal);
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    position: absolute;
    z-index: 12000;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-tooltip *,
.k-tooltip *::before,
.k-tooltip *::after {
    box-sizing: border-box;
}

.k-tooltip-icon {
    margin-inline-end: var(--kendo-spacing-1, 0.25rem);
    flex-shrink: 0;
}

.k-tooltip-title {
    margin-bottom: 0.25em;
    font-size: calc(var(--kendo-font-size, 0.875rem) * 1.25);
    line-height: var(--kendo-line-height-xs, normal);
}

.k-tooltip-content {
    align-self: stretch;
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
}

.k-tooltip-button {
    margin-inline-start: var(--kendo-spacing-1, 0.25rem);
    flex-shrink: 0;
}
.k-tooltip-button .k-icon {
    color: inherit;
    vertical-align: top;
}

.k-callout {
    width: calc(6px * 2);
    height: calc(6px * 2);
    border-width: 6px;
    border-style: solid;
    border-color: transparent;
    position: absolute;
    pointer-events: none;
}

.k-callout-n {
    margin-left: calc(6px * -1);
    border-bottom-color: currentColor;
    top: calc(6px * -1 * 2);
    left: 50%;
    pointer-events: none;
}

.k-callout-e {
    margin-top: calc(6px * -1);
    border-left-color: currentColor;
    top: 50%;
    right: calc(6px * -1 * 2);
    pointer-events: none;
}

.k-callout-s {
    margin-left: calc(6px * -1);
    border-top-color: currentColor;
    bottom: calc(6px * -1 * 2);
    left: 50%;
    pointer-events: none;
}

.k-callout-w {
    margin-top: calc(6px * -1);
    border-right-color: currentColor;
    top: 50%;
    left: calc(6px * -1 * 2);
    pointer-events: none;
}

.k-tooltip {
    border-color: var(--kendo-color-on-app-surface, #3d3d3d);
    color: var(--kendo-color-app-surface, #ffffff);
    background-color: var(--kendo-color-on-app-surface, #3d3d3d);
    box-shadow: var(--kendo-elevation-2, 0 4px 6px rgba(0, 0, 0, 0.06), 0 4px 16px rgba(0, 0, 0, 0.12));
}
.k-tooltip .k-callout {
    color: var(--kendo-color-on-app-surface, #3d3d3d);
}

.k-tooltip-inverse {
    border-color: var(--kendo-color-dark, #3d3d3d);
    color: var(--kendo-color-on-inverse, #ffffff);
    background-color: var(--kendo-color-dark, #3d3d3d);
}
.k-tooltip-inverse .k-callout {
    color: var(--kendo-color-dark, #3d3d3d);
}

.k-tooltip-light {
    border-color: var(--kendo-color-light, #ebebeb);
    color: var(--kendo-color-on-light, #000000);
    background-color: var(--kendo-color-light, #ebebeb);
}
.k-tooltip-light .k-callout {
    color: var(--kendo-color-light, #ebebeb);
}

.k-tooltip-dark {
    border-color: var(--kendo-color-dark, #3d3d3d);
    color: var(--kendo-color-on-dark, #ffffff);
    background-color: var(--kendo-color-dark, #3d3d3d);
}
.k-tooltip-dark .k-callout {
    color: var(--kendo-color-dark, #3d3d3d);
}

.k-tooltip-error {
    border-color: var(--kendo-color-error, #f31700);
    color: var(--kendo-color-on-error, #ffffff);
    background-color: var(--kendo-color-error, #f31700);
}
.k-tooltip-error .k-callout {
    color: var(--kendo-color-error, #f31700);
}

.k-tooltip-warning {
    border-color: var(--kendo-color-warning, #ffc000);
    color: var(--kendo-color-on-warning, #3d3d3d);
    background-color: var(--kendo-color-warning, #ffc000);
}
.k-tooltip-warning .k-callout {
    color: var(--kendo-color-warning, #ffc000);
}

.k-tooltip-success {
    border-color: var(--kendo-color-success, #37b400);
    color: var(--kendo-color-on-success, #ffffff);
    background-color: var(--kendo-color-success, #37b400);
}
.k-tooltip-success .k-callout {
    color: var(--kendo-color-success, #37b400);
}

.k-tooltip-info {
    border-color: var(--kendo-color-info, #0058e9);
    color: var(--kendo-color-on-info, #ffffff);
    background-color: var(--kendo-color-info, #0058e9);
}
.k-tooltip-info .k-callout {
    color: var(--kendo-color-info, #0058e9);
}

.k-tooltip-tertiary {
    border-color: var(--kendo-color-tertiary, #03a9f4);
    color: var(--kendo-color-on-tertiary, #ffffff);
    background-color: var(--kendo-color-tertiary, #03a9f4);
}
.k-tooltip-tertiary .k-callout {
    color: var(--kendo-color-tertiary, #03a9f4);
}

.k-tooltip-secondary {
    border-color: var(--kendo-color-secondary, #666666);
    color: var(--kendo-color-on-secondary, #ffffff);
    background-color: var(--kendo-color-secondary, #666666);
}
.k-tooltip-secondary .k-callout {
    color: var(--kendo-color-secondary, #666666);
}

.k-tooltip-primary {
    border-color: var(--kendo-color-primary, rgb(44, 91, 204));
    color: var(--kendo-color-on-primary, white);
    background-color: var(--kendo-color-primary, rgb(44, 91, 204));
}
.k-tooltip-primary .k-callout {
    color: var(--kendo-color-primary, rgb(44, 91, 204));
}

.k-validator-tooltip {
    margin-top: calc(6px + 0px);
    width: auto;
    white-space: normal;
    display: flex;
    align-items: center;
    z-index: 9999;
}

.k-grid .k-validator-tooltip {
    max-width: 300px;
}

.k-input,
.k-picker {
    margin: 0;
    padding: 0;
    width: 100%;
    min-width: 0;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    outline: 0;
    font-family: var(--kendo-font-family, inherit);
    font-size: var(--kendo-font-size, inherit);
    line-height: var(--kendo-line-height, normal);
    font-weight: normal;
    text-align: start;
    box-shadow: none;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: stretch;
    vertical-align: middle;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-appearance: none;
}
.k-input *,
.k-input *::before,
.k-input *::after,
.k-picker *,
.k-picker *::before,
.k-picker *::after {
    box-sizing: border-box;
}
.k-input > .k-input,
.k-input > .k-picker,
.k-picker > .k-input,
.k-picker > .k-picker {
    border-width: 0 !important;
    color: inherit;
    background: none;
    font: inherit;
    outline: 0;
    box-shadow: none;
}
.k-input > *,
.k-picker > * {
    margin: 0;
}
.k-input::placeholder,
.k-picker::placeholder {
    color: var(--kendo-color-subtle, #666666);
    opacity: 1;
    user-select: none;
}

input.k-input,
textarea.k-textarea {
    padding-block: var(--kendo-spacing-1, 0.25rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
}
input.k-input:disabled,
input.k-input[disabled],
textarea.k-textarea:disabled,
textarea.k-textarea[disabled] {
    outline: none;
    cursor: default;
    opacity: 0.6;
    filter: grayscale(0.1);
    pointer-events: none;
    box-shadow: none;
}
[disabled] input.k-input:disabled,
.k-disabled input.k-input:disabled,
[disabled] input.k-input[disabled],
.k-disabled input.k-input[disabled],
[disabled] textarea.k-textarea:disabled,
.k-disabled textarea.k-textarea:disabled,
[disabled] textarea.k-textarea[disabled],
.k-disabled textarea.k-textarea[disabled] {
    opacity: 1;
    filter: grayscale(0);
}

textarea.k-textarea {
    overflow: auto;
}

.k-picker {
    cursor: pointer;
}

select.k-picker {
    padding-block: var(--kendo-spacing-1, 0.25rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
    appearance: auto;
}
select.k-picker:disabled,
select.k-picker[disabled] {
    outline: none;
    cursor: default;
    opacity: 0.6;
    filter: grayscale(0.1);
    pointer-events: none;
    box-shadow: none;
}
[disabled] select.k-picker:disabled,
.k-disabled select.k-picker:disabled,
[disabled] select.k-picker[disabled],
.k-disabled select.k-picker[disabled] {
    opacity: 1;
    filter: grayscale(0);
}

.k-input-inner {
    padding-block: var(--kendo-spacing-1, 0.25rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
    width: 100%;
    border: 0;
    outline: 0;
    color: inherit;
    background: none;
    font: inherit;
    flex: 1;
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-appearance: none;
}
.k-input-inner::placeholder {
    color: var(--kendo-color-subtle, #666666);
    opacity: 1;
    user-select: none;
}
.k-input-inner:invalid {
    box-shadow: none;
}

span.k-input-inner {
    white-space: nowrap;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

textarea.k-input-inner {
    margin: 0;
}

.k-input-value-icon {
    flex: none;
}

.k-input-value-text {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.k-input-value-text::before {
    content: '​';
    width: 0px;
    overflow: hidden;
    flex: none;
    display: inline-block;
    vertical-align: top;
}

.k-input-values {
    min-width: 0px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    cursor: text;
}

.k-input-values .k-chip-list {
    display: contents;
}

.k-icon-picker .k-input-inner {
    padding: var(--kendo-spacing-1, 0.25rem);
    justify-content: center;
}

.k-input-prefix,
.k-input-suffix {
    border-color: inherit;
    display: flex;
    align-items: center;
    flex: none;
}
.k-input-prefix > *,
.k-input-suffix > * {
    flex-shrink: 0;
    border: none;
}
.k-input-prefix-vertical,
.k-input-suffix-vertical {
    flex-flow: column wrap;
}
.k-input-prefix-horizontal,
.k-input-suffix-horizontal {
    flex-flow: row wrap;
}

.k-input-separator {
    margin: 0;
    border-style: solid;
    border-color: inherit;
    border-width: 0 0 0 1px;
    height: 16px;
    align-self: center;
}
.k-input-separator-horizontal {
    height: auto;
    margin-inline: var(--kendo-spacing-1, 0.25rem);
    align-self: stretch;
    border-width: 1px 0 0;
}
.k-input-separator-vertical {
    height: auto;
    margin-block: var(--kendo-spacing-1, 0.25rem);
    align-self: stretch;
    border-width: 0 0 0 1px;
}

.k-input-icon,
.k-input-validation-icon,
.k-input-loading-icon {
    flex: none;
    align-self: center;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;
}

.k-input-loading-icon {
    width: 1em;
    height: 1em;
    font-size: 16px;
}

.k-input-icon.k-svg-icon > svg,
.k-input-icon.k-icon-md > svg,
.k-input-validation-icon.k-svg-icon > svg,
.k-input-validation-icon.k-icon-md > svg,
.k-input-loading-icon.k-svg-icon > svg,
.k-input-loading-icon.k-icon-md > svg {
    width: 16px;
    height: 16px;
}
.k-input-icon.k-icon-xs > svg,
.k-input-validation-icon.k-icon-xs > svg,
.k-input-loading-icon.k-icon-xs > svg {
    width: calc(16px * 0.75);
    height: calc(16px * 0.75);
}
.k-input-icon.k-icon-sm > svg,
.k-input-validation-icon.k-icon-sm > svg,
.k-input-loading-icon.k-icon-sm > svg {
    width: calc(16px * 0.875);
    height: calc(16px * 0.875);
}
.k-input-icon.k-icon-lg > svg,
.k-input-validation-icon.k-icon-lg > svg,
.k-input-loading-icon.k-icon-lg > svg {
    width: calc(16px * 1.25);
    height: calc(16px * 1.25);
}
.k-input-icon.k-icon-xl > svg,
.k-input-validation-icon.k-icon-xl > svg,
.k-input-loading-icon.k-icon-xl > svg {
    width: calc(16px * 1.5);
    height: calc(16px * 1.5);
}
.k-input-icon.k-icon-xxl > svg,
.k-input-validation-icon.k-icon-xxl > svg,
.k-input-loading-icon.k-icon-xxl > svg {
    width: calc(16px * 2);
    height: calc(16px * 2);
}
.k-input-icon.k-icon-xxxl > svg,
.k-input-validation-icon.k-icon-xxxl > svg,
.k-input-loading-icon.k-icon-xxxl > svg {
    width: calc(16px * 3);
    height: calc(16px * 3);
}

.k-clear-value {
    outline: 0;
    flex: none;
    align-self: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0.5;
}

.k-clear-value:hover,
.k-clear-value.k-hover {
    opacity: 1;
}

.k-clear-value:focus-visible {
    outline: 1px dotted;
    outline-offset: -1px;
}

.k-input-button {
    width: calc(var(--kendo-line-height, normal) * 1em + var(--kendo-spacing-1, 0.25rem) * 2);
    border-width: 0;
    border-inline-start-width: 1px;
    flex: none;
    aspect-ratio: auto;
    box-shadow: none;
}
.k-input-button > .k-button-icon {
    min-width: auto !important;
}
.k-input-button:focus {
    box-shadow: none;
}

.k-picker .k-input-button {
    color: inherit;
    background: none;
    border-color: transparent;
}

.k-input-spinner {
    width: calc(var(--kendo-line-height, normal) * 1em + var(--kendo-spacing-1, 0.25rem) * 2);
    flex: none;
    display: flex;
    flex-flow: column nowrap;
}
.k-input-spinner .k-spinner-increase,
.k-input-spinner .k-spinner-decrease {
    border-width: 0;
    border-inline-start-width: 1px;
    flex: 1 1 50%;
    box-shadow: none;
    position: relative;
    aspect-ratio: auto;
}
.k-input-spinner .k-spinner-increase .k-button-icon,
.k-input-spinner .k-spinner-decrease .k-button-icon {
    margin-block: -1em;
    margin-inline: 0;
    min-width: auto !important;
    min-height: auto !important;
}
.k-input-spinner .k-spinner-increase .k-icon {
    bottom: auto;
}
.k-input-spinner .k-spinner-decrease .k-icon {
    top: auto;
}

.k-searchbar {
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;
}

.k-picker-wrap,
.k-dropdown-wrap,
.k-dateinput-wrap,
.k-multiselect-wrap,
.k-numeric-wrap {
    width: 100%;
    border-width: 0;
    border-color: inherit;
    box-sizing: border-box;
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;
    overflow: hidden;
    position: relative;
}

.k-input-flat,
.k-picker-flat {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-width: 1px 0;
    border-top-color: transparent !important;
    background-image: none !important;
}

.k-input-outline {
    background: none !important;
}

.k-input-sm,
.k-picker-sm {
    font-size: var(--kendo-font-size, inherit);
    line-height: var(--kendo-line-height, normal);
}
.k-input-sm .k-input-values,
.k-picker-sm .k-input-values {
    padding: calc(var(--kendo-spacing-0\.5, 0.125rem) / 2);
    gap: calc(var(--kendo-spacing-0\.5, 0.125rem) / 2);
}
.k-input-sm .k-input-values > .k-searchbar,
.k-input-sm .k-input-values > .k-input-inner,
.k-picker-sm .k-input-values > .k-searchbar,
.k-picker-sm .k-input-values > .k-input-inner {
    margin: calc(var(--kendo-spacing-0\.5, 0.125rem) / 2 * -1);
}
.k-input-sm .k-input-inner,
.k-picker-sm .k-input-inner {
    padding-block: var(--kendo-spacing-0\.5, 0.125rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-input-sm .k-input-button,
.k-input-sm .k-spinner-increase,
.k-input-sm .k-spinner-decrease,
.k-picker-sm .k-input-button,
.k-picker-sm .k-spinner-increase,
.k-picker-sm .k-spinner-decrease {
    padding-block: var(--kendo-spacing-0\.5, 0.125rem);
    padding-inline: var(--kendo-spacing-0\.5, 0.125rem);
}
.k-input-sm .k-input-icon,
.k-input-sm .k-input-validation-icon,
.k-input-sm .k-input-loading-icon,
.k-input-sm .k-clear-value,
.k-input-sm .k-input-prefix > .k-icon,
.k-input-sm .k-input-prefix > .k-input-prefix-text,
.k-input-sm .k-input-suffix > .k-icon,
.k-input-sm .k-input-suffix > .k-input-suffix-text,
.k-picker-sm .k-input-icon,
.k-picker-sm .k-input-validation-icon,
.k-picker-sm .k-input-loading-icon,
.k-picker-sm .k-clear-value,
.k-picker-sm .k-input-prefix > .k-icon,
.k-picker-sm .k-input-prefix > .k-input-prefix-text,
.k-picker-sm .k-input-suffix > .k-icon,
.k-picker-sm .k-input-suffix > .k-input-suffix-text {
    padding-block: var(--kendo-spacing-0\.5, 0.125rem);
    padding-inline: var(--kendo-spacing-0\.5, 0.125rem);
    box-sizing: content-box;
}
.k-input-sm .k-input-separator-horizontal,
.k-picker-sm .k-input-separator-horizontal {
    margin-inline: var(--kendo-spacing-0\.5, 0.125rem);
}
.k-input-sm .k-input-separator-vertical,
.k-picker-sm .k-input-separator-vertical {
    margin-block: var(--kendo-spacing-0\.5, 0.125rem);
}
.k-input-sm.k-icon-picker .k-input-inner,
.k-picker-sm.k-icon-picker .k-input-inner {
    width: calc(var(--kendo-line-height, normal) * 1em);
    height: calc(var(--kendo-line-height, normal) * 1em);
    padding: var(--kendo-spacing-0\.5, 0.125rem);
    box-sizing: content-box;
}

.k-dropdown-operator.k-picker-sm .k-input-button {
    padding: var(--kendo-spacing-0\.5, 0.125rem);
}

select.k-picker-sm {
    padding-block: var(--kendo-spacing-0\.5, 0.125rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
}

.k-input-md,
.k-picker-md {
    font-size: var(--kendo-font-size, inherit);
    line-height: var(--kendo-line-height, normal);
}
.k-input-md .k-input-values,
.k-picker-md .k-input-values {
    padding: calc(var(--kendo-spacing-1, 0.25rem) / 2);
    gap: calc(var(--kendo-spacing-1, 0.25rem) / 2);
}
.k-input-md .k-input-values > .k-searchbar,
.k-input-md .k-input-values > .k-input-inner,
.k-picker-md .k-input-values > .k-searchbar,
.k-picker-md .k-input-values > .k-input-inner {
    margin: calc(var(--kendo-spacing-1, 0.25rem) / 2 * -1);
}
.k-input-md .k-input-inner,
.k-picker-md .k-input-inner {
    padding-block: var(--kendo-spacing-1, 0.25rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-input-md .k-input-button,
.k-input-md .k-spinner-increase,
.k-input-md .k-spinner-decrease,
.k-picker-md .k-input-button,
.k-picker-md .k-spinner-increase,
.k-picker-md .k-spinner-decrease {
    padding-block: var(--kendo-spacing-1, 0.25rem);
    padding-inline: var(--kendo-spacing-1, 0.25rem);
}
.k-input-md .k-input-icon,
.k-input-md .k-input-validation-icon,
.k-input-md .k-input-loading-icon,
.k-input-md .k-clear-value,
.k-input-md .k-input-prefix > .k-icon,
.k-input-md .k-input-prefix > .k-input-prefix-text,
.k-input-md .k-input-suffix > .k-icon,
.k-input-md .k-input-suffix > .k-input-suffix-text,
.k-picker-md .k-input-icon,
.k-picker-md .k-input-validation-icon,
.k-picker-md .k-input-loading-icon,
.k-picker-md .k-clear-value,
.k-picker-md .k-input-prefix > .k-icon,
.k-picker-md .k-input-prefix > .k-input-prefix-text,
.k-picker-md .k-input-suffix > .k-icon,
.k-picker-md .k-input-suffix > .k-input-suffix-text {
    padding-block: var(--kendo-spacing-1, 0.25rem);
    padding-inline: var(--kendo-spacing-1, 0.25rem);
    box-sizing: content-box;
}
.k-input-md .k-input-separator-horizontal,
.k-picker-md .k-input-separator-horizontal {
    margin-inline: var(--kendo-spacing-1, 0.25rem);
}
.k-input-md .k-input-separator-vertical,
.k-picker-md .k-input-separator-vertical {
    margin-block: var(--kendo-spacing-1, 0.25rem);
}
.k-input-md.k-icon-picker .k-input-inner,
.k-picker-md.k-icon-picker .k-input-inner {
    width: calc(var(--kendo-line-height, normal) * 1em);
    height: calc(var(--kendo-line-height, normal) * 1em);
    padding: var(--kendo-spacing-1, 0.25rem);
    box-sizing: content-box;
}

.k-dropdown-operator.k-picker-md .k-input-button {
    padding: var(--kendo-spacing-1, 0.25rem);
}

select.k-picker-md {
    padding-block: var(--kendo-spacing-1, 0.25rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
}

.k-input-lg,
.k-picker-lg {
    font-size: var(--kendo-font-size-lg, inherit);
    line-height: var(--kendo-line-height-lg, normal);
}
.k-input-lg .k-input-values,
.k-picker-lg .k-input-values {
    padding: calc(var(--kendo-spacing-2, 0.5rem) / 2);
    gap: calc(var(--kendo-spacing-2, 0.5rem) / 2);
}
.k-input-lg .k-input-values > .k-searchbar,
.k-input-lg .k-input-values > .k-input-inner,
.k-picker-lg .k-input-values > .k-searchbar,
.k-picker-lg .k-input-values > .k-input-inner {
    margin: calc(var(--kendo-spacing-2, 0.5rem) / 2 * -1);
}
.k-input-lg .k-input-inner,
.k-picker-lg .k-input-inner {
    padding-block: var(--kendo-spacing-2, 0.5rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-input-lg .k-input-button,
.k-input-lg .k-spinner-increase,
.k-input-lg .k-spinner-decrease,
.k-picker-lg .k-input-button,
.k-picker-lg .k-spinner-increase,
.k-picker-lg .k-spinner-decrease {
    padding-block: var(--kendo-spacing-2, 0.5rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-input-lg .k-input-icon,
.k-input-lg .k-input-validation-icon,
.k-input-lg .k-input-loading-icon,
.k-input-lg .k-clear-value,
.k-input-lg .k-input-prefix > .k-icon,
.k-input-lg .k-input-prefix > .k-input-prefix-text,
.k-input-lg .k-input-suffix > .k-icon,
.k-input-lg .k-input-suffix > .k-input-suffix-text,
.k-picker-lg .k-input-icon,
.k-picker-lg .k-input-validation-icon,
.k-picker-lg .k-input-loading-icon,
.k-picker-lg .k-clear-value,
.k-picker-lg .k-input-prefix > .k-icon,
.k-picker-lg .k-input-prefix > .k-input-prefix-text,
.k-picker-lg .k-input-suffix > .k-icon,
.k-picker-lg .k-input-suffix > .k-input-suffix-text {
    padding-block: var(--kendo-spacing-2, 0.5rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
    box-sizing: content-box;
}
.k-input-lg .k-input-separator-horizontal,
.k-picker-lg .k-input-separator-horizontal {
    margin-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-input-lg .k-input-separator-vertical,
.k-picker-lg .k-input-separator-vertical {
    margin-block: var(--kendo-spacing-2, 0.5rem);
}
.k-input-lg.k-icon-picker .k-input-inner,
.k-picker-lg.k-icon-picker .k-input-inner {
    width: calc(var(--kendo-line-height-lg, normal) * 1em);
    height: calc(var(--kendo-line-height-lg, normal) * 1em);
    padding: var(--kendo-spacing-2, 0.5rem);
    box-sizing: content-box;
}

.k-dropdown-operator.k-picker-lg .k-input-button {
    padding: var(--kendo-spacing-2, 0.5rem);
}

select.k-picker-lg {
    padding-block: var(--kendo-spacing-2, 0.5rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
}

.k-input > kendo-popup,
.k-picker > kendo-popup {
    position: fixed;
}

@keyframes autoFillStart {
}
@keyframes autoFillEnd {
}
.k-autofill:-webkit-autofill {
    animation-name: autoFillStart;
}

.k-autofill:not(:-webkit-autofill) {
    animation-name: autoFillEnd;
}

.k-input:-webkit-autofill {
    animation-name: autoFillStart;
}

.k-input:not(:-webkit-autofill) {
    animation-name: autoFillEnd;
}

.k-input-solid {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    color: var(--kendo-color-on-app-surface, #3d3d3d);
    background-color: var(--kendo-color-surface-alt, #ffffff);
}
.k-input-solid:hover,
.k-input-solid.k-hover {
    border-color: var(--kendo-color-border-alt, rgba(0, 0, 0, 0.16));
}
.k-input-solid:focus,
.k-input-solid.k-focus {
    border-color: var(--kendo-color-border-alt, rgba(0, 0, 0, 0.16));
    box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-input-solid:focus-within {
    border-color: var(--kendo-color-border-alt, rgba(0, 0, 0, 0.16));
    box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-input-solid.k-invalid,
.k-input-solid.ng-invalid.ng-touched,
.k-input-solid.ng-invalid.ng-dirty {
    border-color: var(--kendo-color-error, #f31700);
}
.k-input-solid.k-invalid .k-input-validation-icon,
.k-input-solid.ng-invalid.ng-touched .k-input-validation-icon,
.k-input-solid.ng-invalid.ng-dirty .k-input-validation-icon {
    color: var(--kendo-color-error, #f31700);
}
.k-input-solid .k-input-prefix {
    color: var(--kendo-color-subtle, #666666);
}
.k-input-solid .k-input-suffix {
    color: var(--kendo-color-subtle, #666666);
}
.k-input-solid .k-input-separator {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}

.k-picker-solid {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    color: var(--kendo-color-on-base, #3d3d3d);
    background-color: var(--kendo-color-base, #f5f5f5);
}
.k-picker-solid:hover,
.k-picker-solid.k-hover {
    background-color: var(--kendo-color-base-hover, #ebebeb);
}
.k-picker-solid:focus,
.k-picker-solid.k-focus {
    box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-picker-solid:focus-within {
    box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-picker-solid.k-invalid,
.k-picker-solid.ng-invalid.ng-touched,
.k-picker-solid.ng-invalid.ng-dirty {
    border-color: var(--kendo-color-error, #f31700);
}
.k-picker-solid.k-invalid .k-input-validation-icon,
.k-picker-solid.ng-invalid.ng-touched .k-input-validation-icon,
.k-picker-solid.ng-invalid.ng-dirty .k-input-validation-icon {
    color: var(--kendo-color-error, #f31700);
}
.k-input-outline {
    border-color: color-mix(in srgb, var(--kendo-color-on-base, #3d3d3d) 50%, transparent);
    color: var(--kendo-color-on-app-surface, #3d3d3d);
}
.k-input-outline > .k-input-button {
    border-color: inherit;
}
.k-input-outline > .k-input-spinner {
    border-color: inherit;
}
.k-input-outline > .k-input-spinner > .k-spinner-increase,
.k-input-outline > .k-input-spinner > .k-spinner-decrease {
    border-color: inherit;
}
.k-input-outline:hover,
.k-input-outline.k-hover {
    border-color: color-mix(in srgb, var(--kendo-color-on-base, #3d3d3d) 80%, transparent);
}
.k-input-outline:focus,
.k-input-outline.k-focus {
    box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-input-outline:focus-within {
    box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-input-outline.k-invalid,
.k-input-outline.ng-invalid.ng-touched,
.k-input-outline.ng-invalid.ng-dirty {
    border-color: var(--kendo-color-error, #f31700);
}
.k-input-outline.k-invalid .k-input-validation-icon,
.k-input-outline.ng-invalid.ng-touched .k-input-validation-icon,
.k-input-outline.ng-invalid.ng-dirty .k-input-validation-icon {
    color: var(--kendo-color-error, #f31700);
}
.k-input-outline .k-input-prefix {
    color: var(--kendo-color-subtle, #666666);
}
.k-input-outline .k-input-suffix {
    color: var(--kendo-color-subtle, #666666);
}
.k-input-outline .k-input-separator {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}

.k-picker-outline {
    border-color: color-mix(in srgb, var(--kendo-color-on-base, #3d3d3d) 50%, transparent);
    color: var(--kendo-color-on-base, #3d3d3d);
}
.k-picker-outline:hover,
.k-picker-outline.k-hover {
    border-color: var(--kendo-color-on-base, #3d3d3d);
    color: var(--kendo-color-base, #f5f5f5);
    background-color: var(--kendo-color-on-base, #3d3d3d);
}
.k-picker-outline:focus,
.k-picker-outline.k-focus {
    box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-picker-outline:focus-within {
    box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-picker-outline.k-invalid,
.k-picker-outline.ng-invalid.ng-touched,
.k-picker-outline.ng-invalid.ng-dirty {
    border-color: var(--kendo-color-error, #f31700);
}
.k-picker-outline.k-invalid .k-input-validation-icon,
.k-picker-outline.ng-invalid.ng-touched .k-input-validation-icon,
.k-picker-outline.ng-invalid.ng-dirty .k-input-validation-icon {
    color: var(--kendo-color-error, #f31700);
}
.k-input-flat {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    color: var(--kendo-color-on-app-surface, #3d3d3d);
}
.k-input-flat:hover,
.k-input-flat.k-hover {
    border-color: var(--kendo-color-border-alt, rgba(0, 0, 0, 0.16));
}
.k-input-flat:focus,
.k-input-flat.k-focus {
    box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-input-flat:focus-within {
    box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-input-flat.k-invalid,
.k-input-flat.ng-invalid.ng-touched,
.k-input-flat.ng-invalid.ng-dirty {
    border-color: var(--kendo-color-error, #f31700);
}
.k-input-flat.k-invalid .k-input-validation-icon,
.k-input-flat.ng-invalid.ng-touched .k-input-validation-icon,
.k-input-flat.ng-invalid.ng-dirty .k-input-validation-icon {
    color: var(--kendo-color-error, #f31700);
}
.k-input-flat .k-input-prefix {
    color: var(--kendo-color-subtle, #666666);
}
.k-input-flat .k-input-suffix {
    color: var(--kendo-color-subtle, #666666);
}
.k-input-flat .k-input-separator {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}

.k-picker-flat {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    color: var(--kendo-color-on-base, #3d3d3d);
}
.k-picker-flat:hover,
.k-picker-flat.k-hover {
    border-color: var(--kendo-color-border-alt, rgba(0, 0, 0, 0.16));
    background-color: color-mix(in srgb, var(--kendo-color-on-base, #3d3d3d) 4%, transparent);
}
.k-picker-flat:focus,
.k-picker-flat.k-focus {
    box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-picker-flat:focus-within {
    box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-picker-flat.k-invalid,
.k-picker-flat.ng-invalid.ng-touched,
.k-picker-flat.ng-invalid.ng-dirty {
    border-color: var(--kendo-color-error, #f31700);
}
.k-picker-flat.k-invalid .k-input-validation-icon,
.k-picker-flat.ng-invalid.ng-touched .k-input-validation-icon,
.k-picker-flat.ng-invalid.ng-dirty .k-input-validation-icon {
    color: var(--kendo-color-error, #f31700);
}
.k-floating-label-container {
    padding-top: calc(var(--kendo-line-height, normal) * var(--kendo-font-size, inherit));
    box-sizing: border-box;
    display: inline-flex;
    vertical-align: middle;
    position: relative;
    flex-direction: column;
    justify-content: stretch;
}
.k-floating-label-container *,
.k-floating-label-container *::before,
.k-floating-label-container *::after {
    box-sizing: border-box;
}
.k-floating-label-container > .k-floating-label {
    max-width: 90%;
    font-size: var(--kendo-font-size, inherit);
    line-height: var(--kendo-line-height, normal);
    white-space: nowrap;
    text-overflow: ellipsis;
    position: absolute;
    top: var(
        --kendo-floating-label-offset-y,
        calc(calc(var(--kendo-line-height, normal) * var(--kendo-font-size, inherit)) + 1px + var(--kendo-spacing-1, 0.25rem))
    );
    left: var(--kendo-floating-label-offset-x, calc(var(--kendo-spacing-2, 0.5rem) + 1px));
    overflow: hidden;
    cursor: text;
    transform-origin: left center;
    transition:
        transform 0.2s ease-out,
        color 0.2s ease-out,
        top 0.2s ease-out,
        left 0.2s ease-out;
}
.k-floating-label-container.k-empty > .k-floating-label {
    top: var(
        --kendo-floating-label-offset-y,
        calc(calc(var(--kendo-line-height, normal) * var(--kendo-font-size, inherit)) + 1px + var(--kendo-spacing-1, 0.25rem))
    );
    left: var(--kendo-floating-label-offset-x, calc(var(--kendo-spacing-2, 0.5rem) + 1px));
    transform: scale(1);
    pointer-events: none;
}
.k-floating-label-container > .k-floating-label,
.k-floating-label-container.k-focus > .k-floating-label {
    top: var(--kendo-floating-label-focus-offset-y, 0);
    left: var(--kendo-floating-label-focus-offset-x, 0);
    transform: scale(1);
}
.k-floating-label-container:focus-within > .k-floating-label {
    top: var(--kendo-floating-label-focus-offset-y, 0);
    left: var(--kendo-floating-label-focus-offset-x, 0);
    transform: scale(1);
}
.k-floating-label-container.k-empty:not(.k-focus):not(:focus-within) ::placeholder {
    color: transparent;
}
[dir='rtl'] .k-floating-label-container > .k-floating-label,
.k-rtl .k-floating-label-container > .k-floating-label,
.k-floating-label-container[dir='rtl'] > .k-floating-label {
    transform-origin: right center;
    transition:
        transform 0.2s ease-out,
        color 0.2s ease-out,
        top 0.2s ease-out,
        right 0.2s ease-out;
}
[dir='rtl'] .k-floating-label-container.k-empty > .k-floating-label,
.k-rtl .k-floating-label-container.k-empty > .k-floating-label,
.k-floating-label-container[dir='rtl'].k-empty > .k-floating-label {
    left: auto;
    right: var(--kendo-floating-label-offset-x, calc(var(--kendo-spacing-2, 0.5rem) + 1px));
}
[dir='rtl'] .k-floating-label-container > .k-floating-label,
[dir='rtl'] .k-floating-label-container.k-focus > .k-floating-label,
.k-rtl .k-floating-label-container > .k-floating-label,
.k-rtl .k-floating-label-container.k-focus > .k-floating-label,
.k-floating-label-container[dir='rtl'] > .k-floating-label,
.k-floating-label-container[dir='rtl'].k-focus > .k-floating-label {
    left: auto;
    right: var(--kendo-floating-label-focus-offset-x, 0);
}
[dir='rtl'] .k-floating-label-container:focus-within > .k-floating-label,
.k-rtl .k-floating-label-container:focus-within > .k-floating-label,
.k-floating-label-container[dir='rtl']:focus-within > .k-floating-label {
    left: auto;
    right: var(--kendo-floating-label-focus-offset-x, 0);
}

.k-floating-label-container.k-invalid > .k-floating-label,
.k-floating-label-container.ng-invalid.ng-touched > .k-floating-label,
.k-floating-label-container.ng-invalid.ng-dirty > .k-floating-label {
    color: var(--kendo-color-error, #f31700);
}

.k-textarea:has(.k-input-inner[style*='width']) {
    width: max-content;
}

.k-align-items-normal {
    align-items: normal;
}

.\!k-align-items-normal {
    align-items: normal !important;
}

.k-align-items-stretch {
    align-items: stretch;
}

.\!k-align-items-stretch {
    align-items: stretch !important;
}

.k-align-items-center {
    align-items: center;
}

.\!k-align-items-center {
    align-items: center !important;
}

.k-align-items-start {
    align-items: start;
}

.\!k-align-items-start {
    align-items: start !important;
}

.k-align-items-end {
    align-items: end;
}

.\!k-align-items-end {
    align-items: end !important;
}

.k-align-items-flex-start {
    align-items: flex-start;
}

.\!k-align-items-flex-start {
    align-items: flex-start !important;
}

.k-align-items-flex-end {
    align-items: flex-end;
}

.\!k-align-items-flex-end {
    align-items: flex-end !important;
}

.k-align-items-baseline {
    align-items: baseline;
}

.\!k-align-items-baseline {
    align-items: baseline !important;
}

.k-align-items-first-baseline {
    align-items: first baseline;
}

.\!k-align-items-first-baseline {
    align-items: first baseline !important;
}

.k-align-items-last-baseline {
    align-items: last baseline;
}

.\!k-align-items-last-baseline {
    align-items: last baseline !important;
}

.k-align-items-self-start {
    align-items: self-start;
}

.\!k-align-items-self-start {
    align-items: self-start !important;
}

.k-align-items-self-end {
    align-items: self-end;
}

.\!k-align-items-self-end {
    align-items: self-end !important;
}

.k-flex-row,
.k-d-flex-row {
    flex-direction: row;
}

.\!k-flex-row,
.\!k-d-flex-row,
.\!k-d-flex-col {
    flex-direction: row !important;
}

.k-flex-row-reverse {
    flex-direction: row-reverse;
}

.\!k-flex-row-reverse {
    flex-direction: row-reverse !important;
}

.k-flex-col,
.k-flex-column,
.k-d-flex-col {
    flex-direction: column;
}

.\!k-flex-col {
    flex-direction: column !important;
}

.k-flex-col-reverse,
.k-flex-column-reverse {
    flex-direction: column-reverse;
}

.\!k-flex-col-reverse {
    flex-direction: column-reverse !important;
}

.k-flex-1 {
    flex: 1 1 0%;
}

.\!k-flex-1 {
    flex: 1 1 0% !important;
}

.k-flex-auto {
    flex: 1 1 auto;
}

.\!k-flex-auto {
    flex: 1 1 auto !important;
}

.k-flex-initial {
    flex: 0 1 auto;
}

.\!k-flex-initial {
    flex: 0 1 auto !important;
}

.k-flex-none {
    flex: none;
}

.\!k-flex-none {
    flex: none !important;
}

.k-flex-wrap {
    flex-wrap: wrap;
}

.\!k-flex-wrap {
    flex-wrap: wrap !important;
}

.k-flex-wrap-reverse {
    flex-wrap: wrap-reverse;
}

.\!k-flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}

.k-flex-nowrap {
    flex-wrap: nowrap;
}

.\!k-flex-nowrap {
    flex-wrap: nowrap !important;
}

.k-resize {
    resize: both;
}

.\!k-resize {
    resize: both !important;
}

.k-resize-none {
    resize: none;
}

.\!k-resize-none {
    resize: none !important;
}

.k-resize-x {
    resize: horizontal;
}

.\!k-resize-x {
    resize: horizontal !important;
}

.k-resize-y {
    resize: vertical;
}

.\!k-resize-y {
    resize: vertical !important;
}

.k-slider {
    border: 0;
    box-sizing: border-box;
    outline: 0;
    font-family: var(--kendo-font-family, inherit);
    font-size: var(--kendo-font-size, inherit);
    line-height: var(--kendo-line-height, normal);
    background: none;
    display: inline-flex;
    align-items: center;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-slider *,
.k-slider *::before,
.k-slider *::after {
    box-sizing: border-box;
}
.k-slider .k-label {
    width: auto;
    font-size: 0.92em;
    line-height: 1;
    white-space: nowrap;
    position: absolute;
}
.k-slider .k-tick,
.k-slider .k-slider-track {
    cursor: pointer;
}
.k-slider .k-tick {
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    margin: 0;
    padding: 0;
    position: relative;
}
.k-slider.k-disabled .k-tick,
.k-slider.k-disabled .k-slider-track,
.k-slider.k-disabled .k-draghandle {
    cursor: default;
}

.k-slider {
    width: min-content;
    height: min-content;
    gap: calc(14px / 2);
}
.k-slider .k-button {
    flex: none;
}
.k-slider .k-slider-track-wrap {
    flex: 1 1 auto;
    display: flex;
    flex-flow: inherit;
    position: relative;
    touch-action: none;
}
.k-slider .k-slider-track-wrap .k-slider-items {
    margin: 0;
    padding: 0;
    list-style: none;
    flex: 1 1 100%;
    display: flex;
    flex-flow: inherit;
    justify-content: space-between;
    user-select: none;
}
.k-slider .k-slider-track-wrap .k-tick {
    flex: 0 0 1px;
}
.k-slider .k-slider-track-wrap .k-tick-horizontal {
    width: 1px;
    min-height: 100%;
}
.k-slider .k-slider-track-wrap .k-tick-vertical {
    width: 100%;
    min-height: 1px;
}
.k-slider .k-slider-track-wrap .k-draghandle {
    position: absolute;
}
.k-slider-horizontal .k-slider-track-wrap {
    height: 26px;
}
.k-slider-horizontal .k-slider-track-wrap .k-slider-track {
    width: 100%;
}
.k-slider-horizontal .k-slider-track-wrap .k-draghandle {
    top: 50%;
    transform: translate(-50%, -50%);
}
.k-slider-horizontal .k-slider-track-wrap .k-draghandle:focus,
.k-slider-horizontal .k-slider-track-wrap .k-draghandle:active {
    transform: translate(-50%, -50%) scale(1);
}
.k-slider-horizontal .k-slider-track-wrap .k-slider-selection {
    width: calc((var(--kendo-slider-end, 0) - var(--kendo-slider-start, 0)) * 1%);
    inset-inline-start: calc(var(--kendo-slider-start, 0) * 1%);
}
.k-slider-horizontal .k-slider-track-wrap .k-draghandle-start {
    inset-inline-start: calc(var(--kendo-slider-start, 0) * 1%);
}
.k-slider-horizontal .k-slider-track-wrap .k-draghandle-end {
    inset-inline-start: calc(var(--kendo-slider-end, 0) * 1%);
}
.k-slider-vertical .k-slider-track-wrap {
    width: 26px;
}
.k-slider-vertical .k-slider-track-wrap .k-slider-track {
    height: 100%;
}
.k-slider-vertical .k-slider-track-wrap .k-draghandle {
    left: 50%;
    transform: translate(-50%, 50%);
}
.k-slider-vertical .k-slider-track-wrap .k-draghandle:focus,
.k-slider-vertical .k-slider-track-wrap .k-draghandle:active {
    transform: translate(-50%, 50%) scale(1);
}
.k-slider-vertical .k-slider-track-wrap .k-slider-selection {
    bottom: calc(var(--kendo-slider-start, 0) * 1%);
    height: calc((var(--kendo-slider-end, 0) - var(--kendo-slider-start, 0)) * 1%);
}
.k-slider-vertical .k-slider-track-wrap .k-draghandle-start {
    bottom: calc(var(--kendo-slider-start, 0) * 1%);
}
.k-slider-vertical .k-slider-track-wrap .k-draghandle-end {
    bottom: calc(var(--kendo-slider-end, 0) * 1%);
}

.k-slider-vertical {
    height: 200px;
    flex-flow: column-reverse nowrap;
}
.k-slider-vertical .k-tick {
    text-align: end;
    margin-left: 2px;
}
.k-slider-vertical .k-slider-topleft .k-tick {
    text-align: start;
}
.k-slider-vertical .k-tick {
    background-position: -94px center;
}
.k-slider-vertical .k-slider-topleft .k-tick {
    background-position: -124px center;
}
.k-slider-vertical .k-slider-bottomright .k-tick {
    background-position: -154px center;
}
.k-slider-vertical .k-tick-large {
    display: flex;
    align-items: center;
    background-position: -4px center;
}
.k-slider-vertical .k-slider-topleft .k-tick-large {
    background-position: -34px center;
}
.k-slider-vertical .k-slider-bottomright .k-tick-large {
    background-position: -64px center;
}
.k-slider-vertical .k-first {
    background-position: -94px 100%;
}
.k-slider-vertical .k-tick-large.k-first {
    background-position: -4px 100%;
}
.k-slider-vertical .k-slider-topleft .k-first {
    background-position: -124px 100%;
}
.k-slider-vertical .k-slider-topleft .k-tick-large.k-first {
    background-position: -34px 100%;
}
.k-slider-vertical .k-slider-bottomright .k-first {
    background-position: -154px 100%;
}
.k-slider-vertical .k-slider-bottomright .k-tick-large.k-first {
    background-position: -64px 100%;
}
.k-slider-vertical .k-last {
    background-position: -94px 0;
}
.k-slider-vertical .k-tick-large.k-last {
    background-position: -4px 0;
}
.k-slider-vertical .k-slider-topleft .k-last {
    background-position: -124px 0;
}
.k-slider-vertical .k-slider-topleft .k-tick-large.k-last {
    background-position: -34px 0;
}
.k-slider-vertical .k-slider-bottomright .k-last {
    background-position: -154px 0;
}
.k-slider-vertical .k-slider-bottomright .k-tick-large.k-last {
    background-position: -64px 0;
}
.k-slider-vertical .k-label {
    text-align: start;
    inset-inline-start: 120%;
    top: 50%;
    transform: translateY(-50%);
}
.k-slider-vertical .k-first .k-label {
    top: 100%;
}
.k-slider-vertical .k-last .k-label {
    top: 0;
}
.k-slider-vertical .k-slider-topleft .k-label {
    inset-inline-start: auto;
    inset-inline-end: 120%;
}

.k-slider-horizontal {
    width: 200px;
    flex-flow: row nowrap;
}
.k-slider-horizontal .k-tick {
    background-position: center -92px;
}
.k-slider-horizontal .k-slider-topleft .k-tick {
    background-position: center -122px;
}
.k-slider-horizontal .k-slider-bottomright .k-tick {
    background-position: center -152px;
}
.k-slider-horizontal .k-tick-large {
    background-position: center -2px;
}
.k-slider-horizontal .k-slider-topleft .k-tick-large {
    background-position: center -32px;
}
.k-slider-horizontal .k-slider-bottomright .k-tick-large {
    background-position: center -62px;
}
.k-slider-horizontal .k-first {
    background-position: 0 -92px;
}
.k-slider-horizontal .k-tick-large.k-first {
    background-position: 0 -2px;
}
.k-slider-horizontal .k-slider-topleft .k-first {
    background-position: 0 -122px;
}
.k-slider-horizontal .k-slider-topleft .k-tick-large.k-first {
    background-position: 0 -32px;
}
.k-slider-horizontal .k-slider-bottomright .k-first {
    background-position: 0 -152px;
}
.k-slider-horizontal .k-slider-bottomright .k-tick-large.k-first {
    background-position: 0 -62px;
}
.k-slider-horizontal .k-last {
    background-position: 100% -92px;
}
.k-slider-horizontal .k-tick-large.k-last {
    background-position: 100% -2px;
}
.k-slider-horizontal .k-slider-topleft .k-last {
    background-position: 100% -122px;
}
.k-slider-horizontal .k-slider-topleft .k-tick-large.k-last {
    background-position: 100% -32px;
}
.k-slider-horizontal .k-slider-bottomright .k-last {
    background-position: 100% -152px;
}
.k-slider-horizontal .k-slider-bottomright .k-tick-large.k-last {
    background-position: 100% -62px;
}
.k-slider-horizontal .k-label {
    inset-inline-start: 50%;
    bottom: -1.2em;
    transform: translateX(-50%);
}
.k-slider-horizontal .k-first .k-label {
    inset-inline-start: 0;
}
.k-slider-horizontal .k-last .k-label {
    inset-inline-start: 100%;
}
.k-slider-horizontal .k-slider-topleft .k-label {
    top: -1.2em;
}

.k-slider-track,
.k-slider-selection {
    margin: 0;
    padding: 0;
    position: absolute;
}
.k-slider-horizontal .k-slider-track,
.k-slider-horizontal .k-slider-selection {
    height: 4px;
    left: 0;
    margin-top: -2px;
    top: 50%;
}
.k-slider-vertical .k-slider-track,
.k-slider-vertical .k-slider-selection {
    bottom: 0;
    left: 50%;
    margin-left: -2px;
    width: 4px;
}

.k-draghandle {
    background-color: transparent;
    background-repeat: no-repeat;
    border-style: solid;
    border-width: 1px;
    outline: 0;
    position: absolute;
    text-align: center;
    text-decoration: none;
    width: 14px;
    height: 14px;
}
.k-slider-transitions.k-slider-horizontal .k-draghandle {
    transition:
        inset-inline-start 0.3s ease-out,
        background-color 0.3s ease-out,
        transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.k-slider-transitions.k-slider-vertical .k-draghandle {
    transition:
        bottom 0.3s ease-out,
        background-color 0.3s ease-out,
        transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.k-draghandle.k-pressed {
    transition: none;
}

.k-slider-transitions.k-slider-horizontal .k-slider-selection {
    transition: width 0.3s ease-out;
}
.k-slider-transitions.k-slider-vertical .k-slider-selection {
    transition: height 0.3s ease-out;
}
.k-slider-selection.k-pressed {
    transition: none;
}

.k-slider.k-readonly .k-button,
.k-slider.k-readonly .k-slider-track,
.k-slider.k-readonly .k-tick,
.k-slider.k-readonly .k-draghandle {
    pointer-events: none;
}

.k-slider-tooltip .k-callout-n,
.k-slider-tooltip .k-callout-s {
    margin-inline-start: -3px;
}
.k-slider-tooltip .k-callout-w,
.k-slider-tooltip .k-callout-e {
    margin-top: -3px;
}

.k-slider-rtl.k-slider-horizontal .k-draghandle,
.k-slider-horizontal.k-slider[dir='rtl'] .k-draghandle,
[dir='rtl'] .k-slider-horizontal.k-slider .k-draghandle,
.k-slider-horizontal.k-slider.k-rtl .k-draghandle,
.k-rtl .k-slider-horizontal.k-slider .k-draghandle {
    transform: translate(50%, -50%);
}
.k-slider-rtl.k-slider-horizontal .k-draghandle:focus,
.k-slider-horizontal.k-slider[dir='rtl'] .k-draghandle:focus,
[dir='rtl'] .k-slider-horizontal.k-slider .k-draghandle:focus,
.k-slider-horizontal.k-slider.k-rtl .k-draghandle:focus,
.k-rtl .k-slider-horizontal.k-slider .k-draghandle:focus,
.k-slider-rtl.k-slider-horizontal .k-draghandle:active,
.k-slider-horizontal.k-slider[dir='rtl'] .k-draghandle:active,
[dir='rtl'] .k-slider-horizontal.k-slider .k-draghandle:active,
.k-slider-horizontal.k-slider.k-rtl .k-draghandle:active,
.k-rtl .k-slider-horizontal.k-slider .k-draghandle:active {
    transform: translate(50%, -50%) scale(1);
}
.k-slider-rtl.k-slider-horizontal .k-label,
.k-slider-horizontal.k-slider[dir='rtl'] .k-label,
[dir='rtl'] .k-slider-horizontal.k-slider .k-label,
.k-slider-horizontal.k-slider.k-rtl .k-label,
.k-rtl .k-slider-horizontal.k-slider .k-label {
    transform: translateX(50%);
}

.k-slider kendo-resize-sensor {
    position: absolute;
}

.k-slider {
    color: var(--kendo-color-on-app-surface, #3d3d3d);
}
.k-slider .k-slider-track,
.k-slider .k-slider-selection {
    border-radius: var(--kendo-border-radius-md, 0.25rem);
}
.k-slider .k-slider-track {
    background-color: var(--kendo-color-base-emphasis, #c2c2c2);
}
.k-slider .k-slider-selection {
    background-color: var(--kendo-color-primary, rgb(44, 91, 204));
}
.k-slider .k-draghandle {
    border-color: var(--kendo-color-primary, rgb(44, 91, 204));
    color: var(--kendo-color-on-primary, white);
    background-color: var(--kendo-color-primary, rgb(44, 91, 204));
    border-radius: 50%;
}
.k-slider .k-draghandle:hover {
    border-color: var(--kendo-color-primary, rgb(44, 91, 204));
    background-color: var(--kendo-color-primary-hover, #2a57c4);
}
.k-slider .k-draghandle:active,
.k-slider .k-draghandle.k-pressed {
    border-color: var(--kendo-color-primary-active, #2750b4);
    background-color: var(--kendo-color-primary-active, #2750b4);
}
.k-slider .k-draghandle:focus,
.k-slider .k-draghandle.k-focus {
    box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-primary, rgb(44, 91, 204)) 30%, transparent);
}
.k-slider.k-focus .k-draghandle {
    box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-primary, rgb(44, 91, 204)) 30%, transparent);
}
.k-slider-horizontal .k-tick {
    background-image: url(data:image/gif;base64,R0lGODlhAQC0AIABALi4uAAAACH5BAEAAAEALAAAAAABALQAAAIWjIGJxqzazlux2ovlzND2rAHgSIZWAQA7);
}

.k-slider-vertical .k-tick {
    background-image: url(data:image/gif;base64,R0lGODlhtAABAIABALi4uAAAACH5BAEAAAEALAAAAAC0AAEAAAIWjIGJxqzazlux2ovlzND2rAHgSIZWAQA7);
}

.k-calendar {
    inline-size: var(--INTERNAL--kendo-calendar-width, min-content);
    block-size: var(--INTERNAL--kendo-calendar-height, min-content);
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-family: var(--kendo-font-family, inherit);
    font-size: var(--kendo-font-size, inherit);
    line-height: var(--kendo-line-height, normal);
    position: relative;
    overflow: hidden;
    display: inline-flex;
    flex-flow: column nowrap;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-calendar > .k-calendar {
    border: 0;
}

.k-calendar-table {
    margin-block: 0;
    margin-inline: auto;
    border-width: 0;
    border-color: inherit;
    border-spacing: 0;
    border-collapse: separate;
    table-layout: fixed;
    text-align: center;
    outline: 0;
    display: table;
    position: relative;
    z-index: 1;
}

.k-calendar-caption,
.k-calendar-caption.k-calendar-th,
.k-calendar .k-meta-header,
.k-calendar .k-month-header {
    padding-block: var(--kendo-spacing-1, 0.25rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
    height: 32px;
    box-sizing: border-box;
    text-transform: none;
    text-align: start;
    font-weight: var(--kendo-font-weight-bold, normal);
    cursor: default;
}

.k-calendar-th,
.k-calendar-td {
    border-width: 0;
    padding: 0;
    text-align: center;
    border-style: solid;
    border-color: inherit;
    font-weight: normal;
    cursor: default;
}

.k-calendar-th {
    inline-size: var(--INTERNAL--kendo-calendar-cell-size, 32px);
    block-size: var(--INTERNAL--kendo-calendar-cell-size, 32px);
    font-size: var(--kendo-font-size-sm, inherit);
    line-height: 2;
    text-transform: uppercase;
}

.k-calendar-td {
    border-radius: var(--kendo-border-radius-md, 0.25rem);
    inline-size: var(--INTERNAL--kendo-calendar-cell-size, min-content);
    block-size: var(--INTERNAL--kendo-calendar-cell-size, min-content);
    border-color: transparent;
}

.k-calendar-td.k-alt {
    font-size: 0.75rem;
}

.k-calendar .k-calendar-cell-inner,
.k-calendar .k-link {
    border-radius: var(--kendo-border-radius-md, 0.25rem);
    padding-inline: var(--INTERNAL--kendo-calendar-cell-padding-x, 0.25em);
    padding-block: var(--INTERNAL--kendo-calendar-cell-padding-y, 0.25em);
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    white-space: normal;
    position: relative;
    overflow: hidden;
}

.k-calendar .k-header {
    padding-block: var(--kendo-spacing-1, 0.25rem);
    padding-inline: var(--kendo-spacing-1, 0.25rem);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position: relative;
    z-index: 2;
}

.k-calendar-header {
    padding-block: var(--kendo-spacing-1, 0.25rem);
    padding-inline: var(--kendo-spacing-1, 0.25rem);
    min-width: 256px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 auto;
}
.k-calendar-header .k-calendar-nav {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.k-calendar-footer,
.k-calendar .k-footer {
    padding-block: var(--kendo-spacing-2, 0.5rem);
    padding-inline: var(--kendo-spacing-4, 1rem);
    text-align: center;
    clear: both;
}

.k-calendar-view {
    margin: auto;
    padding-block: 0;
    padding-inline: var(--kendo-spacing-1, 0.25rem);
    width: 256px;
    inline-size: var(--INTERNAL--kendo-calendar-view-width, 256px);
    min-height: 224px;
    box-sizing: content-box;
    gap: var(--kendo-spacing-4, 1rem);
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.k-calendar-view .k-today {
    font-weight: bold;
}

.k-month-calendar .k-calendar-td,
.k-calendar-monthview .k-calendar-td {
    width: 32px;
    height: 32px;
    inline-size: var(--INTERNAL--kendo-calendar-cell-size, 32px);
    block-size: var(--INTERNAL--kendo-calendar-cell-size, 32px);
}

.k-year-calendar .k-calendar-td,
.k-calendar-yearview .k-calendar-td {
    width: 64px;
    height: 64px;
    inline-size: var(--INTERNAL--kendo-calendar-cell-size, 64px);
    block-size: var(--INTERNAL--kendo-calendar-cell-size, 64px);
}

.k-decade-calendar .k-calendar-td,
.k-calendar-decadeview .k-calendar-td {
    width: 64px;
    height: 64px;
    inline-size: var(--INTERNAL--kendo-calendar-cell-size, 64px);
    block-size: var(--INTERNAL--kendo-calendar-cell-size, 64px);
}

.k-century-calendar .k-calendar-td,
.k-calendar-centuryview .k-calendar-td {
    width: 64px;
    height: 64px;
    inline-size: var(--INTERNAL--kendo-calendar-cell-size, 64px);
    block-size: var(--INTERNAL--kendo-calendar-cell-size, 64px);
}
.k-century-calendar .k-calendar-cell-inner,
.k-century-calendar .k-link,
.k-calendar-centuryview .k-calendar-cell-inner,
.k-calendar-centuryview .k-link {
    text-align: start;
}

.k-popup .k-calendar {
    height: 100%;
}

.k-calendar-container,
.k-datetime-container {
    padding: 0;
}
.k-calendar-container .k-calendar,
.k-datetime-container .k-calendar {
    border-width: 0;
}

.k-calendar .k-content.k-scrollable,
.k-calendar .k-calendar-content.k-scrollable {
    box-sizing: content-box;
    overflow-x: hidden;
    overflow-y: auto;
    display: block;
    padding-right: 100px;
    padding-left: 100px;
    margin-left: -100px;
    margin-right: calc(-100px - var(--kendo-scrollbar-width, 17px));
}

.k-calendar .k-scrollable-placeholder {
    position: absolute;
    z-index: -1;
    width: 1px;
    top: 0;
    right: 0;
}

.k-calendar-sm {
    --INTERNAL--kendo-calendar-view-width: 224px;
    --INTERNAL--kendo-calendar-cell-padding-x: var(--kendo-calendar-sm-cell-padding-x, var(--kendo-spacing-0\.5, 0.125rem));
    --INTERNAL--kendo-calendar-cell-padding-y: var(--kendo-calendar-sm-cell-padding-y, var(--kendo-spacing-0\.5, 0.125rem));
    font-size: var(--kendo-font-size, inherit);
    line-height: var(--kendo-line-height, normal);
}
.k-calendar-sm.k-month-calendar,
.k-calendar-sm .k-calendar-monthview {
    --INTERNAL--kendo-calendar-cell-size: var(--kendo-calendar-sm-month-cell-size, 28px);
}
.k-calendar-sm.k-year-calendar,
.k-calendar-sm .k-calendar-yearview {
    --INTERNAL--kendo-calendar-cell-size: var(--kendo-calendar-sm-year-cell-size, 56px);
}
.k-calendar-sm.k-decade-calendar,
.k-calendar-sm .k-calendar-decadeview {
    --INTERNAL--kendo-calendar-cell-size: var(--kendo-calendar-sm-decade-cell-size, 56px);
}
.k-calendar-sm.k-century-calendar,
.k-calendar-sm .k-calendar-centuryview {
    --INTERNAL--kendo-calendar-cell-size: var(--kendo-calendar-sm-century-cell-size, 56px);
}

.k-calendar-md {
    --INTERNAL--kendo-calendar-view-width: 256px;
    --INTERNAL--kendo-calendar-cell-padding-x: var(--kendo-calendar-md-cell-padding-x, var(--kendo-spacing-1, 0.25rem));
    --INTERNAL--kendo-calendar-cell-padding-y: var(--kendo-calendar-md-cell-padding-y, var(--kendo-spacing-1, 0.25rem));
    font-size: var(--kendo-font-size, inherit);
    line-height: var(--kendo-line-height, normal);
}
.k-calendar-md.k-month-calendar,
.k-calendar-md .k-calendar-monthview {
    --INTERNAL--kendo-calendar-cell-size: var(--kendo-calendar-md-month-cell-size, 32px);
}
.k-calendar-md.k-year-calendar,
.k-calendar-md .k-calendar-yearview {
    --INTERNAL--kendo-calendar-cell-size: var(--kendo-calendar-md-year-cell-size, 64px);
}
.k-calendar-md.k-decade-calendar,
.k-calendar-md .k-calendar-decadeview {
    --INTERNAL--kendo-calendar-cell-size: var(--kendo-calendar-md-decade-cell-size, 64px);
}
.k-calendar-md.k-century-calendar,
.k-calendar-md .k-calendar-centuryview {
    --INTERNAL--kendo-calendar-cell-size: var(--kendo-calendar-md-century-cell-size, 64px);
}

.k-calendar-lg {
    --INTERNAL--kendo-calendar-view-width: 320px;
    --INTERNAL--kendo-calendar-cell-padding-x: var(--kendo-calendar-lg-cell-padding-x, var(--kendo-spacing-2, 0.5rem));
    --INTERNAL--kendo-calendar-cell-padding-y: var(--kendo-calendar-lg-cell-padding-y, var(--kendo-spacing-2, 0.5rem));
    font-size: var(--kendo-font-size-lg, inherit);
    line-height: var(--kendo-line-height-lg, normal);
}
.k-calendar-lg.k-month-calendar,
.k-calendar-lg .k-calendar-monthview {
    --INTERNAL--kendo-calendar-cell-size: var(--kendo-calendar-lg-month-cell-size, 40px);
}
.k-calendar-lg.k-year-calendar,
.k-calendar-lg .k-calendar-yearview {
    --INTERNAL--kendo-calendar-cell-size: var(--kendo-calendar-lg-year-cell-size, 80px);
}
.k-calendar-lg.k-decade-calendar,
.k-calendar-lg .k-calendar-decadeview {
    --INTERNAL--kendo-calendar-cell-size: var(--kendo-calendar-lg-decade-cell-size, 80px);
}
.k-calendar-lg.k-century-calendar,
.k-calendar-lg .k-calendar-centuryview {
    --INTERNAL--kendo-calendar-cell-size: var(--kendo-calendar-lg-century-cell-size, 80px);
}

.k-calendar-infinite {
    box-sizing: content-box;
    display: inline-flex;
    flex-flow: row nowrap;
}
.k-calendar-infinite .k-calendar-view {
    padding-block: var(--kendo-spacing-0, 0px);
    padding-inline: var(--kendo-spacing-4, 1rem);
    height: 288px;
    flex: 0 0 auto;
    display: flex;
    flex-flow: column nowrap;
    gap: 0;
    overflow: hidden;
}
.k-calendar-infinite .k-calendar-view .k-content.k-scrollable {
    position: relative;
}
.k-calendar-infinite .k-calendar-view::after {
    display: block;
    position: absolute;
    bottom: 0;
    content: '​';
    height: 0;
    line-height: 0;
    z-index: 1;
    width: 150%;
    left: -25%;
    box-shadow: 0 0 32px 16px var(--kendo-color-surface-alt, #ffffff);
}
.k-calendar-infinite .k-calendar-header {
    margin-left: calc(var(--kendo-spacing-4, 1rem) * -1);
    margin-right: calc(var(--kendo-spacing-4, 1rem) * -1);
    padding-block: var(--kendo-spacing-2, 0.5rem);
    padding-inline: var(--kendo-spacing-4, 1rem);
    width: auto;
    min-width: 0;
}
.k-calendar-infinite .k-calendar-weekdays {
    flex: 0 0 auto;
}

.k-calendar-navigation {
    width: 5em;
    text-align: center;
    flex: 0 0 auto;
    display: block;
    overflow: hidden;
    position: relative;
    z-index: 1;
}
.k-calendar-navigation::before,
.k-calendar-navigation::after {
    display: block;
    position: absolute;
    content: '​';
    height: 0;
    line-height: 0;
    z-index: 1;
    width: 200%;
    left: -50%;
    box-shadow: 0 0 6em 3em var(--kendo-color-surface, #fafafa);
}
.k-calendar-navigation::before {
    top: 0;
}
.k-calendar-navigation::after {
    bottom: 0;
}
.k-calendar-navigation .k-content,
.k-calendar-navigation .k-calendar-content {
    background: transparent;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.k-calendar-navigation .k-content ul,
.k-calendar-navigation .k-calendar-content ul {
    width: 5em;
}
.k-calendar-navigation .k-content li,
.k-calendar-navigation .k-calendar-content li {
    height: 2em;
    line-height: 2em;
    cursor: pointer;
    padding-block: 0;
    padding-inline: 1em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: clip;
}
.k-calendar-navigation .k-calendar-navigation-marker {
    font-weight: bold;
}
.k-calendar-navigation .k-calendar-navigation-highlight {
    width: 100%;
    border-width: 1px 0;
    border-style: solid;
    height: 2em;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.k-calendar-range {
    width: auto;
}
.k-calendar-range .k-calendar-view {
    width: auto;
    white-space: nowrap;
}
.k-calendar-range .k-calendar-view::after {
    display: none;
}
.k-calendar-range .k-calendar-view:focus {
    outline: 0;
}

.k-range-start {
    border-radius: var(--kendo-border-radius-lg, 0.375rem) 0 0 var(--kendo-border-radius-lg, 0.375rem);
}
.k-range-start .k-calendar-cell-inner,
.k-range-start .k-link {
    border-color: inherit;
    border-radius: inherit;
}

.k-range-end {
    border-radius: 0 var(--kendo-border-radius-lg, 0.375rem) var(--kendo-border-radius-lg, 0.375rem) 0;
}
.k-range-end .k-calendar-cell-inner,
.k-range-end .k-link {
    border-color: inherit;
    border-radius: inherit;
}

.k-range-mid {
    border-color: inherit;
    border-radius: 0;
}

.k-range-start.k-range-end {
    border-radius: var(--kendo-border-radius-lg, 0.375rem);
}

.k-rtl .k-calendar .k-content.k-scrollable,
.k-rtl .k-calendar .k-calendar-content.k-scrollable,
[dir='rtl'] .k-calendar .k-content.k-scrollable,
[dir='rtl'] .k-calendar .k-calendar-content.k-scrollable,
.k-calendar.k-rtl .k-content.k-scrollable,
.k-calendar.k-rtl .k-calendar-content.k-scrollable,
.k-calendar[dir='rtl'] .k-content.k-scrollable,
.k-calendar[dir='rtl'] .k-calendar-content.k-scrollable {
    padding-right: 100px;
    padding-left: 100px;
    margin-left: calc(-100px - var(--kendo-scrollbar-width, 17px));
    margin-right: -100px;
}
.k-rtl .k-calendar .k-range-start,
[dir='rtl'] .k-calendar .k-range-start,
.k-calendar.k-rtl .k-range-start,
.k-calendar[dir='rtl'] .k-range-start {
    border-radius: 0 var(--kendo-border-radius-lg, 0.375rem) var(--kendo-border-radius-lg, 0.375rem) 0;
}
.k-rtl .k-calendar .k-range-end,
[dir='rtl'] .k-calendar .k-range-end,
.k-calendar.k-rtl .k-range-end,
.k-calendar[dir='rtl'] .k-range-end {
    border-radius: var(--kendo-border-radius-lg, 0.375rem) 0 0 var(--kendo-border-radius-lg, 0.375rem);
}

.k-calendar {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    color: var(--kendo-color-on-app-surface, #3d3d3d);
    background-color: var(--kendo-color-surface-alt, #ffffff);
}
.k-calendar .k-header {
    border-color: inherit;
    color: var(--kendo-color-on-app-surface, #3d3d3d);
    background-color: var(--kendo-color-surface, #fafafa);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
}
.k-calendar .k-calendar-th,
.k-calendar .k-calendar-caption,
.k-calendar .k-meta-header,
.k-calendar .k-month-header {
    color: var(--kendo-color-subtle, #666666);
}
.k-calendar .k-calendar-view .k-today {
    color: var(--kendo-color-primary, rgb(44, 91, 204));
}
.k-calendar .k-other-month {
    color: var(--kendo-color-subtle, #666666);
}
.k-calendar .k-alt {
    background-color: transparent;
    color: var(--kendo-color-subtle, #666666);
}
.k-calendar .k-calendar-td:hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td:hover .k-link,
.k-calendar .k-calendar-td.k-hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-hover .k-link {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    color: var(--kendo-color-on-app-surface, #3d3d3d);
    background-color: var(--kendo-color-base-hover, #ebebeb);
}
.k-calendar .k-calendar-td.k-selected .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected .k-link {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    color: var(--kendo-color-on-primary, white);
    background-color: var(--kendo-color-primary, rgb(44, 91, 204));
}
.k-calendar .k-calendar-td.k-selected:hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected:hover .k-link,
.k-calendar .k-calendar-td.k-selected.k-hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected.k-hover .k-link {
    color: var(--kendo-color-on-primary, white);
    background-color: var(--kendo-color-primary-hover, #2a57c4);
}
.k-calendar .k-calendar-td:focus .k-calendar-cell-inner,
.k-calendar .k-calendar-td:focus .k-link,
.k-calendar .k-calendar-td.k-focus .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-focus .k-link {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-calendar .k-calendar-td.k-selected:focus .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected:focus .k-link,
.k-calendar .k-calendar-td.k-selected.k-focus .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected.k-focus .k-link {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-calendar .k-calendar-navigation {
    color: var(--kendo-color-on-app-surface, #3d3d3d);
    background-color: var(--kendo-color-surface, #fafafa);
    box-shadow: inset -1px 0 var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}
.k-calendar .k-calendar-navigation li:hover,
.k-calendar .k-calendar-navigation li.k-hover {
    color: var(--kendo-color-primary-hover, #2a57c4);
}
.k-calendar .k-calendar-navigation-highlight {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    color: var(--kendo-color-on-app-surface, #3d3d3d);
    background-color: var(--kendo-color-surface-alt, #ffffff);
}
.k-calendar[dir='rtl'] .k-calendar-navigation,
.k-rtl .k-calendar .k-calendar-navigation {
    box-shadow: inset 1px 0 var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}
.k-calendar.k-invalid,
.k-calendar.k-invalid:hover,
.k-calendar.ng-invalid.ng-touched,
.k-calendar.ng-invalid.ng-dirty {
    border-color: var(--kendo-color-error, #f31700);
}

.k-scheduler .k-calendar .k-other-month {
    color: var(--kendo-color-subtle, #666666);
    background-color: transparent;
}

.k-calendar .k-range-start,
.k-calendar .k-range-end,
.k-calendar .k-range-mid {
    background-color: color-mix(in srgb, var(--kendo-color-primary, rgb(44, 91, 204)) 25%, transparent);
}
.k-calendar .k-range-start .k-calendar-cell-inner,
.k-calendar .k-range-start .k-link,
.k-calendar .k-range-end .k-calendar-cell-inner,
.k-calendar .k-range-end .k-link {
    background-color: var(--kendo-color-primary, rgb(44, 91, 204));
}
.k-calendar .k-range-start.k-active .k-calendar-cell-inner,
.k-calendar .k-range-start.k-active .k-link,
.k-calendar .k-range-end.k-active .k-calendar-cell-inner,
.k-calendar .k-range-end.k-active .k-link {
    box-shadow: inset 0 0 2px 2px rgba(0, 0, 0, 0.2);
}
.k-calendar .k-range-split-start,
.k-calendar .k-range-split-end {
    position: relative;
}
.k-calendar .k-range-split-start::after,
.k-calendar .k-range-split-end::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 5px;
}
.k-calendar .k-range-split-start::after {
    left: -5px;
    right: auto;
    background-image: linear-gradient(
        to left,
        color-mix(in srgb, var(--kendo-color-primary, rgb(44, 91, 204)) 25%, transparent),
        transparent 100%
    );
}
.k-calendar .k-range-split-end::after {
    right: -5px;
    left: auto;
    background-image: linear-gradient(
        to right,
        color-mix(in srgb, var(--kendo-color-primary, rgb(44, 91, 204)) 25%, transparent),
        transparent 100%
    );
}

.k-pointer-events-none {
    pointer-events: none;
}

.\!k-pointer-events-none {
    pointer-events: none !important;
}

.k-pointer-events-auto {
    pointer-events: auto;
}

.\!k-pointer-events-auto {
    pointer-events: auto !important;
}

.k-timeselector {
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-family: var(--kendo-font-family, inherit);
    font-size: var(--kendo-font-size, inherit);
    line-height: var(--kendo-line-height, normal);
    position: relative;
    overflow: hidden;
    display: flex;
    flex-flow: column nowrap;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-popup > .k-timeselector {
    border-width: 0;
}

.k-time-header,
.k-time-selector-header {
    padding-block: var(--kendo-spacing-2, 0.5rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 auto;
}
.k-time-header .k-title,
.k-time-header .k-time-selector-header-title,
.k-time-selector-header .k-title,
.k-time-selector-header .k-time-selector-header-title {
    padding-block: var(--kendo-spacing-1, 0.25rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
    font-weight: bold;
    display: inline-block;
}
.k-time-header .k-time-now,
.k-time-selector-header .k-time-now {
    border-width: 0;
    line-height: inherit;
    cursor: pointer;
}

.k-actions.k-time-fast-selection,
.k-time-fast-selection.k-edit-buttons,
.k-time-fast-selection.k-action-buttons,
.k-time-fast-selection.k-columnmenu-actions,
.k-time-fast-selection.k-form-buttons {
    margin-top: 0;
}

.k-time-list-container {
    display: flex;
    position: relative;
    flex: 1 1 auto;
    overflow: hidden;
}

.k-time-highlight,
.k-time-list-highlight {
    width: 100%;
    height: calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal) + var(--kendo-spacing-1, 0.25rem) * 2);
    border-width: 1px 0px;
    border-style: solid;
    box-sizing: border-box;
    position: absolute;
    top: calc(50% + calc((var(--kendo-font-size-sm, inherit)) * (var(--kendo-line-height-lg, normal))) / 2);
    left: 0;
    right: 0;
    transform: translateY(-50%);
    z-index: 1;
}

.k-time-list-wrapper {
    min-width: 4em;
    height: 240px;
    box-sizing: content-box;
    display: inline-flex;
    flex-flow: column nowrap;
    align-items: stretch;
    overflow: hidden;
    position: relative;
    text-align: center;
    flex: 1 1 auto;
}
.k-time-list-wrapper .k-title {
    font-size: var(--kendo-font-size-sm, inherit);
    line-height: var(--kendo-line-height-lg, normal);
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;
    display: block;
}
.k-time-list-wrapper.k-focus::before,
.k-time-list-wrapper.k-focus::after {
    display: block;
    content: '​';
    position: absolute;
    width: 100%;
    left: 0;
    pointer-events: none;
    height: calc(50% - calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal) + var(--kendo-spacing-1, 0.25rem) * 2) / 2);
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
}
.k-time-list-wrapper.k-focus::before {
    top: calc((var(--kendo-font-size-sm, inherit)) * (var(--kendo-line-height-lg, normal)));
}
.k-time-list-wrapper.k-focus::after {
    bottom: 0;
}

.k-time-list {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    flex: 1;
    position: relative;
    z-index: 1;
    outline: 0;
    overflow: hidden;
}
.k-time-list::before,
.k-time-list::after {
    display: block;
    position: absolute;
    content: '​';
    height: 0;
    line-height: 0;
    z-index: 1;
    width: 200%;
    left: -50%;
}
.k-time-list::before {
    top: 0;
}
.k-time-list::after {
    bottom: 0;
}

.k-time-container,
.k-time-list-content {
    position: relative;
    flex: 1 1 auto;
    display: block;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-right: 100px;
    padding-left: 100px;
    margin-left: -100px;
    margin-right: calc(-100px - var(--kendo-scrollbar-width, 17px));
}
.k-time-container > ul,
.k-time-list-content > ul {
    height: auto;
    width: 4em;
    margin: auto;
}
.k-time-container .k-scrollable-placeholder,
.k-time-list-content .k-scrollable-placeholder {
    position: absolute;
    width: 1px;
    top: 0;
    right: 0;
}

.k-time-list-item,
.k-time-list .k-item {
    padding-block: var(--kendo-spacing-1, 0.25rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
}

.k-time-separator {
    width: 0;
    height: calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal) + var(--kendo-spacing-1, 0.25rem) * 2);
    align-self: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 11;
    top: calc(calc((var(--kendo-font-size-sm, inherit)) * (var(--kendo-line-height-lg, normal))) / 2);
}

.k-timeselector-sm {
    font-size: var(--kendo-font-size, inherit);
    line-height: var(--kendo-line-height, normal);
}
.k-timeselector-sm .k-time-highlight,
.k-timeselector-sm .k-time-list-highlight {
    height: calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal) + var(--kendo-spacing-0\.5, 0.125rem) * 2);
}
.k-timeselector-sm .k-time-separator {
    height: calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal) + var(--kendo-spacing-0\.5, 0.125rem) * 2);
}
.k-timeselector-sm .k-time-list-item,
.k-timeselector-sm .k-time-list .k-item {
    padding-block: var(--kendo-spacing-0\.5, 0.125rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
}

.k-timeselector-md {
    font-size: var(--kendo-font-size, inherit);
    line-height: var(--kendo-line-height, normal);
}
.k-timeselector-md .k-time-highlight,
.k-timeselector-md .k-time-list-highlight {
    height: calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal) + var(--kendo-spacing-1, 0.25rem) * 2);
}
.k-timeselector-md .k-time-separator {
    height: calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal) + var(--kendo-spacing-1, 0.25rem) * 2);
}
.k-timeselector-md .k-time-list-item,
.k-timeselector-md .k-time-list .k-item {
    padding-block: var(--kendo-spacing-1, 0.25rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
}

.k-timeselector-lg {
    font-size: var(--kendo-font-size-lg, inherit);
    line-height: var(--kendo-line-height-lg, normal);
}
.k-timeselector-lg .k-time-highlight,
.k-timeselector-lg .k-time-list-highlight {
    height: calc(var(--kendo-font-size-lg, inherit) * var(--kendo-line-height-lg, normal) + var(--kendo-spacing-2, 0.5rem) * 2);
}
.k-timeselector-lg .k-time-separator {
    height: calc(var(--kendo-font-size-lg, inherit) * var(--kendo-line-height-lg, normal) + var(--kendo-spacing-2, 0.5rem) * 2);
}
.k-timeselector-lg .k-time-list-item,
.k-timeselector-lg .k-time-list .k-item {
    padding-block: var(--kendo-spacing-2, 0.5rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
}

.k-rtl .k-time-container,
.k-rtl .k-time-list-content,
[dir='rtl'] .k-time-container,
[dir='rtl'] .k-time-list-content {
    padding-right: 100px;
    padding-left: 100px;
    margin-left: calc(-100px - var(--kendo-scrollbar-width, 17px));
    margin-right: -100px;
}

.k-timeselector {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    color: var(--kendo-color-on-app-surface, #3d3d3d);
    background-color: var(--kendo-color-surface-alt, #ffffff);
}

.k-time-header .k-time-now,
.k-time-selector-header .k-time-now {
    color: var(--kendo-color-primary, rgb(44, 91, 204));
}
.k-time-header .k-time-now:hover,
.k-time-selector-header .k-time-now:hover {
    color: var(--kendo-color-primary-hover, #2a57c4);
}

.k-time-list-wrapper .k-title {
    color: var(--kendo-color-subtle, #666666);
}
.k-time-list-wrapper.k-focus .k-title {
    color: var(--kendo-color-on-app-surface, #3d3d3d);
}
.k-time-list-wrapper.k-focus::before,
.k-time-list-wrapper.k-focus::after {
    background-color: rgba(0, 0, 0, 0.04);
}

.k-time-list::before,
.k-time-list::after {
    box-shadow: 0 0 3em 1.5em var(--kendo-color-surface-alt, #ffffff);
}
.k-time-list .k-item:hover {
    color: var(--kendo-color-primary, rgb(44, 91, 204));
}

.k-time-container {
    background: transparent;
}

.k-time-highlight {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    background-color: var(--kendo-color-surface-alt, #ffffff);
}

.k-datetime-selector {
    display: flex;
    transition: transform 0.2s;
}

.k-datetime-wrap {
    width: calc(32px * 8 + var(--kendo-spacing-4, 1rem) * 2);
    overflow: hidden;
}
.k-datetime-wrap .k-datetime-buttongroup {
    padding-block: var(--kendo-spacing-2, 0.5rem);
    padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-datetime-wrap .k-calendar,
.k-datetime-wrap .k-timeselector {
    width: 100%;
    border-width: 0;
}

.k-datetime-calendar-wrap,
.k-datetime-time-wrap {
    text-align: center;
    flex: 0 0 calc(32px * 8 + var(--kendo-spacing-4, 1rem) * 2);
}

.k-date-tab .k-datetime-selector {
    transform: translateX(0);
}

.k-time-tab .k-datetime-selector {
    transform: translateX(-100%);
}

.k-time-tab .k-datetime-selector.k-rtl,
.k-time-tab .k-datetime-selector[dir='rtl'],
.k-rtl .k-time-tab .k-datetime-selector,
[dir='rtl'] .k-time-tab .k-datetime-selector {
    transform: translateX(100%);
}

.k-chip {
    padding-block: var(--kendo-spacing-1, 0.25rem);
    padding-inline: var(--kendo-spacing-1, 0.25rem);
    border-width: 1px;
    border-style: solid;
    outline: 0;
    font-family: var(--kendo-font-family, inherit);
    font-size: var(--kendo-font-size, inherit);
    line-height: 1;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: var(--kendo-spacing-1, 0.25rem);
    position: relative;
    overflow: hidden;
    cursor: pointer;
    user-select: none;
}
.k-chip *,
.k-chip *::before,
.k-chip *::after {
    box-sizing: border-box;
}
.k-chip .k-selected-icon-wrapper {
    display: none !important;
}

.k-chip-content {
    padding-block: 0.5em;
    padding-inline: 0;
    margin-block: -0.5em;
    margin-inline: 0;
    min-width: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    overflow: hidden;
    flex: 1 1 auto;
}
.k-chip-content .k-icon-wrapper-host {
    display: initial;
}

.k-chip-content:first-child {
    margin-inline-start: var(--kendo-spacing-1, 0.25rem);
}

.k-chip-content:last-child {
    margin-inline-end: var(--kendo-spacing-1, 0.25rem);
}

.k-chip-text,
.k-chip-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1 1 auto;
}

.k-chip-avatar {
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    flex: none;
}

.k-chip-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
}

.k-chip-actions {
    margin-block: -5em;
    margin-inline: 0;
    flex: none;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    align-self: center;
}

.k-chip-action {
    flex: none;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    align-self: center;
}

.k-remove-icon {
    font-size: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
}

.k-chip-list {
    min-width: 0px;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
}

.k-chip-sm {
    padding-block: var(--kendo-spacing-0\.5, 0.125rem);
    padding-inline: var(--kendo-spacing-1, 0.25rem);
    font-size: var(--kendo-font-size, inherit);
    line-height: 1;
}
.k-chip-sm .k-chip-action {
    padding: var(--kendo-spacing-0\.5, 0.125rem);
}

.k-chip-avatar {
    width: 1em;
    height: 1em;
    flex-basis: 1em;
}

.k-chip-md {
    padding-block: var(--kendo-spacing-1, 0.25rem);
    padding-inline: var(--kendo-spacing-1, 0.25rem);
    font-size: var(--kendo-font-size, inherit);
    line-height: 1;
}
.k-chip-md .k-chip-action {
    padding: var(--kendo-spacing-1, 0.25rem);
}

.k-chip-avatar {
    width: 1em;
    height: 1em;
    flex-basis: 1em;
}

.k-chip-lg {
    padding-block: var(--kendo-spacing-1\.5, 0.375rem);
    padding-inline: var(--kendo-spacing-1, 0.25rem);
    font-size: var(--kendo-font-size, inherit);
    line-height: 1;
}
.k-chip-lg .k-chip-action {
    padding: var(--kendo-spacing-1\.5, 0.375rem);
}

.k-chip-avatar {
    width: 1em;
    height: 1em;
    flex-basis: 1em;
}

.k-chip-list-sm {
    gap: var(--kendo-spacing-1, 0.25rem);
}

.k-chip-list-md {
    gap: var(--kendo-spacing-1, 0.25rem);
}

.k-chip-list-lg {
    gap: var(--kendo-spacing-1, 0.25rem);
}

.k-chip-solid-base {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    color: var(--kendo-color-on-base, #3d3d3d);
    background-color: var(--kendo-color-base-subtle, #ebebeb);
}
.k-chip-solid-base:focus,
.k-chip-solid-base.k-focus {
    box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-chip-solid-base:hover,
.k-chip-solid-base.k-hover {
    background-color: var(--kendo-color-base-subtle-hover, #e0e0e0);
}
.k-chip-solid-base.k-selected {
    background-color: var(--kendo-color-base-active, #d6d6d6);
}

.k-chip-solid-error {
    border-color: var(--kendo-color-error-emphasis, #f76f60);
    color: var(--kendo-color-error-on-subtle, #7a0c00);
    background-color: var(--kendo-color-error-subtle, #fcddda);
}
.k-chip-solid-error:focus,
.k-chip-solid-error.k-focus {
    box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-error, #f31700) 16%, transparent);
}
.k-chip-solid-error:hover,
.k-chip-solid-error.k-hover {
    background-color: var(--kendo-color-error-subtle-hover, #fbc8c3);
}
.k-chip-solid-error.k-selected {
    background-color: var(--kendo-color-error-subtle-active, #f98b80);
}

.k-chip-solid-info {
    border-color: var(--kendo-color-info-emphasis, #6098f2);
    color: var(--kendo-color-info-on-subtle, #002259);
    background-color: var(--kendo-color-info-subtle, #d2e2fb);
}
.k-chip-solid-info:focus,
.k-chip-solid-info.k-focus {
    box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-info, #0058e9) 16%, transparent);
}
.k-chip-solid-info:hover,
.k-chip-solid-info.k-hover {
    background-color: var(--kendo-color-info-subtle-hover, #bdd4f8);
}
.k-chip-solid-info.k-selected {
    background-color: var(--kendo-color-info-subtle-active, #80acf4);
}

.k-chip-solid-warning {
    border-color: var(--kendo-color-warning-emphasis, #ffd760);
    color: var(--kendo-color-warning-on-subtle, #5e4700);
    background-color: var(--kendo-color-warning-subtle, #fff4d3);
}
.k-chip-solid-warning:focus,
.k-chip-solid-warning.k-focus {
    box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-warning, #ffc000) 16%, transparent);
}
.k-chip-solid-warning:hover,
.k-chip-solid-warning.k-hover {
    background-color: var(--kendo-color-warning-subtle-hover, #ffeebd);
}
.k-chip-solid-warning.k-selected {
    background-color: var(--kendo-color-warning-subtle-active, #ffe79e);
}

.k-chip-solid-success {
    border-color: var(--kendo-color-success-emphasis, #81d15f);
    color: var(--kendo-color-success-on-subtle, #1c5a00);
    background-color: var(--kendo-color-success-subtle, #dcf0d3);
}
.k-chip-solid-success:focus,
.k-chip-solid-success.k-focus {
    box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-success, #37b400) 16%, transparent);
}
.k-chip-solid-success:hover,
.k-chip-solid-success.k-hover {
    background-color: var(--kendo-color-success-subtle-hover, #cbe9bf);
}
.k-chip-solid-success.k-selected {
    background-color: var(--kendo-color-success-subtle-active, #b7e1a5);
}

.k-chip-outline-base {
    border-color: var(--kendo-color-base-on-surface, #3d3d3d);
    color: var(--kendo-color-base-on-surface, #3d3d3d);
    background-color: transparent;
}
.k-chip-outline-base:focus,
.k-chip-outline-base.k-focus {
    box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-chip-outline-base:hover,
.k-chip-outline-base.k-hover {
    color: var(--kendo-color-base, #f5f5f5);
    background-color: var(--kendo-color-base-on-surface, #3d3d3d);
}
.k-chip-outline-base.k-selected {
    color: var(--kendo-color-base, #f5f5f5);
    background-color: var(--kendo-color-base-on-surface, #3d3d3d);
}

.k-chip-outline-error {
    border-color: var(--kendo-color-error-on-surface, #ca1400);
    color: var(--kendo-color-error-on-surface, #ca1400);
    background-color: transparent;
}
.k-chip-outline-error:focus,
.k-chip-outline-error.k-focus {
    box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-error, #f31700) 16%, transparent);
}
.k-chip-outline-error:hover,
.k-chip-outline-error.k-hover {
    border-color: var(--kendo-color-error-hover, #df1600);
    color: var(--kendo-color-on-error, #ffffff);
    background-color: var(--kendo-color-error-hover, #df1600);
}
.k-chip-outline-error.k-selected {
    border-color: var(--kendo-color-error-active, #ca1400);
    color: var(--kendo-color-on-error, #ffffff);
    background-color: var(--kendo-color-error-active, #ca1400);
}

.k-chip-outline-info {
    border-color: var(--kendo-color-info-on-surface, #004ac2);
    color: var(--kendo-color-info-on-surface, #004ac2);
    background-color: transparent;
}
.k-chip-outline-info:focus,
.k-chip-outline-info.k-focus {
    box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-info, #0058e9) 16%, transparent);
}
.k-chip-outline-info:hover,
.k-chip-outline-info.k-hover {
    border-color: var(--kendo-color-info-hover, #0052d6);
    color: var(--kendo-color-on-info, #ffffff);
    background-color: var(--kendo-color-info-hover, #0052d6);
}
.k-chip-outline-info.k-selected {
    border-color: var(--kendo-color-info-active, #004ac2);
    color: var(--kendo-color-on-info, #ffffff);
    background-color: var(--kendo-color-info-active, #004ac2);
}

.k-chip-outline-warning {
    border-color: var(--kendo-color-warning, #ffc000);
    color: var(--kendo-color-on-app-surface, #3d3d3d);
    background-color: transparent;
}
.k-chip-outline-warning:focus,
.k-chip-outline-warning.k-focus {
    box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-warning, #ffc000) 16%, transparent);
}
.k-chip-outline-warning:hover,
.k-chip-outline-warning.k-hover {
    color: var(--kendo-color-on-warning, #3d3d3d);
    background-color: var(--kendo-color-warning, #ffc000);
}
.k-chip-outline-warning.k-selected {
    color: var(--kendo-color-on-warning, #3d3d3d);
    background-color: var(--kendo-color-warning, #ffc000);
}

.k-chip-outline-success {
    border-color: var(--kendo-color-success-on-surface, #2d9600);
    color: var(--kendo-color-success-on-surface, #2d9600);
    background-color: transparent;
}
.k-chip-outline-success:focus,
.k-chip-outline-success.k-focus {
    box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-success, #37b400) 16%, transparent);
}
.k-chip-outline-success:hover,
.k-chip-outline-success.k-hover {
    border-color: var(--kendo-color-success-hover, #32a500);
    color: var(--kendo-color-on-success, #ffffff);
    background-color: var(--kendo-color-success-hover, #32a500);
}
.k-chip-outline-success.k-selected {
    border-color: var(--kendo-color-success-active, #2d9600);
    color: var(--kendo-color-on-success, #ffffff);
    background-color: var(--kendo-color-success-active, #2d9600);
}

.k-switch {
    box-sizing: border-box;
    outline: 0;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    line-height: 1;
    position: relative;
    cursor: pointer;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-switch *,
.k-switch *::before,
.k-switch *::after {
    box-sizing: border-box;
}
.k-switch [type='checkbox'] {
    display: none;
}
.k-switch[aria-readonly='true'] {
    pointer-events: none;
}

.k-switch.k-readonly {
    pointer-events: none;
}

.k-switch-track {
    border-width: 1px;
    border-style: solid;
    outline: 0;
    display: flex;
    flex-flow: row nowrap;
    flex: 1 0 auto;
    position: relative;
    transition: background-color 200ms ease-in-out;
}

.k-switch-thumb-wrap {
    width: 0;
    height: 0;
    overflow: visible;
    position: absolute;
    transition: left 200ms ease-in-out;
    top: 50%;
}

.k-switch-thumb {
    border-width: 1px;
    border-style: solid;
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
}

.k-switch-label-on,
.k-switch-label-off {
    text-transform: uppercase;
    display: inline;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    overflow: hidden;
}

.k-switch-sm {
    width: 52px;
    height: 26px;
    font-size: 10px;
}
.k-switch-sm .k-switch-track {
    width: 52px;
    height: 26px;
}
.k-switch-sm .k-switch-label-on {
    left: 4px;
}
.k-switch-sm .k-switch-label-off {
    right: 4px;
}
.k-switch-sm .k-switch-thumb {
    width: 26px;
    height: 26px;
}
.k-switch-sm.k-switch-on .k-switch-thumb-wrap {
    left: calc(100% - 13px - 0px);
}
.k-switch-sm.k-switch-off .k-switch-thumb-wrap {
    left: calc(13px + 0px);
}

.k-switch-sm[dir='rtl'] .k-switch-label-on,
[dir='rtl'] .k-switch-sm .k-switch-label-on,
.k-rtl .k-switch-sm .k-switch-label-on {
    left: auto;
    right: 4px;
}
.k-switch-sm[dir='rtl'] .k-switch-label-off,
[dir='rtl'] .k-switch-sm .k-switch-label-off,
.k-rtl .k-switch-sm .k-switch-label-off {
    right: auto;
    left: 4px;
}
.k-switch-sm[dir='rtl'].k-switch-on .k-switch-thumb-wrap,
[dir='rtl'] .k-switch-sm.k-switch-on .k-switch-thumb-wrap,
.k-rtl .k-switch-sm.k-switch-on .k-switch-thumb-wrap {
    left: calc(13px + 0px);
}
.k-switch-sm[dir='rtl'].k-switch-off .k-switch-thumb-wrap,
[dir='rtl'] .k-switch-sm.k-switch-off .k-switch-thumb-wrap,
.k-rtl .k-switch-sm.k-switch-off .k-switch-thumb-wrap {
    left: calc(100% - 13px - 0px);
}

.k-switch-md {
    width: 60px;
    height: 30px;
    font-size: 10px;
}
.k-switch-md .k-switch-track {
    width: 60px;
    height: 30px;
}
.k-switch-md .k-switch-label-on {
    left: 6px;
}
.k-switch-md .k-switch-label-off {
    right: 6px;
}
.k-switch-md .k-switch-thumb {
    width: 30px;
    height: 30px;
}
.k-switch-md.k-switch-on .k-switch-thumb-wrap {
    left: calc(100% - 15px - 0px);
}
.k-switch-md.k-switch-off .k-switch-thumb-wrap {
    left: calc(15px + 0px);
}

.k-switch-md[dir='rtl'] .k-switch-label-on,
[dir='rtl'] .k-switch-md .k-switch-label-on,
.k-rtl .k-switch-md .k-switch-label-on {
    left: auto;
    right: 6px;
}
.k-switch-md[dir='rtl'] .k-switch-label-off,
[dir='rtl'] .k-switch-md .k-switch-label-off,
.k-rtl .k-switch-md .k-switch-label-off {
    right: auto;
    left: 6px;
}
.k-switch-md[dir='rtl'].k-switch-on .k-switch-thumb-wrap,
[dir='rtl'] .k-switch-md.k-switch-on .k-switch-thumb-wrap,
.k-rtl .k-switch-md.k-switch-on .k-switch-thumb-wrap {
    left: calc(15px + 0px);
}
.k-switch-md[dir='rtl'].k-switch-off .k-switch-thumb-wrap,
[dir='rtl'] .k-switch-md.k-switch-off .k-switch-thumb-wrap,
.k-rtl .k-switch-md.k-switch-off .k-switch-thumb-wrap {
    left: calc(100% - 15px - 0px);
}

.k-switch-lg {
    width: 68px;
    height: 34px;
    font-size: 10px;
}
.k-switch-lg .k-switch-track {
    width: 68px;
    height: 34px;
}
.k-switch-lg .k-switch-label-on {
    left: 8px;
}
.k-switch-lg .k-switch-label-off {
    right: 8px;
}
.k-switch-lg .k-switch-thumb {
    width: 34px;
    height: 34px;
}
.k-switch-lg.k-switch-on .k-switch-thumb-wrap {
    left: calc(100% - 17px - 0px);
}
.k-switch-lg.k-switch-off .k-switch-thumb-wrap {
    left: calc(17px + 0px);
}

.k-switch-lg[dir='rtl'] .k-switch-label-on,
[dir='rtl'] .k-switch-lg .k-switch-label-on,
.k-rtl .k-switch-lg .k-switch-label-on {
    left: auto;
    right: 8px;
}
.k-switch-lg[dir='rtl'] .k-switch-label-off,
[dir='rtl'] .k-switch-lg .k-switch-label-off,
.k-rtl .k-switch-lg .k-switch-label-off {
    right: auto;
    left: 8px;
}
.k-switch-lg[dir='rtl'].k-switch-on .k-switch-thumb-wrap,
[dir='rtl'] .k-switch-lg.k-switch-on .k-switch-thumb-wrap,
.k-rtl .k-switch-lg.k-switch-on .k-switch-thumb-wrap {
    left: calc(17px + 0px);
}
.k-switch-lg[dir='rtl'].k-switch-off .k-switch-thumb-wrap,
[dir='rtl'] .k-switch-lg.k-switch-off .k-switch-thumb-wrap,
.k-rtl .k-switch-lg.k-switch-off .k-switch-thumb-wrap {
    left: calc(100% - 17px - 0px);
}

.k-switch[dir='rtl'] .k-switch-thumb,
[dir='rtl'] .k-switch .k-switch-thumb,
.k-switch.k-rtl .k-switch-thumb,
.k-rtl .k-switch .k-switch-thumb {
    transform: translate(50%, -50%);
}

.k-switch-off .k-switch-track {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    color: var(--kendo-color-on-app-surface, #3d3d3d);
    background-color: var(--kendo-color-surface-alt, #ffffff);
}
.k-switch-off .k-switch-thumb {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    color: var(--kendo-color-on-app-surface, #3d3d3d);
    background-color: var(--kendo-color-surface, #fafafa);
}
.k-switch-off:focus .k-switch-track,
.k-switch-off.k-focus .k-switch-track {
    outline: 2px solid color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-switch-off .k-switch-label-on {
    color: transparent;
}

.k-switch-on .k-switch-track {
    border-color: var(--kendo-color-primary, rgb(44, 91, 204));
    color: var(--kendo-color-on-primary, white);
    background-color: var(--kendo-color-primary, rgb(44, 91, 204));
}
.k-switch-on .k-switch-thumb {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    color: var(--kendo-color-on-app-surface, #3d3d3d);
    background-color: var(--kendo-color-surface, #fafafa);
}
.k-switch-on:focus .k-switch-track,
.k-switch-on.k-focus .k-switch-track {
    outline: 2px solid color-mix(in srgb, var(--kendo-color-primary, rgb(44, 91, 204)) 25%, transparent);
}
.k-switch-on .k-switch-label-off {
    color: transparent;
}

.k-expander {
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    overflow: hidden;
    outline: 0;
    font-family: var(--kendo-font-family, inherit);
    font-size: var(--kendo-font-size, inherit);
    line-height: var(--kendo-line-height, normal);
    display: flex;
    flex-direction: column;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-expander *,
.k-expander *::before,
.k-expander *::after {
    box-sizing: border-box;
}
.k-rtl .k-expander .k-expander-indicator,
.k-expander.k-rtl .k-expander-indicator,
.k-expander[dir='rtl'] .k-expander-indicator {
    margin-left: 0;
    margin-right: var(--kendo-spacing-3, 0.75rem);
}

.k-expander-header {
    padding-block: var(--kendo-spacing-3, 0.75rem);
    padding-inline: var(--kendo-spacing-4, 1rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.k-expander-spacer {
    flex: 1 1 auto;
}

.k-expander-title {
    text-transform: uppercase;
    font-weight: 500;
}

.k-expander-indicator {
    margin-left: var(--kendo-spacing-3, 0.75rem);
    display: flex;
    justify-content: center;
    align-items: center;
}

.k-expander-content {
    padding-block: var(--kendo-spacing-4, 1rem);
    padding-inline: var(--kendo-spacing-4, 1rem);
}

.k-expander + .k-expander.k-expanded,
.k-expander.k-expanded + .k-expander {
    margin-top: var(--kendo-spacing-2, 0.5rem);
}

.k-expander:not(.k-expanded) + .k-expander:not(.k-expanded) {
    border-top-width: 0;
}

.k-expander {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    color: var(--kendo-color-on-app-surface, #3d3d3d);
    background-color: var(--kendo-color-surface-alt, #ffffff);
}
.k-expander.k-focus {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}

.k-expander-header {
    color: var(--kendo-color-on-app-surface, #3d3d3d);
    background-color: transparent;
    outline: none;
}
.k-expander-header:hover,
.k-expander-header.k-hover {
    background-color: rgba(0, 0, 0, 0.04);
}
.k-expander-header.k-focus {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}

.k-expander-title {
    color: var(--kendo-color-primary, rgb(44, 91, 204));
}

.k-expander-sub-title {
    color: var(--kendo-color-subtle, #666666);
}

.k-panelbar {
    margin: 0;
    padding: 0;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-family: var(--kendo-font-family, inherit);
    font-size: var(--kendo-font-size, inherit);
    line-height: var(--kendo-line-height, normal);
    list-style: none;
    display: block;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-panelbar *,
.k-panelbar *::before,
.k-panelbar *::after {
    box-sizing: border-box;
}
.k-panelbar > .k-panelbar-header {
    border-width: 0;
    border-style: solid;
    border-color: inherit;
    display: block;
}
.k-panelbar > .k-panelbar-header > .k-link {
    padding-block: var(--kendo-spacing-3, 0.75rem);
    padding-inline: var(--kendo-spacing-4, 1rem);
    color: inherit;
    background: none;
    text-decoration: none;
    font-weight: 500;
    display: flex;
    flex-flow: row nowrap;
    gap: var(--kendo-spacing-1, 0.25rem);
    align-items: center;
    align-content: center;
    position: relative;
    user-select: none;
    cursor: default;
    transition:
        color 0.2s ease-in-out,
        background-color 0.2s ease-in-out,
        border-color 0.2s ease-in-out,
        box-shadow 0.2s ease-in-out;
}
.k-panelbar > .k-panelbar-header + .k-panelbar-header {
    border-top-width: 1px;
}
.k-panelbar .k-panelbar-group {
    margin: 0;
    padding: 0;
    border-width: 0;
    border-color: inherit;
    color: inherit;
    background-color: transparent;
    list-style: none;
}
.k-panelbar .k-panelbar-group > .k-panelbar-item {
    display: block;
}
.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link {
    padding-block: var(--kendo-spacing-2, 0.5rem);
    padding-inline: var(--kendo-spacing-4, 1rem);
    color: inherit;
    text-decoration: none;
    display: flex;
    flex-flow: row nowrap;
    gap: var(--kendo-spacing-1, 0.25rem);
    align-items: center;
    align-content: center;
    position: relative;
    user-select: none;
    cursor: default;
    transition:
        color 0.2s ease-in-out,
        background-color 0.2s ease-in-out,
        border-color 0.2s ease-in-out,
        box-shadow 0.2s ease-in-out;
}
.k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-1 .k-link {
    padding-left: calc(var(--kendo-spacing-4, 1rem) * 1);
}
.k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-2 .k-link {
    padding-left: calc(var(--kendo-spacing-4, 1rem) * 2);
}
.k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-3 .k-link {
    padding-left: calc(var(--kendo-spacing-4, 1rem) * 3);
}
.k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-4 .k-link {
    padding-left: calc(var(--kendo-spacing-4, 1rem) * 4);
}
.k-panelbar .k-panelbar-item {
    outline-style: none;
}
.k-panelbar .k-panelbar-content {
    display: flow-root;
}
.k-panelbar .k-panelbar-expand,
.k-panelbar .k-panelbar-collapse,
.k-panelbar .k-panelbar-toggle {
    margin-inline-start: auto;
}
.k-panelbar .k-panelbar-group .k-panelbar-expand,
.k-panelbar .k-panelbar-group .k-panelbar-collapse,
.k-panelbar .k-panelbar-group .k-panelbar-toggle {
    margin-inline-end: calc(var(--kendo-spacing-4, 1rem) - var(--kendo-spacing-4, 1rem));
}
.k-rtl .k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-1 .k-link,
.k-panelbar.k-rtl .k-panelbar-group > .k-panelbar-item.k-level-1 .k-link,
.k-panelbar[dir='rtl'] .k-panelbar-group > .k-panelbar-item.k-level-1 .k-link {
    padding-left: var(--kendo-spacing-4, 1rem);
    padding-right: calc(var(--kendo-spacing-4, 1rem) * 1);
}
.k-rtl .k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-2 .k-link,
.k-panelbar.k-rtl .k-panelbar-group > .k-panelbar-item.k-level-2 .k-link,
.k-panelbar[dir='rtl'] .k-panelbar-group > .k-panelbar-item.k-level-2 .k-link {
    padding-left: var(--kendo-spacing-4, 1rem);
    padding-right: calc(var(--kendo-spacing-4, 1rem) * 2);
}
.k-rtl .k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-3 .k-link,
.k-panelbar.k-rtl .k-panelbar-group > .k-panelbar-item.k-level-3 .k-link,
.k-panelbar[dir='rtl'] .k-panelbar-group > .k-panelbar-item.k-level-3 .k-link {
    padding-left: var(--kendo-spacing-4, 1rem);
    padding-right: calc(var(--kendo-spacing-4, 1rem) * 3);
}
.k-rtl .k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-4 .k-link,
.k-panelbar.k-rtl .k-panelbar-group > .k-panelbar-item.k-level-4 .k-link,
.k-panelbar[dir='rtl'] .k-panelbar-group > .k-panelbar-item.k-level-4 .k-link {
    padding-left: var(--kendo-spacing-4, 1rem);
    padding-right: calc(var(--kendo-spacing-4, 1rem) * 4);
}

.k-panelbar {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    color: var(--kendo-color-on-app-surface, #3d3d3d);
    background-color: var(--kendo-color-surface-alt, #ffffff);
}
.k-panelbar > .k-panelbar-header > .k-link {
    color: var(--kendo-color-primary, rgb(44, 91, 204));
    background-color: var(--kendo-color-surface-alt, #ffffff);
}
.k-panelbar > .k-panelbar-header > .k-link .k-icon,
.k-panelbar > .k-panelbar-header > .k-link .k-panelbar-item-icon {
    color: var(--kendo-color-on-app-surface, #3d3d3d);
}
.k-panelbar > .k-panelbar-header > .k-link:hover,
.k-panelbar > .k-panelbar-header > .k-link.k-hover {
    background-color: var(--kendo-color-base-hover, #ebebeb);
}
.k-panelbar > .k-panelbar-header > .k-link:focus,
.k-panelbar > .k-panelbar-header > .k-link.k-focus {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}
.k-panelbar > .k-panelbar-header > .k-link.k-selected {
    color: var(--kendo-color-on-primary, white);
    background-color: var(--kendo-color-primary, rgb(44, 91, 204));
}
.k-panelbar > .k-panelbar-header > .k-link.k-selected .k-icon,
.k-panelbar > .k-panelbar-header > .k-link.k-selected .k-panelbar-item-icon {
    color: inherit;
}
.k-panelbar > .k-panelbar-header > .k-link.k-selected:hover,
.k-panelbar > .k-panelbar-header > .k-link.k-selected.k-hover {
    background-color: var(--kendo-color-primary-hover, #2a57c4);
}
.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link:hover,
.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link.k-hover {
    background-color: var(--kendo-color-base-hover, #ebebeb);
}
.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link:focus,
.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link.k-focus {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}
.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link.k-selected {
    color: var(--kendo-color-on-primary, white);
    background-color: var(--kendo-color-primary, rgb(44, 91, 204));
}
.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link.k-selected:hover,
.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link.k-selected.k-hover {
    background-color: var(--kendo-color-primary-hover, #2a57c4);
}
.k-dropdown-operator {
    width: min-content;
    flex: none;
}
.k-dropdown-operator .k-input-button {
    border-width: 0;
    width: min-content;
}
.k-dropdown-operator .k-input-button .k-button-icon {
    width: auto;
    aspect-ratio: 1;
}
.k-dropdown-operator .k-input-inner {
    display: none;
}

.k-pager {
    padding: 0;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-family: var(--kendo-font-family, inherit);
    font-size: var(--kendo-font-size, inherit);
    line-height: var(--kendo-line-height, normal);
    white-space: nowrap;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    cursor: default;
    flex: 0 0 auto;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-pager *,
.k-pager *::before,
.k-pager *::after {
    box-sizing: border-box;
}

.k-pager-info,
.k-pager-sizes,
.k-pager-input {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.k-pager-nav {
    color: inherit;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.k-pager-nav:hover {
    z-index: 2;
}
.k-disabled.k-pager-nav {
    color: inherit;
}

.k-pager-numbers-wrap {
    display: flex;
    flex-direction: row;
    position: relative;
}
.k-pager-numbers-wrap select.k-dropdown-list,
.k-pager-numbers-wrap select.k-dropdown,
.k-pager-numbers-wrap select.k-dropdownlist {
    width: 5em;
}

.k-pager-numbers {
    display: flex;
    flex-direction: row;
}
.k-pager-numbers .k-selected {
    cursor: inherit;
    z-index: 2;
}

.k-pager-input {
    gap: 1ex;
}
.k-pager-input .k-textbox,
.k-pager-input .k-numerictextbox {
    margin-block: 0;
    width: 5em;
}

.k-pager-sizes {
    gap: 1ex;
}
.k-pager-sizes .k-input-inner,
.k-pager-sizes .k-input-value-text {
    text-overflow: clip;
}

.k-pager-info {
    text-align: end;
    justify-content: flex-end;
    flex: 1 1 0%;
    order: 9;
}

.k-pager-refresh {
    margin-inline-start: auto;
    color: inherit;
    order: 10;
}

.k-pager-sm {
    padding-inline: var(--kendo-spacing-1, 0.25rem);
    padding-block: var(--kendo-spacing-1, 0.25rem);
    gap: var(--kendo-spacing-3, 0.75rem);
}
.k-pager-sm .k-pager-numbers-wrap .k-button {
    min-width: calc(var(--kendo-line-height, normal) * 1em + var(--kendo-spacing-0\.5, 0.125rem) * 2 + 2px);
}
.k-pager-sm .k-pager-numbers-wrap .k-pager-input,
.k-pager-sm .k-pager-numbers-wrap > select.k-dropdown-list,
.k-pager-sm .k-pager-numbers-wrap > select.k-dropdown,
.k-pager-sm .k-pager-numbers-wrap > select.k-dropdownlist {
    margin-inline-start: var(--kendo-spacing-3, 0.75rem);
    margin-inline-end: var(--kendo-spacing-3, 0.75rem);
}
.k-pager-sm .k-pager-sizes .k-dropdown-list,
.k-pager-sm .k-pager-sizes .k-dropdown,
.k-pager-sm .k-pager-sizes .k-dropdownlist,
.k-pager-sm .k-pager-sizes > select {
    width: 5em;
}

.k-pager-md {
    padding-inline: var(--kendo-spacing-2, 0.5rem);
    padding-block: var(--kendo-spacing-2, 0.5rem);
    gap: var(--kendo-spacing-3\.5, 0.875rem);
}
.k-pager-md .k-pager-numbers-wrap .k-button {
    min-width: calc(var(--kendo-line-height, normal) * 1em + var(--kendo-spacing-1, 0.25rem) * 2 + 2px);
}
.k-pager-md .k-pager-numbers-wrap .k-pager-input,
.k-pager-md .k-pager-numbers-wrap > select.k-dropdown-list,
.k-pager-md .k-pager-numbers-wrap > select.k-dropdown,
.k-pager-md .k-pager-numbers-wrap > select.k-dropdownlist {
    margin-inline-start: var(--kendo-spacing-3\.5, 0.875rem);
    margin-inline-end: var(--kendo-spacing-3\.5, 0.875rem);
}
.k-pager-md .k-pager-sizes .k-dropdown-list,
.k-pager-md .k-pager-sizes .k-dropdown,
.k-pager-md .k-pager-sizes .k-dropdownlist,
.k-pager-md .k-pager-sizes > select {
    width: 5em;
}

.k-pager-lg {
    padding-inline: var(--kendo-spacing-2\.5, 0.625rem);
    padding-block: var(--kendo-spacing-2\.5, 0.625rem);
    gap: var(--kendo-spacing-4, 1rem);
}
.k-pager-lg .k-pager-numbers-wrap .k-button {
    min-width: calc(var(--kendo-line-height-lg, normal) * 1em + var(--kendo-spacing-2, 0.5rem) * 2 + 2px);
}
.k-pager-lg .k-pager-numbers-wrap .k-pager-input,
.k-pager-lg .k-pager-numbers-wrap > select.k-dropdown-list,
.k-pager-lg .k-pager-numbers-wrap > select.k-dropdown,
.k-pager-lg .k-pager-numbers-wrap > select.k-dropdownlist {
    margin-inline-start: var(--kendo-spacing-4, 1rem);
    margin-inline-end: var(--kendo-spacing-4, 1rem);
}
.k-pager-lg .k-pager-sizes .k-dropdown-list,
.k-pager-lg .k-pager-sizes .k-dropdown,
.k-pager-lg .k-pager-sizes .k-dropdownlist,
.k-pager-lg .k-pager-sizes > select {
    width: 5em;
}

.k-pager {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    color: var(--kendo-color-on-app-surface, #3d3d3d);
    background-color: var(--kendo-color-surface, #fafafa);
}
.k-pager:focus,
.k-pager.k-focus {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.13);
}

.k-listview {
    margin: 0;
    padding: 0;
    border-width: 0;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-family: var(--kendo-font-family, inherit);
    font-size: var(--kendo-font-size, inherit);
    line-height: var(--kendo-line-height, normal);
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
}

.k-listview-bordered {
    border-width: 1px;
}

.k-listview-header {
    border-width: 0;
    border-style: solid;
    border-color: inherit;
}

.k-listview-bordered > .k-listview-header {
    border-bottom-width: 1px;
}

.k-listview-content {
    margin: 0;
    padding: 0;
    outline: 0;
    flex-grow: 1;
    position: relative;
    overflow: auto;
}
.k-listview-content::after {
    height: 0;
    clear: both;
    display: block;
}

.k-listview-content.k-d-flex::after,
.k-listview-content.k-d-flex-row::after,
.k-listview-content.k-d-flex-col::after,
.k-listview-content.k-d-grid::after {
    display: none;
}

.k-listview-content-padding-rectangle {
    padding-block: var(--kendo-spacing-1, 0.25rem);
    padding-inline: var(--kendo-spacing-1, 0.25rem);
}

.k-listview-content-padding-square {
    padding: var(--kendo-spacing-1, 0.25rem);
}

.k-listview-item {
    padding: 0;
    border-width: 0;
    outline: 0;
    border-style: solid;
    box-sizing: border-box;
    flex-shrink: 0;
}

.k-listview-item-padding-rectangle {
    padding-block: var(--kendo-spacing-1, 0.25rem);
    padding-inline: var(--kendo-spacing-1, 0.25rem);
}

.k-listview-item-padding-rectangle .k-listview-link {
    margin-block: -var(--kendo-spacing-1, 0.25rem);
    margin-inline: -var(--kendo-spacing-1, 0.25rem);
    padding-block: var(--kendo-spacing-1, 0.25rem);
    padding-inline: var(--kendo-spacing-1, 0.25rem);
}

.k-listview-item-padding-square {
    padding: var(--kendo-spacing-1, 0.25rem);
}

.k-listview-item-padding-square .k-listview-link {
    margin: -var(--kendo-spacing-1, 0.25rem);
    padding: var(--kendo-spacing-1, 0.25rem);
}

.k-listview-footer {
    border-width: 0;
    border-style: solid;
    border-color: inherit;
}

.k-listview-bordered > .k-listview-footer {
    border-top-width: 1px;
}

.k-listview-pager {
    border-width: 0;
    border-color: inherit;
}

.k-listview-bordered > .k-listview-pager-top {
    border-block-end-width: var(--kendo-listview-border-width, 1px);
}

.k-listview-bordered > .k-listview-pager-bottom {
    border-block-start-width: var(--kendo-listview-border-width, 1px);
}

.k-listview-borders-all .k-listview-item {
    border-width: 1px;
}

.k-listview-borders-horizontal .k-listview-item {
    border-top-width: 1px;
}
.k-listview-borders-horizontal .k-listview-item:first-child {
    border-top-width: 0;
}

.k-listview-borders-vertical .k-listview-item {
    border-left-width: 1px;
}
.k-listview-borders-vertical .k-listview-item:first-child {
    border-left-width: 0;
}

.k-listview {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
    color: var(--kendo-color-on-app-surface, #3d3d3d);
    background-color: var(--kendo-color-surface-alt, #ffffff);
}

.k-listview-content > .k-focus {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.13);
}
.k-listview-content > .k-selected {
    background-color: color-mix(in srgb, var(--kendo-color-primary, rgb(44, 91, 204)) 25%, transparent);
}

.k-listview-item {
    border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}
